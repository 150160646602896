import React from 'react';
import styled from 'styled-components/macro';
import { UiComponentTypes } from '../../constants';
// eslint-disable-next-line max-len

const TypeLabel = styled.div`
  border-radius: 4px;
  font-weight: 500;
  padding: 5px 6px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #585858;
  ${({ selected }) => (selected
    ? `
  background-color:#FFEEBF;
  opacity:0.6;
  `
    : '  background: #FFFFFF;')}
`;

export const Type = ({ item }) => {
  if (UiComponentTypes.page[item.type]) return 'Page';
  if (UiComponentTypes.pdf[item.type]) return 'PDF';
  if (UiComponentTypes.audio[item.type]) return 'Audio';
  if (UiComponentTypes.video[item.type]) return 'Video';
  if (UiComponentTypes.image[item.type]) return 'Image';
  if (UiComponentTypes.component[item.type]) return 'Component';
  if (UiComponentTypes.archive[item.type]) return 'Archive';
  if (UiComponentTypes.link[item.type]) return 'Link';
  if (UiComponentTypes.xls[item.type] || UiComponentTypes.ms) return 'File';
  if (UiComponentTypes.google_embed_component[item.type]) return 'Google';
  if (UiComponentTypes.dropbox_embed_component[item.type]) return 'Dropbox';
  return 'Other';
};

const SharedShell = styled.div`
  background: #429df8;
  width: fit-content;
  border-radius: 4px;
  padding: 5px 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #ffffff;
  white-space: nowrap;
`;

export const TypeLabelComponent = ({ children, selected = false }) => (
  <TypeLabel selected={selected}>{children}</TypeLabel>
);
export const SharedByMe = () => <SharedShell>Shared</SharedShell>;
export const Shared = () => <SharedShell>Shared to me</SharedShell>;
