import React from 'react';

import {
  BoardColumnWrapper,
  TimeAndAvatar,
} from '../Inbox/styled';
import {
  AvailableBlockOutbox,
  CardInfoChannelOutbox,
  CardInfoHeaderInOutbox,
  CardInfoSizeOutbox,
  HeaderWrapperOutbox,
  InfoBlockOutbox,
} from './styled';

const OutboxHeader = () => {
  return (
    <HeaderWrapperOutbox>
      <CardInfoHeaderInOutbox>
        Title
      </CardInfoHeaderInOutbox>

      <CardInfoChannelOutbox>
        Recipient(s)
      </CardInfoChannelOutbox>

      <InfoBlockOutbox>
        Opened
      </InfoBlockOutbox>

      <CardInfoSizeOutbox isRight>
        Size
      </CardInfoSizeOutbox>

      <AvailableBlockOutbox isRight>
        Availability
      </AvailableBlockOutbox>

      <BoardColumnWrapper>
        Board
      </BoardColumnWrapper>

      <TimeAndAvatar>
        Modified / Sent
      </TimeAndAvatar>

    </HeaderWrapperOutbox>
  );
};

export default OutboxHeader;
