import React, { useEffect, useRef, useState } from 'react';
import styles from '../../pages/ManyPages/index.module.css';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { getFileExt, getFileName } from '../../utils/helpers';
import { DATA_PARENTS, UiComponentTypes } from '../../utils/constants';

export const TitleInput = ({
  title,
  renameCB,
  show,
  setShow,
  placeholder,
  isDisabled,
  ...props
}) => {
  const [initialTitle, setInitialTitle] = useState(title);
  const [tmpTitle, setTmpTitle] = useState(title);
  const [width, setWidth] = useState(165);
  const titleRef = useRef(null);

  useEffect(() => {
    const x = titleRef.current.getBoundingClientRect().width;
    let newWidth;
    if (x < 165) {
      newWidth = 165 - 1;
    } else if (x > 300) {
      newWidth = 300 + 7;
    } else newWidth = x;

    setWidth(newWidth + 12);
  }, [title]);

  useEffect(() => {
    setTmpTitle(title);
  }, [title]);

  useEffect(() => {
    if (show) {
      if (
        UiComponentTypes.component[props.itemType]
        || UiComponentTypes.link[props.itemType]
      ) {
        setTmpTitle(title);
      } else {
        setTmpTitle(getFileName(title));
      }

      setInitialTitle(title);
    }
  }, [show]);

  const onChanger = () => {
    const x = titleRef.current.getBoundingClientRect().width;
    let newWidth;
    if (x < 165) {
      newWidth = 165 - 1;
    } else if (x > 300) {
      newWidth = 300 + 7;
    } else newWidth = x;

    return newWidth + 12;
  };

  const onTitleClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setTmpTitle(getFileName(title));
    setInitialTitle(title);
    setShow(true);
  };

  const onChangeTitle = (e) => {
    if (isDisabled) return;
    if (!e.currentTarget.value && tmpTitle === getFileName(initialTitle)) setTmpTitle('');
    setTmpTitle(e.currentTarget.value);
    setWidth(onChanger());
    e.stopPropagation();
    e.preventDefault();
  };

  const onKeyPressTitle = (e) => {
    if (isDisabled) return;
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      if (
        UiComponentTypes.component[props.itemType]
        || UiComponentTypes.link[props.itemType]
      ) {
        setTmpTitle(`${tmpTitle || 'Unnamed component'}`);
        renameCB(`${tmpTitle || 'Unnamed component'}`);
      } else {
        setTmpTitle(`${tmpTitle || 'unnamed'}.${getFileExt(title)}`);
        renameCB(`${tmpTitle || 'unnamed'}.${getFileExt(title)}`);
      }

      setShow(false);
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      setShow('close');
      setTmpTitle(title || 'Unnamed collection');
    }
  };

  return (
    <div className={styles.component_title_input_wrapper}>
      <Tooltip
        text={title}
        direction="up"
        place="manyPagesHeader"
        isShow={
          !(show && show !== 'close')
          && titleRef?.current?.getBoundingClientRect()?.width > 390
        }
      >
        <div
          data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
          ref={titleRef}
          className={`${styles.component_title} 
              ${show && show !== 'close' ? styles.hide : ''}
              ${isDisabled ? styles.disabled : ''}
              ${props.place === 'metaSideBar' ? styles.metaSideBar : ''}
              `}
          onClick={onTitleClickHandler}
        >
          {show && show !== 'close'
            ? `${tmpTitle}.${getFileExt(title)}`
            : tmpTitle}
        </div>
      </Tooltip>

      {show && show !== 'close' && (
        <div className={styles.folderTitleInputWrapper} style={{ width }}>
          <input
            placeholder={placeholder}
            maxLength={55}
            value={tmpTitle}
            autoFocus
            onChange={onChangeTitle}
            onKeyDown={onKeyPressTitle}
            className={`${styles.input}`}
            onBlur={() => {
              if (
                UiComponentTypes.component[props.itemType]
                || UiComponentTypes.link[props.itemType]
              ) {
                setTmpTitle(`${tmpTitle || 'Unnamed component'}`);
                renameCB(`${tmpTitle || 'Unnamed component'}`);
              } else {
                setTmpTitle(`${tmpTitle || 'unnamed'}.${getFileExt(title)}`);
                renameCB(`${tmpTitle || 'unnamed'}.${getFileExt(title)}`);
              }
              setShow(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
