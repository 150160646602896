import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moduleStyles from '../../pages/Library/Library.module.scss';
import { actionCancelAllUploads } from '../../redux/filesUpload/action';
import { ReactComponent as FailureSvg } from '../../images/icons/upload_failed_20.svg';
import { ReactComponent as SuccessSvg } from '../../images/icons/upload_success_20.svg';
import { ReactComponent as UploadingSvg } from '../../images/icons/upload_20.svg';
import { ReactComponent as CloseSvg } from '../../images/icons/close.svg';

const styles = classNames.bind(moduleStyles);
const CancelUploadDialog = ({ parentRef, cancelUpload, continueUpload }) => {
  const { t } = useTranslation();
  const calculateVerticalPosition = () => {
    if (parentRef && parentRef.current) {
      const height = document.documentElement.clientHeight;
      const positionY = 57;
      const refAbsoluteY = parentRef.current.getBoundingClientRect().y;
      const heightElement = 205;
      const isOpenInDown = height > refAbsoluteY + heightElement + positionY;
      if (isOpenInDown) return positionY;
      return positionY - heightElement + 20;
    }
    return 0;
  };

  const divStyle = {
    top: calculateVerticalPosition(),
    // left: calculateHorizontalPosition(),
  };

  return (
    <div style={divStyle} className={styles('cancel_dialog')}>
      <div className={styles('text')}>
        <div className={styles('title_dialog')}>{t('cancelFilesUploadQuestionT')}</div>
        <div className={styles('message')}>{t('uploadingNotCompleteT')}</div>
      </div>
      <div className={styles('actions')}>
        <div className={styles('accept')} onClick={(e) => cancelUpload(e)}>
          {t('cancelUploadT')}
        </div>
        <div className={styles('decline')} onClick={(e) => continueUpload(e)}>
          {t('continueUploadT')}
        </div>
      </div>
    </div>
  );
};
const ProgressBar = ({ setIsAddFileMenuVisible }) => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [counter, setCounter] = useState(0);
  const [counterInProgress, setCounterInProgress] = useState(0);
  const [counterFailed, setCounterFailed] = useState(0);
  const [hasActiveUploads, setHasActiveUploads] = useState(false);
  const uploads = useSelector((state) => state.uploads.components);
  const [showCloseDialog, setShowCloseDialog] = useState(false);
  const closeButtonRef = useRef(null);
  const { folderId } = useSelector((state) => state.user);

  useEffect(() => {
    const itemsCounter = Object.keys(uploads).length;
    setCounter(itemsCounter);
    setHasActiveUploads(
      !!Object.values(uploads || {}).filter((i) => i.status !== 'READY').length,
    );

    if (itemsCounter) {
      setCounterFailed(
        Object.values(uploads || {}).filter((i) => i.status === 'ERROR').length,
      );
      setCounterInProgress(
        Object.values(uploads || {}).filter((i) => i.status !== 'UPLOADING')
          .length,
      );
      let sizeUpload = 0;
      let sizeAll = 0;
      Object.values(uploads || {})
        .filter((i) => !i.errorMsg)
        .forEach((uploadItem) => {
          sizeUpload
            += uploadItem.size
            * (progress <= 0.95 ? uploadItem.progress : 0.95 || 0);
          sizeAll += uploadItem.size;
        });
      setProgress(sizeUpload / sizeAll || 0);
    }
  }, [uploads]);

  const cancelAllUploads = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actionCancelAllUploads(folderId));
    setShowCloseDialog(false);
    setIsAddFileMenuVisible(false);
  };

  const ProgressLine = () => (
    <svg
      width="432"
      height="1"
      viewBox="0 0 432 1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="432" height="1" fill="#D7DDF7" />
      <rect width={progress * 432} height="1" fill="#0026E5" />
    </svg>
  );

  if (!counter) return <></>;
  if (progress === 1 || counter === counterFailed) {
    if (counter === counterFailed) {
      return (
        <div className={styles('uploading_bar_item')}>
          <FailureSvg /> Uploading {counterFailed} files failed
        </div>
      );
    }
    return (
      <>
        {!counterFailed && (
          <div className={styles('uploading_bar_item')}>
            <SuccessSvg /> Uploaded {counter - counterFailed} files
          </div>
        )}
        {!!counterFailed && (
          <>
            <div className={styles('uploading_bar_item')}>
              <SuccessSvg /> Uploaded {counter - counterFailed}/{counter} files
            </div>
            <div className={styles('uploading_bar_item')}>
              <FailureSvg /> Uploading {counterFailed} files failed
            </div>
          </>
        )}
      </>
    );
  }
  return (
    <div className={styles('uploading_bar')}>
      {/* eslint-disable-next-line react/jsx-no-undef */}
      {showCloseDialog && (
        <CancelUploadDialog
          parentRef={closeButtonRef}
          cancelUpload={cancelAllUploads}
          continueUpload={() => setShowCloseDialog(false)}
        />
      )}
      {hasActiveUploads && (
        <>
          <div className={styles('uploading_bar_progress')}>
            <div className={styles('uploading_bar_item')}>
              <UploadingSvg />
              <div>
                Uploading {counterInProgress}/{counter} files
              </div>
              <div className={styles('progress')}>
                {`  ${Math.round(progress * 100)}`}%
              </div>
            </div>
            <div
              className={styles('wrapper_close', { active: showCloseDialog })}
              onClick={() => setShowCloseDialog(!showCloseDialog)}
              ref={closeButtonRef}
            >
              <CloseSvg />
            </div>
          </div>
          <ProgressLine />
        </>
      )}
    </div>
  );
};
export default ProgressBar;
