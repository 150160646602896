import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SvgWrap,
  ViewModeSwitcherWrap,
  ViewOptionShell,
} from './sharedStyled';
import { NewUPVViewModes } from '../../utils/constants';
import { ReactComponent as ListSvg } from '../../images/icons/icon_18/view_list.svg';
import { ReactComponent as GridSvg } from '../../images/icons/icon_18/view_grid.svg';
import { ReactComponent as ExpandedSvg } from '../../images/icons/icon_18/expand.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';

const SwitchModeAndPublishBlock = ({
  viewMode,
  setViewMode,
  forCutHeader,
  isLargeRightBar,
  isShowLibraryWidget,
  moveLeft,
}) => {
  const { t } = useTranslation();
  const moved = moveLeft || isShowLibraryWidget;
  return (
    <ViewModeSwitcherWrap
      forCutHeader={forCutHeader}
      moved={moved}
    >
      {!isLargeRightBar && (
      <ViewOptionShell>
        <SvgWrap
          isActive={viewMode === NewUPVViewModes.compressed}
          isLeft
          onClick={() => setViewMode(NewUPVViewModes.compressed)}
        >
          {' '}
          <Tooltip
            text={t('listViewT')}
            direction={forCutHeader ? 'up' : 'down'}
          >
            <ListSvg />
          </Tooltip>
        </SvgWrap>

        <SvgWrap
          isActive={viewMode === NewUPVViewModes.card}
          onClick={() => setViewMode(NewUPVViewModes.card)}
        >
          {' '}
          <Tooltip
            text={t('cardViewT')}
            direction={forCutHeader ? 'up' : 'down'}
          >
            <GridSvg />
          </Tooltip>
        </SvgWrap>
        <SvgWrap
          isActive={viewMode === NewUPVViewModes.expanded}
          onClick={() => setViewMode(NewUPVViewModes.expanded)}
          isRight
        >
          {' '}
          <Tooltip
            text={t('expandViewT')}
            direction={forCutHeader ? 'up' : 'down'}
          >
            <ExpandedSvg />
          </Tooltip>
        </SvgWrap>
      </ViewOptionShell>
      )}
    </ViewModeSwitcherWrap>
  );
};

export default SwitchModeAndPublishBlock;
