import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import styles from './uiHelpers.module.scss';
import {
  empty,
  LibraryComponentTypes,
  UiComponentTypes,
} from '../../constants';
import { ReactComponent as ImageBadge48Svg } from '../../../images/icons/image_badge_48.svg';
import { ReactComponent as LinkBadge48Svg } from '../../../images/icons/link_badge_48.svg';
import { ReactComponent as MsBadge48Svg } from '../../../images/icons/ms_badge_48.svg';
import { ReactComponent as PptBadge48Svg } from '../../../images/icons/ppt_badge_48.svg';
import { ReactComponent as PdfBadge48Svg } from '../../../images/icons/pdf_badge_48.svg';
import { ReactComponent as PageBadge48Svg } from '../../../images/icons/pages_badge_48.svg';
import { ReactComponent as VideoBadge48Svg } from '../../../images/icons/video_badge_48.svg';
import { ReactComponent as YoutubeBadge48Svg } from '../../../images/icons/youtube_badge_48.svg';
import { ReactComponent as NoFileFormat56Svg } from '../../../images/icons/no_file_format_30_36.svg';
import { ReactComponent as AudioFormat57Svg } from '../../../images/2023/svg/upv/audio_waves_80_80.svg';
import { ReactComponent as Clock20Svg } from '../../../images/icons/clock_duration_20.svg';
import { ReactComponent as Alert32Svg } from '../../../images/icons/alert_32.svg';
import { ReactComponent as CircleCross32Svg } from '../../../images/icons/circle_cross_32.svg';
import { ReactComponent as UploadImg32Svg } from '../../../images/icons/image_placeholder_upload_32.svg';
import { ReactComponent as Archive36Svg } from '../../../images/icons/playlist_item_types/archive_30_36.svg';
import BlockCreator from '../../../components/LibraryMiniPage/BlockCreator';
import { loader } from '../../../components/MomentBlock/CustomBlocks/pdfBlock/PdfAsPreview';
import { ReactComponent as WordBigSvg } from '../../../images/icons/elements_word_no_preview_43-52.svg';
import { ReactComponent as BrokenImageSvg } from '../../../images/icons/broken_img_32.svg';
import { PlaylistHelper } from './typeResolvers';
import { Circle, progressBarStatus } from './libraryComponent';
import { ReactComponent as EmbedSvg } from '../../../images/icons/embed option_16.svg';
import { ReactComponent as GoogleDrive16Svg } from '../../../images/icons/google_drive_16.svg';
import { ReactComponent as Dropbox16Svg } from '../../../images/icons/dropbox_16.svg';
import { APPURL } from '../../../environments/environments';
import VideoPreview from './VideoPreview';
import { actionCancelUploadAndChangeLinkPageType } from '../../../redux/currentPage/action';

const cn = classNames.bind(styles);

const PDFPreviewImage = styled.img`
    border-radius: 8px;
    object-fit: contain;
    background: #F1F1F0;
    width: 100%;
`;

const IconPlaceholder48 = () => {
  return <div style={{ width: 48, height: 48 }} />;
};
const IconPlaceholderGreen48 = () => {
  return <div style={{ width: 48, height: 48, backgroundColor: 'green' }} />;
};
const PreviewFromBlocks = ({ data, isPlaylist, pageId, ...props }) => {
  return (
    <div
      className={cn('preview_frame', { for_expand_view: props.isExpandView, for_toc: props.isTOC })}
    >
      <div
        className={cn('preview_scale', { for_expand_view: props.isExpandView })}
      >
        <div className={cn('title')}> {data.title || 'Unnamed component'}</div>
        <div className={cn('description')}>{data.description || ''}</div>
        {data?.components?.length
          && data.components
            .sort((a, b) => a.position - b.position)
            .map((i) => (
              <BlockCreator
                key={`
            ${i.parentId}
            ${i.position || (i.components && i.components[0]?.position)}|
            ${i.id}
            ${pageId}`}
                item={i}
                isPlaylist
                isPreview
              />
            ))}
      </div>
    </div>
  );
};

const NOT_UPLOAD = 'NOT_UPLOAD';

const ImagePreview = (props) => {
  const onError = (e) => {
    e.target.onError = null;
    props.setError(true);
  };
  const urlForCoEditAfterUpload = props.data.isNotOwner && props.data.imagePath;

  const calcPath = props.imageLike ? props.data?.urlSmallImage : urlForCoEditAfterUpload
    || props.data?.urlVerySmallImage
    || props.data?.urlFile;

  return (
    <>
      {!props.error ? (
        <div className={cn('image_container')} draggable={false}>
          <img
            className={cn('image_preview', { toc_image: props.isTOC })}
            src={calcPath}
            onError={onError}
            onLoad={() => props.setError(false)}
            alt="image_preview"
            draggable={false}
            onDragStart={(e) => e.preventDefault()}
          />
        </div>
      ) : (
        <>
          <div className={cn('broken_image')}>
            <BrokenImageSvg />
          </div>
        </>
      )}
    </>
  );
};

const UploadWrapper = (props) => {
  const [data, setData] = useState(props.children.props.data);
  const componentData = useSelector(
    (state) => state.content.contentData[data.id],
  );
  const [error, setError] = useState(!data.urlFile);

  const checkUpload = (localData) => {
    return localData.isUpload ? { init: true } : NOT_UPLOAD;
  };
  const [localStatus, setLocalStatus] = useState(checkUpload(data));

  useEffect(() => {
    setData(props.children.props.data);
  }, [props.children.props.data]);

  useEffect(() => {
    if (localStatus.ready) {
      setLocalStatus(NOT_UPLOAD);
      setData((prev) => ({ ...prev, ...componentData }));
      setError(false);
    }

    if (componentData && componentData.isUpload) {
      setLocalStatus({ uploading: true });
    }
  }, [localStatus?.ready, componentData, props.children.props.data]);
  // useEffect(() => {
  //   if (componentData && data.isUpload) {
  //     setLocalStatus({ uploading: true });
  //   }
  // }, [componentData]);
  //
  // useEffect(() => {
  //   if (localStatus.ready) {
  //     setLocalStatus(NOT_UPLOAD);
  //     setData(prev => ({ ...prev, ...componentData }));
  //     setError(false);
  //   }
  // }, [localStatus]);

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        localStatus,
        error,
        setError,
        data,
      });
    }
    return child;
  });
  if (localStatus !== NOT_UPLOAD) {
    return (
      <>
        <UploadPreview
          item={data}
          localStatus={localStatus}
          setLocalStatus={setLocalStatus}
        />
      </>
    );
  }
  return <>{childrenWithProps}</>;
};

export const UploadPreview = ({ item, setLocalStatus, localStatus }) => {
  const { components: uploads } = useSelector((state) => state.uploads);
  const [itemUploadData, setItemUploadData] = useState(empty);

  useEffect(() => {
    if (item?.id) {
      const libraryId = item?.id;
      const dataObj = { ...uploads[libraryId] };
      setItemUploadData(dataObj || empty);
      if (Object.values(dataObj).length) {
        if (dataObj.status === 'ERROR') setLocalStatus({ error: true });
        else if (
          (dataObj.progress === 0 || dataObj.progress === 1)
          && dataObj.status === 'READY'
        ) {
          setLocalStatus({ ready: true });
        } else if (
          dataObj.progress !== 1
          && dataObj.progress !== 0
          && dataObj.status === 'UPLOADING'
        ) {
          setLocalStatus({ uploading: true });
        } else {
          setLocalStatus({ processing: true });
        }
      } else {
        setLocalStatus({ ready: true });
      }
    }
  }, [item, uploads]);

  return (
    <>
      {localStatus.init && (
        <div className={cn('uploading_image')}>
          <UploadImg32Svg />
        </div>
      )}
      {!localStatus.ready && (
        <>
          {localStatus.uploading && (
            <div className={cn('uploading_loader')}>
              <div className={cn('uploading_container')}>
                <Circle progress={100} color="#D7DDF7" />
                {progressBarStatus(
                  itemUploadData.progress,
                  itemUploadData.status,
                )}
              </div>
              <div className={cn('uploading_image')}>
                <UploadImg32Svg />
              </div>
            </div>
          )}
          {localStatus.processing && (
            <div className={cn('uploading_loader')}>
              <div className={cn('uploading_container')}>
                {progressBarStatus(
                  itemUploadData.progress,
                  itemUploadData.status,
                )}
                <div className={cn(styles.uploading_text)}>
                  {!itemUploadData.progress || itemUploadData.progress === 1
                    ? 'Processing'
                    : `${parseInt(itemUploadData.progress * 100)}%`}
                </div>
              </div>
            </div>
          )}
          {localStatus.error && <> error </>}
        </>
      )}
    </>
  );
};

const PdfPreview = ({ data, isTOC }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState({
    httpHeaders: {
      'Access-Control-Allow-Origin': `${APPURL}`,
      'Access-Control-Request-Headers': `${APPURL}`,
      'Cache-Control': 'no-cache',
    },
    url: data.pdfPreviewUrl || data.urlFile,
  });

  useEffect(() => {
    setFile({
      httpHeaders: {
        'Access-Control-Allow-Origin': `${APPURL}`,
        'Access-Control-Request-Headers': `${APPURL}`,
        'Cache-Control': 'no-cache',
      },
      url: data.pdfPreviewUrl || data.urlFile,
    });
  }, [data.pdfPreviewUrl, data.urlFile]);

  if (data.urlSmallImage) {
    return <PDFPreviewImage src={data.urlSmallImage} isTOC={isTOC} />;
  }

  return (
    <div className={cn('preview_frame', { for_toc: isTOC })}>
      <div className={cn('pdf_preview_container')}>
        <Document
          error={t('FailedToLoadAFileT')}
          file={file}
          loading={() => loader(t('downloadingFilePleaseWaitT'), true)}
          imageResourcesPath="../../../images/icons/"
        >
          <Page
            pageNumber={1}
            width={172}
            imageResourcesPath=""
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </div>
  );
};

const MsPreview = () => {
  return (
    <div className={cn('ms_doc_preview')}>
      <WordBigSvg />
    </div>
  );
};

const VideoAudioPreview = (props) => {
  const [data, setData] = useState(props.data);
  const componentData = useSelector(
    (state) => state.content.contentData[data.id],
  );

  const checkUpload = (localData) => {
    return localData.isUpload ? { init: true } : NOT_UPLOAD;
  };
  const [localStatus, setLocalStatus] = useState(checkUpload(data));

  useEffect(() => {
    if (data.isUpload) {
      setLocalStatus({ uploading: true });
    }
  }, [data]);

  useEffect(() => {
    if (localStatus.ready) {
      setLocalStatus(NOT_UPLOAD);
      setData((prev) => ({ ...prev, ...componentData }));
    }
  }, [localStatus, componentData]);
  if (localStatus === NOT_UPLOAD || localStatus.ready) {
    return (
      <div className={cn('video_preview', { for_toc: props.isTOC })}>
        <div className={cn('broken_image', { toc_video: props.isTOC })}>
          {props.isVideo && props?.data?.playbackID ? (
            <VideoPreview
              playbackID={props.data.playbackID}
              width={props.isTOC ? 186 : 94}
              height={props.isTOC ? 100 : 130}
              isTOC={props.isTOC}
            />
          ) : (
            <AudioFormat57Svg />
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      <UploadPreview
        item={data}
        localStatus={localStatus}
        setLocalStatus={setLocalStatus}
      />
      ;
    </>
  );
};

export const Badges = {
  Icon(type) {
    if (UiComponentTypes.component[type]) return <IconPlaceholderGreen48 />;
    if (type === LibraryComponentTypes.embed_component) return <EmbedSvg className={cn('big_icon')} />;
    if (type === LibraryComponentTypes.google_embed_component) {
      return <GoogleDrive16Svg className={cn('google_drive_icon')} />;
    }
    if (type === LibraryComponentTypes.dropbox_embed_component) {
      return <Dropbox16Svg className={cn('google_drive_icon')} />;
    }
    if (UiComponentTypes.link[type]) return <LinkBadge48Svg />;
    if (UiComponentTypes.pdf[type]) return <PdfBadge48Svg />;
    if (UiComponentTypes.media[type]) return <VideoBadge48Svg />;
    if (UiComponentTypes.presentation[type]) return <PptBadge48Svg />;
    if (UiComponentTypes.page[type]) return <PageBadge48Svg />;
    if (UiComponentTypes.image[type]) return <ImageBadge48Svg />;
    if (UiComponentTypes.ms[type]) return <MsBadge48Svg />;
    if (UiComponentTypes.youtube[type]) return <YoutubeBadge48Svg />;
    return <IconPlaceholder48 />;
  },
};

export const PlaylistUI = {
  createPreviewComponent(type, data, pageId, error, isExpandView = false, isTOC = false) {
    if (error) {
      return (
        <div className={cn('broken_image')}>
          <Alert32Svg />
        </div>
      );
    }
    if (UiComponentTypes.archive[type]) {
      return (
        <div className={cn('no_type_element_preview')}>
          <Archive36Svg />
        </div>
      );
    }
    if (UiComponentTypes.component[type]) return <PreviewFromBlocks data={data} pageId={pageId} />;
    if (type === LibraryComponentTypes.embed_component) {
      return (
        <div className={cn('embed_component_preview', { for_toc: isTOC, embed_for_toc: isTOC })}>
          <EmbedSvg />
        </div>
      );
    }
    if (UiComponentTypes.link[type]) {
      return (
        <UploadWrapper>
          <ImagePreview data={data} pageId={pageId} isTOC={isTOC} />
        </UploadWrapper>
      );
    }
    if (UiComponentTypes.pdf[type] || data?.pdfPreviewUrl) {
      return (
        <UploadWrapper>
          <PdfPreview data={data} isTOC={isTOC} />
        </UploadWrapper>
      );
    }
    if (UiComponentTypes.audio[type]) {
      return (
        <UploadWrapper>
          <VideoAudioPreview data={data} isTOC={isTOC} />
        </UploadWrapper>
      );
    }
    if (UiComponentTypes.media[type]) {
      return (
        <UploadWrapper>
          <VideoAudioPreview data={data} isVideo isTOC={isTOC} />
        </UploadWrapper>
      );
    }
    if (UiComponentTypes.presentation[type]) {
      return (
        <UploadWrapper>
          <ImagePreview data={data} pageId={pageId} isTOC={isTOC} />
        </UploadWrapper>
      );
    }
    if (UiComponentTypes.page[type]) {
      return (
        <PreviewFromBlocks
          isPlaylist
          data={data}
          pageId={pageId}
          isExpandView={isExpandView}
          isTOC={isTOC}
        />
      );
    }
    if (UiComponentTypes.image[type] || UiComponentTypes.imageLike[type]) {
      return (
        <UploadWrapper>
          <ImagePreview data={data} pageId={pageId} imageLike={UiComponentTypes.imageLike[type]} isTOC={isTOC} />
        </UploadWrapper>
      );
    }
    if (UiComponentTypes.ms[type]) return <MsPreview data={data} />;
    if (UiComponentTypes.youtube[type]) return <VideoAudioPreview data={data} isVideo isTOC={isTOC} />;
    return (
      <div className={cn('no_type_element_preview')}>
        <NoFileFormat56Svg />
      </div>
    );
  },
  cancelUploadButton(item, linkPage) {
    const dispatch = useDispatch();
    const { id } = useSelector((state) => state.currentPage);
    const componentData = useSelector(
      (state) => state.content.contentData[item.id],
    );
    const uploadData = useSelector(
      (state) => state.uploads.components[item.id],
    );
    if (componentData?.successUpload && !uploadData?.errorMsg) {
      return Badges.Icon(item?.type);
    }
    return (
      <div
        className={cn('circle_cancel_container')}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(
            actionCancelUploadAndChangeLinkPageType(linkPage.id, id, item.id),
          );
        }}
      >
        <CircleCross32Svg />
      </div>
    );
  },
  createHintComponent(item = {}, error) {
    const componentData = useSelector(
      (state) => state.content.contentData[item.id],
    );
    if (!item?.type) return <></>;
    if (error) return <div className={cn('text_limiter_error')}>{error}</div>;
    if (componentData?.successUpload) {
      return (
        <div className={cn('text_limiter')}>
          {PlaylistHelper.hintSelector(componentData)}
        </div>
      );
    }
    if (item?.isUpload) {
      return (
        <div className={cn('text_limiter')}>
          {PlaylistHelper.hintSelector(componentData)}
        </div>
      );
    }
    if (
      UiComponentTypes.pdf[item?.type]
      || UiComponentTypes.ms[item?.type]
      || UiComponentTypes.page[item?.type]
    ) {
      return (
        <div className={cn('duration_container')}>
          <Clock20Svg />
          <div className={cn('text_limiter')}>
            {PlaylistHelper.hintSelector(componentData) || '5 min'}
          </div>
        </div>
      );
    }
    return (
      <div className={cn('text_limiter')}>
        {PlaylistHelper.hintSelector(item)}
      </div>
    );
  },
};
