import React from 'react';
import { CutModeImageAndNameWrapper, ImgWrap, Title } from './styled';
import FolderImage from './FolderImage';
import FolderIconLayout from './FolderIconLayout';

const ImageAndName = React.memo(
  function ImageAndName({ src, title, isWithProgressBar, small, color, moveLeft, isShowLibraryWidget, itemId }) {
    return (
      <CutModeImageAndNameWrapper
        small={small}
        isWithProgressBar={isWithProgressBar}
      >
        {src ? (
          <>
            <ImgWrap src={src} />
            <FolderIconLayout isOverCoverImage />
          </>
        ) : (
          <FolderImage itemId={itemId} isSmall currentColor={color} />
        )}
        <Title
          small={small}
          moveLeft={moveLeft}
          isShowLibraryWidget={isShowLibraryWidget}
          isWithProgressBar={isWithProgressBar}
        >
          {title || 'Unnamed SmartFile'}
        </Title>
      </CutModeImageAndNameWrapper>
    );
  });

export default ImageAndName;
