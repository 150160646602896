import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LoadingScreen,
  MainShell,
} from '../../CommonComponents/Layout';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import WorkspacePlans from './WorkspacePlans';

const WorkspaceUpgrade = () => {
  const { isLoaded } = useSelector((state) => state.settings);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(actionCreator(Settings.DataIsLoaded));
  }, []);

  return (
    <MainShell>
      {!isLoaded && (
        <LoadingScreen>
          <div className="loader-wrapper-page">
            <div className="loader">Loading...</div>
          </div>
        </LoadingScreen>
      )}
      <WorkspacePlans />
    </MainShell>
  );
};

export default WorkspaceUpgrade;
