import React from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { BlueButton, GreyButton } from '../../CommonComponents/Buttons';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { Settings } from '../../../../redux/settings/types';
import ValidationResultsPrinter from '../../CommonComponents/ValidationResultsPrinter';

const EmojiNameInput = () => {
  const { inputs } = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isShowError = !!inputs?.emojiName?.errors?.length;

  const onResetClick = () => {
    dispatch(actionCreator(Settings.Preferences.ResetUploadEmojiDataR));
  };

  const onSaveClick = () => {
    dispatch(actionCreator(Settings.Preferences.SaveEmojiFileS));
  };

  const onInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSaveClick();
    }
  };

  const onBlur = () => {
    dispatch(
      actionCreator(Settings.ValidateInputS, { inputType: 'emojiName' }),
    );
  };

  const onInputChange = (event) => {
    dispatch(
      actionCreator(Settings.Profile.ChangeValue, {
        inputType: 'emojiName',
        inputValue: event.target.value,
      }),
    );
  };

  return (
    <div className={cn(styles.input_container)}>
      <div className={cn(styles.input_border)}>
        <input
          type="text"
          className={cn(styles.input)}
          placeholder={t('emojiNamePlaceholderT')}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          value={inputs.emojiName.value}
          onBlur={onBlur}
        />
      </div>

      <div className={cn(styles.button_wrapper)}>
        <GreyButton
          text={t('resetNameT')}
          customStyles={{ height: 32, width: 56 }}
          onClick={onResetClick}
          secondaryGrey
        />
        <GreyButton
          text={t('saveNameT')}
          customStyles={{ width: 88 }}
          onClick={onSaveClick}
          primaryGrey
        />
      </div>

      <ValidationResultsPrinter
        isShowError={isShowError}
        errorMessage={inputs?.emojiName?.errors[0]?.message}
        isEmojiNameInput
      />
    </div>
  );
};

export default EmojiNameInput;
