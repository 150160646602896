import { call, put, takeEvery } from 'redux-saga/effects';
import { all, select } from '@redux-saga/core/effects';
import { v4 as uuidv4 } from 'uuid';
import { Channels as ChannelsTypes, Channels } from '../redux/channels/types';
import {
  currentPage,
  getChannels,
  getHelp,
  getMyChannels,
  getUser,
  showErrorMessage,
} from './sagasUtils';
import {
  request,
  requestAddSubscribe,
  requestChannelRearrange,
  requestChannelRoleChange,
  requestCheckUser,
  requestConfirmSubscribe,
  requestCreateChannels,
  requestGetChannels,
  requestGetHelpPlaylists,
  requestGetSomeChannelContent,
  requestLeaveChannels,
  requestMatrixData,
  requestPlaylistMetadata,
  requestRemovePlaylistFromChannel,
  requestUpdateChannels,
} from '../utils/request';
import {
  actionAddChannelR,
  actionAddSubscribeR,
  actionDeleteChannelR,
  actionSaveChannel,
  actionSetCurrentChannel,
  actionUpdateChannelR,
} from '../redux/channels/action';
import { actionSwitchFolder } from '../redux/user/action';
import downloadStatus from '../utils/dataUtil';
import {
  actionSaveRedirect,
  actionShowMessage,
  actionToggleRequestSpinner,
} from '../redux/support/action';
import {
  ChannelsSieveTypes,
  DEFAULT_PAGE_PATH,
  DEFAULT_TITLE,
  DELETE_CHANNEL,
  ITEM,
  MessageType,
  METHOD,
  PLACE,
  REMOVE_LIBRARY_COMPONENT_FROM_CHANNEL,
  TOGGLE_CHANNEL_PIN,
} from '../utils/constants';
import { deletePlaylist } from './playlistSagas';
import {
  calculateIndex,
  derivePositionFromName,
  sanitizeToLoad,
  sanitizeToSave,
} from '../utils/helpers';
import { CHANNEL_PERMISSIONS, HELP_PERMISSIONS } from '../utils/permissions';
import { actionChangeSieveValue } from '../redux/library/actions';
import { actionCreator } from '../shared/redux/actionHelper';
import {
  actionDragPage,
  actionPageWillDownload,
} from '../redux/currentPage/action';
import { HelpActions } from '../redux/help/types';
import { ContentActionType } from '../redux/content/contentTypes';
import Contacts from '../redux/contacts/types';
import { Category } from '../utils/dataConst';
import { savedValue } from '../utils/query/converter';
import { actionInitialContactsLoadR } from '../redux/contacts/action';
import i18n from '../i18n';

function* CreateChannel(action) {
  try {
    const user = yield select(getUser);
    const channelIdNew = uuidv4();
    const { channelID, title = 'Unnamed channel', callback } = action.payload;
    const channelId = channelID || channelIdNew;
    const { myChannels } = yield select(getChannels);
    const position = Object.values(myChannels || {}).sort(
      (a, b) => a.position - b.position,
    )[0]?.position;
    const newPosition = calculateIndex(null, position);
    yield put(actionAddChannelR(channelId, user, true, newPosition, title));

    yield call(requestCreateChannels, {
      id: channelId,
      userId: user.id,
      name: sanitizeToSave(title),
      position: newPosition,
      createDate: Math.floor(Date.now() / 1000),
      category: Object.values(Category)[0],
    });
    if (callback) yield callback();
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* GetUserChannels(action) {
  try {
    const {
      data,
    } = yield call(requestGetChannels());
    const user = data.User[0];

    const channels = {};
    if (user.channelSubscriptionConnection?.edges?.length) {
      const channelsWithOwner = {};

      user.channelSubscriptionConnection.edges.forEach((channel) => {
        const channelOwner = channel?.node?.owner;
        channelsWithOwner[channel?.node?.id] = channelOwner.showUsername && channelOwner?.username
          ? `${channelOwner?.username}`
          : `${channelOwner?.first_name} ${channelOwner?.last_name}`;
      });

      user.channelSubscriptionConnection.edges?.forEach((channel) => {
        const channelOwner = channel?.node?.owner;
        const channelName = sanitizeToLoad(channel.node?.name);
        const derivedPosition = derivePositionFromName(channelName) * 100;
        const owner = {
          ...channelOwner,
          name: channelsWithOwner[channel?.node?.id],
        };
        channels[channel?.node?.id] = {
          ...channel,
          id: channel?.node?.id,
          name: channelName,
          role:
            channel.role.toLowerCase() === 'viewer'
              ? 'Viewer'
              : channel.role.toLowerCase(),
          permissions:
            CHANNEL_PERMISSIONS[
              channel.role.toLowerCase() === 'viewer'
                ? 'Viewer'
                : channel.role.toLowerCase()
            ],
          status: channel.status,
          isShared: true,
          isCanPublish: false,
          owner,
          playlistsCounter: channel.node?.playlistsCounter,
          position: channel.position ? channel.position : derivedPosition,
        };
      });
    }
    yield put(actionCreator(Channels.UploadUserChannelsR, channels));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* CheckUserHelpRights() {
  const { isAdmin } = yield select(getUser);
  const role = isAdmin ? 'admin' : 'viewer';
  return { permissions: HELP_PERMISSIONS[role], role };
}

function* FetchChannelObserverRole(action) {
  try {
    const { channelId } = action.payload;
    const { data } = yield requestGetSomeChannelContent(['role', channelId]);
    if (data?.Channel?.length) {
      const [channel] = data?.Channel;
      const [sub] = channel.channelSubscriptionConnection.edges;
      const trace = yield select((state) => state.historyTrace.trace);
      const isMaker = trace.length && trace[trace.length - 1]?.startsWith('/maker/');
      if (!isMaker) {
        yield put(
          actionChangeSieveValue(
            ChannelsSieveTypes.ChannelsAll,
            PLACE.Channels,
          ),
        );
      }
      yield put(
        actionUpdateChannelR({
          id: channelId,
          role: sub.role,
          permissions: CHANNEL_PERMISSIONS[sub.role],
        }),
      );
      return {
        id: channelId,
        role: sub.role,
        permissions: CHANNEL_PERMISSIONS[sub.role],
      };
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* getChannelData(action) {
  try {
    const {
      payload: { channelId },
    } = action;
    const user = yield select(getUser);
    const { myChannels } = yield select((state) => state.channels);
    const { permissions, role: userRole } = yield FetchChannelObserverRole({
      payload: {
        channelId,
        userId: user.id,
        isShowNotification: true,
      },
    });
    const isAdmin = myChannels[channelId]?.isCanPublish;
    const { data } = yield requestGetSomeChannelContent(['data', channelId]);
    const [channel] = data.Channel;
    if (!channel) return;
    const totalSubs = channel.subscribers + channel.subscribersInvited;
    const updateChannel = {
      id: channel.id,
      ...channel,
      name: sanitizeToLoad(channel.name),
      totalSubs,
      description: sanitizeToLoad(channel.description),
      isPrivate: channel.isPrivate,
      category: channel.category,
      playlistsCounter: channel.playlistsCounter,
      isShared: channel.owner[0]?.id !== user.id,
      isCanPublish: isAdmin,
      permissions,
      role: userRole,
      readyStatus: 'SUCCESS',
      owner: {
        id: channel.owner[0]?.id,
        name: `${channel.owner[0]?.first_name} ${channel.owner[0]?.last_name}`,
        email: channel.owner[0]?.email,
        firstName: channel.owner[0]?.first_name,
        lastName: channel.owner[0]?.last_name,
        avatarUrlVerySmall: channel.owner[0]?.avatarUrlVerySmall,
        avatarUrlSmall: channel.owner[0]?.avatarUrlSmall,
        phone: channel.owner[0]?.phone,
        username: sanitizeToLoad(channel.owner[0]?.username),
        showUsername: sanitizeToLoad(channel.owner[0]?.showUsername),
        company: channel.owner[0]?.company,
        companyPosition: channel.owner[0]?.companyPosition,
        displayImage: channel.owner[0]?.displayImage ?? 'AvatarImage',
      },
    };

    yield put(actionSaveChannel(updateChannel, channel.id));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* ConfirmSubscribe(action) {
  try {
    const {
      payload: { key, token, history, confirmUrl },
    } = action;
    if (!token) {
      const { data } = yield call(requestCheckUser, { key, token });
      if (data.msg === 'user exist') {
        yield put(actionSaveRedirect(confirmUrl));
        yield put(
          actionShowMessage({
            type: MessageType.Invite_NeedToLogin,
            effects: 'shake',
          }),
        );
        history.push('/login');
      } else {
        yield put(actionSaveRedirect(confirmUrl));
        yield put(
          actionShowMessage({
            type: MessageType.Invite_NeedToRegister,
            effects: 'shake',
          }),
        );
        history.push('/signup');
      }

      return;
    }
    try {
      const { data } = yield call(requestConfirmSubscribe, { key, token });
      if (data.message === 'already' || data.message === 'ok') {
        if (data.data?.id) {
          yield getChannelData({ payload: { channelId: data.data?.id } });
          yield put(actionSwitchFolder('folder', data.data?.id));
          yield put(
            actionUpdateChannelR({
              id: data.data?.id,
              role: data.data?.role,
              permissions: CHANNEL_PERMISSIONS[data.data.role],
            }),
          );
          history.push(`/channel/${data.data?.id}`);
        }
      }
    } catch (e) {
      const userClickInsideInApp = history.action !== 'POP';
      const message = 'This channel is not available !';
      if (userClickInsideInApp) {
        history.push('/inbox');
        yield put(actionShowMessage({
          type: MessageType.ErrorRegular,
          text: message,
        }));
        return;
      }
      if (e.response?.data?.msg === 'not you link') {
        yield put(actionSaveRedirect(confirmUrl));
        history.push('/channel_forbidden');
        return;
      }
      if (e.response?.data?.msg === 'invite canceled') {
        history.push(DEFAULT_PAGE_PATH);
        yield put(actionShowMessage({ type: MessageType.InvitationIsExpired }));
        return;
      }
      history.push('/login');
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* AddSubscribers(action) {
  try {
    const {
      payload: { emailsToInvite, channelId },
    } = action;
    const user = yield select(getUser);

    const {
      data: {
        data: { data },
        newContact,
      },
    } = yield call(requestAddSubscribe, {
      emailsToInvite: emailsToInvite.map((item) => (typeof item === 'string' ? item : item.email),
      ),
      channelId,
    });
    const newContactMapEmail = {};
    newContact.forEach((i) => (newContactMapEmail[i.email] = true));
    if (data) {
      const addedUsers = {};

      Object.values(data || {}).forEach((userResp) => {
        if (userResp?.email) {
          addedUsers[userResp.id] = {
            email: userResp.email,
            id: userResp.id,
            name: userResp.email,
            role: 'Viewer',
            status: 'Invited',
            organization: userResp.organization,
            userFunction: userResp.function,
            invitedBy: user.email,
            isTemporaryUser: newContactMapEmail[userResp.email],
          };
        }
        if (userResp?.to?.email) {
          addedUsers[userResp.to.id] = {
            email: userResp.to.email,
            id: userResp.to.id,
            name: userResp.to.first_name
              ? `${userResp.to.first_name} ${userResp.to.last_name}`
              : userResp?.to?.email,
            role: 'Viewer',
            status: 'Invited',
            invitedBy: 'You',
            organization: userResp.organization,
            userFunction: userResp.function,
            isTemporaryUser: newContactMapEmail[userResp.to.email],
          };
        }
      });
      yield put(actionAddSubscribeR(addedUsers, channelId));
    }
    yield put(
      actionShowMessage({
        type: MessageType.InviteUserToChannel,
      }),
    );
    yield put(actionCreator(ContentActionType.updateCounterS, {}));
    const { activeNavSlider, selectorType } = yield select((state) => state.content);
    yield put(
      actionCreator(ContentActionType.startUpload, { isNeedUpdate: true, activeNavSlider, selectorType }),
    );
    yield all(
      newContact.map((contact) => {
        return put(
          actionCreator(Contacts.GetContactS, {
            id: contact.id,
          }),
        );
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* deleteChannel(action) {
  try {
    const {
      payload: { channelId, nextChannel, history },
    } = action;
    const { myChannels } = yield select((state) => state.channels);
    yield call(request(DELETE_CHANNEL(channelId), METHOD.DELETE));
    const channels = Object.values(myChannels || {})
      .filter(channel => !channel.isUnpin);
    if (!channels.length) {
      history.push(DEFAULT_PAGE_PATH);
      return;
    }
    if (!nextChannel) {
      history.push(`/channel/${channels[0]?.id}`);
    }
    const newId = nextChannel ?? channels[0]?.id;
    yield put(actionSetCurrentChannel({ id: newId }));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

//
// function* toggleFavoritePageInChannel(action) {
//   try {
//     const { payload: { pageId, isFavorite } } = action;
//     const user = yield select(getUser);
//     if (isFavorite) {
//       yield call(requestNeo4j, {
//         query: queryAddFavorite(user.id, pageId),
//       });
//     } else {
//       yield call(requestNeo4j, {
//         query: queryRemoveFavorite(user.id, pageId),
//       });
//     }
//   } catch (e) {
//     yield showErrorMessage(e, action);
//   }
// }

function* userLeaveChannel(action) {
  try {
    const { channelId, channelName, nextItem, history } = action.payload;
    const { myChannels } = yield select(getChannels);
    yield put(actionDeleteChannelR(channelId));
    const channels = Object.values(myChannels || {}).filter(channel => !channel.isUnpin);
    if (!channels.length) history.push(DEFAULT_PAGE_PATH);
    if (!nextItem?.id) {
      history.push(`/channel/${channels[0]?.id}`);
    }
    const newId = nextItem?.id ?? channels[0]?.id;
    yield put(actionSetCurrentChannel({ id: newId }));
    yield call(requestLeaveChannels, {
      id: channelId,
    });
    if (channelName) {
      yield put(
        actionShowMessage({ type: MessageType.LeaveChannel, channelName }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* renameChannel(action) {
  try {
    const {
      payload: { channelId, name },
    } = action;
    yield call(requestUpdateChannels, {
      id: channelId,
      fieldsUpdateObg: {
        name: savedValue(sanitizeToSave(name)),
      },
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* ChangeMemberRole(action) {
  try {
    const {
      payload: { itemId, newRole, channelId },
    } = action;
    const user = yield select(getUser);

    yield put(actionToggleRequestSpinner(itemId));
    let response;
    try {
      response = yield call(requestChannelRoleChange, {
        channelId,
        data: { userId: itemId, role: newRole },
      });
    } catch (err) {
      if (err.response.status === 403) {
        yield FetchChannelObserverRole({
          payload: { channelId, userId: user.id },
        });
      }
      yield put(actionToggleRequestSpinner(false));
      yield put(
        actionShowMessage({
          type: MessageType.RoleNotChanged,
        }),
      );
      return;
    }
    if (response.status === 200) {
      const user = { id: itemId, role: newRole };
      yield put(actionCreator(Channels.UpdateChannelChangeUserR, { user }));
      yield put(actionToggleRequestSpinner(false));
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* RemoveContentInSharedChannel(action) {
  try {
    const { contentId, type, moveCallback } = action.payload;
    const { folderId } = yield select(getUser);
    const { myChannels } = yield select(getChannels);

    if (type === ITEM.LibraryPage) {
      yield call(
        request(
          REMOVE_LIBRARY_COMPONENT_FROM_CHANNEL({
            channelId: folderId,
            contentId,
          }),
          METHOD.DELETE,
        ),
        { channelId: folderId, contentId },
      );
      const libraryComponents = { ...myChannels[folderId]?.libraryComponents };
      delete libraryComponents[contentId];
      yield put(actionUpdateChannelR({ id: folderId, libraryComponents }));
    } else if (type === ITEM.Playlist) {
      yield put(
        actionCreator(ContentActionType.updatePlaylistR, {
          idRemove: contentId,
        }),
      );
      yield call(requestRemovePlaylistFromChannel(folderId, contentId));
      yield put(actionCreator(ChannelsTypes.UpdatePlaylistsCountersS));
      if (moveCallback) moveCallback();
    }
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* RemovePlaylistFromHelp(action) {
  try {
    const { contentId } = action.payload;
    const { playlists } = yield select(getHelp);
    yield deletePlaylist({ payload: { id: contentId, isPublish: true } });
    const newPlaylists = { ...playlists };
    delete newPlaylists[contentId];

    yield put(
      actionCreator(HelpActions.UpdateHelpPlaylistsR, {
        playlists: newPlaylists,
      }),
    );
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* UpdateChannelsCounters(action) {
  try {
    const myChannels = yield select(getMyChannels);
    const idsArray = Object.values(myChannels || {}).map((item) => item.id);
    if (!idsArray.length) return;
    const {
      data: { Pairs },
    } = yield requestGetSomeChannelContent(['counters', idsArray.join('_')]);
    yield put(
      actionCreator(Channels.UpdatePlaylistsCountersR, { channelsMap: Pairs }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* GetPlaylistMatrixData(action) {
  try {
    const {
      payload: { playlistId, playlistManagerId },
    } = action;

    const { folderId: channelId } = yield select(getUser);
    let data;
    try {
      data = yield call(requestMatrixData, [
        channelId,
        playlistManagerId,
        playlistId,
      ]);
      const matrixValue = data.data;
      yield put(
        actionCreator(Channels.SetPlaylistMatrixDataR, {
          playlistId,
          channelId,
          matrixValue,
        }),
      );
    } catch (err) {
      yield put(
        actionCreator(Channels.SetPlaylistMatrixDataR, {
          playlistId,
          channelId,
          matrixValue: null,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* RearrangeChannelItem(action) {
  try {
    const { nextItem, currentItem } = action.payload;
    const { idDragPage } = yield select(currentPage);
    const { myChannels } = yield select(getChannels);

    const position = calculateIndex(currentItem?.position, nextItem?.position);
    const subscription = {
      position,
      status: myChannels[idDragPage].status,
      role: myChannels[idDragPage].role,
    };

    yield put(
      actionCreator(Channels.ChangeChannelItemPositionR, {
        id: idDragPage,
        newPosition: subscription.position,
      }),
    );

    yield call(requestChannelRearrange, { subscription, idDragPage });
    yield put(actionDragPage(null, null));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

const prepareRawPlaylist = (
  PlaylistManager,
  permissions,
  role,
  playlistsWithProgress,
  userId,
) => {
  const playlist = PlaylistManager?.editPlaylist;
  const owner = {
    ...PlaylistManager.users[0],
    role,
    name: `${PlaylistManager.users[0]?.first_name} ${PlaylistManager.users[0]?.last_name}`,
  };
  if (!playlist || PlaylistManager.movedToTrash) return;

  const { img } = playlist;
  const isPlaylistFinished = !!PlaylistManager?.readByUsers?.length;
  const isHide = !!PlaylistManager?.hideByUsers?.length;
  const personalProgress = playlistsWithProgress[playlist.id]
    ? (100 / playlistsWithProgress[playlist.id].totalLinkPages)
      * playlistsWithProgress[playlist.id].readLinkPages
    : 0;
  const derivedPosition = derivePositionFromName(playlist.title || i18n.t(DEFAULT_TITLE.Playlist)) * 100;

  return {
    ...playlist,
    status: downloadStatus.success,
    img: img?.edges && img?.edges[0]?.node?.urlFile,
    position: img?.edges && img?.edges[0]?.position,
    isPublish: true,
    totalSizeInBytes: PlaylistManager.totalSizeInBytes,
    title: sanitizeToLoad(playlist.title),
    editablePlayListId: PlaylistManager.editPlaylist?.id,
    playlistManagerId: PlaylistManager.id,
    isFavorite: !!PlaylistManager.favorites.length,
    owner,
    isHide,
    isOwnContent: owner.id === userId,
    finished: { current: 12, total: 250 },
    personalProgress: isPlaylistFinished ? 100 : personalProgress,
    commentsNumber: 327,
    inColumn: PlaylistManager.card[0]?.column,
    cardId: PlaylistManager.card[0]?.id,
    playlistPosition: PlaylistManager.position || derivedPosition,
  };
};

function* GetHelpChannel(action) {
  try {
    const order = action.payload;
    yield put(actionCreator(HelpActions.UpdateHelp, { isDownload: true }));
    const { id: userId } = yield select(getUser);
    const {
      data: { HelpChannel, playlistsWithProgress },
    } = yield call(requestGetHelpPlaylists(order));
    const { role, permissons } = CheckUserHelpRights();
    const playlists = HelpChannel?.playlistManager.reduce((acc, item) => {
      acc[item?.editPlaylist?.id] = prepareRawPlaylist(
        item,
        permissons,
        role,
        playlistsWithProgress,
        userId,
      );
      return acc;
    }, {}) || {};

    yield put(actionCreator(HelpActions.UpdateHelp, { playlists }));
    yield put(actionPageWillDownload(true));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* CheckHasPin(action) {
  try {
    const { modalWarningCallback, confirmCallback } = action.payload;
    const { myChannels } = yield select((state) => state.channels);
    const isHasSomeUpPinContact = !!Object.values(myChannels).find(i => i.isUnpin);
    if (!isHasSomeUpPinContact) modalWarningCallback();
    else confirmCallback();
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* UpdatePinState(action) {
  try {
    const item = action.payload;
    yield call(request(TOGGLE_CHANNEL_PIN(item.id), METHOD.PUT), { state: !item.isUnpin });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* GetPlaylistButtonsStatesS(action) {
  try {
    const {
      payload: { playlistManagerId, channelId, playlistId, playlistIdStatus },
    } = action;

    let data;
    try {
      data = yield call(requestPlaylistMetadata, [
        channelId,
        playlistManagerId,
      ]);
      const buttonsState = data.data;
      yield put(
        actionCreator(Channels.SetPlaylistButtonsStatesR, {
          playlistId,
          playlistIdStatus,
          channelId,
          buttonsState,
        }),
      );
    } catch (err) {
      yield put(
        actionCreator(Channels.SetPlaylistButtonsStatesR, {
          playlistId,
          channelId,
          playlistIdStatus,
          buttonsState: null,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

export default function* channelSaga() {
  yield takeEvery(Channels.CreateChannel, CreateChannel);
  yield takeEvery(Channels.GetUserChannels, GetUserChannels);
  yield takeEvery(Channels.ConfirmSubscribe, ConfirmSubscribe);
  yield takeEvery(Channels.AddSubscribers, AddSubscribers);
  yield takeEvery(Channels.DeleteChannel, deleteChannel);
  yield takeEvery(Channels.RenameChannel, renameChannel);
  yield takeEvery(Channels.getChannelData, getChannelData);
  // yield takeEvery(EditPage.ToggleFavoritePageInChannel, toggleFavoritePageInChannel);
  yield takeEvery(Channels.UserLeaveChannel, userLeaveChannel);
  yield takeEvery(Channels.ChangeMemberRole, ChangeMemberRole);
  yield takeEvery(Channels.FetchChannelObserverRole, FetchChannelObserverRole);
  yield takeEvery(
    Channels.RemoveContentInSharedChannel,
    RemoveContentInSharedChannel,
  );
  yield takeEvery(Channels.UpdatePlaylistsCountersS, UpdateChannelsCounters);
  yield takeEvery(Channels.GetPlaylistMatrixDataS, GetPlaylistMatrixData);
  yield takeEvery(Channels.RearrangeChannelItemS, RearrangeChannelItem);
  yield takeEvery(Channels.GetHelpChannel, GetHelpChannel);
  yield takeEvery(HelpActions.RemoveContentFromHelp, RemovePlaylistFromHelp);
  yield takeEvery(Channels.UpdatePinState, UpdatePinState);
  yield takeEvery(Channels.CheckHasPin, CheckHasPin);
  yield takeEvery(
    Channels.GetPlaylistButtonsStatesS,
    GetPlaylistButtonsStatesS,
  );
}
