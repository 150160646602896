import React from 'react';
import classNames from 'classnames/bind';
import styled from 'styled-components/macro';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './index.scss';
import { ReactComponent as CheckedSVG } from '../../images/2023/svg/small/upv/checked_bird_16_15_thin.svg';
import { ReactComponent as ExpandSVG } from '../../images/2023/svg/small/upv/expand.svg';

import { isRoleInPlaylist } from '../../utils/permissions';
import LockButton from '../../pages/Maker/MakerCommon/LockButton';
import { actionUpdateReadState } from '../../redux/playlists/action';
import { ReactComponent as DownloadSvg } from '../../images/icons/icon_18/download.svg';
import { ReactComponent as PlusSvg } from '../../images/icons/icon_18/duplicate.svg';
import Tooltip from '../../shared/Tooltips/Tooltip';
import { axiosAbortarium } from '../../utils/axiosAbortarium';
import { actionShowMessage } from '../../redux/support/action';
import { Group, MessageType, stopPropagation } from '../../utils/constants';
import { actionCreator } from '../../shared/redux/actionHelper';
import Playlist from '../../redux/playlists/types';
import { getItemName } from '../../utils/helpers';

const Separator = styled.div`
  height: 18px;
  width: 1px;
  margin: 0 4px;
  background: rgba(29, 29, 29, 0.08);
`;
const cn = classNames.bind(rawStyles);

const OptionsLinkPage = ({
  linkPage,
  setIsThreeDotsActive,
  isExpandedView,
  handleOpen,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const libraryComponentId = linkPage?.libraryComponent?.id
    || linkPage?.textComponent?.id;

  const currentPage = useSelector((state) => state.currentPage);
  const { id: userId } = useSelector((state) => state.user);
  const { currentRole } = currentPage;
  const isOwner = userId === linkPage?.owner?.id;
  const { isViewer } = isRoleInPlaylist;

  const isDownloadDisabled = !isOwner && linkPage.isRemixLocked;
  const { isOwner: isOwnerPlaylist } = isRoleInPlaylist;
  const playlistOwner = isOwnerPlaylist[currentRole];
  const onDownloadButtonClickHandler = (e) => {
    e.stopPropagation();

    if (linkPage.isRemixLocked && isDownloadDisabled) {
      return;
    }
    axiosAbortarium.generateNew(libraryComponentId);
    dispatch(
      actionShowMessage({
        type: MessageType.DownloadOneItem,
        itemName: 'playlist',
        mode: Group.processing,
        id: libraryComponentId,
      }),
    );

    dispatch(
      actionCreator(Playlist.DownloadOneElement, {
        elementId: libraryComponentId,
        elementTitle: linkPage?.title || linkPage?.libraryComponent?.title,
        elementType: linkPage?.libraryComponent?.type || linkPage?.type,
        isText: !!linkPage.textComponent?.id,
        text: getItemName(linkPage),
        owner: linkPage.owner || { id: userId },
      }),
    );
  };
  const toggleView = (e) => {
    e.stopPropagation();
    if (isOwner) return;
    dispatch(actionUpdateReadState(linkPage?.mainUserInterface?.id, linkPage.isViewed, linkPage.id));
  };

  const isLockDisabled = isViewer[currentRole] || !isOwner;

  return (
    <div onClick={stopPropagation} className={cn('option_shell')}>
      {isExpandedView && (
        <>
          <Tooltip text={t('expandT')} direction="down">
            <div className={cn('svg_wrapper')} onClick={handleOpen}>
              <ExpandSVG />
            </div>
          </Tooltip>
          <Separator />
        </>
      )}
      {!playlistOwner && (
        <>
          <Tooltip text={t('markAsReadT')} direction="down">
            <div
              className={cn('svg_wrapper', { isDisabled: isOwner, isNotViewed: !linkPage.isViewed })}
              onClick={toggleView}
            >
              <CheckedSVG />
            </div>
          </Tooltip>
          <Separator />
        </>
      )}

      <Tooltip text={t('addToT')} direction="down">
        <div className={cn('svg_wrapper')} onClick={() => setIsThreeDotsActive(true)}>
          <PlusSvg />
        </div>
      </Tooltip>

      <Tooltip text={t('downloadT')} direction="down">
        <div onClick={onDownloadButtonClickHandler} className={cn('svg_wrapper', { isDisabled: isDownloadDisabled })}>
          <DownloadSvg />
        </div>
      </Tooltip>

      <Tooltip text={linkPage.isRemixLocked ? t('unlockT') : t('lockT')} direction="down">
        <LockButton
          upvCard
          isRemixLocked={linkPage.isRemixLocked}
          linkPageId={linkPage.id}
          isDisabled={isLockDisabled}
        />
      </Tooltip>
    </div>
  );
};


export default OptionsLinkPage;
