import React from 'react';
import classNames from 'classnames/bind';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { getLinkPagePosition, generateSimpleRTBState } from '../../utils/helpers';
import { empty } from '../../utils/constants';
import { actionChangeLinkPageType, actionEmptyAddLinkPageToPlaylist } from '../../redux/currentPage/action';
import { smartFileItemType } from '../../shared/smartFile/constant';
import { actionChangeTextElementBlockReduxMaker } from '../../redux/playlists/action';

const cx = classNames.bind(styles);

const Summarize = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const close = () => {
    dispatch(actionCloseModal());
  };

  const { isSummarizeLoading, currentSummarize, linkPages, id } = useSelector((state) => state.currentPage);

  const { dataPayload } = useSelector((state) => state.user);

  const user = useSelector((state) => state.user || empty);
  const createLinkPage = (destinationIndex, targetIndex) => {
    const newPos = getLinkPagePosition(destinationIndex, linkPages, targetIndex);
    const linkPageId = uuid();
    const pageData = {
      id: linkPageId,
      position: newPos,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
      mainUserInterface: {
        id: uuid(),
      },
    };
    dispatch(actionEmptyAddLinkPageToPlaylist(id, pageData));
    return pageData;
  };

  const setSmartText = () => {
    const createdItem = createLinkPage(0);
    const newState = generateSimpleRTBState(currentSummarize);
    const updateCBaction = (blockId) => {
      return (
        actionChangeTextElementBlockReduxMaker(
          blockId,
          newState,
          '',
          id,
          createdItem.id,
          smartFileItemType.title,
        ));
    };
    dispatch(actionChangeLinkPageType(createdItem.id, smartFileItemType.richText, id, null, updateCBaction));
    close();
  };

  return (
    <div onMouseDown={close} className={cx('modal_wrapper')}>
      <div
        onMouseDown={(e) => e.stopPropagation()}
        className={cx('modal', 'Summarize')}
      >
        <h3 className={cx('title')}>Summarization results {dataPayload.item ? '' : 'across the Smartfile'}:</h3>
        {isSummarizeLoading ? (
          <div className={cx('spinner_wrapper')}>
            <div className="loader_mini">{t('loadingT')}</div>
          </div>
        ) : (
          <>
            {<div className={cx('break_text')}>{currentSummarize}</div> ?? <div> This item has no summarize</div>}
            <div className={cx('buttons_group')}>
              <Button2023
                variant="gradientGreenSquare"
                width={69}
                height={32}
                text={t('addUpT')}
                noMargins
                handleButtonClick={setSmartText}
              />
              <Button2023
                variant="gradientGreenSquare"
                width={69}
                height={32}
                text={t('closeUpT')}
                noMargins
                handleButtonClick={close}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Summarize;
