import React, { memo, useEffect, useRef, useState } from 'react';
import {
  CompositeDecorator,
  ContentState,
  convertToRaw,
  DefaultDraftBlockRenderMap,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RichUtils,
} from 'draft-js';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import createImagePlugin from 'draft-js-image-plugin';
import createVideoPlugin from 'draft-js-video-plugin';
import Editor from 'draft-js-plugins-editor';
import styles from './MomentBlock.module.css';
import './MomentBlock.css';
import 'draft-js/dist/Draft.css';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import {
  blockRenderMap,
  createLinkContent,
  findLinkEntities,
  myBlockStyleFn,
  typeFormatterPlaceholder,
} from '../../utils/helpersDragt';
import {
  requestCreateLibraryComponent,
  requestUpdateLibraryComponent,
  requestUpdatePlaylist,
} from '../../utils/request';
import BlockOption from '../DropDownOption/BlockOption';
import addComponentIcon from '../../images/icons/add_component.svg';
import {
  actionBulkToggleIsBlockHidden,
  actionChangeTypeBlock,
  actionClearPreSaveFlag,
  actionSwitchPage,
  actionToggleIsBlockHidden,
  actionToggleLibraryDrag,
  actionUpdateBlock,
} from '../../redux/currentPage/action';
import CreateBlockOption from '../DropDownOption/CreateBlockOption';
import createInlineToolbarPlugin from './addLinkPlugin';
import { ReactComponent as DragDotsSvg } from '../../images/icons/drag_dots.svg';
import { ReactComponent as EyeOffSvg } from '../../images/icons/eye_off.svg';

import {
  clearInlineStyles,
  getStyles,
  styleMap,
} from '../../utils/richTextBar';
import {
  actionAddEditableBlock,
  actionAddManyEditableBlock,
  actionChangeEditableBlock,
} from '../../redux/pages/action';

import { ReactComponent as DragsSvg } from '../../images/icons/many_drag.svg';

import AddLink from './AddLink';
import WrapperLink from './Link';
import LinkParseOption from '../DropDownOption/LinkParseOption';
import CreateBlockButton from '../DropdownButton';
import { toolBarCreator } from './utilsDraft';
import BlockImageAdd from '../BlockImageAdd/MediaAdd';
import {
  actionBlockUnloading,
  actionUnblockUnloading,
} from '../../redux/user/action';
import { actionWriteCombination } from '../../redux/shortcuts/action';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import { CopyBlockImageToPage, CopyUploadToPage } from '../../redux/library/actions';
import {
  allowedFormatsImage,
  BlockTypes,
  empty,
  MessageType,
  UiComponentTypes,
} from '../../utils/constants';
import useThrottle from '../../utils/hooks/useThrottle';
import ActionDraggable from '../../redux/dragable/action';
import ErrorBoundary from '../ErrorBoundary';
import ResizeImage from '../ResizeImage';
import { limitNumberChapner } from '../../utils/draftConstants';
import Tooltip from '../../shared/Tooltips/Tooltip';
import {
  innerHtmlLineSeparatorTemplate,
  pastedHandlerHelper,
  replaceDoubleQuotes,
  sanitizeToSave,
  stringifyInnerHtml,
} from '../../utils/helpers';
import {
  actionAddSupportEditableBlockRedux,
  actionChangeSupportEditableBlockRedux,
  actionCreateSupportEditableBlockWithState,
  actionShowMessage,
  actionUpdateSupportBlock,
} from '../../redux/support/action';
import EmbedBlock from './CustomBlocks/EmbedBlock';
import PdfBlock from './CustomBlocks/pdfBlock/PdfBlock';
import { actionCreator } from '../../shared/redux/actionHelper';
import { EditPage } from '../../redux/pages/types';
import {
  checkIsLastRow,
  getTextLastROwStartSimvol,
  isFirsRw,
} from '../../utils/helperText';
import {
  markDownBySpase,
  markTypeBySpaseAndHandledKey,
} from './helperFuncDradtJs/markDownBySpase';
import markDownByKey, {
  markTypeAndHandledKeys,
} from './helperFuncDradtJs/markDownByKey';
import MediaAdd from '../MediaAdd/MediaAdd';
import { createUpdateComponentBody } from '../../utils/query/helper';


const OSName = window.navigator.platform;

const imagePlugin = createImagePlugin();
const videoPlugin = createVideoPlugin();

const MomentBlockText = memo(({ props: {
  stopPropagation,
  clear,
  index,
  setIdOpenMenuBlock,
  idOpenMenuBlock,
  isDragging,
  state,
  componentId,
  position,
  innerHtml,
  dragHandleProps,
  addEditableBlock,
  deleteEditableBlock,
  onChangeEditableBlock,
  onChangeBlocksWithData,
  type1,
  handlerClick,
  canUpdForRedoUndo,
  setCanUpdForRedo,
  page,
  isLibraryCreate,
  isBlockHidden,
  currentPageId,
  isBuilderTextElement,
  isLibraryPage,
  upvCard,
  ...props } }) => {
  const [refMenu, isOpenMenu, setIsOpenMenu] = useComponentVisible(false);
  const [refOption, isOptionVisible, setIsOptionVisible] = useComponentVisible(false);
  const [refLink, isLinkVisible, setIsLinkVisible] = useComponentVisible(false);
  const [refMenuBrowse, isOpenMenuBrowse, setOpenMenuBrowse] = useComponentVisible(false);
  const [throttleSave] = useThrottle();
  const showToolBar = useRef(false);
  const [savedExternalProps, setSavedExternalProps] = useState(false);
  // const [numPage, setNumPage] = useState(false);
  const [url, setUrl] = useState('');
  const [linkOption, setLinkOption] = useState({});
  const [localState, setLocalState] = useState(state || empty);
  const [isShowBrowseBlocks, setIsShowBrowseBlocks] = useState(false);
  const [type, setType] = useState(type1);
  const [plugins, setPlugins] = useState([]);
  const [InlineToolbar, setInlineToolbar] = useState(<></>);
  const { t } = useTranslation();

  const [timeoutID, setTimeoutID] = useState(0);
  const [isNeedToUpdate, setNeedToUpdate] = useState(false);
  const [loacalLayers, setLayers] = useState(null);
  const editor = useRef(null);
  const toolbarRef = useRef(null);
  const editorWR = useRef(null);
  const editorWrapperRef = useRef(null);
  const history = useHistory();
  const { draggableBlocks } = useSelector((reduxState) => reduxState.draggable);
  const { newBlockId, layers, blocks, selectedItems } = useSelector((reduxState) => (isLibraryCreate ? reduxState.support : reduxState.currentPage),
  );
  const dispatch = useDispatch();
  const isHasContent = localState
    && localState.getCurrentContent
    && localState.getCurrentContent().blockMap.size !== 1;
  const isMedia = type === BlockTypes.image || type === BlockTypes.video;
  const isTitle = type === BlockTypes.title
    || type === BlockTypes.title2
    || type === BlockTypes.title3;
  const isPage = type === BlockTypes.page;
  const isEmbed = type === BlockTypes.embed;
  const isGoogleEmbed = type === BlockTypes.googleEmbed;
  const isDropboxEmbed = type === BlockTypes.dropboxEmbed;
  const isPdf = type === BlockTypes.pdf;
  const isHasPdfPreview = UiComponentTypes.ms[type] || UiComponentTypes.xls[type];

  const isButton = type === BlockTypes.approveButton
    || type === BlockTypes.actionButton
    || type === BlockTypes.shortText;
  const isWebSite = type === BlockTypes.webSite;
  const isSeparatorLine = type === BlockTypes.lineSeparator;
  const isParagraph = type === BlockTypes.text;
  const isCallout = type === BlockTypes.callout;
  const isOrderedList = type?.startsWith('ordered-list-item');
  const isUnorderedList = type?.startsWith('unordered-list-item');
  const isList = isOrderedList || isUnorderedList;
  const isUnloadingBlocked = useSelector(
    (reduxState) => reduxState.user.isUnloadingBlocked,
  );
  const isHasMediaContentOrNotMedia = (isMedia && isHasContent) || !isMedia;
  const [loader, setLoader] = useState(false);
  const [uploadUrlData, setUploadUrlData] = useState({});
  const isLargeRightBar = useSelector(
    (reduxState) => reduxState.currentPage.isLargeRightBar,
  );
  // const { isHidden: isBlockHidden } = useSelector(reduxState => reduxState
  //   .currentPage.blocks.find(block => block.id === componentId)) || {};
  const [hoverBlock, setHoverBlock] = useState(false);
  const playlistField = false;

  useEffect(() => {
    if (!isOpenMenu && setIdOpenMenuBlock) setIdOpenMenuBlock('');
  },
  [isOpenMenu]);

  useEffect(() => {
    if (props.setPlainText && localState) {
      props.setPlainText(localState?.getCurrentContent && localState?.getCurrentContent()?.getPlainText());
    }
  }, [localState]);

  const selectAddEditableBlock = (
    itemType,
    id,
    isClone,
    eraseContent,
    text,
  ) => {
    if (isOpenMenu) setTimeout(() => setIsOpenMenu(false), 0);
    if (props.changeTypeBlock) {
      // for page
      props.changeTypeBlock(itemType, eraseContent, id, text);
      return;
    }
    addEditableBlock(itemType, id, isClone, eraseContent, text);
  };

  const duplicateBlock = () => {
    if (isOpenMenu) setTimeout(() => setIsOpenMenu(false), 0);
    if (props.addDuplicateBlock) {
      props.addDuplicateBlock();
    } else addEditableBlock('', '', true);
  };

  const addNewEmptyEditableBlock = (order) => {
    if (isOpenMenu) setTimeout(() => setIsOpenMenu(false), 0);
    if (props.addNewEmptyEditableBlock) {
      props.addNewEmptyEditableBlock(order);
    } else addEditableBlock(order);
  };

  const MAX_LENGTH = isBuilderTextElement
    ? 2000
    : limitNumberChapner[type] || 1000;

  const save = async (newState, newHtml, width) => {
    if (isPdf || isHasPdfPreview) return;
    if (type1 === BlockTypes.embed || type === BlockTypes.embed) {
      if (isUnloadingBlocked) dispatch(actionUnblockUnloading());
      return;
    }
    if (
      newState
      && newState?.innerHtml
      && newState?.innerHtml === localState?.innerHtml
      && !props.createComponentWidget
    ) {
      if (isUnloadingBlocked && !props.createComponentWidget) {
        dispatch(actionUnblockUnloading());
      }
      return;
    }
    const html = editorWR?.current?.innerHTML;
    if (!isLibraryCreate && componentId) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      if (newState?.data?.lineType) {
        requestUpdateLibraryComponent()(
          createUpdateComponentBody(
            {
              id: componentId,
              state: newState || localState,
              position: isBuilderTextElement ? 0 : position,
              innerHtml: newHtml || html,
              type: BlockTypes.lineSeparator,
            },
            currentPageId,
            null,
            isBuilderTextElement,
            null,
            lastModifiedDate,
          ),
        ).then(() => {
          if (isUnloadingBlocked) {
            dispatch(actionSwitchPage({ lastModifiedDate }));
            dispatch(actionUnblockUnloading());
          }
        });
      } else {
        if (
          type !== BlockTypes.shortText
          && ((isBuilderTextElement && !canUpdForRedoUndo?.current)
            || !isBuilderTextElement)
        ) {
          const newState2 = newState || localState;
          const selectionState = newState2.getSelection();
          if (!canUpdForRedoUndo?.current) {
            canUpdForRedoUndo.current = false;
            onChangeEditableBlock(
              EditorState.create({
                currentContent: newState2.getCurrentContent(),
                selection: selectionState,
              }),
              newHtml || html,
            );
          }
        }
        if (isBuilderTextElement) {
          const type1 = type ? `type: "${type}"` : '';

          const objState = convertToRaw(
            (newState || localState)?.getCurrentContent(),
          );
          objState.blocks.forEach((it) => {
            it.text = sanitizeToSave(it.text);
          });
          const newState2 = replaceDoubleQuotes(JSON.stringify(objState));
          const calcFiledNested = `
    textComponent:{
      where:{node:{  id: "${componentId}"}}
        update:{ node:{      
        ${type1}
        content: ${newState2}
       }  
        }}`;
          await requestUpdatePlaylist()({
            id: currentPageId,
            updateNested: {
              linkPages: [
                {
                  id: isBuilderTextElement.linkPageId,

                  fieldNested: calcFiledNested,
                },
              ],
            },
          });
          if (isUnloadingBlocked) {
            dispatch(actionSwitchPage({ lastModifiedDate }));
            dispatch(actionUnblockUnloading());
          }
        } else {
          const newBlockState = (newState || localState).getCurrentContent
            ? { ...convertToRaw((newState || localState).getCurrentContent()) }
            : {};
          newBlockState?.blocks.forEach((block) => {
            block.text = sanitizeToSave(block.text);
          });
          const field = {
            content: replaceDoubleQuotes(JSON.stringify(newBlockState)),
          };

          if (type === BlockTypes.embed || type === BlockTypes.webSite) {
            field.content = `"${sanitizeToSave(
              JSON.stringify(newState || localState),
            )}"`;
          }

          if (newHtml || html) {
            field.innerHtml = stringifyInnerHtml(newHtml || html);
          }

          requestUpdateLibraryComponent()({
            place: 'queryUpdateComponentText',
            id: currentPageId,
            updateNested: {
              components: [{ id: componentId, field }],
            },
          }).then(() => {
            if (isUnloadingBlocked) {
              dispatch(actionSwitchPage({ lastModifiedDate }));
              dispatch(actionUnblockUnloading());
            }
          });
        }
      }
    } else if (isLibraryCreate && componentId) {
      onChangeEditableBlock(newState || localState, newHtml || html);
    }
  };
  const update = (updateState) => {
    const currentContent1 = JSON.stringify(
      localState.getCurrentContent && localState.getCurrentContent(),
    );
    const updateState1 = JSON.stringify(
      updateState.getCurrentContent && updateState.getCurrentContent(),
    );
    const state1 = JSON.stringify(
      state.getCurrentContent && state.getCurrentContent(),
    );

    if (updateState1 === currentContent1) {
      setLocalState(updateState);
      return;
    }
    if (updateState1 === state1) {
      setLocalState(updateState);
      return;
    }

    const isOldStateBeforeChangeType = localState
      .getCurrentContent()
      .getPlainText()
      .startsWith(markTypeBySpaseAndHandledKey[type1]);

    if (type !== type1) {
      if (isOldStateBeforeChangeType) return;
    }
    if (updateState.data) {
      setTimeout(() => {
        const html = editorWR?.current?.innerHTML;
        throttleSave(() => () => save(updateState, html));
      }, 100);
    } else if (
      !!localState?.getCurrentContent
      && JSON.stringify(convertToRaw(updateState?.getCurrentContent()))
        !== JSON.stringify(convertToRaw(localState?.getCurrentContent()))
    ) {
      if (!isUnloadingBlocked) dispatch(actionBlockUnloading());
      setTimeout(() => {
        const html = editorWR?.current?.innerHTML;
        throttleSave(() => () => save(updateState, html));
      }, 100);
    }
    setLocalState(updateState);
  };

  useEffect(() => {
    if (localState && props.preSave && props.createComponentWidget) {
      if (localState.data) {
        setTimeout(() => {
          const html = editorWR?.current?.innerHTML;
          throttleSave(() => () => save(localState, html));
        }, 100);
      } else if (localState?.getCurrentContent) {
        if (!isUnloadingBlocked) dispatch(actionBlockUnloading());
        setTimeout(() => {
          const html = editorWR?.current?.innerHTML;
          throttleSave(() => () => save(localState, html));
        }, 100);
      }
      dispatch(actionClearPreSaveFlag(componentId, playlistField));
    }
  }, [props.preSave]);

  const saveAndUpdate = (itemType, itemState) => {
    if (!isLibraryCreate && isUnloadingBlocked) dispatch(actionUnblockUnloading());
    onChangeEditableBlock(
      itemState.getEditorState(),
      editorWR?.current?.innerHTML,
    );
    selectAddEditableBlock(itemType);
  };

  const getLengthOfSelectedText = () => {
    const currentSelection = localState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();
    let length = 0;

    if (!isCollapsed) {
      const currentContent = localState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if (isStartAndEndBlockAreTheSame) {
        length
          += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }
    return length;
  };

  useEffect(() => {
    if (editorWR?.current?.getElementsByClassName('show-link').length) return () => {};
    if (
      editorWR?.current?.getElementsByClassName(
        'public-DraftEditorPlaceholder-inner',
      ).length
    ) return () => {};
    if (
      editorWR?.current?.innerHTML
      && editorWR?.current?.innerHTML !== innerHtml
      && type === BlockTypes.image
    ) {
      // TODO GIX FOR EMPTY
      // save();
    } else if (!innerHtml && type !== 'page') {
      // need more test
      // save();
    }
  }, [localState]);

  // show-hide widget. no onDrag event available
  useEffect(() => {
    if (props.showDragWidget) {
      dispatch(actionToggleLibraryDrag(true));
      setIsOpenMenu(false);
    }
  }, [props.showDragWidget]);

  useEffect(() => {
    if (type1 !== type) {
      setType(type1);
      setLocalState(state);
    }
  }, [type1]);

  const updateType = (newBlockType) => {
    setType(newBlockType);
    dispatch(actionChangeTypeBlock(newBlockType, index, playlistField));
  };

  const handleBeforeInput = () => {
    const currentContent = localState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      // ('you can type max ten characters');
      return 'handled';
    }
    return 'not-handled';
  };

  const indentCalc = (direction) => {
    if (type.includes('unordered-list-item')) {
      if (direction === 'IndentDecrease') {
        const now = parseInt(type[19]) || 0;
        const wnow = now === 2 ? 2 : now + 1;
        return `unordered-list-item${wnow}`;
      }
      if (direction === 'indent') {
        const now = parseInt(type[19]) || 0;

        const wnow = now <= 1 ? '0' : now - 1;
        return `unordered-list-item${wnow}`;
      }
    }
    if (type.includes('ordered-list-item')) {
      if (direction === 'IndentDecrease') {
        const now = parseInt(type[17]) || 0;

        const wnow = now === 2 ? 2 : now + 1;
        return `ordered-list-item${wnow}`;
      }
      if (direction === 'indent') {
        const now = parseInt(type[17]) || 0;

        const wnow = now <= 1 ? '0' : now - 1;
        return `ordered-list-item${wnow}`;
      }
    }
    return false;
  };

  const deleteEmptyComponent = (e) => {
    if (
      e.code === 'Backspace'
      && localState?.getCurrentContent
      && !localState?.getCurrentContent().hasText()
    ) {
      deleteEditableBlock(e);
    }
  };

  useEffect(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin({ isLibraryCreate });
    setPlugins([imagePlugin, videoPlugin, inlineToolbarPlugin]);
  }, []);

  useEffect(() => {
    if (
      !isLinkVisible
      && !isPage
      && !isWebSite
      && !isSeparatorLine
      && !isButton
      && !isEmbed
      && !isGoogleEmbed
      && !isDropboxEmbed
      && !isPdf
      && !isHasPdfPreview
      && localState !== empty
    ) {
      const inlineStyle = localState.getCurrentInlineStyle && localState.getCurrentInlineStyle();
      const isHasSelected = inlineStyle.has('style');
      if (isHasSelected) {
        update(clearInlineStyles(localState, getStyles(localState, 'style')));
      }
    }
  }, [savedExternalProps]);

  const linkCreateHandler = (externalProps) => {
    const itemState = externalProps.getEditorState();
    setUrl('');
    const newState = RichUtils.toggleInlineStyle(itemState, 'style');

    RichUtils.toggleInlineStyle(itemState, 'style');
    update(newState);
    setIsLinkVisible(true);
  };

  const toggleSelect = () => {
    const itemState = savedExternalProps.getEditorState();
    const newState = RichUtils.toggleInlineStyle(itemState, 'style');
    update(newState);
  };

  useEffect(() => {
    if (newBlockId === componentId) {
      setTimeout(() => {
        editor?.current?.focus();
      }, 120);
    }
  }, [componentId, type, newBlockId]);
  const onFocus = () => {
    props?.setIsEditorWidthFocus && props.setIsEditorWidthFocus(true);
    setTimeout(() => setIsShowBrowseBlocks(true), 100);

    if (isLinkVisible) {
      setIsLinkVisible(false);
    }
    setTimeout(() => {
      showToolBar.current = true;
    }, 100);
  };

  useEffect(() => {
    if (
      isHasMediaContentOrNotMedia
      && !isButton
      && !isEmbed
      && !isWebSite
      && !isGoogleEmbed
      && !isDropboxEmbed
      && !isPdf
      && !isHasPdfPreview
      && !isPage
      && !isSeparatorLine
      && !!plugins.length
    ) {
      const { InlineToolbar: itemInlineToolbar } = plugins[2];
      const bar = toolBarCreator(
        type,
        isTitle,
        isLinkVisible,
        isParagraph,
        isList,
        itemInlineToolbar,
        setIsLinkVisible,
        saveAndUpdate,
        updateType,
        indentCalc,
        toolbarRef,
        isCallout,
        setSavedExternalProps,
        linkCreateHandler,
      );
      setInlineToolbar(bar);
    }
  }, [
    type,
    savedExternalProps,
    plugins,
    isHasMediaContentOrNotMedia,
    isLinkVisible,
    localState,
  ]);

  const onBlurBlock = () => {
    props?.setIsEditorWidthFocus && props.setIsEditorWidthFocus(false);

    setTimeout(() => setIsShowBrowseBlocks(false), 100);
    if (!isLinkVisible) {
      showToolBar.current = false;
    }
    if (editorWR?.current?.getElementsByClassName('show-link').length) return;
    if (!isBuilderTextElement && !props.createComponentWidget) {
      dispatch(
        actionUpdateBlock(
          componentId,
          localState,
          null,
          editorWR?.current?.innerHTML,
          playlistField,
        ),
      );
    }
    if (props.onBlurHandler) props.onBlurHandler(localState);
  };

  const saveHtml = () => {
    const html = editorWR?.current?.innerHTML;
    if (
      !isLibraryCreate
      && componentId
      && html !== innerHtml
      && sanitizeToSave(innerHtml)
    ) {
      requestCreateLibraryComponent({
        place: 'queryUpdateHtmlComponent',
        id: currentPageId,
        updateNested: {
          components: [
            {
              id: componentId,
              field: { innerHtml: `"${sanitizeToSave(innerHtml)}"` },
            },
          ],
        },
      }).then(() => {
        if (!isLibraryCreate && isUnloadingBlocked) dispatch(actionUnblockUnloading());
      });
    }
  };

  const selectAddEditableBlockClear = (itemType, id, isClone, eraseContent) => {
    const editorState = EditorState.push(
      localState,
      ContentState.createFromText(''),
    );
    if (type !== itemType) {
      selectAddEditableBlock(itemType, id, isClone, eraseContent);
    } else {
      onChangeEditableBlock(editorState, '');
      selectAddEditableBlock(itemType, id, isClone);
    }
  };
  const selectAddEditableBlockSave = (itemType, id, isClone) => {
    if (
      type !== BlockTypes.approveButton
      && type !== BlockTypes.shortText
      && type !== BlockTypes.actionButton
    ) {
      onChangeEditableBlock(localState, editorWR?.current?.innerHTML);
    }
    if (!isLibraryCreate && isUnloadingBlocked) dispatch(actionUnblockUnloading());
    selectAddEditableBlock(itemType, id, isClone);
  };

  useEffect(() => {
    if (!innerHtml) {
      setTimeout(saveHtml);
    }
    if (
      isNeedToUpdate
      || isWebSite
      || canUpdForRedoUndo?.current
      || type === BlockTypes.image
    ) {
      isNeedToUpdate && setNeedToUpdate(false);
      setLocalState(state);
    }
  }, [state]);

  useEffect(() => {
    if (props.newFocus && props.newFocus.newFocusId === componentId) {
      const { order, offset, anchorOffset, anchorKey } = props.newFocus;
      const selectionState = localState.getSelection();
      editor.current?.focus();
      const contentState = localState.getCurrentContent();

      const arrayOBlocks = contentState.getBlocksAsArray();
      const arrayOBlocksKeys = arrayOBlocks.map((block) => block.getKey());
      let blockKey;
      let content;
      if (order === 'top') {
        blockKey = arrayOBlocksKeys[arrayOBlocksKeys.length - 1];
        content = arrayOBlocks[arrayOBlocksKeys.length - 1];
      } else {
        blockKey = arrayOBlocksKeys[0];
        content = arrayOBlocks[0];
      }

      let newOffset;
      if (order === 'top') {
        const new1Offset = getTextLastROwStartSimvol(content.getText()) + offset;
        newOffset = Math.min(content.getText().length, new1Offset);
      } else {
        newOffset = Math.min(content.getText().length, offset);
      }
      const newSelect = selectionState.merge({
        focusKey: blockKey,
        anchorKey: anchorKey || blockKey,
        anchorOffset: anchorOffset || newOffset,
        focusOffset: newOffset,
      });

      const newEditorState = EditorState.forceSelection(localState, newSelect);
      showToolBar.current = true;

      setLocalState(newEditorState);
    }
  }, [props.newFocus]);

  const updateSize = (width) => {
    if (props.createComponentWidget) {
      dispatch(
        actionUpdateSupportBlock(
          componentId,
          localState,
          width,
          null,
          playlistField,
        ),
      );
    } else {
      dispatch(
        actionCreator(EditPage.ResizeImageInBlock, {
          id: componentId,
          width,
          html: editorWR?.current?.innerHTML,
          state: localState,
          position,
        }),
      );
    }
  };
  const customKeyBindingFn = (e) => {
    if (e.keyCode === 38) {
      const isCollapsed = localState.getSelection().isCollapsed();
      const contentState = localState.getCurrentContent();

      const arrayOBlocks = contentState.getBlocksAsArray();
      const arrayOBlocksKeys = arrayOBlocks.map((block) => block.getKey());
      const selectionState = localState.getSelection();

      const focusKey = selectionState.getFocusKey();
      const topBlock = arrayOBlocksKeys[0] === focusKey;
      const offset = localState.getSelection().getFocusOffset();
      const firstBlock = arrayOBlocks[0];
      const anchorOffset = selectionState.getAnchorOffset();
      const anchorKey = selectionState.getAnchorKey();
      if (!e.shiftKey) {
        const isFirstRow = isFirsRw(
          firstBlock.getText().slice(0, anchorOffset),
        );
        if (topBlock && isCollapsed && isFirstRow) {
          if (props.goToOtherTextBlock) {
            e.preventDefault();
            props.goToOtherTextBlock('top', offset);
            return 'handled';
          }
        }
      } else if ((topBlock, offset === 0)) {
        if (props.selectBlockOnArrow) {
          e.preventDefault();
          editor.current.blur();
          props.selectBlockOnArrow({ offset, anchorOffset, anchorKey });
          return 'handled';
        }
      }
    }
    if (e.keyCode === 40) {
      const isCollapsed = localState.getSelection().isCollapsed();
      const contentState = localState.getCurrentContent();

      const arrayOBlocks = contentState.getBlocksAsArray();
      const arrayOBlocksKeys = arrayOBlocks.map((block) => block.getKey());
      const selectionState = localState.getSelection();

      const focusKey = selectionState.getFocusKey();
      const lastBlockKey = arrayOBlocksKeys[arrayOBlocksKeys.length - 1];
      const isLastBlock = lastBlockKey === focusKey;
      const offset = localState.getSelection().getFocusOffset();
      const lastBlock = arrayOBlocks[arrayOBlocks.length - 1];
      const anchorOffset = selectionState.getAnchorOffset();
      const anchorKey = selectionState.getAnchorKey();

      if (!e.shiftKey) {
        const isLastRow = checkIsLastRow(e.target);
        if (isLastBlock && isCollapsed && isLastRow) {
          if (props.goToOtherTextBlock) {
            e.preventDefault();
            props.goToOtherTextBlock();
            return 'handled';
          }
        }
      } else if (isLastBlock && offset === lastBlock.getText().length) {
        editor.current.blur();
        if (props.selectBlockOnArrow) {
          e.preventDefault();
          props.selectBlockOnArrow({
            offset,
            anchorOffset,
            order: 'top',
            anchorKey,
          });
          return 'handled';
        }
      }
    }
    if (
      e.keyCode === 90
      && e.shiftKey
      && (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
    ) {
      e.preventDefault();
      setCanUpdForRedo && setCanUpdForRedo();
      dispatch(actionWriteCombination('mod+shift+z'));
      return 'handled';
    }
    if (e.keyCode === 90 && (e.metaKey || (OSName === 'Win32' && e.ctrlKey))) {
      e.preventDefault();
      setCanUpdForRedo && setCanUpdForRedo();
      dispatch(actionWriteCombination('mod+z'));
      return 'handled';
    }
    if (e.key === 'Enter' && !e.shiftKey && !isBuilderTextElement) {
      const hasText = localState.getCurrentContent().hasText();
      // const stateText = localState.getCurrentContent().getPlainText();

      if ((isUnorderedList || isOrderedList) && !hasText) {
        if (props.changeTypeBlock) {
          props.changeTypeBlock();
        } else {
          selectAddEditableBlockSave(BlockTypes.text);
        }

        return 'handled';
      }
      const contentState = localState.getCurrentContent();
      const selectionState = localState.getSelection();
      const text = localState.getCurrentContent().getPlainText();
      const arrayOBlocks = contentState.getBlocksAsArray();
      const arrayOBlocksKeys = arrayOBlocks.map((block) => block.getKey());
      const focusKey = selectionState.getFocusKey();
      const caretBlockIndex = arrayOBlocksKeys.indexOf(focusKey);
      if (
        hasText
        && text.length !== localState.getSelection().getFocusOffset()
      ) {
        const blocksBefore = arrayOBlocksKeys.filter(
          (item, idx) => idx <= caretBlockIndex,
        );
        const blocksAfter = arrayOBlocksKeys.filter(
          (item, idx) => idx >= caretBlockIndex,
        );
        const newContentForOld = ContentState.createFromBlockArray(
          arrayOBlocks.filter((block) => {
            const blockKey = block.getKey();
            return blocksBefore.includes(blockKey);
          }),
        );
        const newContentForNew = ContentState.createFromBlockArray(
          arrayOBlocks.filter((block) => {
            const blockKey = block.getKey();
            return blocksAfter.includes(blockKey);
          }),
        );
        const textFirst = text.substr(
          0,
          localState.getSelection().getAnchorOffset(),
        );
        const textAfter = text.substr(
          localState.getSelection().getFocusOffset(),
        );

        const oldBlockEditorState2 = Modifier.removeRange(
          newContentForOld,
          // The text to replace, which is represented as a range with a start & end offset.
          selectionState.merge({
            // The starting position of the range to be replaced.
            anchorOffset: textFirst.length,
            // The end position of the range to be replaced.
            focusOffset: text.length,
          }),
          // The new string to replace the old string.
          textAfter,
        );
        const oldBlockEditorState = EditorState.push(
          localState,
          oldBlockEditorState2,
          'remove-range',
        );

        const newBlockEditorState2 = Modifier.removeRange(
          newContentForNew,
          // The text to replace, which is represented as a range with a start & end offset.
          selectionState.merge({
            // The starting position of the range to be replaced.
            anchorOffset: 0,
            // The end position of the range to be replaced.
            focusOffset: textFirst.length,
          }),
          // The new string to replace the old string.
          textFirst,
        );

        const newBlockEditorState = EditorState.push(
          localState,
          newBlockEditorState2,
          'remove-range',
        );

        setLocalState(oldBlockEditorState);

        if (isLibraryCreate) {
          dispatch(
            actionChangeSupportEditableBlockRedux(
              componentId,
              oldBlockEditorState,
              props.createComponentWidget,
              playlistField,
            ),
          );
          dispatch(
            actionCreateSupportEditableBlockWithState(
              componentId,
              newBlockEditorState,
            ),
          );
        } else {
          // only page
          dispatch(
            actionCreator(EditPage.SeparateBlock, {
              id: componentId,
              replaceState: oldBlockEditorState,
              newState: newBlockEditorState,
              pageId: currentPageId,
              isWidget: props.createComponentWidget,
              newBlockId: uuid(),
            }),
          );
        }
        return 'handled';
      }
      const newType = isUnorderedList || isOrderedList ? type : BlockTypes.text;
      editor.current.blur();
      isLibraryCreate
        ? dispatch(
          actionAddSupportEditableBlockRedux(
            componentId,
            { newType },
            null,
            null,
            null,
            null,
            playlistField,
          ),
        )
        : dispatch(
          actionAddEditableBlock(
            componentId,
            { newType },
            null,
            null,
            null,
            null,
            playlistField,
          ),
        );
      return 'handled';
    }
    if (isParagraph || isUnorderedList || isOrderedList) {
      if (e.keyCode === 9 && !e.shiftKey) {
        return 'IndentDecrease';
      }
      if (e.keyCode === 9 && e.shiftKey) {
        return 'indent';
      }
      if (
        e.shiftKey
        && (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 82
      ) {
        return 'rightAlign';
      }
      if (
        e.shiftKey
        && (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 76
      ) {
        return 'leftAlign';
      }
      if (
        e.shiftKey
        && (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 69
      ) {
        return 'centerAlign';
      }
      if (
        (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 66
      ) {
        return 'bold';
      }
      if (
        (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 73
      ) {
        return 'italicize';
      }
      if (
        (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 85
      ) {
        return 'underline';
      }
      if (
        e.shiftKey
        && (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 88
      ) {
        return 'strikethrough';
      }
      if (
        (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 69
      ) {
        // cmd+e conflict in Chrome
        return 'codeStyling';
      }
      if (
        (e.metaKey || (OSName === 'Win32' && e.ctrlKey))
        && e.keyCode === 75
      ) {
        return 'createLink';
      }
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command) => {
    const indentCommand = indentCalc(command);
    if (indentCommand) {
      selectAddEditableBlockSave(indentCommand, componentId);
      return 'handled';
    }
    if (isParagraph) {
      if (command === 'IndentDecrease') {
        const blockStyle = localState
          .getCurrentContent()
          .getBlockForKey(localState.getSelection().getStartKey())
          .getType();
        let newBlockStyle = 'indent_text_1';
        if (blockStyle === 'indent_text_0') newBlockStyle = 'indent_text_1';
        else if (blockStyle === 'indent_text_1') newBlockStyle = 'indent_text_2';
        else if (blockStyle === 'indent_text_2') newBlockStyle = 'indent_text_2';
        if (newBlockStyle !== blockStyle) {
          update(RichUtils.toggleBlockType(localState, newBlockStyle));
        }
        return 'handled';
      }
      if (command === 'indent') {
        const blockStyle = localState
          .getCurrentContent()
          .getBlockForKey(localState.getSelection().getStartKey())
          .getType();
        let newBlockStyle = 'indent_text_0';
        if (blockStyle === 'indent_text_0') newBlockStyle = 'indent_text_0';
        else if (blockStyle === 'indent_text_1') newBlockStyle = 'indent_text_0';
        else if (blockStyle === 'indent_text_2') newBlockStyle = 'indent_text_1';
        if (newBlockStyle !== blockStyle) {
          update(RichUtils.toggleBlockType(localState, newBlockStyle));
        }
        return 'handled';
      }
      if (command === 'rightAlign') {
        const blockStyle = localState
          .getCurrentContent()
          .getBlockForKey(localState.getSelection().getStartKey())
          .getType();
        const newBlockStyle = 'alignRight';
        if (newBlockStyle !== blockStyle) {
          update(RichUtils.toggleBlockType(localState, newBlockStyle));
        }
        return 'handled';
      }
      if (command === 'leftAlign') {
        const blockStyle = localState
          .getCurrentContent()
          .getBlockForKey(localState.getSelection().getStartKey())
          .getType();
        const newBlockStyle = 'alignLeft';
        if (newBlockStyle !== blockStyle) {
          update(RichUtils.toggleBlockType(localState, newBlockStyle));
        }
        return 'handled';
      }
      if (command === 'centerAlign') {
        const blockStyle = localState
          .getCurrentContent()
          .getBlockForKey(localState.getSelection().getStartKey())
          .getType();
        const newBlockStyle = 'alignCenter';
        if (newBlockStyle !== blockStyle) {
          update(RichUtils.toggleBlockType(localState, newBlockStyle));
        }
        return 'handled';
      }
    }
    if (isParagraph || isUnorderedList || isOrderedList) {
      if (command === 'bold') {
        update(RichUtils.toggleInlineStyle(localState, 'BOLD'));
        return 'handled';
      }
      if (command === 'italicize') {
        update(RichUtils.toggleInlineStyle(localState, 'ITALIC'));
        return 'handled';
      }
      if (command === 'underline') {
        update(RichUtils.toggleInlineStyle(localState, 'UNDERLINE'));
        return 'handled';
      }
      if (command === 'strikethrough') {
        update(RichUtils.toggleInlineStyle(localState, 'STRIKETHROUGH'));
        return 'handled';
      }
      if (command === 'codeStyling') {
        update(RichUtils.toggleInlineStyle(localState, 'CODE'));
        return 'handled';
      }
      if (command === 'createLink') {
        setUrl('');
        const newState = RichUtils.toggleInlineStyle(localState, 'style');
        RichUtils.toggleInlineStyle(state, 'style');
        update(newState);
        setIsLinkVisible(true);
        return 'handled';
      }
    }

    return 'not-handled';
  };

  useEffect(() => {
    if (timeoutID) clearTimeout(timeoutID);

    if (loacalLayers && isOrderedList) {
      const newTimeoutID = setTimeout(() => {
        if (editorWR?.current?.getElementsByClassName('show-link').length) return () => {};
        if (editorWR?.current?.innerHTML) {
          saveHtml();
        }
      }, 500);
      setTimeoutID(newTimeoutID);
    }
    setLayers(layers);
  }, [layers && layers[index]?.counter?.toString()]);

  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

  const focus = () => {
    editor.current?.focus();
  };

  const handlerClickOnDots = (event) => {
    handlerClick(event, componentId);
    setIsOpenMenu(true);
    event.stopPropagation();

    if (event.currentTarget !== event.target) {
      dispatch(ActionDraggable.BlocksOnPage([]));
      dispatch(ActionDraggable.SetHasDraggable(false));
    }
  };
  const onDotsMouseDownHandler = (event) => {
    event.stopPropagation();
    if (!props.blocksIds?.length || !props.blocksIds?.includes(componentId)) {
      props.selectBlock([componentId]);
      dispatch(ActionDraggable.BlocksOnPage([componentId]));
    }
    if (props.blocksIds?.length) dispatch(ActionDraggable.BlocksOnPage(props.blocksIds));
    editor.current?.blur();
    props.createComponentWidget
      ? dispatch(
        actionUpdateSupportBlock(
          componentId,
          localState,
          null,
          null,
          playlistField,
        ),
      )
      : dispatch(
        actionUpdateBlock(componentId, localState, null, null, playlistField),
      );
  };

  const onKeyPressed = (event) => {
    if (isButton || isBuilderTextElement) return;

    if (markTypeAndHandledKeys.includes(event.key)) markDownByKey({ event, localState, update, key: event.key });

    if (
      event.key === '/'
      && type === BlockTypes.text
      && !localState.getCurrentContent().hasText()
    ) {
      setOpenMenuBrowse(true);
    } else {
      setOpenMenuBrowse(false);
    }
    if (event.key === '-') {
      const hasText = localState.getCurrentContent().hasText();
      const stateText = localState.getCurrentContent().getPlainText();
      if (hasText && stateText === '--') {
        setType(BlockTypes.lineSeparator);
        selectAddEditableBlock(BlockTypes.lineSeparator, componentId);
        update({ data: { lineType: 'single' } });
        if (!isLibraryCreate) {
          dispatch(
            actionChangeEditableBlock({
              id: componentId,
              newState: {
                data: {
                  lineType: 'single',
                },
              },
              innerHtml: innerHtmlLineSeparatorTemplate,
              playlistField,
              blockType: state.type,
            }),
          );
        }
        if (isLibraryCreate) {
          dispatch(
            actionChangeSupportEditableBlockRedux(
              componentId,
              {
                data: {
                  lineType: 'single',
                },
              },
              innerHtmlLineSeparatorTemplate,
            ),
          );
        }
        event.preventDefault();
        event.stopPropagation();
      }
    }
    if (event.key === ' ') {
      markDownBySpase({
        event,
        localState,
        isLibraryCreate,
        dispatch,
        componentId,
        playlistField,
        type,
      });
    }
    if (event.key === 'Tab') {
      const newEditorState = RichUtils.onTab(
        event,
        localState,
        2 /* maxDepth */,
      );
      if (newEditorState !== localState) {
        update(newEditorState);
      }
      event.preventDefault();
    }

    if (event.metaKey && event.key === 'a') {
      const SelectionState = localState.getSelection();
      const selectionStart = SelectionState.getStartOffset();
      const selectionEnd = SelectionState.getEndOffset();
      const contentLength = localState
        .getCurrentContent()
        .getPlainText().length;

      if (selectionStart === 0 && selectionEnd === contentLength) {
        editor?.current?.blur();
        setTimeout(() => dispatch(actionWriteCombination('mod+a')));
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  // function findLinkEntities(contentBlock, callback, contentState) {
  //   contentBlock.findEntityRanges(
  //     (character) => {
  //       const entityKey = character.getEntity();
  //       return (
  //         entityKey !== null
  //         && contentState.getEntity(entityKey).getType() === 'LINK'
  //       );
  //     },
  //     callback,
  //   );
  // }

  useEffect(() => {
    if (idOpenMenuBlock !== componentId) setIsOpenMenu(false);
  }, [idOpenMenuBlock]);

  useEffect(() => {
    if (props.selectionBox?.current?.left) {
      editor.current?.blur();
    }
  }, [props]);

  useEffect(() => {
    if (linkOption.ref?.current) {
      linkOption.ref.current.focus();
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(linkOption.ref.current);
      selection.removeAllRanges();
      selection.addRange(range);
      if (linkOption.edit) {
        const blockState = savedExternalProps.getEditorState();
        const newState = RichUtils.toggleInlineStyle(blockState, 'style');
        RichUtils.toggleInlineStyle(blockState, 'style');
        update(newState);
        const { url: itemUrl } = linkOption.props.contentState
          .getEntity(linkOption.props.entityKey)
          .getData();
        setUrl(itemUrl);
        setIsLinkVisible(true);
      }
      if (linkOption.remove) {
        update(
          RichUtils.toggleLink(localState, localState.getSelection(), null),
        );
      }
    }
  }, [linkOption]);

  const LinkW = WrapperLink({ setLinkOption, localState });

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: LinkW,
    },
  ]);

  const confirmLink = (e, newUrl) => {
    e.preventDefault();
    const itemState = savedExternalProps.getEditorState();
    const contentState = itemState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: newUrl.trim() },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(itemState, {
      currentContent: contentStateWithEntity,
    });
    update(
      RichUtils.toggleLink(
        newEditorState,
        contentStateWithEntity.getSelectionBefore(),
        entityKey,
      ),
    );
  };
  const handlePastedFiles = (files) => {
    const isReplace = !localState.getCurrentContent().hasText() && isParagraph;
    let isNeedReplace;
    if (isBuilderTextElement) {
      props.onPasteFile(files, isReplace);
      return 'handled';
    }
    const newBlock = [];
    const startIndex = index + (isReplace ? 0 : 1);
    files
      .filter((i) => allowedFormatsImage[i.type] || UiComponentTypes.ms[i.type] || UiComponentTypes.pdf[i.type])
      .forEach((item, fileIndex) => {
        if (fileIndex > 6) return;
        isNeedReplace = isReplace && fileIndex === 0 && allowedFormatsImage[item.type];

        const blockId = isNeedReplace ? componentId : uuid();
        const libCompId = uuid();
        let type;
        let callback;
        if (allowedFormatsImage[item.type]) {
          type = BlockTypes.image;
          callback = () => CopyBlockImageToPage(
            blockId,
            startIndex + fileIndex,
            libCompId,
          );
        } else {
          type = BlockTypes.upload;
          callback = () => CopyUploadToPage(
            blockId,
            startIndex + fileIndex,
            libCompId,
          );
        }

        dispatch(
          actionCreateUpload({
            newId: libCompId,
            blockId,
            file: item,
            title: item.name,
            type: item.type,
            history,
            callback,
          }),
        );
        newBlock.push({
          isNew: true,
          id: blockId,
          libCompId,
          type,
        });
      });
    if (newBlock.length) {
      dispatch(
        actionAddManyEditableBlock(
          componentId,
          newBlock,
          currentPageId,
          isNeedReplace,
          playlistField),
      );
    } else {
      dispatch(actionShowMessage({ type: MessageType.fileNotSupported }));
    }
    return 'handled';
  };

  const handlePastedText = (text, html) => {
    const { type: localType, payload } = pastedHandlerHelper(
      localState,
      type,
      componentId,
      text,
      html,
      MAX_LENGTH,
    );

    if (isBuilderTextElement) {
      const currentLength = localState
        .getCurrentContent()
        .getPlainText().length;
      if (localType === 'TEXT_IS_TOO_LONG') {
        if (currentLength !== MAX_LENGTH) {
          const newText = text.substr(0, MAX_LENGTH - currentLength);
          // props.setPlainText && props.setPlainText(localState.getCurrentContent().getPlainText() + newText);

          const newEditorState = Modifier.insertText(
            localState.getCurrentContent(),
            localState.getSelection(),
            newText,
          );

          const newLocalState = EditorState.push(
            localState,
            newEditorState,
            'insert-characters',
          );

          // setLocalState(newLocalState);
          save(newLocalState);
        }

        dispatch(
          actionShowMessage({
            type: MessageType.EditorPastedTextError,
            lengthLimit: MAX_LENGTH,
            effects: 'shaking',
          }),
        );

        return 'handled';
      }
      if (text.length + currentLength > MAX_LENGTH) {
        dispatch(
          actionShowMessage({
            type: MessageType.EditorPastedTextError,
            lengthLimit: MAX_LENGTH,
            effects: 'shaking',
          }),
        );
        return 'handled';
      }
      return 'not-handled';
    }
    switch (localType) {
      case 'ADD_BLOCKS': {
        const isHasText = localState.getCurrentContent().hasText();
        if (!isHasText) setNeedToUpdate(true);
        dispatch(
          actionAddManyEditableBlock(
            componentId,
            payload,
            currentPageId,
            !isHasText,
            playlistField,
          ),
        );
        return 'handled';
      }
      case 'CREATE_AS_TEXT': {
        // return 'handled';
        break;
      }
      case 'CREATE_AS_LINK': {
        update(createLinkContent(text));
        setIsOptionVisible(true);
        return 'handled';
      }
      case 'CREATE_AS_EMBED': {
        update(createLinkContent(text));
        setIsOptionVisible({ type: BlockTypes.embed, text });
        return 'handled';
      }
      default:
    }
    return 'not-handled';
  };

  if (isDragging && !!draggableBlocks.length) {
    return (
      <div className={` wrapper_drag_${type}`}>
        <div className={styles.count}>
          <DragsSvg />
          <div> {draggableBlocks.length} BLOCKS</div>
        </div>
      </div>
    );
  }
  const dragEndHandler = () => {
    const div = document.getElementById('draggable_page');
    // todo obsolete Page drag
    // dispatch(actionDragPage(null, null));
    // dispatch(actionDragManyPage(false));
    dispatch(ActionDraggable.BlocksOnPage([]));
    const parent = document.getElementById('root');
    if (div) parent.removeChild(div);
    dispatch(ActionDraggable.SetHasDraggable(false));
  };

  const dragStartHandler = (e) => {
    setIsOpenMenu(false);
    const draggableId = draggableBlocks.filter((i) => i);
    let handledBlocks;
    if (draggableId.includes(componentId)) {
      handledBlocks = draggableId;
    } else {
      handledBlocks = [componentId];
    }
    props.selectBlock(handledBlocks);

    dispatch(ActionDraggable.BlocksOnPage(handledBlocks, playlistField));
    const div = document.createElement('div');
    div.className = 'draggable_page';
    div.id = 'draggable_page';
    if (handledBlocks.length > 1) {
      div.textContent = `${handledBlocks.length} BLOCKS`;
    } else {
      div.textContent = '1 BLOCK';
    }
    // todo obsolete Page Drag
    // if (!isLibraryCreate) dispatch(actionDragManyPage(true));
    const parent = document.getElementById('root');
    parent.appendChild(div);
    e.dataTransfer.setDragImage(div, 117, 20);
  };

  const toggleIsHidden = (e, id, value) => {
    if (draggableBlocks?.length > 1) {
      const processedBlocks = [];
      blocks.forEach((item) => {
        if (draggableBlocks.includes(item.id)) {
          processedBlocks.push({
            id: item.id,
            isHidden: !item.isHidden,
            type: item.type,
          });
        }
      });
      dispatch(actionBulkToggleIsBlockHidden(processedBlocks));
      e.preventDefault();
      e.stopPropagation();
    } else {
      dispatch(actionToggleIsBlockHidden(id, value, null, playlistField));
      e.preventDefault();
      e.stopPropagation();
    }
    clear();
  };

  return (
    <div
      data-cy="block-in-component"
      onClick={focus}
      className={`${styles.wrapper_component_balancer} 
      ${isBuilderTextElement ? styles.builder_text_element : ''}
           ${upvCard ? styles.upv_text_element : ''}

       `}
    >
      <div
        className={`wrapper_component_${type}  
      ${
        isLibraryCreate || playlistField
          ? styles.wrapper_component_in_library
          : styles.wrapper_component
      } 
      ${isBuilderTextElement ? styles.wrapper_component_in_builder : ''} 
      ${props.isMakerField ? styles.wrapper_component_in_maker : ''} 
      ${isLargeRightBar ? styles.wrapper_component_small : ''} 
      ${upvCard ? styles.upv_card_view : ''}
      ${selectedItems[isBuilderTextElement?.linkPageId] ? styles.selected : ''}
      `}
      >
        {!isBuilderTextElement && (
          <div
            className={` 
            ${styles.add_line} 
            ${isLibraryCreate || playlistField ? styles.add_line_in_library : ''}
          `}
          >
            <div className={` ${styles.img_wrapper} `}>
              <img
                draggable={false}
                src={addComponentIcon}
                onClick={() => addNewEmptyEditableBlock('new_before')}
                alt="add component"
              />
            </div>

            <div className={` ${styles.separating_line} `} />
            <div className={` ${styles.img_wrapper} `}>
              <img
                draggable={false}
                src={addComponentIcon}
                onClick={() => addNewEmptyEditableBlock('new')}
                alt="add component"
              />
            </div>
          </div>
        )}

        <div
          onKeyDown={deleteEmptyComponent}
          // onMouseUp={stopPropagation}
          // onMouseMove={stopPropagation}
          // onMouseDown={stopPropagation}
          className={`component_${type} 
            component 
            ${isBuilderTextElement ? styles.builder_component : styles.component}
            ${isBuilderTextElement ? 'builder_component' : ''}
            ${props.isNotOwner ? styles.not_owned_margins : ''}
          `}
        >
          {!isBuilderTextElement && (
            <div
              // onMouseDown={(e) => { e.stopPropagation(); }}
              className={`height_wrapper_component_${type} height_wrapper_component`}
            >
              <div
                ref={refMenu}
                data-parent="data-parent"
                draggable
                onDragEnd={dragEndHandler}
                onDragStart={dragStartHandler}
                onMouseDown={onDotsMouseDownHandler}
                onClick={handlerClickOnDots}
                onMouseUp={() => {
                  dispatch(ActionDraggable.BlocksOnPage([]));
                  dispatch(ActionDraggable.SetHasDraggable(false));
                }}
                className={`${styles.rectangle} ${
                  isOpenMenu && styles.rectangle_active
                }`}
              >
                <DragDotsSvg
                  className={`${styles.dots} ${
                    isOpenMenu && styles.dots_active
                  }`}
                />

                {isOpenMenu && (
                  <>
                    <BlockOption
                      blocksToCreateLComponent={props.blocksToCreateLComponent}
                      setBlocksToCreateLComponent={
                        props.setBlocksToCreateLComponent
                      }
                      blocksIds={props.blocksIds}
                      blocksIndices={props.blocksIndices}
                      mouseup={props.mouseup}
                      isOpenMenu={isOpenMenu}
                      isLibraryCreate={isLibraryCreate}
                      setIsOpenMenu={setIsOpenMenu}
                      isCreateComponentMenuVisible={
                        props.isCreateComponentMenuVisible
                      }
                      setIsCreateComponentMenuVisible={
                        props.setIsCreateComponentMenuVisible
                      }
                      selectAddEditableBlock={selectAddEditableBlockSave}
                      canTurnInto={!isPage && !isMedia}
                      parentRef={refMenu}
                      playlistField={playlistField}
                      addCloneBlock={() => {
                        duplicateBlock();
                        // to cancel selection on duplicated block
                        clear();
                      }}
                      deleteEditableBlock={deleteEditableBlock}
                      type={type}
                      setOpenMenuDetach={props.setOpenMenuDetach}
                      isBlockHidden={isBlockHidden}
                      toggleIsHidden={toggleIsHidden}
                      componentId={componentId}
                      clear={clear}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          <div
            className={`${hoverBlock ? styles.hiddenBlockWrapperBg : ''} ${
              styles.hiddenWrapper
            } opacityWrapper`}
          >
            {!isBuilderTextElement && (
              <div
                className={`${styles.hiddenContainer} ${
                  isBlockHidden ? styles.visible : ''
                }`}
                onMouseDown={(e) => toggleIsHidden(e, componentId, false)}
                onMouseEnter={() => setHoverBlock(true)}
                onMouseLeave={() => setHoverBlock(false)}
              >
                <Tooltip text={t('showBlockT')} direction="down">
                  <div className={`${styles.imageContainer} ${styles.visible}`}>
                    <EyeOffSvg />
                  </div>
                </Tooltip>
              </div>
            )}

            <div
              className={
                showToolBar.current ? styles.showToolBar : styles.hidden
              }
            >
              {InlineToolbar}
            </div>

            <div
              ref={editorWrapperRef}
              onMouseDown={onFocus}
              className={` ${styles.wrapper} ${
                isBlockHidden ? styles.addOpacity : ''
              } `}
              onKeyDown={onKeyPressed}
            >
              {isHasMediaContentOrNotMedia
                && !isButton
                && !isPage
                && !isEmbed
                && !isPdf
                && !isHasPdfPreview
                && !isWebSite
                && !isSeparatorLine
                && !!plugins.length && (
              // {isHasMediaContentOrNotMedia && !isPage && !isWebSite && !isSeparatorLine && !!plugins.length && (
                <>
                    {loader && <div className={styles.loader} />}

                  <div ref={editorWR}>
                    {isOrderedList
                        && type !== BlockTypes.ordered_list_item && (
                          <p className={styles.test1}>
                            {`${
                              layers[index]?.counter?.join('.')
                              || (layers[playlistField]
                                && layers[playlistField][index]?.counter?.join(
                                  '.',
                                ))
                              || 1
                            }.`}
                          </p>
                    )}
                    {isUnorderedList
                        && type !== BlockTypes.unordered_list_item && (
                          <p className={`${styles.test2}`} />
                    )}
                    <div className="customEditor">
                      <ResizeImage
                        save={updateSize}
                        index={index}
                        startWith={props.width}
                        type={type}
                        innerHtml={innerHtml}
                        localState={localState}
                      >
                        <ErrorBoundary>
                          <Editor
                            customStyleMap={styleMap()}
                            readOnly={isMedia || isLargeRightBar}
                            placeholder={
                                isBuilderTextElement
                                  ? t('emptyTextBlockPlaceholderT')
                                  : props.isMakerField
                                    ? ''
                                    : typeFormatterPlaceholder[type]
                              }
                            ref={editor}
                            editorState={localState}
                            onChange={!isMedia ? update : () => {}}
                            handlePastedFiles={handlePastedFiles}
                            onFocus={onFocus}
                            handleBeforeInput={handleBeforeInput}
                              // handlePastedText={_handlePastedText}
                            handlePastedText={handlePastedText}
                            onBlur={onBlurBlock}
                            blockStyleFn={myBlockStyleFn}
                            blockRenderMap={extendedBlockRenderMap}
                            plugins={plugins}
                            handleKeyCommand={handleKeyCommand}
                            keyBindingFn={customKeyBindingFn}
                            decorators={[decorator]}
                          />
                        </ErrorBoundary>
                      </ResizeImage>
                    </div>
                  </div>
                </>
              )}

              {isEmbed && (
                <EmbedBlock
                  state={state}
                  innerHtml={innerHtml}
                  saveItem={onChangeBlocksWithData}
                />
              )}
              {type === BlockTypes.video && !isHasContent && (
                <MediaAdd
                  type={type}
                  editorState={localState}
                  setLocalState={setLocalState}
                  onChange={onChangeEditableBlock}
                  modifier={videoPlugin.addVideo}
                />
              )}
              {type === BlockTypes.image && !isHasContent && (
                <BlockImageAdd
                  index={index}
                  playlistField={playlistField}
                  libCompId={props.libCompId}
                  id={componentId}
                  modifier={(newUrl) => onChangeEditableBlock(
                    imagePlugin.addImage(localState, newUrl),
                  )
                  }
                  isLibraryCreate={isLibraryCreate}
                  position={position}
                />
              )}
              {(isPdf || isHasPdfPreview) && (
                <PdfBlock
                  state={localState}
                  innerHtml={innerHtml}
                  relationId={componentId}
                  componentId={componentId}
                  // isSharePage={isSharePage}
                />
              )}
            </div>
          </div>
          <div ref={refMenuBrowse}>
            {isOpenMenuBrowse && (
              <>
                <CreateBlockOption
                  selectAddEditableBlock={selectAddEditableBlockClear}
                  parentRef={refMenuBrowse}
                  setIsComponentVisible={setOpenMenuBrowse}
                />
              </>
            )}
          </div>
          <div ref={refOption}>
            {isOptionVisible && (
              <LinkParseOption
                onChange={onChangeEditableBlock}
                modifier={(newUrl) => {
                  setType(BlockTypes.image);
                  onChangeEditableBlock(
                    imagePlugin.addImage(localState, newUrl),
                  );
                }}
                setType={setType}
                parentRef={refOption}
                setLoader={setLoader}
                setUploadUrlData={setUploadUrlData}
                setLocalState={update}
                editorState={localState}
                id={componentId}
                selectAddEditableBlock={selectAddEditableBlock}
                selectAddEditableBlockClear={selectAddEditableBlockClear}
                addImage={imagePlugin.addImage}
                addVideo={videoPlugin.addVideo}
                setIsOptionVisible={setIsOptionVisible}
                data={uploadUrlData?.data?.data}
                currentBlockType={isOptionVisible}
              />
            )}
          </div>
          {type === BlockTypes.text
            && !(
              localState.getCurrentContent
              && localState.getCurrentContent().hasText()
            ) && (
              <CreateBlockButton
                playlistField={playlistField}
                isShowBrowseBlocks={isShowBrowseBlocks}
                selectAddEditableBlock={selectAddEditableBlock}
                isBuilderTextElement={isBuilderTextElement}
                componentId={componentId}
                index={index}
                changeType
              />
          )}
        </div>
        {isLinkVisible && (
          <AddLink
            confirmLink={confirmLink}
            initialUrl={url}
            toolbarRef={toolbarRef}
            editorWrapperRef={editorWrapperRef}
            toggleSelect={toggleSelect}
            close={() => setIsLinkVisible(false)}
            refLink={refLink}
          />
        )}

        {/* {isLinkVisible */}
        {/* // && showToolBar.current && !localState.getSelection().isCollapsed() */}
        {/* // && localState.getSelection().getHasFocus() */}
        {/* // && */}
        {/* <div */}
        {/*  // onMouseDown={prevent} */}

        {/*  <input ref={refLinkInput} className={styles.link_input}/> */}
        {/*  <div className={styles.link_apply}>APPLY</div> */}

        {/* </div> */}
        {/* } */}
      </div>
    </div>
  );
});

export default MomentBlockText;
