import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import User from '../../../../components/MainHeader/User';
import UserMenu from '../../../../components/UserMenu';
import useComponentVisible from '../../../../utils/hooks/useComponentVisible';
import { DATA_PARENTS } from '../../../../utils/constants';
import UnifiedChevronNavigation from '../../../../components/UnifiedNavigation/UnifiedChevronNavigation';
import Logo from '../../../../components/MainHeader/Logo';
import ChannelsTab from '../../MakerContent/ChannelsTab';
import { isRoleInPlaylist } from '../../../../utils/permissions';
import {
  LeftNav,
  RoleDISPLAYCoedit,
  RoleDISPLAYOwner,
  RoleDISPLAYView,
} from '../../sharedStyled';
import { ReactComponent as SenderSvg } from '../../../../images/2023/svg/sender_icon.svg';
import { ReactComponent as ViewerSvg } from '../../../../images/icons/icon_20/download_yellow.svg';
import { ReactComponent as CoEditorSvg } from '../../../../images/icons/icon_20/pencil_yellow.svg';
import {
  PlaylistMainHeaderInChannelWrapper,
  PlaylistMainHeaderRole,
  PlaylistMainHeaderRoleSection,
  PlaylistMainHeaderShell,
  PlaylistMainHeaderUserWrap,
} from './styled';

const roles = {
  author: 'SENDER',
  viewer: 'RECIPIENT',
  coEditor: 'CO-EDITOR',
};

const svgRoles = {
  [roles.author]: <SenderSvg />,
  [roles.coEditor]: <CoEditorSvg />,
  [roles.viewer]: <ViewerSvg />,
};

const { isCoEdit, isOwner, isViewer } = isRoleInPlaylist;
const PlaylistMainHeader = ({ isForSharedToWeb, isPlayer }) => {
  const { channelID } = useParams();
  const { t } = useTranslation();

  const [role, setRole] = useState('');
  const [currentChannelName, setCurrentChannelName] = useState({});

  const { owner, isCanCoEdit, editManager, inChannel, currentRole } = useSelector((state) => state.currentPage);
  const user = useSelector((state) => state.user);

  const [refUserMenu, isComponentVisible, setIsComponentVisible] = useComponentVisible(false, DATA_PARENTS.UserMenu);

  const toggleShowUserMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  useEffect(() => {
    if (owner && user.id) {
      if (owner?.id === user.id) {
        setRole(roles.author);
      } else if (isCanCoEdit) {
        setRole(roles.coEditor);
      } else {
        setRole(roles.viewer);
      }
    } else if (isForSharedToWeb) {
      setRole(roles.viewer);
    }


    if (inChannel?.length) {
      if (channelID) {
        setCurrentChannelName(inChannel.filter((c) => c.id === channelID)[0]);
      } else if (isCanCoEdit) {
        setCurrentChannelName(inChannel[0]);
      }
    }
  }, [editManager, owner, isCanCoEdit]);

  return (
    <PlaylistMainHeaderShell isPlayer={isPlayer}>
      <LeftNav>
        <Logo isMaker isForSharedToWeb={isForSharedToWeb} />
        <UnifiedChevronNavigation
          isForSharedToWeb={isForSharedToWeb}
          isPlayer={isPlayer}
        />
      </LeftNav>
      {process.env.REACT_APP_ENVIRONMENT === 'development' && (
        <>
          {isCoEdit[currentRole] && (
            <RoleDISPLAYCoedit>{t('coeditLowT')}</RoleDISPLAYCoedit>
          )}
          {isOwner[currentRole] && <RoleDISPLAYOwner>{t('ownerLowT')}</RoleDISPLAYOwner>}
          {isViewer[currentRole] && <RoleDISPLAYView>{t('viewerLowT')}</RoleDISPLAYView>}
        </>
      )}
      <PlaylistMainHeaderRoleSection>
        <PlaylistMainHeaderInChannelWrapper>
          {role !== roles.author && currentChannelName.id && (
            <>
              <div>{t('inChannelT')} : {currentChannelName.name}</div>
              {inChannel?.length > 1 && (
                <ChannelsTab
                  currentChannelID={currentChannelName.id}
                  channels={inChannel}
                />
              )}
            </>
          )}
        </PlaylistMainHeaderInChannelWrapper>

        <PlaylistMainHeaderRole>
          {svgRoles[role]}
          {role}
        </PlaylistMainHeaderRole>


      </PlaylistMainHeaderRoleSection>

      {!isForSharedToWeb && (
        <PlaylistMainHeaderUserWrap>
          <User
            isNew
            toggleUserMenu={toggleShowUserMenu}
            isActive={isComponentVisible}
            isMaker
          />
          {isComponentVisible && (
            <UserMenu
              refUserMenu={refUserMenu}
              setIsComponentVisible={setIsComponentVisible}
            />
          )}
        </PlaylistMainHeaderUserWrap>
      )}
    </PlaylistMainHeaderShell>
  );
};

export default PlaylistMainHeader;
