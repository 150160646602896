export const OutboxAction = {
  DeleteOutboxCard: '[OutboxAction] Delete Outbox Card',
  DeleteOutboxCards: '[OutboxAction] Delete Outbox Cards',
  UploadOutboxCardSaga: '[OutboxAction] Upload Outbox Card Saga',
  Clear: '[OutboxAction] Clear Outbox Card Saga',
  UploadOutboxCardRedux: '[OutboxAction] Upload Outbox Card Redux',
  UpdatePageInOutboxCardRedux: '[OutboxAction] Update page Outbox Card Redux',
  FilterOutboxCards: '[OutboxAction] Filter Outbox Cards',
  UpdateCounter: '[OutboxAction] Update counter',
  UpdateCounterS: '[OutboxAction] Update counter Saga',
  UploadPage: '[OutboxAction] Upload page',
  AddNewAwaitedNotification: '[OutboxAction] Add new updated notification',
  CheckCurrentPageAddNewAwaitedNotification: '[OutboxAction] Check Current Page and go to Add new updated notification',
  ClearAwaitedNotification: '[OutboxAction] Clear notification',
  UpdateNotificationNotificationAfterUpdate: '[OutboxAction] Update Notification Notification After Update',
};
