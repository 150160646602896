import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ArrowDownSvg } from '../../../../images/2023/svg/small/fluent-table/arrow_down_12_14.svg';
import { actionCreator } from '../../../../shared/redux/actionHelper';
import { ContentActionType } from '../../../../redux/content/contentTypes';
import { actionChangeSortSagas } from '../../../../redux/settings/actions';
import { actionSwitchContentSortType } from '../../../../redux/content/actions';
import { sortByType } from '../../../DropDownOption/helpers/newSorting';
import { LOCAL_SORT_MODE, LOCAL_SORT_TYPE } from '../../../DropDownOption/helpers/SortOptionENUM';

const sortEnum = {
  isModified: {
    lastModified: 'firstModified',
    firstModified: 'lastModified',
  },
  isName: {
    nameAtoZ: 'nameZtoA',
    nameZtoA: 'nameAtoZ',
  },
  isCreated: {
    lastAdded: 'firstAdded',
    firstAdded: 'lastAdded',
  },
  isType: {
    typeAtoZ: 'typeZtoA',
    typeZtoA: 'typeAtoZ',
  },
};
const SvgArrowWrapper = styled.div`
  visibility: hidden;
  svg {
    path {
      stroke: #585858;
    }
  }

  ${({ shouldTransform }) => (shouldTransform
    ? `
   top: 5px;
 transform: rotate(180deg);`
    : ' top: 8px;')}
  position: absolute;

  ${({ isModified }) => (isModified ? 'left: 75px;' : '')}
  ${({ isName }) => (isName ? 'left: 57px;visibility: hidden;' : '')}
  ${({ isCreated }) => (isCreated ? 'left: 69px;' : '')}
  ${({ active }) => (active ? 'visibility:visible;' : '')}
  &:hover {
    visibility: visible;
  }
`;

const SortComponent = ({ isModified, isCreated, isName, isSize, isContact, isType, ...props }) => {
  const { sortType, activeNavSlider, selectorType } = useSelector(
    (state) => state.content,
  );
  const dispatch = useDispatch();
  const handlerClickType = (key, order) => {
    if (activeNavSlider === 'smartfiles' || activeNavSlider === 'help' || activeNavSlider === 'channelPlaylists') {
      const sortType = sortByType(key, (order ? LOCAL_SORT_MODE.DESCENDING : LOCAL_SORT_MODE.ASCENDING), props.setSort);
      dispatch(actionSwitchContentSortType(sortType));
      dispatch(actionChangeSortSagas(sortType));
      dispatch(
        actionCreator(ContentActionType.startUpload, {
          activeNavSlider,
          selectorType,
          sortType,
          isNeedUpdate: true,
        },
        'sortComponent'),
      );
    } else if (activeNavSlider === 'contact') {
      props.setSort(order ? sortEnum.isName.nameAtoZ : sortEnum.isName.nameZtoA);
    } else {
      const sortType = sortByType(key, (order ? LOCAL_SORT_MODE.DESCENDING : LOCAL_SORT_MODE.ASCENDING), props.setSort);
      dispatch(actionSwitchContentSortType(sortType));
      dispatch(actionChangeSortSagas(sortType));
    }
  };
  const calcText = useCallback(() => {
    if (isModified) {
      return 'Modified';
    }
    if (isCreated) {
      return 'Created';
    }
    if (isType) {
      return 'Type';
    }
    if (isName) {
      return isContact ? 'Name' : 'Title';
    }
    if (isSize) {
      return 'Size';
    }
    return 'Sender';
  }, [isSize, isModified, isCreated, isType, isName, isContact]);
  return (
    <div className="custom_header">
      {calcText()}
      {isModified && (
        <SvgArrowWrapper
          className={`svg_arrow_wrapper  ${
            sortType === 'recent' ? 'in_recent_mode' : ''
          }`}
          active={!!sortEnum.isModified[sortType]}
          onClick={() => handlerClickType(LOCAL_SORT_TYPE.MODIFIED, sortType === 'firstModified')
          }
          isModified
          shouldTransform={sortType === 'firstModified'}
        >
          <ArrowDownSvg />
        </SvgArrowWrapper>
      )}
      {isCreated && (
        <SvgArrowWrapper
          className={`svg_arrow_wrapper  ${
            sortType === 'recent' ? 'in_recent_mode' : ''
          }`}
          active={!!sortEnum.isCreated[sortType]}
          onClick={() => handlerClickType(LOCAL_SORT_TYPE.CREATED, sortType === 'firstAdded')}
          isCreated
          shouldTransform={sortType === 'firstAdded'}
        >
          <ArrowDownSvg />
        </SvgArrowWrapper>
      )}
      {isName && (
        <SvgArrowWrapper
          className={`svg_arrow_wrapper  ${
            sortType === 'recent' ? 'in_recent_mode' : ''
          }`}
          active={!!sortEnum.isName[sortType]}
          onClick={() => handlerClickType(LOCAL_SORT_TYPE.NAME, sortType === 'nameAtoZ')}
          isName
          shouldTransform={sortType === 'nameZtoA'}
        >
          <ArrowDownSvg />
        </SvgArrowWrapper>
      )}
      {isType && (
        <SvgArrowWrapper
          className={`svg_arrow_wrapper  ${
            sortType === 'recent' ? 'in_recent_mode' : ''
          }`}
          active={!!sortEnum.isType[sortType]}
          onClick={() => handlerClickType(LOCAL_SORT_TYPE.TYPE, sortType === 'typeZtoA')}
          isName
          shouldTransform={sortType === 'typeZtoA'}
        >
          <ArrowDownSvg />
        </SvgArrowWrapper>
      )}
    </div>
  );
};

export default SortComponent;
