import React from 'react';
import { convertDate } from '../../../../../utils/dateConvert';
import { ReactComponent as EditSvg } from '../../../../../images/icons/edit_page.svg';
import { AvailabilityDateWrapper, AvailabilityMainWrapper, SvgWrapper } from './styled';
import { emptyCallback } from '../../../../../utils/constants';


const Availability = ({ endDate, isChannel, handleClick, user, isShared, ...props }) => {
  if (!user) return <></>;
  const exEndDate = isShared && user.sharedAvailableTo ? user.sharedAvailableTo : endDate;
  const date = exEndDate
    ? `End date: ${convertDate(exEndDate).toUpperCase()}`
    : 'End date: Open to read';

  return (
    <AvailabilityMainWrapper isChannel={isChannel} data-parent={props.dataParent}>
      <AvailabilityDateWrapper>{date}</AvailabilityDateWrapper>
      <SvgWrapper isEdit onClick={handleClick || emptyCallback}>
        <EditSvg data-parent={props.dataParent} />
      </SvgWrapper>
    </AvailabilityMainWrapper>
  );
};

export default Availability;
