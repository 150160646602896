import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import rawStyles from './index.module.scss';
import { calculateIndex, checkString } from '../../utils/helpers';
import TagsList from './TagsList';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import {
  actionCheckTag,
  actionDeleteTag,
  actionCreateTag,
} from '../../redux/tags/action';
import {
  DATA_PARENTS,
  DEFAULT_POSITION_STEP,
  empty,
  emptyArr,
} from '../../utils/constants';
import { createRandomColor } from './utils';
import { actionCreator } from '../../shared/redux/actionHelper';
import SupportAction from '../../redux/support/types';
import { Tag } from '../../components/Tag';
import { calcColorClassname } from '../../sagas/helpers';
import { Tags } from '../../redux/tags/types';

const styles = classnames.bind(rawStyles);

const HashtagsInput = ({
  isEditable = true,
  showMessageNotEditable,
  isPage,
  type,
  state,
  noDescription,
  isExpanded,
  tagsInputRef,
  isComponentDescription,
  place,
  ...props
}) => {
  const dataParent = DATA_PARENTS.PlaylistBuilderLibrary;
  const { id: componentId, folderId } = useSelector(
    (reduxState) => reduxState.support.componentDescriptionData || empty,
  );
  const { id: currentPageId } = useSelector(
    (reduxState) => reduxState.currentPage || empty,
  );
  const componentTags = useSelector((reduxState) => {
    if (!componentId && !props.modalComponentId) return {};
    if (props.isModalOnCurrentPage) {
      return (
        reduxState?.content.contentData[props.modalComponentId]?.tags?.filter(
          (t) => {
            return reduxState?.user?.tags && reduxState.user.tags[t.id];
          },
        ) || []
      );
    }
    return (
      reduxState?.content.contentData[componentId]?.tags?.filter((t) => {
        return reduxState?.user?.tags && reduxState.user.tags[t.id];
      }) || []
    );
  });
  const selectedPageTags = useSelector((reduxState) => {
    if (props.isSelected) {
      const uniqTags = {};
      const allTagInSelected = Object.keys(props.selectedPage)
        .map((pageId) => {
          return (
            reduxState?.content.contentData[pageId]?.tags?.filter((t) => {
              return reduxState?.user?.tags && reduxState.user.tags[t.id];
            }) || []
          );
        })
        .flat();
      allTagInSelected.forEach((tag) => {
        if (uniqTags[tag.id]) return;
        uniqTags[tag.id] = tag;
      });
      return Object.values(uniqTags);
    }
    return [];
  });
  // const [throttleSearch] = useThrottle();
  const history = useHistory();
  const [activeInput, setActiveInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [randomColor, setRandomColor] = useState('');
  const [isTagListUpdated, setIsTagListUpdated] = useState(false);

  const [refList, isListActive, setIsListActive] = useComponentVisible(
    false,
    'tagList',
  );
  const dispatch = useDispatch();
  const hashtagsInput = useRef(null);
  const { id } = useSelector((reduxState) => reduxState.currentPage) || empty;
  const { anchorId } = useSelector((reduxState) => reduxState.support) || empty;
  // const hashtags = useSelector(reduxState => reduxState.currentPage.hashtags) || emptyArr;
  const playlistTags = useSelector((reduxState) => reduxState.currentPage.tags) || emptyArr;
  const allTags = useSelector((reduxState) => reduxState.user.tags) || emptyArr;
  const tagItems = props.isSelected
    ? selectedPageTags
    : props.isLibraryComponentSettings
      ? componentTags
      : playlistTags;
  // const tagItems = (state.type === 'hashtags' ? hashtags : tags) || emptyArr;
  const components = useSelector(
    (reduxState) => reduxState.content.contentData,
  );
  const playlists = useSelector((reduxState) => reduxState.playlists);
  const isPlaylists = history.location.pathname === '/playlists';
  const isInit = useRef(true);


  const saveTag = (e, clickedItem) => {
    e.stopPropagation();
    e.preventDefault();
    setIsTagListUpdated(true);
    if (tagItems.length < 10 && checkString(inputValue)) {
      const tempId = props.modalComponentId || componentId || id;
      const tempTags = isPlaylists
        ? playlists[tempId]?.tags
        : components[tempId]?.tags;
      if (!clickedItem) {
        if (!inputValue.trim()) return;
        const nestedPosition = calculateIndex(
          tagItems[tagItems.length - 1]?.position,
        );
        const position = Math.max(
          ...Object.values(allTags || {}).map((tag) => tag.position || 0),
          0,
        ) + DEFAULT_POSITION_STEP;
        const newId = uuidv4();

        const newTag = {
          type: 'tags',
          id: newId,
          title: inputValue.trim().replace(/\s+/g, ' '),
          position,
          nestedPosition,
          backgroundColor: randomColor,
          lastModifiedDate: Math.floor(Date.now() / 1000),
        };
        const lastModifiedDate = Math.floor(Date.now() / 1000);
        dispatch(actionCreateTag(newTag));
        if (props.isSelected) {
          const parents = Object.keys(props.selectedPage).map((parentId) => {
            return { id: parentId, collectionId: folderId, lastModifiedDate };
          });
          dispatch(
            actionCreator(SupportAction.updateTag.AddLocal, {
              tag: { id: newId },
            }),
          );
          dispatch(
            actionCreator(Tags.actionAttachTagToParentsRedux, {
              tag: newTag,
              type,
              parents,
              lastModifiedDate,
            }),
          );
        } else {
          dispatch(
            actionCheckTag(
              newTag,
              {
                id: tempId,
                folderId,
                tags: tempTags || [],
                lastModifiedDate,
              },
              type,
              isPage,
              props.isTemplate,
              props.isSelected,
            ),
          );
        }
      } else {
        const nestedPosition = calculateIndex(
          tagItems[tagItems.length - 1]?.position,
        );
        const lastModifiedDate = Math.floor(Date.now() / 1000);
        if (props.isSelected) {
          const parents = Object.keys(props.selectedPage).map((parentId) => {
            return { id: parentId, collectionId: folderId, lastModifiedDate };
          });
          dispatch(
            actionCreator(SupportAction.updateTag.AddLocal, {
              tag: { id: clickedItem.id },
            }),
          );
          dispatch(
            actionCreator(Tags.actionAttachTagToParentsRedux, {
              tag: { ...clickedItem, nestedPosition },
              type,
              parents,
              lastModifiedDate,
            }),
          );
        } else {
          dispatch(
            actionCheckTag(
              {
                ...clickedItem,
                nestedPosition,
              },
              {
                id: tempId,
                folderId,
                tags: tempTags || [],
                lastModifiedDate,
              },
              type,
              isPage,
              props.isTemplate,
              props.isSelected,
            ),
          );
        }
      }
      setInputValue('');
      setActiveInput(false);
      hashtagsInput.current.focus();
    } else hashtagsInput.current.focus();
    setInputValue('');
  };

  //
  // const searchHashTags = (value) => {
  //   dispatch(actionFindHashTags(value));
  // };

  const handleInputChange = (e) => {
    if (!isListActive) setIsListActive(true);
    const inputVal = e.target.value.replace(/\s+/g, ' ');
    if (checkString(inputVal)) {
      setInputValue(inputVal);
    }
  };

  const handleInputKeyDown = (e) => {
    if (inputValue && !isListActive) {
      setIsListActive(true);
      setActiveInput(false);
    }
    // if (e.keyCode === 32 && !!inputValue.trim()) {
    //
    // }
    if (e.key === 'Enter') {
      if (
        tagItems.length < 10
        && checkString(e.target.value)
        && !!inputValue.trim()
      ) {
        // if (state.type === 'hashtags') {
        //   saveHashtag(e);
        // } else {
        saveTag(e);
        // }
      }
    }
    if (e.key === 'Escape') {
      e.currentTarget.blur();
      setInputValue('');
    }
    if (e.key === 'Backspace' && tagItems?.length && !inputValue) {
      setInputValue('');
      // if (state.type === 'hashtags') {
      //   deleteHashtag(e, tagItems[tagItems.length - 1]?.title);
      // } else {
      deleteTag(e, tagItems[tagItems.length - 1]?.id);
      // }
    }
    if (e.key === 'Backspace' && !tagItems?.length && !inputValue) {
      e.target.blur();
      setIsListActive(false);
    }
  };

  // const deleteHashtag = (e, title) => {
  //   e.stopPropagation();
  //   const lastModifiedDate = Math.floor(Date.now() / 1000);
  //   dispatch(actionDeleteHashtag({ title }, { id, lastModifiedDate }, isPage));
  // };
  const deleteTag = (e, tagId, index) => {
    e.stopPropagation();
    setIsTagListUpdated(true);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    if (props.isSelected) {
      const parents = Object.keys(props.selectedPage).map((parentId) => {
        return { id: parentId, collectionId: folderId, lastModifiedDate };
      });
      if (props.isTemplate) {
        dispatch(
          actionCreator(SupportAction.updateTag.DeleteLocal, {
            tag: { id: tagId },
            parent: { id: null, collectionId: folderId, lastModifiedDate },
            type,
            lastModifiedDate,
            isPage,
            isSelected: props.isSelected,
          }),
        );
      }
      dispatch(
        actionCreator(Tags.MultipleDeleteTag, {
          tag: { id: tagId },
          index,
          type,
          parents,
          lastModifiedDate,
          isPage,
        }),
      );
    } else {
      const parentId = props.modalComponentId || componentId || currentPageId;
      if (props.isTemplate) {
        dispatch(
          actionCreator(SupportAction.updateTag.DeleteLocal, {
            tag: { id: tagId },
            parent: { id: parentId, collectionId: folderId, lastModifiedDate },
            index,
            type,
            lastModifiedDate,
            isPage,
          }),
        );
        return;
      }
      dispatch(
        actionDeleteTag(
          { id: tagId },
          index,
          type,
          { id: parentId, collectionId: folderId, lastModifiedDate },
          lastModifiedDate,
          isPage,
        ),
      );
    }
  };

  useEffect(() => {
    if (!isListActive) {
      setInputValue('');
      setActiveInput(false);
      if (!isInit.current && isComponentDescription) {
        dispatch(
          actionCreator(SupportAction.checkExistItemInFilter, {
            id: componentId,
          }),
        );
      }
    } else if (isInit.current) {
      isInit.current = false;
    }
    if (isListActive && hashtagsInput.current) {
      hashtagsInput.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isListActive]);

  useEffect(() => {
    if (!!hashtagsInput?.current && anchorId === 'meta_tags_input') {
      setActiveInput(true);
      setTimeout(() => {
        hashtagsInput.current.focus();
        dispatch(actionCreator(SupportAction.JumpTo, {}));
      }, 0);
    }
  }, [anchorId]);

  useEffect(() => {
    if (!inputValue) {
      setRandomColor(createRandomColor());
    }
  }, [inputValue]);

  useEffect(() => {
    if (props.isSelected && selectedPageTags.length) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      const tagIds = [...selectedPageTags].map((tag) => tag.id);
      dispatch(actionCreator(SupportAction.updateTag.AddTagsLocal, { tagIds }));
      dispatch(
        actionCreator(Tags.actionAttachTagsToParentRedux, {
          selectedPage: props.selectedPage,
          tags: selectedPageTags,
          lastModifiedDate,
        }),
      );
    }
  }, [props.isSelected]);

  useEffect(() => {
    return () => {
      if (!isTagListUpdated || !isComponentDescription) return;
      dispatch(
        actionCreator(SupportAction.updateTag.Save, { id: componentId, history }),
      );
    };
  }, [isTagListUpdated]);

  return (
    <div
      className={styles('item_wrapper', `${state.type}`, {
        isPlaylist: type === 'Playlist',
        isPage,
        isExpanded,
        isComponentDescription,
        noMargins: props.modalComponentId || props.isSelected,
      })}
      data-parent={dataParent}
    >
      <div>
        {!isComponentDescription && (
          <div
            className={styles('item_title', 'hashtags', {
              active: activeInput,
              noDescription,
              isPlaylist: type === 'Playlist',
            })}
            data-parent={dataParent}
          >
            {state.title}
          </div>
        )}
        {state.description && !noDescription && (
          <div
            className={styles('item_description', 'hashtags', {
              active: activeInput,
            })}
            data-parent={dataParent}
          >
            {state.description}
          </div>
        )}
        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} tagList`}
          ref={refList}
          className={styles('item_container', 'hashtags', {
            active: activeInput,
            isExpanded,
          })}
        >
          <div
            data-parent={dataParent}
            onClick={() => {
              if (!isEditable) showMessageNotEditable();
            }}
          >
            <div
              className={styles({ not_editable: !isEditable })}
              style={isExpanded ? { width: '100%' } : {}}
            >
              <div
                className={styles('input_hashtags_wrapper', {
                  empty: !tagItems.length,
                })}
                data-parent={dataParent}
              >
                <div
                  data-parent={dataParent}
                  className={styles('hashtags_container', {
                    isExpanded,
                    forComponentDescription: isComponentDescription,
                  })}
                  onClick={() => {
                    setActiveInput(true);
                    setTimeout(() => {
                      hashtagsInput.current?.focus();
                    }, 0);
                  }}
                >
                  {tagItems
                    ?.sort((a, b) => a.position - b.position)
                    .map((item) => {
                      return (
                        <Tag
                          key={item.id}
                          color={allTags[item.id].color}
                          backgroundColor={allTags[item.id].backgroundColor}
                          id={item.id}
                        >
                          <div className={calcColorClassname(item)}>
                            {item.title}
                          </div>
                        </Tag>
                      );
                    })}
                  {/*  {(state.type === 'tags') */}
                  {/* && ( */}
                  <input
                    data-parent={dataParent}
                    autoComplete="off"
                    ref={hashtagsInput}
                    type="text"
                    value={inputValue}
                    readOnly={tagItems.length > 9}
                    onChange={handleInputChange}
                    className={styles({
                      emptyInput: tagItems.length > 9,
                      hidden:
                        !activeInput && !isListActive && !!tagItems?.length,
                    })}
                    onKeyDown={handleInputKeyDown}
                    onBlur={() => {
                      setInputValue('');
                      setActiveInput(false);
                    }}
                    onFocus={() => {
                      setActiveInput(true);
                      setIsListActive(true);
                    }}
                    placeholder={
                      !tagItems?.length || tagItems?.length === 0
                        ? state.placeholder(place)
                        : ''
                    }
                  />
                  {/* )} */}
                  {/*  {state.type !== 'tags' */}
                  {/* && ( */}
                  {/*  <input */}
                  {/*    data-parent={dataParent} */}
                  {/*    autoComplete="off" */}
                  {/*    ref={hashtagsInput} */}
                  {/*    type="text" */}
                  {/*    value={inputValue} */}
                  {/*    readOnly={tagItems.length > 9} */}
                  {/*    onChange={handleInputChange} */}
                  {/*    onKeyDown={handleInputKeyDown} */}
                  {/*    className={styles({ */}
                  {/*      emptyInput: tagItems.length > 9, */}
                  {/*      hidden: (!activeInput && !isListActive && !!tagItems?.length) })} */}
                  {/*    onFocus={() => { */}
                  {/*      setActiveInput(true); */}
                  {/*      setIsListActive(true); */}
                  {/*    }} */}
                  {/*    maxLength={64} */}
                  {/*    onBlur={() => { */}
                  {/*      setInputValue(''); */}
                  {/*      dispatch(actionUpdateHashTags([])); */}
                  {/*      setActiveInput(false); */}
                  {/*    }} */}
                  {/*    placeholder={(!tagItems?.length || tagItems?.length === 0) ? state.placeholder : ''} */}
                  {/*  /> */}
                  {/* )} */}
                </div>
              </div>
              {/* {(state.type === 'tags') && ( */}
              <TagsList
                state={state}
                inputValue={inputValue}
                isListActive={isListActive}
                deleteCb={deleteTag}
                saveCb={saveTag}
                activeInput={activeInput}
                type={type}
                randomColor={randomColor}
                outerCloseAllMenus={props.outerCloseAllMenus}
              />
              {/* )} */}
              {/* {(state.type !== 'tags') && ( */}
              {/* <HashTagsList */}
              {/*  state={state} */}
              {/*  inputValue={inputValue} */}
              {/*  activeInput={activeInput} */}
              {/*  type={type} */}
              {/*  saveHashtag={saveHashtag} */}
              {/*  isListActive={isListActive} */}
              {/*  setIsListActive={setIsListActive} */}
              {/*  deleteCb={state.type === 'hashtags' ? deleteHashtag : deleteTag} */}
              {/*  saveCb={state.type === 'hashtags' ? saveHashtag : saveTag} */}
              {/* /> */}
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HashtagsInput;
