import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rawStyles from './index.module.scss';
import { DATA_PARENTS, empty } from '../../../utils/constants';
import { convertToSmartNumbers, calcSelectorForSmartfiles } from '../../../utils/helpers';
import { MainNavigate } from '../../../navigate/navigate';
import SelectorDropDown from '../../LowResolutionNavDropDown/LowResolutionNavDropDown';
import SecondaryMenu from '../../LibraryHeaderMenu/childComponents/SecondaryMenu';
import { actionChangeSieveValue } from '../../../redux/library/actions';

import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import { Main, MainNavSliderShell, New, Text } from './styled';
import DynamicTabForMainNav from './DynamicTabForMainNav';
import { actionSwitchContentSortType } from '../../../redux/content/actions';
import { sortOptionChooser } from '../../../utils/sort/sortTypeChooser';


const styles = classnames.bind(rawStyles);
const NewTolTip = ({ count }) => <New>{count}</New>;

const MainNavSlider = ({ ...props }) => {
  const history = useHistory();
  const [selectorOptions, setSelectorOptions] = useState([]);
  const [sieveOptions, setSieveOptions] = useState([]);
  const { place = 'smartfiles', selector = 'all' } = useParams();

  const navOptionsRefs = useRef(
    Array.from(Object.values(sieveOptions || {}), () => React.createRef()),
  );
  const isDescriptionOpened = useSelector(
    (state) => state.support.componentDescription,
  );
  const { counters = empty } = useSelector((state) => state.content);
  const { activeSieveOption } = useSelector((state) => state.library);
  const sortOptions = useSelector((state) => state.settings.sortOptions);
  const { tags } = useSelector((state) => state?.user);
  const { unseenPagesManagerMap, unseenPlaylistManagerMapInAll } = useSelector(
    (state) => state.support,
  );
  const isHiddenSeparator = place === 'smartfiles';
  const isDynamicTabActive = !!activeSieveOption?.id;
  const dispatch = useDispatch();
  const isTagMode = activeSieveOption?.mode === 'tag';
  const dynamicSmartviews = useSelector((state) => state.dynamicCollection);
  const dynamicCollection = isTagMode ? tags : dynamicSmartviews;
  let tagCounter = 0;
  const isResentTab = selector === 'recently_viewed';
  const { t } = useTranslation();

  if (isTagMode) {
    tagCounter = tags[activeSieveOption.id] ? tags[activeSieveOption.id].itemsCounter : 0;
  }
  const clickDynamicTab = () => {
    history.push(
      `/content/library/${isTagMode ? 'tag' : 'dynamic'}_${
        activeSieveOption.id
      }`,
    );
  };
  const unseenCounter = (value) => {
    if (`/${place}/${value}` === '/pages/shared') return Object.keys(unseenPagesManagerMap).length;
    if (`/${place}/${value}` === '/smartfiles/shared') return Object.keys(unseenPlaylistManagerMapInAll).length;
    return 0;
  };
  const [exOptionRef, isExOpened, setIsExOpened] = useComponentVisible(
    false,
    DATA_PARENTS.smartview,
  );

  const toggleDynamicDD = (e) => {
    e.stopPropagation();
    setIsExOpened(!isExOpened);
  };
  const closeDynamicTab = (e, withHistory = true) => {
    e && e.stopPropagation();
    props.setShowNewSmartView(false);
    dispatch(actionChangeSieveValue('[LibComponent]Pages', 'library'));
    if (withHistory) history.push('/content/library/all');
  };
  const setSieve = (value) => {
    if (!MainNavigate[place].selectors[value]) return;
    const sortName = sortOptionChooser(place, value);
    if (sortName && sortOptions[sortName]) {
      dispatch(actionSwitchContentSortType(sortOptions[sortName]));
    }
    history.push(`/content/${place}/${value}`);
  };

  const convert = (itemType) => {
    if (itemType === 'board') return '';
    if (place === 'smartfiles' && counters[`playlists/${itemType}`]) return counters[`playlists/${itemType}`];
    if (counters[`${place}/${itemType}`]) return counters[`${place}/${itemType}`];
    return 0;
  };
  // const isOptionComponentsInSideBar = (itemType) => itemType === LibCompSieveTypesSideBar.Components
  //   && history.location.pathname.includes('playlist');

  const calculateDisplayedSelector = (itemType) => {
    if (itemType === 'sharedByMe') return t('sharedT').toUpperCase();
    if (itemType === 'shared') return t('sharedWithMeT').toUpperCase();
    if (itemType === 'recently_viewed') return t('recentlyViewedT').toUpperCase();
    if (itemType === 'recently_edited') return t('recentlyEditedT').toUpperCase();
    return (t(`${itemType?.toLowerCase()}T`) || itemType).toUpperCase();
  };

  useEffect(() => {
    const calcSieveOptions = Object.assign({},
      place === 'smartfiles' ? MainNavigate[place]?.selectorsTabsExtensions[calcSelectorForSmartfiles(selector)]
        : MainNavigate[place]?.selectorsOrder);
    setSieveOptions(calcSieveOptions);
    navOptionsRefs.current = Array.from(Object.values(calcSieveOptions || {}), () => React.createRef());
    setSelectorOptions({ selectorsOrder: Object.values(calcSieveOptions || {}) });
  }, [place, selector]);

  return (
    <MainNavSliderShell isSmartFiles={place === 'smartfiles'}>
      <Main>
        {!!Object.values(sieveOptions || {}).length && (
          <>
            <div className={styles('nav_bar', { isDescriptionOpened })}>
              <div className={styles('isNavVisible', { isDescriptionOpened })}>
                {Object.values(sieveOptions || {}).map((itemType, index) => (
                  <div
                    data-cy={itemType}
                    ref={navOptionsRefs.current[index]}
                    data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
                    className={styles('nav_item', {
                      first: index === 0 && !isHiddenSeparator,
                    })}
                    key={`${itemType}`}
                    onClick={() => {
                      setSieve(itemType);
                    }}
                  >
                    {!!unseenCounter(itemType) && (
                      <NewTolTip count={unseenCounter(itemType)} />
                    )}
                    <Text
                      active={itemType?.toLowerCase() === selector?.toLowerCase()}
                    >
                      {calculateDisplayedSelector(itemType)}
                      <div
                        className={styles('users_count', {
                          active:
                            itemType?.toLowerCase() === selector?.toLowerCase(),
                        })}
                      >
                        {itemType === 'board'
                          ? ''
                          : convertToSmartNumbers(convert(itemType)) || '??'}
                      </div>
                    </Text>
                  </div>
                ))}
              </div>
              <SelectorDropDown
                dataP={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
                setSelector={setSieve}
                selector={selector}
                itemsList={selectorOptions}
                calcCount={(itemType) => convertToSmartNumbers(convert(itemType))}
                className={styles('isDropdownVisible')}
              />
              {isDynamicTabActive && (
                <DynamicTabForMainNav
                  isExOpened={isExOpened}
                  setIsExOpened={setIsExOpened}
                  clickDynamicTab={clickDynamicTab}
                  exOptionRef={exOptionRef}
                  dynamicCollection={dynamicCollection}
                  activeSieveOption={activeSieveOption}
                  setSmartViewForEdit={props.setSmartViewForEdit}
                  setShowNewSmartView={props.setShowNewSmartView}
                  resetSmartview={props.resetSmartview}
                  isTagMode={isTagMode}
                  selector={selector}
                  calculateDisplayedSelector={calculateDisplayedSelector}
                  closeDynamicTab={closeDynamicTab}
                  tagCounter={tagCounter}
                  counters={counters}
                  toggleDynamicDD={toggleDynamicDD}
                />
              )}
            </div>

          </>
        )}
      </Main>
      <SecondaryMenu
        dataParent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
        sortType={props.sortType}
        setSort={props.setSort}
        place={place}
        isHideSort={isResentTab}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        smartViewForEdit={props.smartViewForEdit}
        setSmartViewForEdit={props.setSmartViewForEdit}
      />
    </MainNavSliderShell>
  );
};

export default MainNavSlider;
