import styled from 'styled-components/macro';
import { spinTheSpinner } from '../../../Channels/MemberRow/RolesCell/Spinner';

export const TableWrapper = styled.div`
  margin-top: 6px;
`;
export const DragLineWrap = styled.tr`
  transition-property: height, opacity, top, left;
  padding: 0 !important;
  ${({ dragHover }) => (dragHover
    ? `
  height: 24px  !important;
`
    : `
  height: 0 !important;
`)}
}
`;
export const LastDragLineWrap = styled.div`
  opacity: 0;
  position: relative;
  transition-property: height, opacity, top, left;
  left: 64px;
  top: 1px;
  height: 0.01px;
  width: 100%;
  transition: ease-out 0.25s;

  & svg {
    position: absolute;
  }

  ${({ dragHover }) => (dragHover
    ? `
  height: 24px;
   opacity:1;
   top: 7px;
    left: 64px;
`
    : '')}
}
`;
export const DragLineWrapDiv = styled.td`
  opacity: 0;
  position: relative;
  transition-property: height, opacity, top, left;
  top: 1px;
  height: 0.01px !important;
  width: 100%;
  transition: ease-out 0.25s;

  & svg {
    transition-property: height, opacity, top, left;
    top: 10px;
    left: 240px;
    position: absolute;
  }

  padding: 0 !important;
  ${({ dragHover }) => (dragHover
    ? `
  height: 24px  !important;
   opacity:1;
  & svg {
    top: 16px;
    left: 244px;
  }
`
    : `
  
`)}
}
`;
export const DNDElementWrap = styled.div`
  position: relative;
  transition-property: padding;
  transition: ease-out 0.25s;
  ${({ dragHover }) => (dragHover
    ? `
     padding-top: 24px;
  `
    : '')}
  ${({ dragHoverLast }) => (dragHoverLast
    ? `
     padding-bottom: 24px;
  `
    : '')}
`;
export const DropPlace = styled.div`
  position: absolute !important;
  transition-property: height;
  top: -20px;
  ${({ dragHover }) => (dragHover
    ? `
  height: 70px  !important;
`
    : `
  height: 0.01px !important;
`)}
  left: -206px;
  width: calc(100% + 204px);
`;
export const LastItem = styled.div`
  width: 100%;
  height: 12px;
`;
export const AvatarWrapper = styled.div`
  position: absolute;
  left: -160px;
  top: 2px;
  ${({ itemColor }) => (itemColor
    ? `
  &::before {
    content: " ";
    border: ${itemColor ?? '#d6dde3'};
    border-style: solid;
    width: 16px;
    right: -119px;
    top: 20px;
    position: absolute;
    border-width: 1px;
  }
` : '')}
 ${({ isListView }) => (isListView
    ? `
      &::before {
        right: -115px;
        top: 19px;
      }
    ` : '')}
`;
export const DeleteWrapper = styled.div`
  position: absolute !important;
  right: -36px;
  top: 5px;
  width: 40px;
  height: 40px;
  padding-left: 20px;

  ${({ isPlaceholder }) => (isPlaceholder
    ? '  top: 10px;' : '')}
`;


export const DeleteButtonContainer = styled.div`
  width: 32px;
  height: 32px;
  border: 0.6px solid #ece2e2;
  border-radius: 32px;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
  //padding-left: 7px;
  //padding-top: 5px;
  display: none;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
 
`;
export const CustomTd = styled.td`
  position: relative;
  border-radius: 8px;
  ${({ isWrap }) => (isWrap
    ? `
    border: 1px solid #FFC72C;
        padding:0;
  `
    : '')}
`;
export const TitleWrapper = styled.div`
  padding-left: 10px;
`;
export const TextWrapper = styled.div`
    border-radius: 12px;
    border: 0.6px solid #ECE2E2;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(122, 105, 105, 0.10);
    width: 808px;
    margin: auto;
`;
export const ActionButton = styled.div`
  width: 28px !important;
  height: 28px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;

  &:hover {
    //background: #D7DDF7;
  }

  ${({ isDisabled }) => (isDisabled
    ? `
    &:hover {
      background: initial;
    }
    color: lightgrey;
    & svg path {
      stroke: lightgrey
    }
  `
    : '')}
  ${({ isActive }) => (isActive
    ? `
    background: #D7DDF7;
  `
    : '')}
`;
export const DownloadWrapper = styled.div`
  position: absolute;
  right: 50px;
  height: 26px;
  width: 26px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: #ffdf85;
  }

   ${({ isDisabled }) => (isDisabled
    ? `
    &:hover {
      background: initial;
    }
    color: lightgrey;
    & svg path {
      stroke: lightgrey
    }
  `
    : '')}
`;
export const LockWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 19px; 

  &:hover {
    //opacity: 1;
    & svg path {
      //stroke: black
    }
  }
`;
export const EditWrapper = styled.div`
  //opacity: 0;
  position: absolute;
  //background:  #E8EBFA;
  padding-top: 2px;
  right: 52px;

  &:hover {
    //opacity: 1;
    & svg path {
      //stroke: black
    }
  }
`;
export const PlaylistStatusSpinner = styled.div`
  margin-top: 8px;
  margin-right: 4px;

  & svg {
    width: 20px;
    height: 20px;
    animation: linear ${spinTheSpinner} 1.3s infinite;
  }
`;
export const PlaylistSelectorScrollWrapper = styled.div`
  border-radius: 12px;
  max-height: 362px;
  overflow: hidden;
  position: absolute;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  color: #484343;
  width: 250px;
  ${({ side }) => (side === 'left'
    ? `
    left: -249px;
  top: -81px;`
    : `
    left: 190px;
  top: -81px;
  `)}
  ${({ isMultiselect }) => (!isMultiselect
    ? ''
    : `
    left: 0px;
    top: -368px;
  `)}
  ${({ isMultiActionPanel }) => (isMultiActionPanel
    ? `
      top: auto;
      bottom: -10px;`
    : '')}
`;
export const PlaylistSelectorSelectorWrapper = styled.div`
  width: 250px;
  max-height: 362px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  //border-radius: 12px;

  overflow-y: scroll;
  overflow-x: hidden;
  scroll-margin-right: 4px;
  scroll-margin: 4px;
  border-bottom: 6px white solid;
  /* width */
  ::-webkit-scrollbar {
    display: none;
  }

  ${({ isForLib }) => (isForLib ? 'top: -78px;' : '')}
`;
export const PlaylistSelectorPlaylistTitleWrapper = styled.div`
  padding: 5px 12px 5px 16px;
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  &:hover {
    background-color: #ffeebf;
  }
`;
export const PlaylistSelectorSeparator = styled.div`
  width: 208px;
  height: 1px;
  background: rgba(29, 29, 29, 0.08);
  margin: 4px auto;
`;
export const PlaylistSelectorSearchWrapper = styled.input`
  font-weight: 400;
  font-size: 14px;
  border: none;
  padding-left: 16px;
  padding-bottom: 6px;

  ::placeholder {
    color: rgba(122, 105, 105, 0.5);
  }

  :-ms-input-placeholder {
    color: rgba(122, 105, 105, 0.5);
  }

  ::-ms-input-placeholder {
    color: rgba(122, 105, 105, 0.5);
  }
`;

export const PlayButtonWrapper = styled.div`
  position: absolute;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ right }) => (right ? `right : ${right}px;` : '')}
  
  :hover {
    border-radius: 6px;
    background: #FFDF85;
  }
`;
export const ThreeDotsDropWrapper = styled.div`
  width: 195px;
  position: absolute;
  z-index: 9999;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  padding-bottom: 10px;
  height: fit-content;
  ${({ isWholePlaylistOperation }) => (isWholePlaylistOperation
    ? `
    top: 22px;
    left: 311px;
  `
    : `  
          bottom: 8px;
          right: 50px;`)}

  ${({ isShort, isWholePlaylistOperation }) => (isShort && isWholePlaylistOperation ? 'left:260px;' : '')}
  ${({ forPreview }) => (forPreview
    ? `
    top: 26px;
    right: 63px;
    padding-bottom: 8px;
  `
    : '')}
  ${({ isForLib }) => (isForLib
    ? `
  top:32px;
  `
    : '')}
  ${({ positionDown }) => (positionDown ? '    bottom: -171px;' : '')}
  ${({ upvCard }) => (upvCard
    ? `
    bottom: -2px;
    right: -218px;
  `
    : '')}

  ${({ isMaker }) => isMaker
  && `
    top: 20px;
    left: 0;
  `}
  ${({ isPlayer }) => (isPlayer ? `
    bottom: unset; 
    top: 31px;
    right: 58px;
  ` : '')}
`;
