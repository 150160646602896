import React from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { MainNavigate, SideBarNavigate } from '../../navigate/navigate';
import { ReactComponent as SmartViewSVG } from '../../images/icons/smartView.svg';
import { ReactComponent as TagsGroupSvg } from '../../images/icons/tagsGroup.svg';
import { ReactComponent as PlaylistScg } from '../../images/2023/svg/Playlist_70_70.svg';

const MainContentShell = styled.div`
  display: flex;
  height: calc(100vh - 220px);
  background: inherit;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 20px 1px 1px 0;
  ${({ isEmptyState }) => (isEmptyState ? 'margin-right: 76px;' : '')};

  & svg path {
    stroke: #484343;
  }
  ${({ forUPV }) => (forUPV
    ? `
    top: 100px;
    height: max-content;
    left: 177px;`
    : '')}
`;

const MainText = styled.div`
  margin-top: 10px;
  font-family: RobotoFlex;
  font-size: 14px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #484343;
`;

const Text = styled.div`
  margin-top: ${({ noMarginTop }) => (noMarginTop ? 0 : '8px')};
  font-family: RobotoFlex;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #939393;
`;
export const ImageWrapper = styled.div`
  display: flex;
  width: 80px;
  height: 80px;

  ${({ isLibComponent }) => (isLibComponent
    ? `
  width:211px;
  height:211px;
  margin-left:40px;
  margin-bottom:17px;
  img {
      width: 100%;
      object-fit: cover;
  }
  `
    : '')}
  & svg {
    margin: 0 auto;
  }

  ${({ smartview }) => (smartview
    ? `
  svg{
    width: 70px;
    height: 70px;
  }`
    : '')}
`;

export const TagWrapper = styled.div`
  svg {
    width: 72px;
    height: 72px;
  }
`;

const NewEmptyState = ({ place, selector, isSideBar, isLoading, ...props }) => {
  const history = useHistory();

  const derivedSelector = SideBarNavigate[place] ? 'all' : selector;
  const isSmartView = props.showNewSmartView || history.location.pathname.includes('dynamic') || !!derivedSelector.name;
  const isTag = derivedSelector.includes('tag_');
  const isUser = history.location.pathname.includes('/users_smartfiles');

  const derivedNavigate = SideBarNavigate[place]
    ? SideBarNavigate
    : MainNavigate;

  return (
    <MainContentShell isEmptyState={!isSideBar} forUPV={props.forUPV}>
      <EmptyStateBuilder
        isSmartView={isSmartView}
        isTag={isTag}
        derivedNavigate={derivedNavigate}
        derivedSelector={derivedSelector}
        place={place}
        isUser={isUser}
      />
    </MainContentShell>
  );
};


const EmptyStateBuilder = React.memo(
  function EmptyStateBuilder({ isSmartView, isTag, derivedNavigate, derivedSelector, place, isUser }) {
    if (isUser) {
      return (
        <>
          <ImageWrapper>
            <PlaylistScg />
          </ImageWrapper>
          <MainText>
            NO SMARTFILES
          </MainText>
          <Text>
            You could find here SmartFiles you have shared with this user
          </Text>
          <Text noMarginTop>
            Start by creating a new SmartFile
          </Text>
        </>
      );
    }
    if (isSmartView) {
      return (
        <>
          <ImageWrapper smartview>
            <SmartViewSVG />
          </ImageWrapper>
          <MainText>
            NO FILES
          </MainText>
          <Text>
            No files matching the search criteria
          </Text>
        </>
      );
    }
    if (isTag) {
      return (
        <>
          <ImageWrapper>
            <TagWrapper>
              <TagsGroupSvg />
            </TagWrapper>
          </ImageWrapper>
          <MainText>
            NO TAGS
          </MainText>
          <Text>
            You could find here all your tagged content
          </Text>
        </>
      );
    }
    return (
      <>
        <ImageWrapper smartview={isSmartView}>
          { derivedNavigate[place]?.selectors[derivedSelector]?.empty.icon }
        </ImageWrapper>
        <MainText>
          {derivedNavigate[place]?.selectors[derivedSelector]?.empty.main}
        </MainText>
        <Text>
          {derivedNavigate[place]?.selectors[derivedSelector]?.empty.text}
        </Text>
        <Text noMarginTop>
          {derivedNavigate[place]?.selectors[derivedSelector]?.empty?.text2}
        </Text>
      </>
    );
  },
);
export default NewEmptyState;
