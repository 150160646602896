import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import tableStyles from '../LibraryMiniPage/index.module.css';
import { resolvePath } from '../../utils/helpers';
import EmptyStateSelector from '../EmptyStateSelector/EmptyStateSelector';
import { actionSetBlinkId } from '../../redux/support/action';
import RecentFilterGroupInChannel from '../RecentComponents/InChannel/RecentFilterGroup';
import NewEmptyState from '../MainContent';
import downloadStatus from '../../utils/dataUtil';
import NewPlaylist2023 from '../MiniPlaylist/NewPlaylist2023/NewPlaylist2023';
import { FluentTableView } from '../MiniPlaylist/NewPlaylist2023/table/FluentTableView';
import { PlaylistGap } from '../MiniPlaylist/NewPlaylist2023/styled';
import { actionClearPayloadUnifyHistory } from '../../redux/history/actions';
import { ItemPlaceHolder } from '../MiniPlaylist/NewPlaylist/CardView/ItemPlaceHolder';
import { ChannelsSieveTypes } from '../../utils/constants';

const convert = {
  [ChannelsSieveTypes.ChannelsMembers]: 'channelUsers',
  [ChannelsSieveTypes.ChannelsFavorite]: 'channelFavoritePlaylists',
  [ChannelsSieveTypes.ChannelsAll]: 'channelPlaylists',
};
const PlaylistContentSelector = ({
  filteredPages,
  isSimplifiedMultiselect,
  setSimplifiedMultiselect,
  counterSelectedPage,
  needToDelete,
  setSort,
  sortType,
  isFilter,
  isFavoriteEmpty,
  filter,
  refSelectedBlocks,
  createPage,
  totalFavorites,
  loading,
  isChannelMiniPlaylist,
  permissions,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isCardView, id: channelId } = useSelector(
    (state) => state.currentPage,
  );
  const { selectorType } = useSelector((state) => state.user);
  const history = useHistory();
  const { loadStatus, contentIds, contentData, existMore } = useSelector(
    (state) => state.content,
  );
  const today = contentIds.map((id) => contentData[id]).filter((i) => i);
  const isDescriptionOpen = useSelector(
    (state) => state.support?.componentDescription,
  );

  const activeNavSlider = 'channelPlaylists' || convert[props.activeSieveOption];

  const isNotEmpty = !!contentIds.length;
  const lastStep = useSelector((state) => state.historyTrace.somePayload);
  const getTableStyles = () => {
    return !isCardView
      ? `${styles.recent_list_view_block} ${tableStyles.outerTableWrapper}`
      : '';
  };
  const isShowPlaceholder = (existMore
    || (!isNotEmpty
      && (loadStatus === downloadStatus.init
        || loadStatus === downloadStatus.pending)));

  useEffect(() => {
    let timer;
    if (lastStep) {
      const activeManagerId = lastStep?.step?.activeManagerId;
      if (activeManagerId) {
        timer = setTimeout(() => {
          dispatch(actionSetBlinkId([activeManagerId]));
        }, 300);
      }
    }
    return () => {
      dispatch(actionClearPayloadUnifyHistory());
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {!filteredPages.length && !(
        loadStatus === downloadStatus.init
        || loadStatus === downloadStatus.pending
      ) && (
        <NewEmptyState
          place={props.activeSieveOption}
          selector={props.activeSieveOption}
          isLoading={
            loadStatus === downloadStatus.init
            || loadStatus === downloadStatus.pending
          }
        />
      )}
      <EmptyStateSelector
        isFilter={isFilter}
        loading={loading}
        isCardView={isCardView}
        sortedItems={filteredPages}
        isChannel={props.isChannel}
        totalFavorites={totalFavorites}
        selectorType={selectorType}
        path={resolvePath(history.location.pathname)}
      />
      {!!today.length && (
        <div className={`${!isCardView ? styles.row_list_spacing : ''}`}>
          {selectorType === 'recent' && !!today.length && (
            <div
              className={`
            ${styles.recent_title_wrapper}
            ${
              !isCardView
                ? styles.title_list_spacing
                : styles.title_grid_spacing
            }
          `}
            >
              TODAY
            </div>
          )}
          <div
            className={`${isCardView ? styles.recent_grid_row_container : ''}
          ${isCardView ? styles.smartfiles_tab : ''}
          ${getTableStyles()}`}
            style={sortType === 'recent' ? { flexDirection: 'column' } : {}}
          >
            {sortType === 'recent' && (
              <RecentFilterGroupInChannel
                props={props}
                refSelectedBlocks={refSelectedBlocks}
                isSimplifiedMultiselect={isSimplifiedMultiselect}
                setSimplifiedMultiselect={setSimplifiedMultiselect}
                counterSelectedPage={counterSelectedPage}
                needToDelete={needToDelete}
                today={today}
                channelId={channelId}
                isChannelMiniPlaylist={isChannelMiniPlaylist}
                permissions={permissions}
                isDescriptionOpen={isDescriptionOpen}
              />
            )}
            {sortType !== 'recent'
              && isCardView
              && !!today.length
              && today.map(page => (
                <PlaylistGap
                  className={`${!isCardView ? styles.fullParentWidth : ''}`}
                  key={page.id}
                  ref={ref => refSelectedBlocks.current.push({
                    ref,
                    pageId: page.id,
                    folderId: page.folderId,
                  })
                  }
                >
                  <NewPlaylist2023
                    item={page}
                    isSimplifiedMultiselect={isSimplifiedMultiselect}
                    setSimplifiedMultiselect={setSimplifiedMultiselect}
                    isDescriptionOpen={isDescriptionOpen}
                  />
                </PlaylistGap>
              ))}

            {!isCardView && sortType !== 'recent' && (
              <FluentTableView
                isSimplifiedMultiselect={isSimplifiedMultiselect}
                setSimplifiedMultiselect={setSimplifiedMultiselect}
                isDescriptionOpen={isDescriptionOpen}
                data={contentData}
              />
            )}
            {isShowPlaceholder && (
              <>
                <ItemPlaceHolder
                  isFlex={sortType === 'recent'}
                  type={isCardView ? activeNavSlider : 'row'}
                />
              </>
            )}
          </div>

        </div>
      )}
      {isShowPlaceholder && !today.length && (
      <div className={`${!isCardView ? styles.row_list_spacing : ''}`}>
        <div
          className={`${isCardView ? styles.recent_grid_row_container : ''}
            ${isCardView ? styles.smartfiles_tab : ''}
            ${getTableStyles()}`}
          style={sortType === 'recent' ? { flexDirection: 'column' } : {}}
        >
          <ItemPlaceHolder isFlex={sortType === 'recent'} type={isCardView ? activeNavSlider : 'row'} />
        </div>
      </div>
      )}

    </>
  );
};

export default PlaylistContentSelector;
