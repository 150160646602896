
interface Translations {
  [key: string]: {
    [key: string]: {
      [key: string]: string | { [lang: string]: string | ((...args: any[]) => string); }
    };
  };
}

export const PLAYLISTS_TRANSLATIONS:Translations = {
  menu: {
    menuOptions: {
      removeContent: {
        en: 'Remove SmartFile',
      },
      rename: {
        en: 'Rename',
      },
      duplicate: {
        en: 'Duplicate',
      },
      viewMetadata: {
        en: 'View Metadata',
      },
      toggleFavorite: {
        en: (isFavorite) => (!isFavorite ? 'Add to Favorites' : 'Remove Favorite'),
      },
      delete: {
        en: 'Move to Trash',
      },
    },
  },
  universalView: {
    header: {
      editButton: {
        en: 'EDIT SmartFile',
      },
    },
    menuOption: {
      delete: {
        en: 'Delete',
      },
    },
  },
};
