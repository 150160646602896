import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import {
  actionAddPageIntoLibrary,
  actionChangeSieveValue,
  actionRenameLibraryCollection,
  actionRenameLibraryCollectionRedux,
  actionSwitchLibrarySelector,
} from '../../redux/library/actions';
import {
  actionResizeToLargeLibraryWidget,
  actionResizeToSmallLibraryWidget,
} from '../../redux/currentPage/action';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import { sortedFunctions } from '../../utils/dateConvert';
import FullscreenHeader from './FullscreenHeader';
import {
  calcIsDynamicCollection,
  ChannelsSieveTypes,
  LibCompSieveTypes,
  LibCompSieveTypesTest,
  PLACE,
  PlaylistSideBarLibrarySieveTypes,
} from '../../utils/constants';
import { actionRenameChannel } from '../../redux/channels/action';
import { maxFileSize } from '../../GLOBAL_CONST';
import { createPageCommon } from '../../utils/helpers';
import ActionCustomEvent from '../../redux/customEvent/action';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import TagHeader from './TagHeader';

const dataParent = 'uploadsButton';
const dataParentCreateLink = 'dataParentCreateLink';

const LibraryHeaderMenu = ({
  activeSieveOption,
  sieveOptions,
  isPageSideBar,
  isLibrary,
  isChannels,
  isManyPages,
  permissions = {},
  isManyPlaylists,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [
    addFileRefHeader,
    isAddFileMenuVisibleHeader,
    setIsAddFileMenuVisibleHeader,
  ] = useComponentVisible(false, dataParent);

  const channels = useSelector((state) => state.channels);
  const { folderId, selectorType, tags } = useSelector((state) => state.user);
  const isLargeRightBar = useSelector(
    (state) => state.currentPage.isLargeRightBar,
  );
  const { isCardView } = useSelector((state) => state.currentPage);

  const isOpenCreateComponent = useSelector(
    (state) => state.library.isOpenCreateComponent,
  );
  const collections = useSelector((state) => state.library.collections[props.folderId]) ?? {};
  const uploads = useSelector((state) => state.uploads.components);

  const [hasActiveUploads, setHasActiveUploads] = useState(false);
  const [progress, setProgress] = useState(0);
  const [activeNavSlider, setActiveNavSlider] = useState(activeSieveOption);
  const [showHeaderDetails, setShowHeaderDetails] = useState(true);

  const [initialTitle, setInitialTitle] = useState('');
  const [tmpTitle, setTmpTitle] = useState(props.title || 'Unnamed collection');
  const [isComponentRename, setIsComponentRename] = useState(false);
  const [width, setWidth] = useState(0);

  const titleRef = useRef(null);
  const addFileParentRef = useRef(null);

  const isDynamicCollection = calcIsDynamicCollection(activeSieveOption) && activeSieveOption !== 'tag';
  const isTag = activeSieveOption === 'tag';

  const handler = (e) => {
    if (e.keyCode === 27) {
      props.setIsShowFilter(false);
      props.setLibFilterSearch && props.setLibFilterSearch('');
      setShowHeaderDetails(true);
      setTimeout(() => dispatch(ActionCustomEvent.Clear(), 0));
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, []);

  useEffect(() => {
    // For Library NavSlider in Tag Folder.
    if (selectorType !== 'tag' && activeSieveOption === '[LibComponent]All') {
      dispatch(actionChangeSieveValue('[LibComponent]Pages', PLACE.Library));
    }
  }, [selectorType]);

  const onClickSetActiveNavSlider = (value) => {
    if (props.setLoading) props.setLoading(true);
    setActiveNavSlider(value);
  };

  useEffect(() => {
    if (!isChannels) {
      setActiveNavSlider(activeSieveOption);
    } else {
      setActiveNavSlider(channels.activeSieveOption);
    }
  }, [folderId, activeSieveOption, channels.activeSieveOption]);

  useEffect(() => {
    const hasActive = !!Object.values(uploads || {}).filter(
      (i) => i.status !== 'READY' && i.status !== 'ERROR',
    ).length;
    setHasActiveUploads(hasActive);
    if (hasActive) {
      let sizeUpload = 0;
      let sizeAll = 0;
      Object.values(uploads || {})
        .filter((i) => !i.errorMsg)
        .forEach((uploadItem) => {
          sizeUpload += uploadItem.size * (uploadItem.progress || 0);
          sizeAll += uploadItem.size;
        });
      setProgress(sizeUpload / sizeAll || 0);
    }
  }, [uploads]);

  useEffect(() => {
    const x = titleRef.current?.getBoundingClientRect()?.width ?? 0;
    let newWidth;
    if (x < 156) {
      newWidth = 156 - 1;
    } else if (x > 458) {
      newWidth = 458 + 7;
    } else newWidth = x;
    setWidth(newWidth + 12);
  }, [props.title]);

  const onChangeFolderTitle = (e) => {
    if (
      !e.currentTarget.value
      && (tmpTitle === initialTitle || tmpTitle === 'Unnamed folder')
    ) setTmpTitle('');
    else setTmpTitle(e.currentTarget.value);
    if (props.folderId) {
      dispatch(
        actionRenameLibraryCollectionRedux({
          id: props.folderId,
          name: e.currentTarget.value,
        }),
      );
    }
    e.stopPropagation();
    e.preventDefault();
  };

  const showCardView = () => {
    if (activeNavSlider !== ChannelsSieveTypes.ChannelsMembers) {
      if (
        Object.keys(sortedFunctions)
          .slice(0, 6)
          .every((i) => i !== props.sortType)
      ) props.setSort(Object.keys(sortedFunctions)[2]);

      props.setIsCardsView(!props.isCardsView);
    }
  };

  useEffect(() => {
    if (isChannels) setShowHeaderDetails(true);
    else if (props.isNoItems && !props.displayedPagesTotal) setShowHeaderDetails(false);
    else setShowHeaderDetails(true);
  }, [props.isNoItems, props.filter, isChannels]);

  useEffect(() => {
    dispatch(actionSwitchLibrarySelector('recent'));
  }, []);

  const isShowSorting = () => {
    // todo SKI-500: tmp blocking ui display, because of logic uncertainty
    return activeNavSlider === ChannelsSieveTypes.ChannelsMembers;
    //   if (props.isNoItems) return false;
    //   if (props.selectorType === 'favorites' && props.totalFavorites === 0) {
    //     return false;
    //   }
    //   return props.selectorType !== 'recent';
  };

  const goToLibrarySideBar = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isCardView) dispatch(actionCreator(CurrentPage.ToggleIsCardView));
    if (!isLargeRightBar) dispatch(actionResizeToLargeLibraryWidget());
    else dispatch(actionResizeToSmallLibraryWidget());
    // props.setShowLibrary(!props.showLibrary);
    // if (history) {
    //   dispatch(actionPageWillDownload());
    //   history.push('/library');
    // }
    // props.setIsExpanded(!props.isExpanded);
  };

  const onTitleClickHandler = (e) => {
    if (isChannels && !permissions?.canEditChannel) return;
    if (props.selectorType === 'folder') {
      e.stopPropagation();
      e.preventDefault();
      setTmpTitle(props.title || 'Unnamed folder');
      setInitialTitle(props.title);
      setIsComponentRename(true);
    }
  };
  const onKeyPressFolderTitle = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      if (isChannels) {
        dispatch(actionRenameChannel(folderId, tmpTitle));
      } else {
        dispatch(
          actionRenameLibraryCollection({ id: props.folderId, name: tmpTitle }),
        );
      }
      setIsComponentRename(false);
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      e.preventDefault();
      setIsComponentRename('close');
      setTmpTitle(props.title || 'Unnamed folder');
      if (isChannels) {
        dispatch(actionRenameChannel(folderId, tmpTitle));
      } else {
        dispatch(
          actionRenameLibraryCollection({ id: props.folderId, name: tmpTitle }),
        );
      }
    }
  };
  const changeLibraryDisplayComponents = (e, newSelector) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actionSwitchLibrarySelector(newSelector));
  };
  const computeNavBarStyles = (currentActiveBar) => {
    return currentActiveBar === activeNavSlider ? 'active' : '';
  };

  const inputFile = useRef(null);
  const onButtonClick = (e) => {
    e.stopPropagation();
    inputFile?.current.click();
    // setIsAddFileMenuVisibleHeader(false);
  };
  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const sortedUploads = Object.values(uploads || {}).sort((a, b) => {
      if (a.errorMsg) return -1;
      if (b.errorMsg) return 1;
      return b.position - a.position;
    });
    [...e.target.files].forEach((item, index) => {
      const newId = uuidv4();
      if (item.size > maxFileSize.bytes) {
        dispatch(
          actionCreateUpload({
            newId,
            file: item,
            title: item.name,
            position: sortedUploads.length
              ? sortedUploads[0]?.position + 1
              : index + 1,
            type: item.type || 'unknown_type',
            errorMsg: `File size is more then ${maxFileSize.text}`,
            history,
          }),
        );
      } else {
        dispatch(
          actionCreateUpload({
            newId,
            file: item,
            title: item.name,
            position: sortedUploads[0]?.position + 1,
            type: item.type || 'unknown_type',
            history,
          }),
        );
      }
    });
    inputFile.current.value = '';
  };

  const openFilterHandler = (e) => {
    props.setIsShowFilter(!props.isShowFilter);
    changeLibraryDisplayComponents(e, 'all');
    dispatch(actionChangeSieveValue(LibCompSieveTypes.All, PLACE.Library));
  };

  const closeFilterHandler = () => {
    props.setIsShowFilter(false);
    props.setLibFilterSearch && props.setLibFilterSearch('');
    props.setFilter({});
    dispatch(
      actionChangeSieveValue(LibCompSieveTypesTest.Pages, PLACE.Library),
    );
  };

  const createNewPage = () => {
    if (isManyPlaylists) {
      props.createItem();
      return;
    }
    const localTags = [];
    if (selectorType === 'tag' && folderId) {
      localTags.push({ ...tags[folderId] });
    }
    // REFACTORING: replace create function to traverse createItem from parent Component for all use cases
    createPageCommon(
      dispatch,
      history,
      selectorType === 'favorites',
      (newPage) => dispatch(
        actionAddPageIntoLibrary(newPage, selectorType === 'favorites'),
      ),
      '',
      localTags,
    );
  };

  useEffect(() => {
    if (
      activeSieveOption === PlaylistSideBarLibrarySieveTypes.PlaylistPdf
      && props.isShowFilter
    ) closeFilterHandler();
  }, [activeSieveOption]);

  if (isTag) {
    return (
      <TagHeader
        isChannels={isChannels}
        isShowFilter={props.isShowFilter}
        setIsShowFilter={props.setIsShowFilter}
        closeFilterHandler={closeFilterHandler}
        openFilterHandler={openFilterHandler}
        libFilterSearch={props.libFilterSearch}
        setLibFilterSearch={props.setLibFilterSearch}
        setFilter={props.setFilter}
        navigate={props.navigate}
        filter={props.filter}
        isCardsView={props.isCardsView}
        setIsCardsView={props.setIsCardsView}
        isShowMenuSort={props.isShowMenuSort}
        activeNavSlider={props.activeNavSlider || activeNavSlider}
        computeNavBarStyles={computeNavBarStyles}
        isShowSorting={isShowSorting}
        setSieve={props.setSieve}
        refShowMenuSort={props.refShowMenuSort}
        setShowMenuSort={props.setShowMenuSort}
        sortType={props.sortType}
        isExpanded={isLargeRightBar}
        goToLibrarySideBar={goToLibrarySideBar}
        changeLibraryDisplayComponents={changeLibraryDisplayComponents}
        setShowLibrary={props.setShowLibrary}
        activeSieveOption={activeSieveOption}
        isShowInput={props.isShowInput}
        isShowOnlyNotNestedPages={props.isShowOnlyNotNestedPages}
        clearAllFilters={props.clearAllFilters}
        isHasSomeFilter={props.isHasSomeFilter}
        sieveOptions={sieveOptions}
      />
    );
  }

  return (
    <FullscreenHeader
      isManyPlaylists={isManyPlaylists}
      isLibrary={isLibrary}
      activeSieveOption={activeSieveOption}
      sieveOptions={sieveOptions}
      isChannels={isChannels}
      titleRef={titleRef}
      isComponentRename={isComponentRename}
      setIsComponentRename={setIsComponentRename}
      onTitleClickHandler={onTitleClickHandler}
      collections={collections}
      width={width}
      tmpTitle={tmpTitle}
      onChangeFolderTitle={onChangeFolderTitle}
      onKeyPressFolderTitle={onKeyPressFolderTitle}
      showHeaderDetails={showHeaderDetails}
      inputFile={inputFile}
      submitForm={submitForm}
      addFileParentRef={addFileParentRef}
      dataParentCreateLink={dataParentCreateLink}
      isOpenCreateComponent={isOpenCreateComponent}
      dataParent={dataParent}
      isAddFileMenuVisibleHeader={isAddFileMenuVisibleHeader}
      hasActiveUploads={hasActiveUploads}
      setIsAddFileMenuVisibleHeader={setIsAddFileMenuVisibleHeader}
      showCardView={showCardView}
      progress={progress}
      onButtonClick={onButtonClick}
      addFileRefHeader={addFileRefHeader}
      setActiveNavSlider={onClickSetActiveNavSlider}
      activeNavSlider={activeNavSlider}
      computeNavBarStyles={computeNavBarStyles}
      isShowSorting={isShowSorting}
      permissions={permissions}
      isManyPages={isManyPages}
      createNewPage={createNewPage}
      {...props}
    />
  );
};

export default LibraryHeaderMenu;
