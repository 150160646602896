import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import {
  THREE_DOTS_DD_OPTIONS,
  THREE_DOTS_DD_OPTIONS_LIBRARY,
  THREE_DOTS_DD_OPTIONS_MiniPage,
} from './helpers/enums';
import { actionOpenModal, actionSaveUser } from '../../../redux/user/action';
import { Roles } from '../../../utils/permissions';
import {
  actionSaveComponentDescriptionData,
  actionShowMessage,
  actionToggleComponentDescription,
} from '../../../redux/support/action';
import { Divider, MainWrapper, OptionsWrapper } from './styled';
import {
  DATA_PARENTS,
  DEFAULT_PLAYER_PATH, DEFAULT_TITLE,
  empty,
  MessageType,
  openModalType,
  stopPropagation,
} from '../../../utils/constants';
import { actionCreator } from '../../../shared/redux/actionHelper';
import {
  actionDuplicatePlaylist,
} from '../../../redux/playlists/action';
import SupportAction from '../../../redux/support/types';
import { ReactComponent as ArrowRightSVG } from '../../../images/2023/svg/small/dropdown/arrow_right_6_10.svg';
import PlaylistSelector from '../../../pages/Maker/MakerCommon/Table/PlaylistSelector';
import { actionSelectedPage } from '../../../redux/selectedPage/action';
import {
  actionDuplicateComponent,
  actionDuplicatePage, actionOpenCreateComponent,
  actionUnsubscribeLibraryEntity,
} from '../../../redux/library/actions';
import { useOpenPlaylist } from './helpers/openPlaylistHook';
import EditPlaylist from '../../../redux/playlists/types';

const ThreeDotsDropDown = ({
  show,
  refMenu,
  userRole,
  setIsDropDownVisible,
  isOwner,
  isUPV,
  ...props
}) => {
  // data parent hardocded now. uses only for Right Library SIde Bar
  const dataParent = DATA_PARENTS.PlaylistBuilderLibrary;
  const isComponent = props.itemType === 'component' || props.item?.type === 'component';
  const history = useHistory();
  const dispatch = useDispatch();
  const handlerOpenPlaylist = useOpenPlaylist(props?.item?.status);
  const { folderId, id } = useSelector((state) => state.user) || empty;

  const isChannel = history.location.pathname.includes('/channel/');
  const { t } = useTranslation();

  const unPublishModeEnabled = isChannel
    && (userRole === Roles.Admin || userRole === Roles['Co-Admin'] || isOwner);
  const isViewerOnChannel = isChannel && userRole === Roles.Viewer;
  const isPage = props.item?.type === 'page' || props.item?.type === 'sharedPage';
  const isPageOwner = isPage && props.item?.users?.length && props.item?.users[0].id === id;

  const openOptions = (e) => {
    e.stopPropagation();
    if (isUPV) return;
    setIsDropDownVisible(false);
    const playlistType = props.status;
    dispatch(
      actionSaveComponentDescriptionData(
        props.itemID,
        '',
        props.isPageMiniPage || props.isLib
          ? 'libComponent'
          : props.itemType || playlistType,
      ),
    );
    dispatch(actionToggleComponentDescription(true));

    e.stopPropagation();
    e.preventDefault();
  };

  const disableClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const renameClickHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDropDownVisible(false);
    if (props.isPageMiniPage || props.isLib) {
      if (props.isSharedToMe) return;
      dispatch(actionOpenModal(openModalType.RenamePlaylistModal));
      dispatch(
        actionSaveUser({ playlistID: props.itemID, playlistStatus: 'media' }),
      );
    } else {
      dispatch(actionOpenModal(openModalType.RenamePlaylistModal));
      dispatch(
        actionSaveUser({
          playlistID: props.itemID,
          playlistStatus: props.status,
        }),
      );
    }
  };

  const duplicatePlaylist = (e) => {
    e.stopPropagation();
    setIsDropDownVisible(false);
    const newId = uuidv4();
    dispatch(actionDuplicatePlaylist(props.itemID, newId));
  };

  const openTagModal = (e) => {
    e.stopPropagation();
    if (props.isSharedToMe) return;
    dispatch(actionOpenModal(openModalType.AddTagModal, { id: props.itemID }));
  };

  const showViewersProgressModal = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      actionSaveComponentDescriptionData(
        props.itemID,
        'item.folderId',
        props.itemType,
      ),
    );
    dispatch(actionOpenModal(openModalType.ViewersProgress));
    dispatch(actionToggleComponentDescription(false));
  };

  const doUnsubscribe = () => {
    const unsubscribeEntityType = props.isLib || props.isPageMiniPage ? 'page' : 'playlist';
    const previewedEntity = props.isLib || props.isPageMiniPage ? 'SmartPage' : 'SmartFile';
    const id = unsubscribeEntityType === 'page'
      ? props.item?.libraryComponentId
      : props.sharedID;

    dispatch(
      actionOpenModal(openModalType.ConfirmModalUniversal, {
        title: 'REMOVE',
        subject: `Remove this ${previewedEntity}?`,
        description: `This ${previewedEntity} will be removed from your feed`,
        confirm: () => {
          dispatch(
            actionShowMessage({
              type: MessageType.Regular,
              text: `${previewedEntity} successfuly removed!`,
            }),
          );
          dispatch(actionUnsubscribeLibraryEntity(id, unsubscribeEntityType, props.itemID));
        },
        cancelText: 'CANCEL',
        okText: 'REMOVE',
      }),
    );
  };

  const deleteComponent = (e) => {
    e.stopPropagation();
    setIsDropDownVisible(false);

    if (props.isSharedToMe) {
      doUnsubscribe();
    } else {
      dispatch(
        actionCreator(SupportAction.CheckIsInUse, {
          itemTitle: props.itemTitle,
          itemId: props.itemID,
        }),
      );
    }
  };

  const deletePlaylist = (e) => {
    e.stopPropagation();
    setIsDropDownVisible(false);
    dispatch(
      actionCreator(SupportAction.CheckIsInUse, {
        isPlaylist: true,
        itemTitle: props.itemTitle,
        itemId: props.itemID,
      }),
    );
  };
  const enableShare = (e, isPage = false) => {
    e.stopPropagation();
    setIsDropDownVisible(false);
    dispatch(actionSelectedPage(props.itemID, folderId));
    dispatch(
      actionOpenModal(openModalType.Share, {
        itemType: isPage ? 'libraryComponent' : 'playlist',
        isMakerStyles: true,
        item: props,
      }),
    );
    setIsDropDownVisible(false);
  };

  const duplicatePage = (e) => {
    e.stopPropagation();
    if (props.itemType === 'page') {
      dispatch(actionDuplicatePage(null, props.itemID));
    } else {
      dispatch(actionDuplicateComponent(null, props.itemID));
    }
    dispatch(
      actionShowMessage({
        type: MessageType.ItemToCopy,
        itemName: props.itemType || t('defaultSmartPageTitleT'),
      }),
    );
    setIsDropDownVisible(false);
  };

  const toggleFavorite = (e) => {
    e.stopPropagation();
    setIsDropDownVisible(false);
    if (!props.itemIsFavorite) {
      dispatch(
        actionShowMessage({
          type: MessageType.PlaylistToFavorite,
          itemName: props.itemTitle || t('defaultSmartPageTitleT'),
        }),
      );
    }
    dispatch(
      actionCreator(EditPlaylist.ToggleFavorite, {
        id: props.itemID,
        isFavorite: !props.itemIsFavorite,
        wrapperId: props.itemWrapperID,
      }),
    );
  };
  const downloadPlaylist = (e) => {
    e.stopPropagation();
    if (!props.isCanBeOpen) return;
    const playlistId = props.sharedID || props.itemID;
    setIsDropDownVisible(false);
    dispatch(actionOpenModal(openModalType.DownloadPlaylistModalOutSide, { playlistId, itemId: props.itemID }));
  };

  const unPublishPlaylist = (e) => {
    dispatch(actionOpenModal(openModalType.UnpublishModal, { currentPageId: props.itemID, wrapperId: props.itemWrapperID }));
    e.stopPropagation();
  };

  const openPlaylist = () => {
    handlerOpenPlaylist({ playlistId: props.itemID, isShared: props.isShared });
  };

  const playPlaylist = (e) => {
    e.stopPropagation();
    if (!props.isCanBeOpen) return;
    if (props.sharedID) {
      history.push(`${DEFAULT_PLAYER_PATH + props.sharedID}/1`);
    } else {
      history.push(`${DEFAULT_PLAYER_PATH + props.itemID}/1`);
    }
  };
  const openPage = (e) => {
    e.stopPropagation();
    dispatch(
      actionOpenModal(openModalType.UPVPreview, { id: props.item.id,
        index: props.index || 2,
        mode: 'LIB' }),
    );
  };

  const goToEdit = (e) => {
    e.stopPropagation();
    if (isComponent) {
      dispatch(actionOpenCreateComponent(props.item.id));
      return;
    }
    if (!isPageOwner || props.isSharedToMe) return;
    history.push(`/libraryPage/${props.item.id}`);
  };

  const calcPosition = () => {
    const position = {};

    if (props.couldBeCutRight) {
      position.right = '14px';
    }
    if (props.couldBeCutBottom) {
      position.top = '-380px;';
      if (props.isPageMiniPage) {
        if (props.bigSizeCondition && !props.isPdf) {
          position.top = '-390px;';
        } else {
          position.top = '-335px;';
        }
      }
      if (props.couldBeCutTop) {
        position.top = '-170px;';
        position.right = '-13px';
      }
    }
    return position;
  };

  if (show) {
    if (props.isLib) {
      return (
        <>
          <MainWrapper
            isPageMiniPage
            isTableView={props.isTableView}
            isLib
            ref={refMenu}
            data-parent={`miniPage ${dataParent}`}
            position={calcPosition()}
          >
            <OptionsWrapper data-parent={dataParent} isFirst>
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.title)}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={dataParent}
              onClick={openPage}
            >
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.open)}
            </OptionsWrapper>
            {(isPage || isComponent) && (
            <OptionsWrapper
              data-parent={dataParent}
              disabled={props.isSharedToMe}
              onClick={goToEdit}
            >
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.edit)}
            </OptionsWrapper>
            )}

            <Divider />
            <OptionsWrapper
              data-parent={dataParent}
              disabled={props.isSharedToMe}
              onClick={renameClickHandler}
            >
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.rename)}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={dataParent}
              disabled={props.isSharedToMe}
              onClick={duplicatePage}
            >
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.duplicate)}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={dataParent}
              onClick={props.toggleFavorite}
            >
              {props.itemIsFavorite
                ? t(THREE_DOTS_DD_OPTIONS_LIBRARY.unfavorite)
                : t(THREE_DOTS_DD_OPTIONS_LIBRARY.favorite)}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={`miniPage ${dataParent}`}
              disabled={props.isSharedToMe || isComponent}
              onClick={stopPropagation}
            >
              <ArrowRightSVG />
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.addToPlaylist)}
              {!(props.isSharedToMe || isComponent) && (
                <PlaylistSelector
                  pageID={props.item?.id}
                  lcID={props.item?.libraryComponent?.id}
                  isWholePlaylistOperation={false}
                  refMenu={refMenu}
                  setIsDropDownVisible={setIsDropDownVisible}
                />
              )}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={dataParent}
              disabled={props.isSharedToMe}
              onClick={openTagModal}
            >
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.addATag)}
            </OptionsWrapper>
            <Divider />
            <OptionsWrapper disabled={isUPV} data-parent={dataParent} onClick={openOptions}>
              {t(THREE_DOTS_DD_OPTIONS_LIBRARY.viewDetails)}
            </OptionsWrapper>
            <Divider />
            <OptionsWrapper
              data-parent={dataParent}
              onClick={deleteComponent}
              isLast
            >
              {props.isSharedToMe
                ? t(THREE_DOTS_DD_OPTIONS.remove)
                : t(THREE_DOTS_DD_OPTIONS_MiniPage.delete)}
            </OptionsWrapper>
          </MainWrapper>
        </>
      );
    }
    if (props.isPageMiniPage) {
      return (
        <>
          <MainWrapper
            isPageMiniPage
            isTableView={props.isTableView}
            ref={refMenu}
            data-parent={dataParent}
            position={calcPosition()}
          >
            <OptionsWrapper data-parent={dataParent} isFirst>
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.title)}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={dataParent}
              onClick={openPage}
            >
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.open)}
            </OptionsWrapper>
            {(isPage || isComponent) && (
            <OptionsWrapper
              disabled={!(isPageOwner || isComponent)}
              data-parent={dataParent}
              onClick={goToEdit}
            >
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.edit)}
            </OptionsWrapper>
            )}
            <Divider />
            <OptionsWrapper
              data-parent={dataParent}
              disabled={props.isSharedToMe}
              onClick={renameClickHandler}
            >
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.rename)}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={dataParent}
              disabled={props.isSharedToMe}
              onClick={duplicatePage}
            >
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.duplicate)}
            </OptionsWrapper>
            {isPage && (
              <OptionsWrapper
                data-parent={dataParent}
                disabled={!isPageOwner}
                onClick={(e) => enableShare(e, true)}
              >
                {t(THREE_DOTS_DD_OPTIONS_MiniPage.share)}
              </OptionsWrapper>
            )}
            <OptionsWrapper
              data-parent={dataParent}
              onClick={props.toggleFavorite}
            >
              {props.itemIsFavorite
                ? t(THREE_DOTS_DD_OPTIONS_MiniPage.unfavorite)
                : t(THREE_DOTS_DD_OPTIONS_MiniPage.favorite)}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={`miniPage ${dataParent}`}
              disabled={props.isSharedToMe || isComponent}
              onClick={stopPropagation}
            >
              <ArrowRightSVG />
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.addToPlaylist)}
              {!(props.isSharedToMe || isComponent) && (
                <PlaylistSelector
                  pageID={props.item?.id}
                  lcID={props.item?.libraryComponent?.id}
                  isWholePlaylistOperation={false}
                  refMenu={refMenu}
                  setIsDropDownVisible={setIsDropDownVisible}
                />
              )}
            </OptionsWrapper>
            <OptionsWrapper
              data-parent={dataParent}
              disabled={props.isSharedToMe}
              onClick={openTagModal}
            >
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.addATag)}
            </OptionsWrapper>
            <Divider />
            <OptionsWrapper disabled={isUPV} data-parent={dataParent} onClick={openOptions}>
              {t(THREE_DOTS_DD_OPTIONS_MiniPage.viewDetails)}
            </OptionsWrapper>
            <Divider />
            <OptionsWrapper
              data-parent={dataParent}
              isLast
              onClick={deleteComponent}
            >
              {props.isSharedToMe
                ? t(THREE_DOTS_DD_OPTIONS.remove)
                : t(THREE_DOTS_DD_OPTIONS_MiniPage.delete)}
            </OptionsWrapper>
          </MainWrapper>
        </>
      );
    }
    return (
      <>
        <MainWrapper
          isTableView={props.isTableView}
          ref={refMenu}
          data-parent={dataParent}
          position={calcPosition()}
        >
          <OptionsWrapper data-parent={dataParent} isFirst>
            {t(THREE_DOTS_DD_OPTIONS.title)}
          </OptionsWrapper>
          <OptionsWrapper data-parent={dataParent} onClick={openPlaylist}>
            {t(THREE_DOTS_DD_OPTIONS.open)}
          </OptionsWrapper>
          <OptionsWrapper data-parent={dataParent} disabled={!props.isCanBeOpen} onClick={downloadPlaylist}>
            {t(THREE_DOTS_DD_OPTIONS.download)}
          </OptionsWrapper>
          <OptionsWrapper data-parent={dataParent} disabled={!props.isCanBeOpen} onClick={playPlaylist}>
            {t(THREE_DOTS_DD_OPTIONS.play)}
          </OptionsWrapper>
          <Divider />
          <OptionsWrapper
            data-parent={dataParent}
            disabled={userRole !== Roles.Admin || isViewerOnChannel || !isOwner}
            onClick={
              userRole !== Roles.Admin || isViewerOnChannel || !isOwner
                ? disableClick
                : renameClickHandler
            }
          >
            {t(THREE_DOTS_DD_OPTIONS.rename)}
          </OptionsWrapper>
          <OptionsWrapper
            data-parent={dataParent}
            disabled={userRole !== Roles.Admin}
            onClick={
              userRole !== Roles.Admin
                ? (e) => {
                  e.stopPropagation();
                }
                : duplicatePlaylist
            }
          >
            {t(THREE_DOTS_DD_OPTIONS.duplicate)}
          </OptionsWrapper>
          <OptionsWrapper data-parent={dataParent} onClick={toggleFavorite}>
            {props.itemIsFavorite
              ? t(THREE_DOTS_DD_OPTIONS.unfavorite)
              : t(THREE_DOTS_DD_OPTIONS.favorite)}
          </OptionsWrapper>
          {!isChannel && (
            <>
              <OptionsWrapper
                data-parent={dataParent}
                disabled={userRole !== Roles.Admin}
                onClick={
                  userRole !== Roles.Admin ? stopPropagation : enableShare
                }
              >
                {t(THREE_DOTS_DD_OPTIONS.share)}
              </OptionsWrapper>
            </>
          )}

          {isChannel && (
            <>
              <OptionsWrapper
                data-parent={dataParent}
                disabled
                onClick={stopPropagation}
              >
                {t(THREE_DOTS_DD_OPTIONS.share)}
              </OptionsWrapper>
              <OptionsWrapper
                data-parent={dataParent}
                disabled={!unPublishModeEnabled}
                onClick={
                  unPublishModeEnabled ? unPublishPlaylist : stopPropagation
                }
              >
                {t(THREE_DOTS_DD_OPTIONS.unpublish)}
              </OptionsWrapper>
            </>
          )}
          <Divider />
          <OptionsWrapper
            data-parent={dataParent}
            disabled
            // onClick={
            //   userRole !== Roles.Admin ? () => {} : showViewersProgressModal
            // }
          >
            {t(THREE_DOTS_DD_OPTIONS.viewerProgress)}
          </OptionsWrapper>
          <OptionsWrapper disabled={isUPV} data-parent={dataParent} onClick={openOptions}>
            {t(THREE_DOTS_DD_OPTIONS.viewDetails)}
          </OptionsWrapper>
          <Divider />
          {props.status === 'shared' ? (
            <OptionsWrapper
              data-parent={dataParent}
              isLast
              onClick={(e) => {
                e.stopPropagation();
                doUnsubscribe();
              }}
            >
              {t(THREE_DOTS_DD_OPTIONS.remove)}
            </OptionsWrapper>
          ) : (
            <OptionsWrapper
              data-parent={dataParent}
              isLast
              disabled={
                userRole !== Roles.Admin || props.isPublished || isChannel
              }
              onClick={
                userRole !== Roles.Admin || props.isPublished || isChannel
                  ? () => {}
                  : (e) => deletePlaylist(e)
              }
            >
              {t(THREE_DOTS_DD_OPTIONS.delete)}
            </OptionsWrapper>
          )}
        </MainWrapper>
      </>
    );
  }

  return <div />;
};

export default ThreeDotsDropDown;
