import React, { useRef } from 'react';
import styles from '../index.module.css';
import { ReactComponent as EditTextSvg } from '../../../images/icons/edit_text.svg';
import { ReactComponent as DuplicateSvg } from '../../../images/icons/duplicate.svg';
import { ReactComponent as DeleteRedSvg } from '../../../images/icons/delete_red.svg';
import { ReactComponent as PlayerAddItemLibrarySvg } from '../../../images/icons/player/player_library_add_17.svg';
import { ReactComponent as HideShow14Svg } from '../../../images/icons/player/hide_show_14.svg';
import { ReactComponent as ArrowMergeSvg } from '../../../images/icons/arrow_merge_16.svg';
import { ReactComponent as FavoriteSvg } from '../../../images/icons/favorites20.svg';
import { ReactComponent as UnPublish20Svg } from '../../../images/icons/unpublish.svg';
import { ReactComponent as AddTag16Svg } from '../../../images/icons/tag.svg';
import { DATA_PARENTS } from '../../../utils/constants';
import { ReactComponent as MoveToSvg } from '../../../images/icons/move_to.svg';
import { ReactComponent as ChevronRightSmallSvg } from '../../../images/icons/chevron_right_small.svg';
import { ReactComponent as Playlist16Svg } from '../../../images/icons/playlist_16.svg';
import { ReactComponent as AddPlaylist16Svg } from '../../../images/icons/playlist_add.svg';
import { ReactComponent as InfoCircle16Svg } from '../../../images/icons/info_circle_16.svg';
import { ReactComponent as EmbedSvg } from '../../../images/icons/link_ext.svg';
import MoveOption from '../MoveOption';
import AddPlaylistOption from '../AddPlaylistOption';
import { Spinner } from '../../../pages/Channels/MemberRow/RolesCell/Spinner';
import { ReactComponent as SpinnerSvg } from '../../../images/icons/spinner.svg';
import { AVATAR_CHANGE_OPTIONS } from '../../../utils/SettingsHelpers/constants';
import i18n from '../../../i18n';

export default {
  universalPlaylistAddToPlaylist:
    (
      openPlaylistsCB,
      isActive,
      addPlaylistRef,
      isLast,
      isProcessing,
      isDisabled,
    ) => () => (
      <div
        key="MENU_OPTIONS_universalPlaylistAddToPlaylist"
        onClick={openPlaylistsCB}
        className={`
          ${styles.editMenu__item} 
          ${styles.editMenu__move_to} 
          ${isLast ? styles.editMenu__item_last : ''} 
          ${isProcessing ? styles.editMenu__item_disable : ''} 
          ${isProcessing ? styles.grey : ''} 
          ${isDisabled ? styles.editMenu__item_disable : ''}
          ${isDisabled ? styles.grey : ''}
        `}
        ref={addPlaylistRef}
      >
        <div className={`${styles.image_wrapper}`}>
          <Playlist16Svg />
        </div>
        <div className={`${styles.editMenu__text}`}>
          {!isProcessing && i18n.t('addToSmartFileT')}
          {isProcessing && i18n.t('addingToSmartFileT')}
        </div>

        {isProcessing && (
          <div>
            <Spinner>
              <SpinnerSvg />
            </Spinner>
          </div>
        )}

        {!isProcessing && (
          <>
            <div className={`${styles.image_wrapper} ${styles.icon}`}>
              <ChevronRightSmallSvg />
            </div>
            <AddPlaylistOption
              addPlaylistRef={addPlaylistRef}
              isDisabled={isDisabled}
            />
          </>
        )}
      </div>
    ),
  universalPlaylistAddAllToPlaylist:
    (
      openPlaylistsCB,
      isActive,
      addPlaylistRef,
      isLast,
      isProcessing,
      isDisabled,
      isWholePlaylistOperation,
      libraryComponent,
      linkPageId,
    ) => () => (
      <div
        key="MENU_OPTIONS_universalPlaylistAddAllToPlaylist"
        onClick={openPlaylistsCB}
        className={`
          ${styles.editMenu__item} 
          ${styles.editMenu__move_to} 
          ${isLast ? styles.editMenu__item_last : ''} 
          ${isProcessing ? styles.editMenu__item_disable : ''} 
          ${isProcessing ? styles.grey : ''} 
          ${isDisabled ? styles.editMenu__item_disable : ''}
          ${isDisabled ? styles.grey : ''}
        `}
        ref={addPlaylistRef}
      >
        <div className={`${styles.image_wrapper}`}>
          <Playlist16Svg />
        </div>
        <div className={`${styles.editMenu__text}`}>
          {!isProcessing && i18n.t('addToSmartFileT')}
          {isProcessing && i18n.t('addingToSmartFileT')}
        </div>

        {isProcessing && (
          <div>
            <Spinner>
              <SpinnerSvg />
            </Spinner>
          </div>
        )}

        {!isProcessing && (
          <>
            <div className={`${styles.image_wrapper} ${styles.icon}`}>
              <ChevronRightSmallSvg />
            </div>
            <AddPlaylistOption
              isWholePlaylistOperation={isWholePlaylistOperation}
              itemId={libraryComponent?.id}
              addPlaylistRef={addPlaylistRef}
              isDisabled={isDisabled}
              linkPageId={linkPageId}
            />
          </>
        )}
      </div>
    ),
  embedPage: (callback, isActiveEmbed) => () => {
    return (
      <div key="MENU_OPTIONS_embedPage_option">
        <>
          <div
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            onClick={() => (isActiveEmbed ? callback() : null)}
            className={`${styles.editMenu__item}
              ${!isActiveEmbed ? styles.editMenu__item_disable : ''}
              ${!isActiveEmbed ? styles.grey : ''}
            `}
          >
            <div
              data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
              className={`${styles.image_wrapper}`}
            >
              <EmbedSvg />
            </div>
            <div className={`${styles.editMenu__text}`}>Embed page</div>
          </div>
        </>
      </div>
    );
  },
  moveToHeader:
    (moveOptionRef, folderId, pageId, setIsMenuVisible, scrollDelta) => () => (
      <div
        key="MENU_OPTIONS_moveToHeader"
        ref={moveOptionRef}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        className={` ${styles.editMenu__item} ${styles.editMenu__move_to}`}
      >
        <div className={`${styles.image_wrapper}`}>
          <MoveToSvg
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          />
        </div>

        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          className={`${styles.editMenu__text}`}
        >
          Move to
        </div>
        <div className={`${styles.image_wrapper} ${styles.icon}`}>
          <ChevronRightSmallSvg
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          />
        </div>
        <MoveOption
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          previousFolderIdMove={folderId}
          pageId={pageId}
          contentType="library"
          isSideBar
          setIsMenuVisible={setIsMenuVisible}
          moveRef={moveOptionRef}
          scrollDelta={scrollDelta}
        />
      </div>
    ),
  addTagLibraryComponent: (clickCB, isLast) => () => (
    <div
      key="MENU_OPTIONS_moveToLibraryLibraryComponent"
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      className={` ${styles.editMenu__item} ${styles.editMenu__move_to} ${
        isLast ? styles.editMenu__item_last : ''
      } `}
      onClick={clickCB}
    >
      <div className={`${styles.image_wrapper}`}>
        <AddTag16Svg
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        />
      </div>

      <div
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        className={`${styles.editMenu__text}`}
      >
        Add a tag
      </div>
    </div>
  ),
  openComponentMetaData: (clickCB, isLast) => () => (
    <div
      key="MENU_OPTIONS_openComponentMetaData"
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      className={` ${styles.editMenu__item} ${styles.editMenu__move_to} ${
        isLast ? styles.editMenu__item_last : ''
      } `}
      onClick={clickCB}
    >
      <div className={`${styles.image_wrapper}`}>
        <InfoCircle16Svg
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        />
      </div>

      <div
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        className={`${styles.editMenu__text}`}
      >
        View Metadata
      </div>
    </div>
  ),
  openPlaylistMetaData: (clickCB, isLast) => () => (
    <div
      key="MENU_OPTIONS_openPlaylistMetaData"
      className={` ${styles.editMenu__item} ${styles.editMenu__move_to} ${
        isLast ? styles.editMenu__item_last : ''
      } `}
      onClick={clickCB}
    >
      <div className={`${styles.image_wrapper}`}>
        <InfoCircle16Svg />
      </div>

      <div className={`${styles.editMenu__text}`}>
        {i18n.t('viewMetadataT')}
      </div>
    </div>
  ),
  createPlaylistHeader: (clickCB, isLast, isProcessing) => () => (
    <div
      key="MENU_OPTIONS_moveToHeaderLibraryComponent"
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      className={`${styles.editMenu__item}
        ${styles.editMenu__move_to}
        ${isLast ? styles.editMenu__item_last : ''} 
        ${isProcessing ? styles.editMenu__item_disable : ''} 
        ${isProcessing ? styles.grey : ''} 
      `}
      onClick={clickCB}
    >
      <div className={`${styles.image_wrapper}`}>
        <AddPlaylist16Svg />
      </div>

      <div
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        className={`${styles.editMenu__text}`}
      >
        {!isProcessing && 'Create New SmartFile'}
        {isProcessing && 'Creating SmartFile'}
      </div>

      {isProcessing && (
        <div>
          <Spinner>
            <SpinnerSvg />
          </Spinner>
        </div>
      )}
    </div>
  ),
  addToPlaylistHeader:
    (openPlaylistsCB, isActive, addPlaylistRef, isLast, isProcessing) => () => (
      <div
        key="MENU_OPTIONS_addToPlaylistHeader"
        onClick={openPlaylistsCB}
        className={`
          ${styles.editMenu__item} 
          ${styles.editMenu__move_to} 
          ${isLast ? styles.editMenu__item_last : ''} 
          ${isProcessing ? styles.editMenu__item_disable : ''} 
          ${isProcessing ? styles.grey : ''} 
        `}
        ref={addPlaylistRef}
      >
        <div className={`${styles.image_wrapper}`}>
          <Playlist16Svg />
        </div>
        <div className={`${styles.editMenu__text}`}>
          {!isProcessing && i18n.t('addToSmartFileT')}
          {isProcessing && i18n.t('addingToSmartFileT')}
        </div>

        {isProcessing && (
          <div>
            <Spinner>
              <SpinnerSvg />
            </Spinner>
          </div>
        )}

        {!isProcessing && (
          <>
            <div className={`${styles.image_wrapper} ${styles.icon}`}>
              <ChevronRightSmallSvg />
            </div>
            <AddPlaylistOption addPlaylistRef={addPlaylistRef} />
          </>
        )}
      </div>
    ),
  addToPlaylistLibrary:
    (isActive, addPlaylistRef, itemId, isLast, isProcessing) => () => {
      const ref = useRef(null);
      return (
        <div
          data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
          key="MENU_OPTIONS_addToPlaylistLibrary"
          className={`
          ${styles.editMenu__item} 
          ${styles.editMenu__move_to} 
          ${isLast ? styles.editMenu__item_last : ''} 
          ${isProcessing ? styles.editMenu__item_disable : ''} 
          ${isProcessing ? styles.grey : ''} 
          `}
          ref={ref}
        >
          <div className={`${styles.image_wrapper}`}>
            <Playlist16Svg />
          </div>
          <div className={`${styles.editMenu__text}`}>
            {!isProcessing && i18n.t('addToSmartFileT')}
            {isProcessing && i18n.t('addingToSmartFileT')}
          </div>

          {isProcessing && (
            <div>
              <Spinner>
                <SpinnerSvg />
              </Spinner>
            </div>
          )}

          {!isProcessing && (
            <>
              <div className={`${styles.image_wrapper} ${styles.icon}`}>
                <ChevronRightSmallSvg />
              </div>
              <AddPlaylistOption
                addPlaylistRef={addPlaylistRef}
                itemId={itemId}
              />
            </>
          )}
        </div>
      );
    },
  unPublish: (unPublishCB, isPublish) => () => (
    <div
      key="MENU_OPTIONS_unPublish"
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      onClick={() => (isPublish ? unPublishCB : () => {})}
      className={`
      ${styles.editMenu__item} 
      ${styles.editMenu__item_first}
      ${!isPublish ? styles.editMenu__item_disable : ''}
      `}
    >
      <div
        data-parent="miniPage"
        className={`
        ${styles.image_wrapper} 
        ${!isPublish ? styles.svg_inactive : ''}
      `}
      >
        <UnPublish20Svg data-parent="miniPage" />
      </div>
      <div
        data-parent="miniPage"
        className={`
        ${styles.editMenu__text}
        ${!isPublish ? styles.text_inactive : ''}
      `}
      >
        {i18n.t('unshareT')}
      </div>
      {/* <div */}
      {/*  data-parent="miniPage" */}
      {/*  className={` */}
      {/*  ${styles.hot_key} */}
      {/*  ${!isPublish ? styles.svg_inactive : ''} */}
      {/* `} */}
      {/* > */}
      {/*  ⇧⌘R */}
      {/* </div> */}
    </div>
  ),
  renamePage: (openRenameCB, isFirst, isLast) => () => (
    <div
      key="MENU_OPTIONS_rename"
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      onClick={openRenameCB}
      className={`
        ${styles.editMenu__item}  
        ${isLast ? styles.editMenu__item_last : ''}  
        ${isFirst ? styles.editMenu__item_first : ''}
      `}
    >
      <div
        data-parent="miniPage"
        className={`${styles.image_wrapper} ${styles.editMenu__inactive} `}
      >
        <EditTextSvg data-parent="miniPage" />
      </div>
      <div data-parent="miniPage" className={`${styles.editMenu__text}`}>
        {i18n.t('renameT')}
      </div>
      <div data-parent="miniPage" className={`${styles.hot_key}`}>
        ⇧⌘R
      </div>
    </div>
  ),
  rename: (openRenameCB, isDisabled) => () => (
    <div
      key="MENU_OPTIONS_rename"
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      onClick={openRenameCB}
      className={`
        ${styles.editMenu__item} 
        ${styles.editMenu__item_first}
        ${isDisabled ? styles.editMenu__item_disable : ''}
        ${isDisabled ? styles.grey : ''}
      `}
    >
      <div
        data-parent="miniPage"
        className={`
        ${styles.image_wrapper} 
        ${styles.editMenu__inactive}
        ${isDisabled ? styles.text_inactive : ''}
      `}
      >
        <EditTextSvg data-parent="miniPage" />
      </div>
      <div data-parent="miniPage" className={`${styles.editMenu__text}`}>
        {i18n.t('renameT')}
      </div>
      <div data-parent="miniPage" className={`${styles.hot_key}`}>
        ⇧⌘R
      </div>
    </div>
  ),
  duplicate: (duplicatePlaylistCB, isLast, isDisabled) => () => (
    <div
      key="MENU_OPTIONS_duplicate"
      onClick={(e) => {
        if (isDisabled) return;
        duplicatePlaylistCB(e);
      }}
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      className={`
        ${styles.editMenu__item} 
        ${isLast ? styles.editMenu__item_last : ''}
        ${isDisabled ? styles.editMenu__item_disable : ''}
        ${isDisabled ? styles.grey : ''}
      `}
    >
      <div className={styles.image_wrapper}>
        <DuplicateSvg data-parent="miniPage" />
      </div>

      <div data-parent="miniPage" className={`${styles.editMenu__text}`}>
        {i18n.t('duplicateT')}
      </div>
      <div data-parent="miniPage" className={`${styles.hot_key}`}>
        ⌘D
      </div>
    </div>
  ),
  duplicatePage: (duplicatePlaylistCB) => () => (
    <div
      key="MENU_OPTIONS_duplicatePage"
      onClick={duplicatePlaylistCB}
      data-parent="miniPage"
      className={`${styles.editMenu__item}`}
    >
      <div className={styles.image_wrapper}>
        <DuplicateSvg data-parent="miniPage" />
      </div>

      <div data-parent="miniPage" className={`${styles.editMenu__text}`}>
        {i18n.t('duplicateT')}
      </div>
      <div data-parent="miniPage" className={`${styles.hot_key}`}>
        ⌘D
      </div>
    </div>
  ),
  delete: (deleteItemCB, isDisabled) => () => (
    <div key="MENU_OPTIONS_delete">
      <div className={styles.editMenu__separator} />

      <div
        className={`
          ${styles.editMenu__item_delete} 
          ${styles.editMenu__item_last}  
          ${styles.editMenu__item_first}
          ${isDisabled ? styles.editMenu__item_disable : ''}
          ${isDisabled ? styles.grey : ''}
        `}
        onClick={(e) => {
          if (isDisabled) return;
          deleteItemCB(e);
        }}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      >
        <div className={styles.image_wrapper}>
          <DeleteRedSvg />
        </div>
        <div className={`${styles.editMenu__text} ${styles.editMenu__delete}`}>
          {i18n.t('moveToTrashT')}
        </div>
      </div>
    </div>
  ),
  favorite: (toggleFavoriteCB, isFavorite, isDisabled) => () => (
    <div key="MENU_OPTIONS_favorite">
      <div className={styles.editMenu__separator} />

      <div
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
        onClick={(e) => {
          if (isDisabled) return;
          toggleFavoriteCB(e);
        }}
        className={`
          ${styles.editMenu__item} 
          ${styles.editMenu__item_last}  
          ${styles.editMenu__item_first}
          ${isDisabled ? styles.editMenu__item_disable : ''}
          ${isDisabled ? styles.grey : ''}
        `}
      >
        <div
          data-parent="miniPage"
          className={`${styles.image_wrapper}  ${
            isFavorite ? styles.favorite : ''
          }`}
        >
          <FavoriteSvg />
        </div>
        <div className={`${styles.editMenu__text}`}>
          {isFavorite ? i18n.t('removeFavoriteT') : i18n.t('addToFavoritesOptionT') }
        </div>
        <div className={`${styles.hot_key}`}>⇧⌘R</div>
      </div>
    </div>
  ),
  removeContent: (removeContentCB) => () => (
    <div key="MENU_OPTIONS_removeContent">
      <div
        className={`${styles.editMenu__item_delete} ${styles.editMenu__item_last}  ${styles.editMenu__item_first}`}
        onClick={removeContentCB}
        data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
      >
        <div className={styles.image_wrapper}>
          <DeleteRedSvg />
        </div>
        <div className={`${styles.editMenu__text} ${styles.editMenu__delete}`}>
          {i18n.t('removeSmartFileT')}
        </div>
      </div>
    </div>
  ),
  leaveChannel:
    (onClick, DATA_PARENT = `${DATA_PARENTS.ActivityMore}`) => () => (
      <div
        onClick={onClick}
        data-parent={DATA_PARENT}
        className={`${styles.editMenu__item} ${styles.editMenu__item_last} ${styles.editMenu__item_first}`}
        key="MENU_OPTIONS_ActivityMore_Leave"
      >
        <div className={`${styles.editMenu__text} ${styles.red_text} `}>
          {i18n.t('leaveChannelT')}
        </div>
      </div>
    ),
  deleteFromTheList:
    (onClick, DATA_PARENT = `${DATA_PARENTS.ActivityMore}`) => () => (
      <div
        onClick={onClick}
        className={`${styles.editMenu__item_delete} ${styles.editMenu__item_last} ${styles.editMenu__item_first}`}
        data-parent={DATA_PARENT}
        key="MENU_OPTIONS_ActivityMore_Delete"
      >

        <div className={`${styles.editMenu__text} ${styles.editMenu__delete} ${styles.red_text}`}>
          {i18n.t('moveToTrashT')}
        </div>
      </div>
    ),
  chooseProfileAvatar: (onClick) => () => (
    <div
      onClick={(e) => onClick(e, AVATAR_CHANGE_OPTIONS.AvatarImage)}
      className={`${styles.editMenu__item} ${styles.editMenu__item_first}`}
      key="MENU_OPTIONS_AvatarSelector_chooseProfileAvatar"
    >
      <div
        className={`${styles.editMenu__text} ${styles.editMenu__item_preferences}`}
      >
        {i18n.t('avatarImageT')}
      </div>
    </div>
  ),
  chooseGeneratedAvatar: (onClick) => () => (
    <div
      onClick={(e) => onClick(e, AVATAR_CHANGE_OPTIONS.GeneratedAvatar)}
      className={`${styles.editMenu__item} ${styles.editMenu__item_last}`}
      key="MENU_OPTIONS_AvatarSelector_chooseGeneratedAvatar"
    >
      <div
        className={`${styles.editMenu__text} ${styles.editMenu__item_preferences}`}
      >
        {i18n.t('lettersT')}
      </div>
    </div>
  ),
  renameEmoji: (onClick, DATA_PARENT) => () => (
    <div
      onClick={(e) => onClick(e, AVATAR_CHANGE_OPTIONS.GeneratedAvatar)}
      className={`${styles.editMenu__item} ${styles.editMenu__item_first}`}
      key="MENU_OPTIONS_emojiTable_renameEmoji"
      data-parent={DATA_PARENT}
    >
      <div className={styles.image_wrapper}>
        <EditTextSvg data-parent="miniPage" />
      </div>
      <div className={`${styles.editMenu__text}`}>
        {i18n.t('renameT')}
      </div>
    </div>
  ),
  removeEmoji: (onClick, DATA_PARENT) => () => (
    <div
      onClick={(e) => onClick(e, AVATAR_CHANGE_OPTIONS.GeneratedAvatar)}
      className={`${styles.editMenu__item_delete} ${styles.editMenu__item_last}`}
      key="MENU_OPTIONS_emojiTable_removeEmoji"
      data-parent={DATA_PARENT}
    >
      <div className={styles.image_wrapper}>
        <DeleteRedSvg />
      </div>
      <div className={`${styles.editMenu__text} ${styles.editMenu__delete}`}>
        {i18n.t('removeT')}
      </div>
    </div>
  ),
  Player: {
    PlayerAddItemToYourLibrary:
      (
        onClick,
        DATA_PARENT = 'PlayerAddItemToYourLibrary',
        isFirst,
        isLast,
        isDisabled,
      ) => () => (
        <div
          onClick={(e) => onClick(e)}
          className={`
          ${styles.editMenu__item} 
          ${isLast ? styles.editMenu__item_last : ''} 
          ${isFirst ? styles.editMenu__item_first : ''}
          ${isDisabled ? styles.editMenu__item_disable : ''}
          ${isDisabled ? styles.grey : ''}
        `}
          key="MENU_OPTIONS_Player_AddItemToYourLibrary"
          data-parent={DATA_PARENT}
        >
          <div className={styles.image_wrapper}>
            <PlayerAddItemLibrarySvg />
          </div>
          <div className={`${styles.editMenu__text}`}>
            {i18n.t('addItemToLibraryT')}
          </div>
        </div>
      ),
    HideShowCaptionsAndApproveButtons:
      (
        isHidden,
        onClick,
        DATA_PARENT = 'HideShowCaptionsAndApproveButtons',
        isFirst,
        isLast,
      ) => () => (
        <div
          onClick={(e) => onClick(e)}
          className={`${styles.editMenu__item}  
          ${isLast ? styles.editMenu__item_last : ''} 
          ${isFirst ? styles.editMenu__item_first : ''}
        `}
          key="MENU_OPTIONS_Player_HideShowCaptionsAndApproveButtons"
          data-parent={DATA_PARENT}
        >
          <div className={styles.image_wrapper}>
            <HideShow14Svg />
          </div>
          <div className={`${styles.editMenu__text} ${styles.long_text}`}>
            {isHidden
              && i18n.t('hideCaptionsAndApproveButtonsT')}
            {!isHidden
              && i18n.t('showCaptionsAndApproveButtonsT')}
          </div>
        </div>
      ),
  },
};
