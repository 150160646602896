import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import classnames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import rawStyles from './index.module.scss';
import SelectorDropDown from '../LowResolutionNavDropDown/LowResolutionNavDropDown';
import { ReactComponent as Google16Svg } from '../../images/icons/google_drive_16.svg';
import { ReactComponent as Dropbox16Svg } from '../../images/icons/dropbox_16.svg';
import {
  DATA_PARENTS,
  LibCompSieveTypesSideBar,
  mapTitles,
} from '../../utils/constants';
import { convertToSmartNumbers } from '../../utils/helpers';

const styles = classnames.bind(rawStyles);

const NavSlider = ({
  setSieve,
  setActiveNavSlider,
  activeNavSlider,
  isNoLeftMargin,
  usage,
  permissions,
  isCardView,
  sieveOptions,
  isUploadElement,
  isBottomMargin,
  isDisableBottomMargin,
  isChannels,
  isHasVerticalLine,
  isComputerTab,
  forContacts,
  forUploadModal,
  ...props
}) => {
  const history = useHistory();
  const [optionsWidthsArray, setOptionsWidthsArray] = useState([]);
  const navOptionsRefs = useRef(
    Array.from(Object.values(sieveOptions || {}), () => React.createRef()),
  );
  const isDescriptionOpened = useSelector(
    (state) => state.support.componentDescription,
  );
  useEffect(() => {
    setOptionsWidthsArray(
      navOptionsRefs.current?.map((i) => i.current?.clientWidth),
    );
  }, [props.items]);

  useEffect(() => {
    navOptionsRefs.current = Array.from(Object.values(sieveOptions || {}), () => React.createRef(),
    );
  }, [sieveOptions, activeNavSlider]);

  const isOptionComponentsInSideBar = (itemType) => (itemType === LibCompSieveTypesSideBar.Components
      && (history.location.pathname.includes('playlist')
        || history.location.pathname.includes('maker')));

  const checkIsActive = (itemType) => {
    return itemType?.toLowerCase() === activeNavSlider?.toLowerCase();
  };
  return (
    <NavWrapper
      isCardView
      isNoLeftMargin={isNoLeftMargin}
      isBottomMargin={isBottomMargin}
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      usage={usage}
      isDisableBottomMargin={isDisableBottomMargin || isComputerTab}
      forUploadModal={forUploadModal}
    >
      <div className={styles('nav_bar', { customTopPadding: isUploadElement, isDescriptionOpened })}>
        <div className={styles('displayFlex', { isNavVisible: !forUploadModal, isDescriptionOpened })}>
          {Object.values(sieveOptions || {}).map((itemType, index) => (
            <div
              data-cy={mapTitles(itemType)}
              ref={navOptionsRefs.current[index]}
              data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
              className={styles('nav_item', {
                disabled: isOptionComponentsInSideBar(itemType),
                first: isChannels && !index,
              })}
              key={`${itemType}`}
              onClick={(e) => {
                if (itemType === activeNavSlider) return;
                if (isOptionComponentsInSideBar(itemType)) return;
                e.stopPropagation();
                setSieve(itemType);
                setActiveNavSlider(itemType);
              }}
            >
              <Text
                forContacts={forContacts}
                active={checkIsActive(itemType)}
                disabled={isOptionComponentsInSideBar(itemType)}
                forUploadModal={forUploadModal}
              >
                {mapTitles(itemType) === 'Google' && (
                  <Google16Svg style={{ marginRight: 2 }} />
                )}
                {mapTitles(itemType) === 'GOOGLE DRIVE' && (
                  <Google16Svg
                    style={{
                      marginRight: 9,
                      position: 'relative',
                      top: 6,
                      left: 5,
                    }}
                  />
                )}
                {mapTitles(itemType) === 'DROPBOX' && (
                  <Dropbox16Svg
                    style={{
                      marginRight: 9,
                      position: 'relative',
                      top: 6,
                      left: 5,
                    }}
                  />
                )}
                {mapTitles(itemType)}

                <div
                  className={styles('users_count', {
                    active: checkIsActive(itemType),
                    disabled: isOptionComponentsInSideBar(itemType),
                  })}
                >
                  {convertToSmartNumbers(
                    props.items && props.items[itemType]?.length,
                  )}
                </div>
              </Text>
            </div>
          ))}
        </div>
        {!forUploadModal && (
          <SelectorDropDown
            dataP={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
            setSelector={setSieve}
            selector={activeNavSlider}
            itemsList={Object.values(sieveOptions)}
            calcCount={() => convertToSmartNumbers(props.items?.[activeNavSlider]?.length)}
            className={styles('isDropdownVisible')}
            isChannels
            mapTitles={mapTitles}
          />
        )}
      </div>
      {props.children}
    </NavWrapper>
  );
};

export default NavSlider;

const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
  margin: ${({ isCardView, isNoLeftMargin, isBottomMargin }) => (isCardView
    ? `0 ${isNoLeftMargin ? '5' : '64'}px ${
      isBottomMargin ? ' 24px ' : ' 13px '
    } ${isNoLeftMargin ? '0' : '76'}px`
    : `0 ${isNoLeftMargin ? '5' : '40'}px ${
      isBottomMargin ? ' 24px ' : ' 13px '
    } ${isNoLeftMargin ? '0' : '44'}px`)};

  ${({ isDisableBottomMargin }) => (isDisableBottomMargin ? 'margin-bottom: 0;' : '')}
  ${({ usage }) => {
    switch (usage) {
      case 'views': {
        return 'margin-left: 0;';
      }
      case 'help': {
        return 'margin: 0 0 0 29px;';
      }
      default:
        return '';
    }
  }}
  
  ${({ usage, forUploadModal }) => (usage === 'help' || forUploadModal
    ? `
      margin: 0;
    ` : `
      @media (max-width: 899.9px) {
        padding-right: 8px;
      }
  `)}
`;

const Text = styled.div`
  position: relative;
  display: flex;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: ${(props) => (props.active ? '#484343' : '#585858')};
  background-color: ${(props) => (props.active ? '#FFC72C' : '')};
  height: 32px;
  line-height: 32px;

  border-radius: 8px;
  white-space: nowrap;
  font-family: RobotoFlex;
  font-size: 14px;
  letter-spacing: 0.03em;

  &:hover {
    background-color: #ffeebf;
  }

  ${({ disabled }) => (disabled
    ? `
    color: lightgrey;
    &:hover {
      background-color: initial;
    }
  `
    : '')}
  ${({ forContacts }) => (forContacts ? 'padding: 0 10px 0 13px;' : 'padding: 0 7px 0 12px;')}
  ${({ forUploadModal }) => (forUploadModal
    ? `
  line-height:28px;
      padding: 0 7px 0 9px;

  height:28px;`
    : '')}
`;
