import React from 'react';
import styled from 'styled-components/macro';
import SelectorDropDown from './ChildComponents/SelectorDropDown';
import SecondaryMenu from '../../../../../components/LibraryHeaderMenu/childComponents/SecondaryMenu';
import { emptyCallback } from '../../../../../utils/constants';

const UPVLibBTNGroupWrapper = styled.div`
  margin-top: 21px;
  padding-left: 29px;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const BtnGroup = ({
  selector,
  setSelector,
  sortType,
  setSort,
  setChosenSmartView,
  setChosenTag,
  dynamicTab,
  resetDynamicTab,
  dataP,
  isPage,
  chosenTag,
}) => {
  return (
    <UPVLibBTNGroupWrapper data-parent={dataP}>
      <SelectorDropDown
        setSelector={setSelector}
        dynamicTab={dynamicTab}
        resetDynamicTab={resetDynamicTab}
        dataP={dataP}
        isPage={isPage}
        chosenTag={chosenTag}
      />
      <SecondaryMenu
        setChosenSmartView={setChosenSmartView}
        sortType={sortType}
        setSort={setSort}
        place={selector}
        newUPVMode
        setShowNewSmartView={emptyCallback}
        resetSmartview={emptyCallback}
        smartViewForEdit={{}}
        setSmartViewForEdit={emptyCallback}
        setChosenTag={setChosenTag}
        dataParent={dataP}
      />
    </UPVLibBTNGroupWrapper>
  );
};

export default BtnGroup;
