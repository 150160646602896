import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSmallSvg } from '../../../images/icons/close_7.svg';
import '../datepicker.css';
import rawStyles from '../index.module.scss';

import { ReactComponent as RadioButton } from '../../../images/icons/icon_20/radioButton.svg';
import {
  calcDuration,
  calcDurationRead,
  calcGreater,
  getDateToStringForSafari,
} from '../helpers';
import { LeftButton, RightButton } from './Buttons';

const styles = classnames.bind(rawStyles);

const PlaylistsDatePickerSafari = ({
  saveState,
  setSelectedDateTo,
  selectedDateTo,
  selectOtherOption,
  setSelectedDateFrom,
  setSelectOtherOption,
  selectedDateFrom,
  textOtherOption,
  dataParent,
}) => {
  const [openedDateTo, setIsOpenedDateTo] = useState(false);
  const [openedDateFrom, setIsOpenedDateFrom] = useState(false);
  const { t } = useTranslation();
  const refDateFrom = useRef(null);
  const refDateTo = useRef(null);
  const setOpenedDateFrom = (val) => {
    if (openedDateFrom) {
      return;
    }

    refDateFrom.current.focus();
    setIsOpenedDateFrom(val);
  };
  const setOpenedDateTo = (val) => {
    if (openedDateTo) {
      return;
    }
    refDateTo?.current?.focus();
    setIsOpenedDateTo(val);
  };
  const nowDate = new Date();
  const duration = calcDuration(selectedDateFrom);

  const setSelectedDateToHandler = (newSelectedDateTo) => {
    newSelectedDateTo.setHours(23, 59, 59, 999);
    setSelectedDateTo(newSelectedDateTo);
  };

  const setSelectedDateFromHandler = (newSelectedDateFrom) => {
    const newDate = new Date(newSelectedDateFrom.setHours(0, 0, 0, 0));
    if (calcGreater(newSelectedDateFrom, selectedDateTo)) {
      setSelectedDateToHandler(new Date(newDate));
    }
    setSelectedDateFrom(newDate);
  };

  useEffect(() => {
    saveState();
  }, [selectedDateFrom, selectedDateTo, selectOtherOption]);

  useEffect(() => {
    if (selectOtherOption && (openedDateFrom || openedDateTo)) setSelectOtherOption(false);
  }, [selectOtherOption, openedDateFrom, openedDateTo]);
  return (
    <div className={styles('radio_selector')} data-parent={dataParent}>
      <div
        className={styles('option')}
        data-parent={dataParent}
        onClick={() => setSelectOtherOption(true)}
      >
        <RadioButton
          className={styles('radioButton', { selected: selectOtherOption })}
          data-parent={dataParent}
        />
        <div className={styles('time', 'no_date')}> {textOtherOption}</div>
      </div>
      <div className={styles('option')} data-parent={dataParent}>
        <RadioButton
          className={styles('radioButton', { selected: !selectOtherOption })}
          onClick={() => setSelectOtherOption(false)}
          data-parent={dataParent}
        />
        <div className={styles('picker_group')}>
          <div className={styles('row')} data-parent={dataParent}>
            <div
              tabIndex="0"
              ref={refDateFrom}
              onClick={() => setOpenedDateFrom(true)}
              className={styles('datePicker')}
              data-parent={dataParent}
            >
              {!openedDateFrom && (
                <div className={styles('overlay_value')}>
                  {getDateToStringForSafari(selectedDateFrom)}
                </div>
              )}

              <div className={styles('name')}>{t('fromDateT')}</div>
              {openedDateFrom && (
                <DatePicker
                  onChange={(e) => {
                    setSelectedDateFromHandler(e);
                  }}
                  value={selectedDateFrom}
                  clearIcon={<CloseSmallSvg />}
                  tileClassName="tileClass"
                  className="playlistsDatePicker"
                  showLeadingZeros
                  isOpen={openedDateFrom}
                  onCalendarClose={(a) => {
                    setIsOpenedDateFrom(false);
                    setOpenedDateFrom(false, 'close', a);
                  }}
                  minDate={openedDateFrom && nowDate}
                  prevLabel={<LeftButton dataParent={dataParent} />}
                  nextLabel={<RightButton dataParent={dataParent} />}
                  disabled={selectOtherOption}
                />
              )}
            </div>
            {!selectOtherOption && selectedDateFrom && (
              <>
                {!!duration && duration > 0 && (
                  <div className={styles('time')}>
                    Available in {duration} days
                  </div>
                )}
                {!!duration && duration < 0 && (
                  <div className={styles('time')}>
                    It was available {Math.abs(duration)} days
                  </div>
                )}
                {!duration && (
                  <div className={styles('time')}>{t('availableTodayT')}</div>
                )}
              </>
            )}
          </div>
          <div className={styles('row')} data-parent={dataParent}>
            <div
              ref={refDateTo}
              tabIndex="0"
              onClick={() => setOpenedDateTo(true)}
              className={styles('datePicker')}
              data-parent={dataParent}
            >
              {!openedDateTo && (
                <div className={styles('overlay_value')}>
                  {getDateToStringForSafari(selectedDateTo)}
                </div>
              )}
              <div className={styles('name')}>{t('toDateT')}</div>
              {openedDateTo && (
                <DatePicker
                  onChange={(e) => setSelectedDateToHandler(e)}
                  minDate={selectedDateFrom || nowDate}
                  value={selectedDateTo}
                  tileClassName="tileClass"
                  className="playlistsDatePicker"
                  showLeadingZeros
                  isOpen={openedDateTo}
                  onCalendarClose={() => {
                    setIsOpenedDateTo(false);
                    setOpenedDateTo(false);
                  }}
                  prevLabel={<LeftButton dataParent={dataParent} />}
                  nextLabel={<RightButton dataParent={dataParent} />}
                  disabled={selectOtherOption}
                />
              )}
            </div>
            {!selectOtherOption && selectedDateTo && (
              <>
                {!!calcDuration(selectedDateTo) && (
                  <div className={styles('time')}>
                    {calcDurationRead(selectedDateFrom, selectedDateTo)} {t('daysToGoT')}
                  </div>
                )}
                {!calcDuration(selectedDateTo) && (
                  <div className={styles('time')}>{t('TodayT')}</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaylistsDatePickerSafari;
