import { useEffect, useRef, useState, Dispatch, SetStateAction, MutableRefObject } from 'react';

interface UseComponentVisibleReturnObject {
  ref: MutableRefObject<HTMLElement | null>;
  isComponentVisible: boolean | string;
  setIsComponentVisible: Dispatch<SetStateAction<boolean | string>>;
}

type UseComponentVisibleReturnArray = [
  MutableRefObject<HTMLElement | null>,
  boolean | string,
  Dispatch<SetStateAction<boolean | string>>
];

export default function useComponentVisible(
  initialIsVisible: boolean | string = false,
  data?: string,
  isObject?: boolean,
  isPrevent?: MutableRefObject<boolean>,
  callbackForOutsideClick?: (event: MouseEvent)=>void,
): UseComponentVisibleReturnObject | UseComponentVisibleReturnArray {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean | string>(initialIsVisible);
  const ref = useRef<HTMLElement | null>(null);

  const handleHideDropdown = (event: KeyboardEvent): void => {
    if (isPrevent?.current) return;
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (isPrevent?.current) return;
    if (isComponentVisible === 'init') return;
    if (data) {
      if (
        ref.current
        && !ref.current.contains(event.target as Node)
      && !(event.target as HTMLElement)?.dataset?.parent?.includes(data)
      && !(event.target as HTMLElement)?.parentElement?.dataset?.parent?.includes(data)
      ) {
        setIsComponentVisible(false);
      }
      return;
    }

    if (ref.current && !ref.current.contains(event.target as Node)) {
      if (callbackForOutsideClick) callbackForOutsideClick(event);
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    if (isComponentVisible) {
      document.addEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('contextmenu', handleClickOutside, true);
      document.addEventListener('click', handleClickOutside, true);
    } else {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('contextmenu', handleClickOutside, true);
      document.removeEventListener('click', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('contextmenu', handleClickOutside, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isComponentVisible]);

  if (isObject) return { ref, isComponentVisible, setIsComponentVisible };
  return [ref, isComponentVisible, setIsComponentVisible];
}
