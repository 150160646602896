import { ChannelsSieveTypes, PLACE } from '../../utils/constants';
import { Library } from '../library/types';
import { Channels } from './types';
import { ServiceUser } from '../user/types';
import EditPlaylist from '../playlists/types';
import { EditPage } from '../pages/types';
import SupportAction from '../support/types';
import {
  CHANNEL_PERMISSIONS,
  RawUserRoleMap,
  Statuses,
} from '../../utils/permissions';
import { ContentActionType } from '../content/contentTypes';
import downloadStatus from '../../utils/dataUtil';

const defaultState = {
  activeSieveOption: ChannelsSieveTypes.ChannelsAll,
  sieveOptions: ChannelsSieveTypes,
  userRoleFilter: '',
  userStatusFilter: '',
  myChannels: {},
  subscriptionStatus: '',
  loadStatus: downloadStatus.pending,

};

export default (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case Library.ChangeSieveValue: {
      if (payload.place === PLACE.Channels) return { ...state, activeSieveOption: payload.value };
      return state;
    }
    case Channels.AddChannelR: {
      state.myChannels[payload.channelId] = {
        id: payload.channelId,
        name: payload.name || payload.title || 'Unnamed channel',
        isShared: payload.isShared,
        // isNew: payload.channelId,
        role: RawUserRoleMap.Admin,
        canPostContent: true,
        permissions: CHANNEL_PERMISSIONS.admin,
        status: Statuses.Active,
        owner: {
          id: payload.user.id,
          name: `${payload.user.first_name} ${payload.user.last_name}`,
          email: payload.user.email,
        },
        playlistsCounter: 0,
        position: payload.newPosition,
      };
      return { ...state, myChannels: { ...state.myChannels } };
    }

    case EditPage.AddPageToChannelR: {
      const { channel, page } = payload;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [channel.id]: {
            ...state.myChannels[channel.id],
            libraryComponents: {
              ...state.myChannels[channel.id].libraryComponents,
              [page.id]: {
                ...page,
              },
            },
          },
        },
      };
    }

    case EditPage.RemovePageFromChannelR: {
      const { channel, page } = payload;
      delete state.myChannels[channel.id].libraryComponents[page.id];
      return { ...state };
    }

    case EditPlaylist.ToggleFavorite: {
      const { id, isFavorite, channelId } = payload;
      let playlistsFavoriteCounter = state.myChannels[channelId]?.playlistsFavoriteCounter;
      if (isFavorite) ++playlistsFavoriteCounter;
      else --playlistsFavoriteCounter;
      if (channelId && state.myChannels[channelId]) {
        return {
          ...state,
          myChannels: {
            ...state.myChannels,
            [channelId]: {
              ...state.myChannels[channelId],
              playlistsFavoriteCounter,
            },
          },
        };
      }
      return { ...state };
    }
    case ContentActionType.updateCounterChannel: {
      const { channelId, counters } = payload;
      if (channelId && state.myChannels[channelId]) {
        return {
          ...state,
          myChannels: {
            ...state.myChannels,
            [channelId]: {
              ...state.myChannels[channelId],
              ...counters,
              totalSubs: counters.subscribersInvited + counters.subscribers,
            },
          },
        };
      }
      return state;
    }
    case ContentActionType.updateCounterSFChannel: {
      const { channelId, counter } = payload;
      if (channelId && state.myChannels[channelId]) {
        return {
          ...state,
          myChannels: {
            ...state.myChannels,
            [channelId]: {
              ...state.myChannels[channelId],
              playlistsCounter: state.myChannels[channelId].playlistsCounter + counter,
            },
          },
        };
      }
      return state;
    }

    // case MiniPlaylists.ToggleHide: {
    //   const { id, isHide, channelId } = payload;
    //   if (channelId && state.myChannels[channelId]) {
    //     return {
    //       ...state,
    //       myChannels: {
    //         ...state.myChannels,
    //         [channelId]: {
    //           ...state.myChannels[channelId],
    //           playlists: {
    //             ...state.myChannels[channelId].playlists,
    //             [id]: {
    //               ...state.myChannels[channelId].playlists[id],
    //               isHide,
    //             },
    //           },
    //         },
    //       },
    //     };
    //   }
    //   return { ...state };
    // }

    // case EditPage.ToggleFavoritePageInChannel: {
    //   const { channelId, pageId, isFavorite } = payload;
    //
    //   return {
    //     ...state,
    //     myChannels: {
    //       ...state.myChannels,
    //       [channelId]: {
    //         ...state.myChannels[channelId],
    //         libraryComponents: {
    //           ...state.myChannels[channelId].libraryComponents,
    //           [pageId]: {
    //             ...state.myChannels[channelId].libraryComponents[pageId],
    //             isFavorite,
    //           },
    //         },
    //       },
    //     },
    //   };
    // }
    //

    case Channels.SaveChannelsR: {
      return { ...state, myChannels: { ...payload } };
    }

    case Channels.UploadUserChannelsR: {
      return { ...state, loadStatus: downloadStatus.success, myChannels: { ...payload, ...state.myChannels } };
    }
    case Channels.SaveChannelR: {
      const newCh = {};
      newCh[payload.id] = {
        ...state.myChannels[payload.id],
        ...payload.channel,
      };
      return { ...state, myChannels: { ...state.myChannels, ...newCh } };
    }
    case Channels.clearChannelData: {
      if (state?.myChannels[payload?.id]) {
        state.myChannels[payload.id] = {
          id: payload?.id,
          name: state?.myChannels[payload?.id]?.name,
          isShared: state?.myChannels[payload?.id]?.isShared,
          isCanPublish: state?.myChannels[payload?.id]?.isCanPublish,
          status: 'init',
        };
        return { ...state, myChannels: { ...state.myChannels } };
      }
      return state;
    }
    // uncomment to force section to drop nav bar state to initial position
    // case Channels.SetCurrentChannel: {
    //   return { ...state, activeSieveOption: ChannelsSieveTypes.Pages };
    // }
    case EditPlaylist.AddPlaylistToChannelR: {
      const { channel, playlist } = payload;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [channel.id]: {
            ...state.myChannels[channel.id],
            playlists: {
              ...state.myChannels[channel.id].playlists,
              [playlist.id]: {
                ...playlist,
              },
            },
          },
        },
      };
    }
    case EditPlaylist.RemovePlaylistFromChannelR: {
      const { channel, playlist } = payload;
      const newState = { ...state };
      if (
        newState.myChannels[channel.id]
        && newState.myChannels[channel.id].playlists
        && newState.myChannels[channel.id].playlists[playlist.id]
      ) {
        delete newState.myChannels[channel.id].playlists[playlist.id];
      }
      return { ...newState };
    }
    // case (Channels.ChangeChannelUserStatus): {
    //   const { userId, newStatus, channelId } = payload;
    //   if (newStatus === 'Deleted') {
    //     const newListUsers = { ...state.myChannels[channelId].users };
    //     delete newListUsers[userId];
    //     return {
    //       ...state,
    //       myChannels: {
    //         ...state.myChannels,
    //         [channelId]: {
    //           ...state.myChannels[channelId],
    //           totalSubs: state.myChannels[channelId].totalSubs - 1,
    //           users: newListUsers,
    //         },
    //       },
    //     };
    //   }
    //   return {
    //     ...state,
    //     myChannels: {
    //       ...state.myChannels,
    //       [channelId]: {
    //         ...state.myChannels[channelId],
    //         users: {
    //           ...state.myChannels[channelId].users,
    //           [userId]: {
    //             ...state.myChannels[channelId].users[userId],
    //             status: newStatus,
    //           },
    //         },
    //       },
    //     },
    //   };
    // }
    case Channels.SetNewFilterValue: {
      return {
        ...state,
        [`user${payload.place}Filter`]: payload.newValue,
      };
    }
    case Channels.AddSubscribersR: {
      const { users, channelId } = payload;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [channelId]: {
            ...state.myChannels[channelId],
            totalSubs:
              state.myChannels[channelId].totalSubs
              + Object.values(users).length,
            users: {
              ...users,
              ...state.myChannels[channelId].users,
            },
          },
        },
      };
    }
    case Channels.UpdateChannelR: {
      const channel = payload;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [channel.id]: {
            ...state.myChannels[channel.id],
            ...channel,
          },
        },
      };
    }
    case Channels.RenameChannel: {
      const updChannel = state.myChannels[payload.channelId];
      updChannel.name = payload.name;
      updChannel.isNew = false;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [payload.channelId]: { ...updChannel },
        },
      };
    }
    case Channels.DeleteChannel: {
      const channelToDell = { ...state.myChannels };
      delete channelToDell[payload.channelId];
      const newState = { ...state, myChannels: channelToDell };

      return { ...newState };
    }
    case Channels.UserLeaveChannel: {
      const channelToDell = { ...state.myChannels };
      delete channelToDell[payload.channelId];
      const newState = { ...state, myChannels: channelToDell };

      return { ...newState };
    }
    case Channels.DeleteChannelR: {
      const newState = { ...state };
      delete newState.myChannels[payload.channelId];
      return { ...newState };
    }
    case ServiceUser.switchFolder: {
      return {
        ...state,
        userStatusFilter: '',
        userRoleFilter: '',
      };
    }
    case Channels.UpdatePlaylistsCountersR: {
      const { channelsMap } = payload;

      const newMyChannels = { ...state.myChannels };
      channelsMap.forEach((pair) => {
        if (newMyChannels[pair.id]) newMyChannels[pair.id].playlistsCounter = pair.playlistsCounter;
      });

      return {
        ...state,
        myChannels: newMyChannels,
      };
    }

    case Channels.ChangeChannelItemPositionR: {
      const { id, newPosition } = payload;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [id]: {
            ...state.myChannels[id],
            position: newPosition,
          },
        },
      };
    }
    case ServiceUser.Logout: {
      return { ...defaultState };
    }
    case EditPlaylist.MarkViewedR: {
      const { isFinished, playlistId, channelId } = payload;
      if (!channelId || !state.myChannels[channelId]?.playlists) return state;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [channelId]: {
            ...state.myChannels[channelId],
            playlists: {
              ...state.myChannels[channelId].playlists,
              [playlistId]: {
                ...state.myChannels[channelId].playlists[playlistId],
                isFinished,
              },
            },
          },
        },
      };
    }
    case Channels.SetPlaylistMatrixDataR: {
      const { playlistId, channelId, matrixValue } = payload;

      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [channelId]: {
            ...state.myChannels[channelId],
            playlists: {
              ...state.myChannels[channelId].playlists,
              [playlistId]: {
                ...state.myChannels[channelId].playlists[playlistId],
                matrixData: matrixValue,
              },
            },
          },
        },
      };
    }
    case Channels.UpdatePinState: {
      const { id } = payload;
      return {
        ...state,
        myChannels: {
          ...state.myChannels,
          [id]: {
            ...state.myChannels[id],
            isUnpin: !state.myChannels[id].isUnpin,
          },
        },
      };
    }
    default:
      return state;
  }
};
