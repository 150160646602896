import * as React from 'react';
import SortComponent from '../../../components/MiniPlaylist/NewPlaylist2023/childComponents/SortComponent';

export const generateColumns = (createTableColumn, setSort) => {
  return [
    createTableColumn({
      columnId: 'checkbox',
      renderHeaderCell: () => <></>,
    }),
    createTableColumn({
      columnId: 'nameAndAva',
      renderHeaderCell: () => <SortComponent isName isContact setSort={setSort} />,
    }),
    createTableColumn({
      columnId: 'mail',
      renderHeaderCell: () => <div className="custom_header">Mail</div>,
    }),
    createTableColumn({
      columnId: 'phone',
      renderHeaderCell: () => <div className="custom_header">Phone</div>,
    }),
    createTableColumn({
      columnId: 'function',
      renderHeaderCell: () => <div className="custom_header">Function</div>,
    }),
    createTableColumn({
      columnId: 'organization',
      renderHeaderCell: () => <div className="custom_header">Organization</div>,
    }),
    createTableColumn({
      columnId: 'channels',
      renderHeaderCell: () => <div className="custom_header">In channels</div>,
    }),
    createTableColumn({
      columnId: 'actions1',
      renderHeaderCell: () => (
        <div style={{ textAlign: 'right', width: '100%', paddingRight: 19 }}>
          Actions
        </div>
      ),
    }),
  ];
};
const getWidth = (k) => ((window.innerWidth - 300) * k) / 100;
export const generateColumnSizing = () => {
  return {
    checkbox: {
      maxWidth: 40,
      idealWidth: 40,
      minWidth: 40,
    },
    nameAndAva: {
      minWidth: 230,
      defaultWidth: getWidth(32),
    },
    mail: {
      minWidth: 230,
      defaultWidth: getWidth(32), // 15.46
    },
    phone: {
      minWidth: 150,
      defaultWidth: getWidth(15), // 16.15
    },
    function: {
      minWidth: 100,
      defaultWidth: getWidth(10), // 10.75
    },
    organization: {
      minWidth: 120,
      defaultWidth: getWidth(12), // 10.6
    },
    channels: {
      minWidth: 100,
      defaultWidth: getWidth(10), // 12.3
    },
    actions1: {
      defaultWidth: getWidth(15), // 3.07
      minWidth: 115,
    },
  };
};
