
interface Translations {
  [key: string]: {
    [key: string]: {
      [key: string]: string | { [lang: string]: string | { [lang: string]: string | { [lang: string]: string | { [lang: string]: string } } } };
    };
  };
}

export const PLAYER_TRANSLATIONS:Translations = {
  menuOptions: {
    AddItemToLibrary: {
      en: 'Add to your library',
    },
    AddItemToPlaylist: {
      en: 'Add to a SmartFile',
    },
    AddItemToPlaylist_processing: {
      en: 'Adding to SmartFile',
    },
    HideCaptionsAndApproveButtons: {
      en: 'Hide captions & approval buttons',
    },
    ShowCaptionsAndApproveButtons: {
      en: 'Show captions & approval buttons',
    },
  },
  header: {
    preview: {
      en: 'Preview',
    },
  },
};
