import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import CreateComponentHashtagsInput from '../../pages/Playlist/CreteComponentHashtagsInput';
import { empty, TagsParams, MessageType, DATA_PARENTS } from '../../utils/constants';
import { LibraryComponents } from '../../redux/library/types';
import { actionCreator } from '../../shared/redux/actionHelper';
import { actionShowMessage } from '../../redux/support/action';
import { actionCheckLinkForLibraryComponent } from '../../redux/library/actions';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ModalWindow = styled.div`
  width: 450px;
  border-radius: 12px;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px 0px #7a696940;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ece2e2, #ece2e2);
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #d3c8c8;
  margin-bottom: 12px;
  font-family: "RobotoFlex", sans-serif;
`;

const InputWrapper = styled.input`
  height: 32px;
  width: 418px;
  border-radius: 6px;
  border: 1px solid #7a696980;
  padding-left: 8px;
  &::placeholder {
    color: #7a696980;
    font-weight: 400;
    font-size: 15px;
  }
  &:focus {
    border: 1px solid #ffc72c;
  }
`;

const TagInputWrapper = styled.div`
  width: 418px;
  margin-top: 16px;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 264px;
  padding-top: 24px;
`;

const CreateLinkModal = () => {
  const { editComponentId } = useSelector((state) => state.library) || empty;
  const components = useSelector((state) => state.content.contentData) || empty;
  const { component } = useSelector((state) => state.support) || empty;
  const [newLibraryComponentId] = useState(editComponentId || uuidv4());
  const item = component
    || (editComponentId && components[editComponentId]) || { tags: [] };
  const [tagsToAttach, setTagsToAttach] = useState(item.tags);

  const history = useHistory();
  const [urlText, setUrlText] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const close = () => {
    dispatch(actionCloseModal());
  };

  const handleError = () => {
    dispatch(
      actionShowMessage({
        type: MessageType.InvalidLink,
      }),
    );
  };

  const onCreateButtonClick = () => {
    if (!urlText) {
      handleError();
      return;
    }
    if (
      urlText
      && urlText.startsWith('<iframe')
      && urlText.endsWith('</iframe>')
    ) {
      const newId = uuidv4();
      dispatch(
        actionCreator(LibraryComponents.CreateEmbedLibraryComponent, {
          iframeText: urlText,
          newId,
          history,
          tagsToAttach,
        }),
      );
      close();
      return;
    }
    dispatch(
      actionCheckLinkForLibraryComponent(
        urlText,
        close,
        handleError,
        tagsToAttach,
      ),
    );
  };

  const onUrlInputChange = (e) => {
    setUrlText(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onCreateButtonClick();
    }
  };

  return (
    <ModalWrapper
      data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      onClick={close}
    >
      <ModalWindow
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
        onClick={(e) => e.stopPropagation()}
      >
        <TitleWrapper>{t('createNewLinkUpT')}</TitleWrapper>
        <InputWrapper
          autoFocus
          placeholder={t('pasteWebPageOrEmbedLinkT')}
          value={urlText}
          onChange={onUrlInputChange}
          onKeyPress={handleInputKeyPress}
        />
        <TagInputWrapper data-parent={DATA_PARENTS.PlaylistBuilderLibrary}>
          <CreateComponentHashtagsInput
            noDescription
            isEditable
            place="LibraryComponent"
            type="LibraryComponent"
            state={TagsParams.createComponentTags}
            newLibraryComponentId={newLibraryComponentId}
            tagsToAttach={tagsToAttach}
            setTagsToAttach={setTagsToAttach}
            isCreateFromLibrary
            forCreateLink
          />
        </TagInputWrapper>
        <ButtonBlockWrapper data-parent={DATA_PARENTS.PlaylistBuilderLibrary}>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t('cancelUpT')}
            noMargins
            handleButtonClick={close}
            derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
          />
          <Button2023
            variant="secondary"
            width={67}
            height={32}
            text={t('createUpT')}
            noMargins
            derivedParentData={DATA_PARENTS.PlaylistBuilderLibrary}
            handleButtonClick={onCreateButtonClick}
          />
        </ButtonBlockWrapper>
      </ModalWindow>
    </ModalWrapper>
  );
};

export default CreateLinkModal;
