
interface Translations {
  [key: string]: {
    [key: string]: {
      [key: string]: string | { [lang: string]: string | { [lang: string]: string | { [lang: string]: string | { [lang: string]: string } } } };
    };
  };
}


export const SETTINGS_TRANSLATIONS: Translations = {
  header: {
    title: {
      Profile: {
        en: 'Profile',
      },
      Preferences: {
        en: 'Preferences',
      },
      Language: {
        en: 'Language',
      },
      Shortcuts: {
        en: 'Shortcuts',
      },
      Upgrade: {
        en: 'Upgrade',
      },
    },
    search: {
      Profile: {
        TooltipText: { en: 'Search in Settings' },
        Alt: { en: 'search' },
      },
      Preferences: {
        TooltipText: { en: 'Search in Preferences' },
        Alt: { en: 'search' },
      },
      Language: {
        TooltipText: { en: 'Search in Language' },
        Alt: { en: 'search' },
      },
      Shortcuts: {
        TooltipText: { en: 'Search in Shortcuts' },
        Alt: { en: 'search' },
      },
      Upgrade: {
        TooltipText: { en: 'Search in Upgrade' },
        Alt: { en: 'search' },
      },
    },
  },
  sideBar: {
    Title: { en: 'Settings' },
    Profile: { en: 'Profile' },
    Infos: { en: 'Info' },
    Activity: { en: 'Activity' },
    Preferences: { en: 'Preferences' },
    General: { en: 'General' },
    Language: { en: 'Language' },
    Emojis: { en: 'Emojis' },
    Notifications: { en: 'Notifications' },
    Workspace: { en: 'Workspace' },
    Organization: { en: 'Organization' },
    Security: { en: 'Security' },
    Upgrade: { en: 'Upgrade' },
    Application: { en: 'Application' },
    Appearance: { en: 'Appearance' },
    Shortcuts: { en: 'Shortcuts' },
    Channels: { en: 'Channels' },
    Logout: { en: 'Logout' },
  },
  upgrade: {
    labels: {
      Current: {
        en: 'Current plan',
      },
      Upgrade: {
        en: 'Upgrade',
      },
    },
    values: {
      plans: {
        Free: {
          title: { en: 'Free' },
          price: {
            eur: '0',
            gbp: '0',
            usd: '',
          },
        },
        Tier1: {
          title: { en: 'Pro' },
          price: {
            eur: '5,99',
            gbp: '5,05',
            usd: '6,10',
          },
        },
        Tier2: {
          title: { en: 'Pro Plus' },
          price: {
            eur: '9,99',
            gbp: '7,58',
            usd: '9,15',
          },
        },
      },
      features: {
        playlistsNumber: {
          Free: {
            name: { en: '10 playlists' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
          Tier1: {
            name: { en: 'Unlimited playlists' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
          Tier2: {
            name: { en: 'Unlimited playlists' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
        },
        availableSpace: {
          Free: {
            name: { en: '250mb upload PKL' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
          Tier1: {
            name: { en: '2gb upload PKL' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
          Tier2: {
            name: { en: '10gb upload PKL' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
        },
        featuresSet: {
          Free: {
            name: { en: 'Limited features' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
          Tier1: {
            name: { en: 'All features' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
          Tier2: {
            name: { en: 'All features' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
        },
        betaFeatures: {
          Tier1: {
            name: { en: 'Beta features' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
          Tier2: {
            name: { en: 'Beta features' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
        },
        supportLevel: {
          Tier2: {
            name: { en: 'Priority support' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
        },
        specialInvitations: {
          Tier2: {
            name: { en: 'Special invitations' },
            tooltipText: { en: 'This is just a sample tooltip for a feature' },
          },
        },
      },
    },
  },
  preferences: {
    labels: {
      Display: {
        en: 'Display',
      },
      Email: {
        en: 'Email',
      },
      LanguageAndRegion: {
        en: 'Language and Region',
      },
      Format: {
        en: 'Format',
      },
      Language: {
        en: 'Language',
      },
      TimeZone: {
        en: 'Time zone',
      },
      Date: {
        en: 'Date',
      },
      Hour: {
        en: 'Hour',
      },
      keepInformed: {
        en: 'Keep me informed about updates ',
      },
      newsletterSubscription: {
        en: 'Subscribe to the free monthly newsletter',
      },
      emailNotifications: {
        en: 'Send me an email when anything is assigned to me',
      },
      showUsername: {
        en: 'Show my username instead of full name',
      },
      displayImage: {
        en: 'Display letters instead of my profile picture',
      },
    },
    values: {
      displayImage: {
        AvatarImage: { en: 'Profile Picture' },
        GeneratedAvatar: { en: 'Letters' },
      },
    },
    menuOptions: {
      AvatarImage: { en: 'Profile Picture (default)' },
      GeneratedAvatar: { en: 'Letters' },
      RenameEmoji: { en: 'Rename' },
      RemoveEmoji: { en: 'Remove' },
    },
  },
  emoji: {
    labels: {
      Import: {
        en: 'Import',
      },
      Upload: {
        en: 'Upload',
      },
      EmojisList: {
        en: 'Custom emojis list',
      },
      importEmojisSlack: {
        en: 'Import your favorite emojis from Slack',
      },
      uploadCustomEmojis: {
        en: 'Upload your custom emojis (max 128*128px)',
      },
      enterName: {
        en: 'Then enter a name (just letters, no special characters)',
      },
      table: {
        Name: {
          en: 'Name',
        },
        Emoji: {
          en: 'Emoji',
        },
        Actions: {
          en: 'Actions',
        },
        NoEmojis: {
          en: 'No emojis',
        },
      },
    },
    buttons: {
      importEmoji: {
        en: 'Import',
      },
      uploadEmoji: {
        en: 'Upload',
      },
      saveName: {
        en: 'Save',
      },
      resetName: {
        en: 'Reset',
      },
    },
    inputs: {
      saveName: {
        placeholder: {
          en: 'Name',
        },
      },
    },
  },
  shortcuts: {
    labels: {
      table: {
        Name: {
          en: 'Name',
        },
        Type: {
          en: 'Type',
        },
        Shortcut: {
          en: 'Shortcuts',
        },
      },
      sections: {
        Editor: { en: 'Editor' },
        Workspace: { en: 'Workspace' },
      },
    },
    values: {
      MAKE_TEXT_UNDERLINED: {
        name: { en: 'Underline' },
        type: { en: 'Text' },
        message: { en: 'Code style applied' },
      },
      MAKE_TEXT_CODE_STYLE: {
        name: { en: 'Inline code' },
        type: { en: 'Text' },
        message: { en: 'Code style applied' },
      },
      MAKE_TEXT_STRIKETHROUGH: {
        name: { en: 'Strikethrough' },
        type: { en: 'Text' },
        message: { en: 'Strikethrough text' },
      },
      MAKE_TEXT_LEFT_ALIGNED: {
        name: { en: 'Align left' },
        type: { en: 'Text' },
        message: { en: 'Text aligned to the left' },
      },
      MAKE_TEXT_RIGHT_ALIGNED: {
        name: { en: 'Align right' },
        type: { en: 'Text' },
        message: { en: 'Text aligned to the right' },
      },
      MAKE_TEXT_CENTER_ALIGNED: {
        name: { en: 'Align center' },
        type: { en: 'Text' },
        message: { en: 'Text is center aligned' },
      },
      INDENT_TEXT: {
        name: { en: 'Text indent' },
        type: { en: 'Text' },
        message: { en: 'Indented' },
      },
      OUTDENT_TEXT: {
        name: { en: 'Text outdent' },
        type: { en: 'Text' },
        message: { en: 'Outdented' },
      },
      MORPH_INTO_UNNUMBERED_LIST: {
        name: { en: 'Bulleted list' },
        type: { en: 'Block' },
        message: { en: 'Changed to Bulleted list' },
      },
      CREATE_LINK_BLOCK: {
        name: { en: 'Create a link' },
        type: { en: 'Block' },
        message: { en: 'Link block added' },
      },
      SELECT_BLOCK_TYPE: {
        name: { en: 'Block type' },
        type: { en: 'Block' },
        message: { en: 'Select block type' },
      },
      ADD_LINE_SEPARATOR_BLOCK: {
        name: { en: 'Divider' },
        type: { en: 'Block' },
        message: { en: 'Line separator block added' },
      },
      ADD_QUOTE_BLOCK: {
        name: { en: 'Quote block' },
        type: { en: 'Block' },
        message: { en: 'Quote block added' },
      },
      MAKE_TEXT_BOLD: {
        name: { en: 'Bold' },
        type: { en: 'Text' },
        message: { en: 'Text made bold' },
      },
      MORPH_INTO_CHECKBOX_BLOCK: {
        name: { en: 'Checkbox' },
        type: { en: 'Text' },
        message: { en: 'Changed to Checkbox block' },
      },
      MAKE_TEXT_ITALIC: {
        name: { en: 'Italic' },
        type: { en: 'Text' },
        message: { en: 'Text made italic' },
      },
      MORPH_INTO_NUMBERED_LIST: {
        name: { en: 'Numbered list' },
        type: { en: 'Block' },
        message: { en: 'Changed to Numbered List' },
      },
      CREATE_NEW_PLAYLIST: {
        name: { en: 'New SmartFile' },
        type: { en: 'Create' },
        message: { en: 'New SmartFile Created' },
      },
      GO_TO_NEXT_PAGE: {
        name: { en: 'Next page' },
        type: { en: 'Go to' },
        message: { en: 'Going next page' },
      },
      GO_TO_PREVIOUS_PAGE: {
        name: { en: 'Previous page' },
        type: { en: 'Go to' },
        message: { en: 'Going previous page' },
      },
    },
  },
  profile: {
    avatarInput: {
      Label: { en: 'Profile picture' },
    },
    username: {
      Label: { en: 'Username' },
      Required: { en: 'This field is required' },
    },
    lastName: {
      Label: { en: 'Last Name' },
    },
    firstName: {
      Label: { en: 'Full Name' },
    },
    email: {
      Label: { en: 'Email' },
    },
    phone: {
      Label: { en: 'Contact phone' },
    },
    company: {
      Label: { en: 'Company' },
    },
    infoRole: {
      Label: { en: 'Role' },
    },
    bio: {
      Label: { en: 'Biography' },
    },
  },
  activity: {
    label: { InChannel: { en: 'List of your channels' } },
    table: {
      header: {
        Names: {
          en: 'Name',
        },
        Role: {
          en: 'Role',
        },
        Authors: {
          en: 'Author',
        },
        Creators: {
          en: 'Creator',
        },
        Actions: {
          en: 'Actions',
        },
      },
      menuOptions: {
        Leave: { en: 'Leave' },
        Delete: { en: 'Delete' },
      },
    },
  },
  forms: {
    errors: {
      infosError: { en: 'Failed to save profile details' },
      emojiFileUploadError: { en: 'Failed to upload emoji' },
    },
    success: {
      infosSuccess: { en: 'Your profile has been saved successfully' },
      emojiFileUploadSuccess: {
        en: 'Your emoji has been uploaded successfully',
      },
    },
  },
};
