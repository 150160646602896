import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import rawStyles from './index.module.scss';
import { calculateIndex, checkString } from '../../utils/helpers';
import TagsList from './TagsList';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import TagItem from './TagItem';
import { actionCheckTag, actionDeleteTag } from '../../redux/tags/action';
import HashTagsList from './HashTagsList';
import useThrottle from '../../utils/hooks/useThrottle';
import { actionFindHashTags } from '../../redux/support/action';
import { DATA_PARENTS, DEFAULT_POSITION_STEP, empty, emptyArr } from '../../utils/constants';
import { createRandomColor } from './utils';
import { actionDeleteHashtag } from '../../redux/hashtags/action';

const styles = classnames.bind(rawStyles);

const CreateComponentHashtagsInput = ({
  isEditable = true,
  showMessageNotEditable,
  isPage,
  type,
  state,
  noDescription,
  isExpanded,
  tagsInputRef,
  place,
  tagsToAttach = [],
  setTagsToAttach,
  isCreateFromLibrary,
  newLibraryComponentId,
  forCreateBlock,
  forCreateLink,
  ...props
}) => {
  const [throttleSearch] = useThrottle();
  const history = useHistory();
  const { editComponentId } = useSelector((reduxState) => reduxState.library) || empty;
  const [activeInput, setActiveInput] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [randomColor, setRandomColor] = useState('');
  const dataParent = `createLibrary ${DATA_PARENTS.PlaylistBuilderLibrary}`;
  const [refList, isListActive, setIsListActive] = useComponentVisible(
    false,
    'tagList',
  );
  const dispatch = useDispatch();
  const hashtagsInput = useRef(null);
  const allTags = useSelector((reduxState) => reduxState.user.tags) || emptyArr;
  const isPlaylists = history.location.pathname === '/playlists';
  const { components } = useSelector((reduxState) => reduxState.library);
  const playlists = useSelector((reduxState) => reduxState.playlists);

  const deleteHashtag = (e, title) => {
    if (editComponentId) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      dispatch(
        actionDeleteHashtag(
          { title },
          { id: editComponentId, lastModifiedDate },
          isPage,
        ),
      );
    }
    e.stopPropagation();
    setTagsToAttach((prev) => prev.filter((item) => item.title !== title));
  };
  const deleteTag = (e, tagId, index) => {
    if (editComponentId) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      dispatch(
        actionDeleteTag(
          { id: tagId },
          index,
          type,
          { id: editComponentId, lastModifiedDate },
          lastModifiedDate,
          isPage,
        ),
      );
    }
    e.stopPropagation();
    setTagsToAttach((prev) => prev.filter((item) => item.id !== tagId));
  };

  useEffect(() => {
    if (!isListActive) {
      setInputValue('');
      setActiveInput(false);
    }
  }, [isListActive]);

  useEffect(() => {
    if (!inputValue) {
      setRandomColor(createRandomColor());
    }
  }, [inputValue]);

  const saveHashtag = () => {
    // TBD
    setInputValue('');
  };
  const saveTag = (e, clickedItem) => {
    e.stopPropagation();
    e.preventDefault();

    if (tagsToAttach.length < 10 && checkString(inputValue)) {
      const tempTags = isPlaylists
        ? playlists[newLibraryComponentId]?.tags
        : components[newLibraryComponentId]?.tags;
      if (!clickedItem) {
        if (!inputValue.trim()) return;
        const nestedPosition = calculateIndex(
          tagsToAttach[tagsToAttach.length - 1]?.position,
        );
        const position = Math.max(
          ...Object.values(allTags || {}).map((tag) => tag.position || 0),
          0,
        ) + DEFAULT_POSITION_STEP;
        const newId = uuidv4();

        const newTag = {
          type: 'tags',
          id: newId,
          title: inputValue.trim(),
          position,
          nestedPosition,
          color: randomColor,
        };

        dispatch(
          actionCheckTag(
            newTag,
            editComponentId
              ? { id: newLibraryComponentId, tags: tempTags || [] }
              : null,
            type,
            isPage,
          ),
        );
        setTagsToAttach([...tagsToAttach, newTag]);
      } else if (
        !tagsToAttach.find((item) => item.title === clickedItem.title)
      ) {
        const nestedPosition = calculateIndex(
          tagsToAttach[tagsToAttach.length - 1]?.position,
        );
        setTagsToAttach([
          ...tagsToAttach,
          {
            ...clickedItem,
            nestedPosition,
          },
        ]);
        dispatch(
          actionCheckTag(
            {
              ...clickedItem,
              nestedPosition,
            },
            editComponentId
              ? { id: newLibraryComponentId, tags: tempTags || [] }
              : null,
            type,
            isPage,
          ),
        );
      }
      setInputValue('');
      setActiveInput(false);
      hashtagsInput.current.focus();
    } else hashtagsInput.current.focus();
    setInputValue('');
  };

  const searchHashTags = (value) => {
    dispatch(actionFindHashTags(value));
  };

  const handleInputChange = (e) => {
    if (!isListActive) setIsListActive(true);

    const inputVal = e.target.value.replace(/\s+/g, ' ');

    if (checkString(inputVal)) {
      setInputValue(inputVal);
      if (state.type === 'hashtags') throttleSearch(() => () => searchHashTags(inputVal));
    }
  };

  const handleInputKeyDown = (e) => {
    if (inputValue && !isListActive) {
      setIsListActive(true);
      setActiveInput(false);
    }
    if (e.key === 'Enter' || e.keyCode === 32) {
      if (
        tagsToAttach.length < 10
        && checkString(e.target.value)
        && !!inputValue.trim()
      ) {
        if (state.type === 'hashtags') {
          saveHashtag(e);
        } else {
          saveTag(e);
        }
      }
    }
    if (e.key === 'Escape') {
      e.currentTarget.blur();
      setInputValue('');
    }
    if (e.key === 'Backspace' && tagsToAttach?.length && !inputValue) {
      setInputValue('');
      if (state.type === 'hashtags') {
        deleteHashtag(e, tagsToAttach[tagsToAttach.length - 1]?.title);
      } else {
        deleteTag(e, tagsToAttach[tagsToAttach.length - 1]?.id);
      }
    }
    if (e.key === 'Backspace' && !tagsToAttach?.length && !inputValue) {
      e.target.blur();
      setIsListActive(false);
    }
  };

  return (
    <div
      className={styles('item_wrapper', 'createComponent', `${state.type}`, {
        isPlaylist: type === 'Playlist',
        isPage,
        isExpanded,
        noMargins: props.modalComponentId,
        isCreateFromLibrary,
      })}
      data-parent={dataParent}
    >
      <div>
        {!isCreateFromLibrary && (
          <div
            className={styles('item_title', 'hashtags', {
              active: activeInput,
              noDescription,
              isPlaylist: type === 'Playlist',
            })}
            data-parent={dataParent}
          >
            {state.title}
          </div>
        )}
        {state.description && !noDescription && (
          <div
            className={styles('item_description', 'hashtags', {
              active: activeInput,
            })}
            data-parent={dataParent}
          >
            {state.description}
          </div>
        )}
        <div
          data-parent={`${dataParent} tagList`}
          ref={refList}
          className={styles('item_container', 'hashtags', {
            active: activeInput,
            isExpanded,
            for_create_block: forCreateBlock,
            for_create_link: forCreateLink,
          })}
          onClick={() => {
            setActiveInput(true);
            setTimeout(() => {
              hashtagsInput.current.focus();
            }, 0);
          }}
        >
          <div
            data-parent={dataParent}
            onClick={() => {
              if (!isEditable) showMessageNotEditable();
            }}
          >
            <div
              className={styles({ not_editable: !isEditable })}
              style={isExpanded ? { width: '100%' } : {}}
            >
              <div
                className={styles('input_hashtags_wrapper', {
                  empty: !tagsToAttach.length,
                })}
                data-parent={dataParent}
              >
                <div
                  data-parent={dataParent}
                  className={styles('hashtags_container', { isExpanded })}
                >
                  {tagsToAttach
                    ?.sort((a, b) => a.position - b.position)
                    .map((item, index) => {
                      return (
                        <TagItem
                          key={item.title}
                          itemIndex={index}
                          {...item}
                          deleteCb={
                            state.type === 'hashtags'
                              ? deleteHashtag
                              : deleteTag
                          }
                          dataParent={dataParent}
                        />
                      );
                    })}
                  {state.type === 'tags' && (
                    <input
                      data-parent={dataParent}
                      autoComplete="off"
                      ref={hashtagsInput}
                      type="text"
                      value={inputValue}
                      readOnly={tagsToAttach.length > 9}
                      onChange={handleInputChange}
                      className={styles({
                        emptyInput: tagsToAttach.length > 9,
                        hidden:
                          !activeInput
                          && !isListActive
                          && !!tagsToAttach?.length,
                      })}
                      onKeyDown={handleInputKeyDown}
                      onBlur={() => {
                        setInputValue('');
                        setActiveInput(false);
                      }}
                      onFocus={() => {
                        setActiveInput(true);
                        setIsListActive(true);
                      }}
                      placeholder={
                        !tagsToAttach?.length || tagsToAttach?.length === 0
                          ? state.placeholder(place)
                          : ''
                      }
                    />
                  )}
                  {state.type !== 'tags' && (
                    <input
                      data-parent={dataParent}
                      autoComplete="off"
                      ref={hashtagsInput}
                      type="text"
                      value={inputValue}
                      readOnly={tagsToAttach.length > 9}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                      className={styles({
                        emptyInput: tagsToAttach.length > 9,
                        hidden:
                          !activeInput
                          && !isListActive
                          && !!tagsToAttach?.length,
                      })}
                      onFocus={() => {
                        setActiveInput(true);
                        setIsListActive(true);
                      }}
                      maxLength={64}
                      onBlur={() => {
                        setInputValue('');
                        // dispatch(actionUpdateHashTags([]));
                        setActiveInput(false);
                      }}
                      placeholder={
                        !tagsToAttach?.length || tagsToAttach?.length === 0
                          ? state.placeholder
                          : ''
                      }
                    />
                  )}
                </div>
              </div>
              {state.type === 'tags' && (
                <TagsList
                  state={state}
                  inputValue={inputValue}
                  isListActive={isListActive}
                  deleteCb={
                    state.type === 'hashtags' ? deleteHashtag : deleteTag
                  }
                  saveCb={state.type === 'hashtags' ? saveHashtag : saveTag}
                  activeInput={activeInput}
                  type={type}
                  randomColor={randomColor}
                  outerCloseAllMenus={props.outerCloseAllMenus}
                  dataParent={dataParent}
                  isCreateLibrary
                  forCreateLink={forCreateLink}
                />
              )}
              {state.type !== 'tags' && (
                <HashTagsList
                  state={state}
                  inputValue={inputValue}
                  activeInput={activeInput}
                  type={type}
                  saveHashtag={saveHashtag}
                  isListActive={isListActive}
                  setIsListActive={setIsListActive}
                  deleteCb={
                    state.type === 'hashtags' ? deleteHashtag : deleteTag
                  }
                  saveCb={state.type === 'hashtags' ? saveHashtag : saveTag}
                  dataParent={dataParent}
                  isCreateLibrary
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateComponentHashtagsInput;
