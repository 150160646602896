import { OutboxAction } from './types';
import downloadStatus from '../../utils/dataUtil';
import SupportAction from '../support/types';


const defaultState = {
  status: downloadStatus.init,
  cards: [],
  pages: {},
  outBoxCounter: 0,
  awaitedNotifications: {},
};

const outboxReducer = (state = defaultState, action) => {
  const { payload, type } = action;

  switch (type) {
    case OutboxAction.UploadOutboxCardSaga: {
      return { ...state, status: downloadStatus.pending };
    }

    case OutboxAction.Clear: {
      return { ...defaultState, outBoxCounter: state.outBoxCounter };
    }

    case OutboxAction.UploadOutboxCardRedux: {
      const { maxDateToShow, newCards, nextStep, hasItem } = payload;
      return { ...state, cards: newCards, hasItem, maxDateToShow, nextStep, status: downloadStatus.success };
    }

    case OutboxAction.UpdatePageInOutboxCardRedux: {
      const pages = { ...state.pages, ...payload };
      return { ...state, pages };
    }
    case OutboxAction.UpdateCounter: {
      return { ...state, outBoxCounter: payload.outBoxCounter };
    }

    case SupportAction.InitialDataFetch: {
      return { ...state, outBoxCounter: payload.outBoxCounter };
    }

    //
    // case OutboxAction.AddSingleWithoutUpdateOutboxCardRedux: {
    //   const { item } = payload;
    //   const index = state.cards.findIndex(i => i.id === item.id);
    //   if (index !== -1) {
    //     // no update
    //     return state;
    //   }
    //   const cards = [item, ...state.cards];
    //   return { ...state, cards };
    // }
    //
    // case OutboxAction.CardOutboxMarkUsReed: {
    //   return { ...state,
    //     cards: state.cards.map(i => {
    //       if (payload.item.id === i.id && i.type === payload.item.type) return { ...i, isRead: !i.isRead };
    //       return i;
    //     }),
    //   };
    // }
    //
    // case OutboxAction.CardsOutboxMarkAsReadOrUnread: {
    //   const { items, type } = payload;
    //   const itemIds = items.reduce((acc, cur) => {
    //     return { ...acc, [cur.id]: type };
    //   }, {});
    //   return {
    //     ...state,
    //     cards: state.cards.map(i => {
    //       if (itemIds[i.id]) {
    //         return { ...i, isRead: itemIds[i.id] !== 'unread' };
    //       }
    //       return i;
    //     }),
    //   };
    // }

    case OutboxAction.DeleteOutboxCard: {
      const cards = [...state.cards].filter(
        (i) => (i.id !== payload.item.id) || (i.type !== payload.item.type),
      );
      return { ...state, cards, outBoxCounter: --state.outBoxCounter };
    }


    case OutboxAction.AddNewAwaitedNotification: {
      const { notification } = payload;
      const playlistId = notification.itemId;
      const awaitedNotifications = { ...state.awaitedNotifications, [playlistId]: notification };
      return { ...state, awaitedNotifications };
    }

    case OutboxAction.ClearAwaitedNotification: {
      return { ...state, awaitedNotifications: defaultState.awaitedNotifications };
    }

    case OutboxAction.DeleteOutboxCards: {
      const { items } = payload;
      const cards = state.cards.filter((card) => !items.find(({ id }) => id === card.id));

      return { ...state, cards, outBoxCounter: state.outBoxCounter - items.length };
    }
    case OutboxAction.UpdateNotificationNotificationAfterUpdate: {
      const { idsForUpdateNotification } = payload;
      const now = Math.round(new Date().getTime() / 1000);

      const updatedCard = state.cards.map(card => {
        if (idsForUpdateNotification[card.id]) {
          return { ...card,
            date: now,
            isRead: false,
            isHasUpdate: card.isHasUpdate || card.isRead,
          };
        }
        return card;
      }).sort((a, b) => b.date - a.date);

      return { ...state, cards: updatedCard };
    }

    default:
      return state;
  }
};

export default outboxReducer;
