import React from 'react';
import classnames from 'classnames/bind';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import rawStyles from './index.module.scss';
import { DATA_PARENTS, empty } from '../../../utils/constants';
import { convertToSmartNumbers } from '../../../utils/helpers';
import { MainNavigate } from '../../../navigate/navigate';
import SelectorDropDown from '../../LowResolutionNavDropDown/LowResolutionNavDropDown';
import SecondaryMenu from '../../LibraryHeaderMenu/childComponents/SecondaryMenu';
import { Main, MainNavSliderShell, New, Text } from './styled';


const styles = classnames.bind(rawStyles);
const NewTolTip = ({ count }) => <New>{count}</New>;

const ContactNavSlider = ({ ...props }) => {
  const history = useHistory();
  const { place = 'smartfiles', selector = 'all' } = useParams();

  const isDescriptionOpened = useSelector(
    (state) => state.support.componentDescription,
  );
  // TODO
  // const { unseenPagesManagerMap, unseenPlaylistManagerMapInAll } = useSelector(
  //   (state) => state.support,
  // );
  const { selectorType: userToShowId } = useSelector((state) => state.content);
  const { contactNicknameMap } = useSelector((state) => state.support);


  const unseenCounter = (value) => {
    // TODO
    // contactNicknameMap[userToShowId].counter;
    // if (`/${place}/${value}` === '/pages/shared') return Object.keys(unseenPagesManagerMap).length;
    // if (`/${place}/${value}` === '/smartfiles/shared') return Object.keys(unseenPlaylistManagerMapInAll).length;
    return 0;
  };

  const setSieve = (value) => {
    if (value === 'all') {
      history.push(`/content/users_smartfiles/${selector}`);
    }
    if (value === 'favorite') {
      history.push(`/content/users_smartfiles_favorite/${selector}`);
    }
  };

  const convert = (value) => {
    if (value === 'all') {
      return convertToSmartNumbers(contactNicknameMap[userToShowId]?.counter || 0);
    }
    if (value === 'favorite') {
      return convertToSmartNumbers(contactNicknameMap[userToShowId]?.counterFavorite || 0);
    }
  };


  const calculateDisplayedSelector = (itemType) => {
    return itemType.toUpperCase();
  };


  return (
    <MainNavSliderShell isSmartFiles>
      <Main>

        <div className={styles('nav_bar', { isDescriptionOpened })}>
          <div className={styles('isNavVisible', { isDescriptionOpened })}>
            <div
              data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
              className={styles('nav_item', { first: true })}
              onClick={() => {
                setSieve('all');
              }}
            >
              {!!unseenCounter('all') && (
                <NewTolTip count={unseenCounter('all')} />
              )}
              <Text active={place === 'users_smartfiles'}>
                {calculateDisplayedSelector('all')}
                <div
                  className={styles('users_count', {
                    active: place === 'users_smartfiles',
                  })}
                >
                  <div
                    className={styles('users_count', {
                      active: place === 'users_smartfiles',
                    })}
                  >
                    {convert('all') || '??'}
                  </div>
                </div>
              </Text>
            </div>
            <div
              data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
              className={styles('nav_item')}
              onClick={() => {
                setSieve('favorite');
              }}
            >
              {!!unseenCounter('itemType') && (
                <NewTolTip count={unseenCounter('itemType')} />
              )}
              <Text active={place === 'users_smartfiles_favorite'}>
                {calculateDisplayedSelector('favorite')}

                <div
                  className={styles('users_count', { active: place === 'users_smartfiles_favorite' })}
                >
                  {convert('favorite') || '??'}
                </div>
              </Text>
            </div>
          </div>

          <SelectorDropDown
            dataP={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
            setSelector={setSieve}
            selector={place === 'users_smartfiles' ? 'all' : 'favorite'}
            itemsList={{ selectorsOrder: ['all', 'favorite'] }}
            calcCount={(itemType) => convert(itemType)}
            className={styles('isDropdownVisible')}
          />
        </div>

      </Main>

      <SecondaryMenu
        dataParent={`${DATA_PARENTS.PlaylistBuilderLibrary} ${DATA_PARENTS.HeaderInput}`}
        sortType={props.sortType}
        setSort={props.setSort}
        place={place}
        setShowNewSmartView={props.setShowNewSmartView}
        resetSmartview={props.resetSmartview}
        smartViewForEdit={props.smartViewForEdit}
        setSmartViewForEdit={props.setSmartViewForEdit}
      />

    </MainNavSliderShell>
  );
};

export default ContactNavSlider;
