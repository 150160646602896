import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DATA_PARENTS, svgDynamicCollection } from '../../../utils/constants';
import { ReactComponent as ThreeDotsIcon } from '../../../images/2023/svg/upv/three_dots_22_6.svg';
import { MainWrapperSmartview, SVGWrapperTagsAndSmartviews } from './styled';

const ListItemSmartView = ({
  smartCollection,
  setPosition,
  canDrag,
  isExOpened,
  setDragItem,
  newUPVMode,
  ...props
}) => {
  const threeDotsRef = useRef(null);
  const item = useSelector(
    (state) => state.dynamicCollection[smartCollection.id],
  );
  // const dispatch = useDispatch();
  // const [isDrag, setIsDrag] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const setPositionEx = (e) => {
    e.stopPropagation();
    setIsActive(!isActive);
    if (threeDotsRef?.current) {
      setPosition(
        threeDotsRef.current.getBoundingClientRect(),
        smartCollection,
      );
    }
  };
  useEffect(() => {
    if (!isExOpened) {
      setIsActive(false);
    }
  }, [isExOpened]);

  // TODO D&D
  // const dragPageStart = (item) => (e) => {
  //   setIsDrag(true);
  //   e.stopPropagation();
  //   const id = document.getElementById(item.id);
  //   const div2 = id.cloneNode('deep');
  //   const div = document.createElement('div');
  //   div.className = dragType.smart_view_list;
  //   div.appendChild(div2);
  //   div.id = dragType.smart_view_list;
  //   document.body.appendChild(div);
  //   setDragItem(item);
  //   setTimeout(() => {
  //     dispatch(actionDragPage(item.id, null, true));
  //   }, 0);
  //   const rect = id.getBoundingClientRect();
  //   e.dataTransfer.setDragImage(div, e.clientX - rect.left, e.clientY - rect.top);
  // };
  //
  // const dragPageEnd = () => {
  //   setIsDrag(false);
  //   const div = document.getElementById(dragType.smart_view_list);
  //   if (div) document.body.removeChild(div);
  //   dispatch(actionDragPage(null, null));
  // };

  return (
    <MainWrapperSmartview
      onClick={(e) => props.handleClick(e, item)}
      isActive={isActive}
      data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary}`}
    >
      <SVGWrapperTagsAndSmartviews
        data-place={item.id}
        color={smartCollection.color}
      >
        {svgDynamicCollection[item.iconKey]
          && svgDynamicCollection[item.iconKey](DATA_PARENTS.smartview)}
      </SVGWrapperTagsAndSmartviews>
      <SVGWrapperTagsAndSmartviews
        data-parent={DATA_PARENTS.PlaylistBuilderLibrary}
      >
        {' '}
        {item.name || 'Unnamed Smart View'}
      </SVGWrapperTagsAndSmartviews>
      {props.isLibrary && !newUPVMode && (
        <SVGWrapperTagsAndSmartviews
          className="to_be_hide"
          isSecond
          onClick={setPositionEx}
          isActive={isActive}
          ref={threeDotsRef}
        >
          <ThreeDotsIcon />
        </SVGWrapperTagsAndSmartviews>
      )}
    </MainWrapperSmartview>
  );
};

export default ListItemSmartView;
