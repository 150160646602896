import { useCallback } from 'react';
import { $getSelection } from 'lexical';
import { $patchStyleText } from '@lexical/selection';
import * as React from 'react';
import DropDown, { DropDownItem } from '../ui/DropDown';

const FONT_FAMILY_OPTIONS_SENT_SERIF = [
  ['"Arial", sans-serif', 'Arial'],
  ['"Inter", sans-serif', 'Inter'],
  ['"Roboto", sans-serif', 'Roboto'],
  ['"Poppins", sans-serif', 'Poppins'],
  ['"Lato", sans-serif', 'Lato'],
  ['"Montserrat", sans-serif', 'Montserrat'],
  ['"Fira Sans", sans-serif', 'Fira Sans'],
];

const FONT_FAMILY_OPTIONS_SERIF = [
  ['"Times New Roman", serif', 'Times New Roman'],
  ['"EB Garamond", serif', 'EB Garamond'],
  ['"Georgia", serif', 'Georgia'],
  ['"Eczar", serif', 'Eczar'],
  ['"Courier New", serif', 'Courier New'],
  ['"Courier Prime", serif', 'Courier Prime'],
  ['"Cormorant", serif', 'Cormorant'],
];

const FONT_SIZE_OPTIONS = [
  ['12px', 'Smaller'],
  ['14px', 'Small'],
  ['16px', 'Medium'],
  ['18px', 'Large'],
  ['20px', 'Extra Large'],
];
export function FontDropDown({
  editor,
  value,
  styles,
  annotation,
  annotationTwo,
  disabled = false,
}) {
  const handleClick = useCallback(
    (option) => {
      editor.update(() => {
        const selection = $getSelection();
        if (selection !== null) {
          $patchStyleText(selection, {
            [styles]: `${option}`,
          });
        }
      });
    },
    [editor, styles],
  );

  const buttonAriaLabel = styles === 'font-family'
    ? 'Formatting options for font family'
    : 'Formatting options for font size';
  const options = (styles === 'font-family' ? FONT_FAMILY_OPTIONS_SENT_SERIF : FONT_SIZE_OPTIONS);
  const optionsTwo = styles === 'font-family' ? FONT_FAMILY_OPTIONS_SERIF : [];
  const activeOption = options.find(option => option[0] === value || option[1] === value)
    || optionsTwo.find(option => option[0] === value) || [];

  return (
    <DropDown
      disabled={disabled}
      editor={editor}
      buttonClassName={`toolbar-item ${styles} `}
      buttonAriaLabel={buttonAriaLabel}
      buttonLabel={activeOption[1] || '    '}
    >
      {annotation && <div className="annotation">{annotation}</div>}
      {options.map(
        ([option, text]) => (
          <DropDownItem
            className={`item ${styles === 'font-size' ? 'fontsize-item' : ''}`}
            onClick={() => handleClick(option)}
            key={option}
          >
            <span style={{ [styles]: option }} className="text">{text}</span>
          </DropDownItem>
        ),
      )}
      {styles === 'font-family' && <div className="annotation">{annotationTwo}</div>}
      {styles === 'font-family' && optionsTwo.map(
        ([option, text]) => (
          <DropDownItem
            className={`item ${styles === 'font-size' ? 'fontsize-item' : ''}`}
            onClick={() => handleClick(option)}
            key={option}
          >
            <span style={{ [styles]: option }} className="text">{text}</span>
          </DropDownItem>
        ),
      )}
    </DropDown>
  );
}
