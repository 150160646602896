import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckBoxOffSvg } from '../../images/icons/checkbox_off.svg';
import { ReactComponent as CheckBoxOnSvg } from '../../images/icons/checkbox_on.svg';
import styles from './index.scss';
import { actionSelectItemInSmartfile } from '../../redux/currentPage/action';
import { emptyCallback } from '../../utils/constants';
import Tooltip from '../../shared/Tooltips/Tooltip';

const cn = classNames.bind(styles);

const CheckBoxLinkPageLayout = React.memo(function IndexSectionLayout({
  selectItem,
  isSelected,
  isDisabled,
  handleShowPannel,
}) {
  const { t } = useTranslation();
  const handleSelectItem = (e) => {
    e.stopPropagation();
    selectItem(e);
    handleShowPannel(e);
  };
  return (
    <Tooltip text={t('selectT')} direction="down">
      <div className={cn('checkbox_shell checkbox_svg', { disabled: isDisabled, is_active: isSelected })}>
        <div className={cn('svg_wrapper checkbox_svg')} onClick={isDisabled ? emptyCallback : handleSelectItem}>
          {isSelected ? (
            <CheckBoxOnSvg className={cn('checkbox_svg')} />
          ) : <CheckBoxOffSvg className={cn('checkbox_svg')} />}
        </div>
      </div>
    </Tooltip>
  );
});

const CheckBoxLinkPage = ({
  itemId, isDisabled, handleShowPannel, isSelected,
}) => {
  const dispatch = useDispatch();

  const selectItem = useCallback((e) => {
    e.stopPropagation();
    dispatch(actionSelectItemInSmartfile(itemId));
  }, [dispatch, itemId]);

  return (
    <CheckBoxLinkPageLayout
      isSelected={isSelected}
      selectItem={selectItem}
      isDisabled={isDisabled}
      handleShowPannel={handleShowPannel}
    />
  );
};


export default CheckBoxLinkPage;
