import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LogoSvg } from '../../images/2023/svg/Logo.svg';
import styles from '../loginLogout/Login.module.scss';
import { LoginBackGround } from '../loginLogout/Login';
import { getReadableTimeLeft } from '../../utils/dateConvert';
import {
  Container,
  ForgotWrapper,
  Logo,
  SignInReturn,
  SubTitle,
  Title,
} from '../loginLogout/ForgotPassword/ForgotView';
import { DEFAULT_PAGE_PATH } from '../../utils/constants';
import { actionSyntheticGoBack } from '../../redux/history/actions';

const DURATION_UPDATE_INTERVAL = 1000;
const NoAvailable = ({ shareState = {}, getPageData, type = 'Page' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { somePayload: lastStep } = useSelector((state) => state.historyTrace);
  const nowSec = Math.floor(Date.now() / 1000);
  const { availableTo, isAvailableRange, availableFrom } = shareState;
  const notYet = availableFrom > nowSec;

  const { id: userId } = useSelector((state) => state.user);

  const [timer, setTimer] = useState(1);
  const timerId = useRef(null);
  const [readableTimeLeft, setReadableTimeLeft] = useState(
    getReadableTimeLeft(availableFrom),
  );

  const clearTimer = () => {
    if (!availableFrom) return;
    clearInterval(timerId.current);
  };

  const goToSignIn = () => {
    history.push('/login');
  };

  const goHome = () => {
    history.push(DEFAULT_PAGE_PATH);
  };

  const goBack = () => {
    dispatch(actionSyntheticGoBack());
    // const stackTrace = history?.location?.state?.traceStack || [];
    // const localStack = [...stackTrace];
    // const { departureUrl } = localStack.pop() || {};
    //
    // if (departureUrl) history.push(departureUrl, { traceStack: localStack });
    // else if (type === 'page') history.push('content/pages/all');
    // else history.push(DEFAULT_PAGE_PATH);
  };
  useEffect(() => {
    if (
      availableTo > nowSec
      && ((isAvailableRange && availableFrom < nowSec) || !isAvailableRange)
    ) {
      getPageData();
      return;
    }

    if (
      availableFrom
      && getReadableTimeLeft(availableFrom) !== readableTimeLeft
    ) {
      setReadableTimeLeft(getReadableTimeLeft(availableFrom));
    }
  }, [availableFrom, timer]);

  useEffect(() => {
    if (
      history.location.pathname.startsWith('/shared_page')
      && !!availableFrom
    ) {
      timerId.current = setInterval(() => {
        setTimer((time) => time + 1);
      }, DURATION_UPDATE_INTERVAL);
    }
    return () => {
      clearTimer();
    };
  }, []);

  const calculateBackButtonText = () => {
    const { step } = lastStep || {};
    if (lastStep.departureUrl?.startsWith('/content/pages/shared')) return 'Return to Pages/Shared to me';
    if (lastStep.departureUrl?.startsWith('/content/pages/')) return 'Return to Pages';
    if (lastStep.departureUrl?.startsWith('/content/library/')) return 'Return to Library';
    // case with return in default path
    if (type === 'page') return 'Return to SmartFile';

    if (step?.from === '/views') return 'Return to Views';
    if (type !== 'page') return 'Return to SmartFile';
    return 'TBD';
  };

  if (notYet) {
    return (
      <>
        <LoginBackGround />
        <div className={styles.scroll_wrapper}>
          <div className={styles.scroll_balancer}>
            <ForgotWrapper>
              <Container>
                <Logo onClick={goHome}>
                  <LogoSvg />
                </Logo>
                <Title>
                  {type} will be available in {readableTimeLeft}
                </Title>
                <SubTitle>
                  Please contact your Quidzi mate to share it again with
                  different settings.
                </SubTitle>
                {!userId && (
                  <SignInReturn onClick={goToSignIn}>
                    Return to Sign In
                  </SignInReturn>
                )}
                {userId && (
                  <SignInReturn onClick={goBack}>
                    {calculateBackButtonText()}
                  </SignInReturn>
                )}
              </Container>
            </ForgotWrapper>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <LoginBackGround />
      <div className={styles.scroll_wrapper}>
        <div className={styles.scroll_balancer}>
          <ForgotWrapper>
            <Container>
              <Logo onClick={goHome}>
                <LogoSvg />
              </Logo>
              <Title>{type} is no longer available</Title>
              <SubTitle>
                Please contact your Quidzi mate to share it again
              </SubTitle>
              {!userId && (
                <SignInReturn onClick={goToSignIn}>
                  Return to Sign In
                </SignInReturn>
              )}
              {userId && (
                <SignInReturn onClick={goBack}>
                  {calculateBackButtonText()}
                </SignInReturn>
              )}
            </Container>
          </ForgotWrapper>
        </div>
      </div>
    </>
  );
};

export default NoAvailable;
