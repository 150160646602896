import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Category, Channel } from '../../utils/dataConst';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { ReactComponent as DropDownSvg } from '../../images/icons/drop-down.svg';
import searchIcon from '../../images/icons/search_folder_16.svg';
import { actionSaveInputPlaylist } from '../../redux/currentPage/action';
import styles from './index.module.scss';
import { ReactComponent as AlertSvg } from '../../images/icons/alert.svg';
import { ReactComponent as CloseSvg } from '../../images/icons/clear_search.svg';
import {
  convertTimeMinutesToHours,
  convertToAmericanFormatPrice,
} from '../../utils/dateConvert';
import { actionUpdateLibraryPageInLibraryField } from '../../redux/library/actions';
import { sanitizeToLoad } from '../../utils/helpers';
import { emptyCallback } from '../../utils/constants';

const cx = classNames.bind(styles);

export const Inputs = {
  chName: {
    type: 'name',
    description: 'Name',
    placeholder: 'New Channel',
    maxLength: 35,
  },
  chDescription: {
    type: 'description',
    description: 'Description',
    placeholder: 'Write few words about your channel',
    maxLength: 1000,
  },
  duration: {
    type: 'duration',
    description: 'Estimated Duration (min)',
    placeholder: 'None',
  },
  category: {
    type: 'category',
    description: 'Category',
    required: 'Category',
    list: Category,
    search: true,
    settingsBar: true,
    placeholder: 'Select category',
  },
  reference: {
    type: 'reference',
    description: 'Reference',
    settingsBar: true,
    placeholder: 'None',
  },
  channel: {
    type: 'channel',
    description: 'Channels',
    note: 'Select Channel(s) where your SmartFile will be Shared',
    list: Channel,
    settingsBar: true,
    search: true,
    required: true,
    placeholder: 'Select channels',
  },
  channel2023: {
    type: 'channel',
    description: 'In channel',
    list: Channel,
    settingsBar: false,
    search: true,
    required: false,
    placeholder: 'Select a channel',
  },
};

const DropDown = ({
  forSelect,
  type,
  disabled,
  save,
  value,
  isSettings,
  isNeedToWarning,
  placeholder,
  isChannel,
}) => {
  const dataParent = 'dropDown';
  const [refMenu, isMenuVisible, setIsMenuVisible] = useComponentVisible(
    false,
    dataParent,
  );
  const { t } = useTranslation();
  const onClickHandler = (i) => {
    save(i);
    setIsMenuVisible(false);
  };

  const [dropDownList, setDropDownList] = useState(
    Object.keys(Inputs[type].list),
  );
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setDropDownList(
      Object.keys(Inputs[type].list).filter((i) => Inputs[type].list[i].toLowerCase().includes(filter.toLowerCase()),
      ),
    );
  }, [filter]);

  return (
    <div className={cx('dropdown_field')}>
      <div
        ref={refMenu}
        onClick={disabled ? emptyCallback : () => setIsMenuVisible((i) => !i)}
        className={cx('input', 'no_edit', {
          settings: isSettings,
          isNeedToWarning,
          placeholder: !value,
          channel: type === 'channel',
          category: type === 'category',
          channels: isChannel,
          forselect: forSelect,
          disabled,
        })}
      >
        {!!value && value}
        {!value && placeholder}
        <DropDownSvg className={cx('no_edit_svg')} />
      </div>
      {isMenuVisible && (
        <div className={cx('dropdown_wrapper')}>
          <div className={cx('dropdown')}>
            {!!Inputs[type].search && (
              <div className={cx('dropdown_search')}>
                <div className={cx('search_icon_input')}>
                  <img
                    src={searchIcon}
                    alt="search"
                    className={cx('dropdown_search_icon')}
                  />
                  <input
                    autoFocus
                    value={filter}
                    placeholder={t('searchPlaceholderT')}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </div>

                <div
                  className={cx('dropdown_close_icon')}
                  onClick={() => {
                    setFilter('');
                  }}
                >
                  {filter && <CloseSvg />}
                </div>
              </div>
            )}
            {!Inputs[type].search && (
              <input autoFocus className={cx('invisible')} />
            )}
            <div className={cx('dropdown_result')}>
              {!!dropDownList.length
                && dropDownList.map((i) => (
                  <div
                    key={i}
                    className={cx('dropdown_row')}
                    data-parent={dataParent}
                    onClick={() => onClickHandler(i)}
                  >
                    {Inputs[type].list[i]}
                  </div>
                ))}
            </div>
            {!dropDownList.length && (
              <div className={cx('dropdown_row')} data-parent={dataParent}>
                {t('noSearchResultsT')}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const PlaylistInput = ({
  forSelect,
  state,
  disabled,
  isEditable = true,
  isPage,
  showMessageNotEditable,
  isChannel,
  ...props
}) => {
  const { t } = useTranslation();
  const downloadValue = useSelector((reduxState) => (isChannel
    ? reduxState.currentPage?.id
        && reduxState.channels.myChannels[reduxState.currentPage?.id]
        && reduxState.channels.myChannels[reduxState.currentPage?.id][state.type]
    : reduxState.currentPage?.inputs
        && reduxState.currentPage?.inputs[state.type]),
  );
  const { anchorId } = useSelector((reduxState) => reduxState.support);
  const { durationTime, id } = useSelector(
    (reduxState) => reduxState.currentPage,
  );
  // handle values from db and initial state
  const rawDataSelector = (dbValue, type, isNum) => {
    if (dbValue === null ?? dbValue === undefined) {
      return '';
    }
    if (type === 'price' || isNum) {
      if (dbValue % 1 === 0) {
        return `${dbValue}`;
      }
      return dbValue;
    }
    if (!isNum) {
      return sanitizeToLoad(dbValue);
    }
  };
  // select value to display (duration input)
  const valueSelector = (rawValue, type) => {
    if (type === 'price' && rawValue !== '') {
      return convertToAmericanFormatPrice(rawValue);
    }
    if (type === 'price') {
      return '0';
    }
    if (rawValue === '') return '';
    return rawValue;
  };
  // select value to forward into input value attribute
  const displaySelector = (rawValue, viewValue, isInputActive, type) => {
    if (isInputActive) {
      return rawValue;
    }
    if ((type === 'price' || type === 'duration') && rawValue === '') {
      return '';
    }
    if (type === 'price') {
      return viewValue;
    }
    if (rawValue === '') {
      return rawValue;
    }
    if (!isInputActive && type === 'duration') return convertTimeMinutesToHours(durationTime);
    return viewValue;
  };
  const tryPublish = useSelector(
    (reduxState) => reduxState.currentPage.tryPublish,
  );
  const [rawValue, setRawValue] = useState(
    rawDataSelector(downloadValue, state.type, state.num),
  );
  const [viewValue, setViewValue] = useState(
    valueSelector(rawValue, state.type),
  );
  const [isInputActive, setIsInputActive] = useState(false);
  const dispatch = useDispatch();
  const [isNeedToWarning, setIsNeedToWarning] = useState(
    tryPublish && (!rawValue || rawValue === 0) && state.required,
  );

  useEffect(() => {
    setRawValue(rawDataSelector(downloadValue, state.type, state.num));
  }, [downloadValue]);

  useEffect(() => {
    setViewValue(valueSelector(rawValue, state.type));
  }, [rawValue]);

  useEffect(() => {
    setIsNeedToWarning(
      tryPublish && !rawValue && rawValue !== 0 && state.required,
    );
  }, [tryPublish, rawValue]);

  useEffect(() => {
    if (anchorId === 'playlistTitleInput') {
      setIsNeedToWarning(!rawValue && rawValue !== 0 && state.required);
    }
  }, [anchorId]);

  const getPlace = () => {
    if (isPage) return 'page';
    if (isChannel) return 'channels';
    return '';
  };

  const save = (i, isListItem) => {
    if (state.disabled) return;
    if (isListItem) {
      setRawValue(state.list[i]);
      if (!isPage) {
        dispatch(
          actionSaveInputPlaylist(
            state.type,
            state.list[i],
            isChannel ? 'channels' : '',
            null,
            props.itemId,
          ),
        );
      } else {
        dispatch(
          actionUpdateLibraryPageInLibraryField({
            id,
            value: state.list[i],
            field: state.type,
          }),
        );
      }
    } else if (state.num) {
      dispatch(
        actionSaveInputPlaylist(
          state.type,
          parseFloat(rawValue),
          isChannel ? 'channels' : '',
          null,
          props.itemId,
        ),
      );
    } else if (state.type !== 'duration') {
      dispatch(
        actionSaveInputPlaylist(
          state.type,
          rawValue,
          getPlace(),
          null,
          props.itemId,
        ),
      );
    } else {
      dispatch(
        actionSaveInputPlaylist(
          state.type,
          rawValue,
          isChannel ? 'channels' : '',
          null,
          props.itemId,
        ),
      );
    }
  };

  const onNumberInputChange = (inputValue, e) => {
    const check = /^[\d\.\,]*$/gim.test(inputValue);
    const lastSymbol = e.target.value[e.target.value.length - 1];
    inputValue = inputValue.replace(/^0+/, '');
    if (!check || lastSymbol === ',') {
      e.preventDefault();
      return;
    }

    if (inputValue.length < 10) {
      setViewValue(convertToAmericanFormatPrice(inputValue));
      setRawValue(inputValue.replaceAll(',', ''));
    } else {
      setRawValue(rawValue);
    }
  };

  const onTextInputChange = (e) => {
    if (state.disabled) return;
    setRawValue(e.target.value);
  };

  const inputKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  if (state.type === 'description') {
    return (
      <div className={cx('input_wrapper', { sideBarWidth: state.settingsBar })}>
        <div
          className={cx('input_group_description', {
            required: state.required,
            settings: state.settingsBar,
            channels: isChannel,
          })}
        >
          {state.description}
        </div>
        <div
          onClick={() => {
            if (!isEditable) showMessageNotEditable();
          }}
        >
          <div className={cx({ not_editable: !isEditable })}>
            {!state.list && !state.num && (
              <textarea
                maxLength={state.maxLength || 100}
                onChange={onTextInputChange}
                readOnly={state.type === 'duration'}
                onFocus={() => {
                  setIsInputActive(true);
                }}
                disabled={disabled}
                onBlur={() => {
                  save();
                  setViewValue(rawValue);
                  setIsInputActive(false);
                }}
                value={displaySelector(
                  rawValue,
                  viewValue,
                  isInputActive,
                  state.type,
                )}
                onKeyDown={inputKeyDownHandler}
                placeholder={state.placeholder}
                className={cx('input', {
                  isNeedToWarning,
                  duration: state.type === 'duration',
                  settings: state.settingsBar,
                  references: state.type === 'reference',
                  chDescription: state.type === 'description',
                  category: state.type === 'category',
                })}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx('input_wrapper', { sideBarWidth: state.settingsBar })}>
      <div
        className={cx('input_group_description', {
          settings: state.settingsBar,
          channels: isChannel,
        })}
      >
        {state.description}
      </div>
      <div
        onClick={() => {
          if (!isEditable) showMessageNotEditable();
        }}
      >
        <div className={cx({ not_editable: !isEditable })}>
          {!state.list && !state.num && (
            <input
              maxLength={state.maxLength || 100}
              onChange={onTextInputChange}
              readOnly={state.type === 'duration'}
              onFocus={() => {
                if (state.type === 'duration') return;
                setIsInputActive(true);
              }}
              onBlur={() => {
                if (state.type === 'duration') return;
                save();
                setViewValue(rawValue);
                setIsInputActive(false);
              }}
              disabled={disabled}
              value={displaySelector(
                rawValue,
                viewValue,
                isInputActive,
                state.type,
              )}
              onKeyDown={inputKeyDownHandler}
              placeholder={state.placeholder}
              className={cx('input', {
                isNeedToWarning,
                duration: state.type === 'duration',
                settings: state.settingsBar,
                references: state.type === 'reference',
                category: state.type === 'category',
                channels: isChannel,
                disabled,
              })}
            />
          )}
          {!state.list && state.num && (
            <>
              <input
                onKeyDown={inputKeyDownHandler}
                onChange={(e) => onNumberInputChange(e.target.value, e)}
                onBlur={() => {
                  save();
                  if (rawValue === '' && state.type === 'price') {
                    setRawValue(0);
                    setViewValue('0');
                  }
                }}
                onFocus={() => {
                  // if (state.type === 'price' && rawValue === '') {
                  //   setRawValue(0);
                  //   setViewValue('0');
                  // }
                }}
                value={displaySelector(
                  rawValue,
                  viewValue,
                  isInputActive,
                  state.type,
                )}
                placeholder={state.placeholder}
                className={cx('input', {
                  isNeedToWarning,
                  settings: state.settingsBar,
                  channels: isChannel,
                  category: state.type === 'category',
                })}
              />
            </>
          )}
          {!!state.list && (
            <DropDown
              type={state.type}
              isNeedToWarning={isNeedToWarning}
              isSettings={state.settingsBar}
              value={rawValue}
              setValue={setRawValue}
              save={(i) => save(i, true)}
              placeholder={state.placeholder}
              isChannel={isChannel}
              forSelect={forSelect}
              disabled={disabled}
            />
          )}
          {isNeedToWarning && (
            <div className={cx('warning_wrapper')}>
              <div className={cx('warning')}>
                {' '}
                <AlertSvg />
                {state.required} {t('isaRequiredFieldT')}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaylistInput;
