import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Column,
  Label,
  Line,
  SectionShell,
  Text,
  HeaderTitle,
} from '../../CommonComponents/Layout';
import AvailablePlans from './AvailablePlans';

const WorkspacePlans = () => {
  const { currentPlan } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <SectionShell paddingForSetting>
      <HeaderTitle marginBottom32>{t('upgradeT')}</HeaderTitle>
      <Label isWorkplans>{t('currentPlanT')}</Label>

      <Line>
        <Column Upgrade>
          <Text>{t(`plan${currentPlan}TitleT`)}</Text>
        </Column>
      </Line>

      <AvailablePlans />
    </SectionShell>
  );
};

export default WorkspacePlans;
