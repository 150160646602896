import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { actionCloseModal } from '../../redux/user/action';
import SimpleTextInput from '../SimpleComponents/Inputs/SimpleTextInput';
import { empty, ValidationMessages } from '../../utils/constants';
import SimpleEmailInput from '../SimpleComponents/Inputs/SimpleEmailnput';
import {
  actionCreateContact,
  actionEditContact,
  actionLoadUserContactsS,
} from '../../redux/contacts/action';
import SimpleNumberInput from '../SimpleComponents/Inputs/SimpleNumberInput';
import Button2023 from '../Buttons/Button2023';
import styles from './index.module.scss';

const ModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
`;

const GreyTitleWrapper = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #D3C8C8;
  letter-spacing: 0.05em;
  margin-bottom: 13px;
  margin-top: 0;

  text-transform: uppercase;
  font-family: "RobotoFlex", sans-serif;
`;

const ContactModalWrapper = styled.div`
  min-height: 518px;
  width: 380px;
  border-radius: 12px;
  border: 0.6px solid #ece2e2;
  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px 14px 16px 20px;
`;

const ButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 186px;
  padding-top: 6px;
`;

const EditContactModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataPayload } = useSelector((state) => state.user);
  const items = useSelector((state) => state.support.contactNicknameMap);
  const item = items[dataPayload?.memberId]
    ?? (Object.values(items).find(elem => elem.contactId === dataPayload?.memberId) || empty);
  const editMode = !!item.id;
  const [inputEmail, setInputEmail] = useState(item.email);
  const [inputFirstName, setInputFirstName] = useState(item.first_name || item?.user?.first_name || '');
  const [inputLastName, setInputLastName] = useState(item.last_name || item?.user?.last_name || '');
  const [inputPhone, setInputPhone] = useState(item.phone || '');
  const [inputRole, setInputRole] = useState(item.userFunction || '');
  const [inputOrganization, setInputOrganization] = useState(item.organization || '');

  const [firstNameValidationError, setFirstNameValidationError] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const [lastNameValidationError, setLastNameValidationError] = useState('');
  const [functionValidationError, setFunctionValidationError] = useState('');
  const [phoneValidationError, setPhoneValidationError] = useState('');
  const [organizationValidationError, setOrganizationValidationError] = useState('');


  const hasAnyInvalidFields = emailValidationError
    || firstNameValidationError
    || lastNameValidationError
    || functionValidationError
    || phoneValidationError
    || organizationValidationError;

  const isContactExists = !!dataPayload?.memberId;

  const close = () => dispatch(actionCloseModal());

  const save = () => {
    if (hasAnyInvalidFields) return;
    const isHasAllRequiredField = inputFirstName && inputEmail && inputLastName;
    if (!isHasAllRequiredField) {
      !inputFirstName
        && setFirstNameValidationError(ValidationMessages.required);
      !inputEmail && setEmailValidationError(ValidationMessages.required);
      !inputLastName && setLastNameValidationError(ValidationMessages.required);
      return;
    }

    const itemToSave = {
      ...item,
      id: item.id,
      first_name: inputFirstName,
      email: inputEmail,
      last_name: inputLastName,
      organization: inputOrganization,
      phone: inputPhone,
      userFunction: inputRole,
    };
    if (isContactExists) dispatch(actionEditContact(itemToSave));
    else dispatch(actionCreateContact(itemToSave));
    if (!itemToSave.contactId) dispatch(actionLoadUserContactsS());
    close();
  };

  return (
    <ModalWrapper>
      <ContactModalWrapper>
        <GreyTitleWrapper>
          {editMode ? t('editContactT') : t('createNewContactT')}
        </GreyTitleWrapper>
        <SimpleTextInput
          fieldName={t('firstNameTextT')}
          value={inputFirstName === 'undefined' ? '' : inputFirstName}
          setValue={setInputFirstName}
          validateError={firstNameValidationError}
          setValidateError={setFirstNameValidationError}
          isSubFieldError
          isRequired
        />

        <SimpleTextInput
          fieldName={t('lastNameT')}
          value={inputLastName === 'undefined' ? '' : inputLastName}
          setValue={setInputLastName}
          validateError={lastNameValidationError}
          setValidateError={setLastNameValidationError}
          isSubFieldError
          isRequired
        />

        <SimpleEmailInput
          fieldName={t('mailT')}
          value={inputEmail}
          setValue={setInputEmail}
          validateError={emailValidationError}
          setValidateError={setEmailValidationError}
          isRequired
          isContactExists={isContactExists}
          isSubFieldError
        />

        <SimpleNumberInput
          fieldName={t('phoneT')}
          value={inputPhone}
          isPhone
          setValue={setInputPhone}
          validateError={phoneValidationError}
          setValidateError={setPhoneValidationError}
          isSubFieldError
        />

        <SimpleTextInput
          fieldName={t('organizationT')}
          value={inputOrganization}
          setValue={setInputOrganization}
          validateError={organizationValidationError}
          setValidateError={setOrganizationValidationError}
          isSubFieldError
        />

        <SimpleTextInput
          fieldName={t('roleT')}
          value={inputRole}
          setValue={setInputRole}
          validateError={functionValidationError}
          setValidateError={setFunctionValidationError}
          isSubFieldError
        />
        <div className={styles.divider} />

        <ButtonBlockWrapper>
          <Button2023
            variant="primary"
            width={69}
            height={32}
            text={t('cancelUpT')}
            noMargins
            handleButtonClick={close}
          />
          <Button2023
            variant="secondary"
            width={67}
            height={32}
            text={item.email ? t('updateUpT') : t('createUpT')}
            noMargins
            handleButtonClick={save}
          />
        </ButtonBlockWrapper>
      </ContactModalWrapper>
    </ModalWrapper>
  );
};

export default EditContactModal;
