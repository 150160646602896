import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import rawStyles from './elements.module.scss';
import useComponentVisible from '../../../utils/hooks/useComponentVisible';
import useComponentVisibleMouseDown from '../../../utils/hooks/useComponentVisibleMouseDown';
import {
  actionRemoveLinkPageFromPlaylist,
  actionToggleLibraryDrag,
  actionUpdateDraggableBlocks,
  actionPageWillDownload,
} from '../../../redux/currentPage/action';
import SupportAction from '../../../redux/support/types';
import { SHARED_PLAYLIST_URL, stopPropagation } from '../../../utils/constants';
import { actionChangeTextElementBlockReduxMaker } from '../../../redux/playlists/action';

import { actionAddPayloadUnifyHistory } from '../../../redux/history/actions';
import SixDotsItem from './SixDotsItem';
import DeleteContentButton from './DeleteContentButton';
import { actionCreator } from '../../../shared/redux/actionHelper';
import { isRoleInPlaylist } from '../../../utils/permissions';
import PlaylistElementCaption from './PlaylistElementCard/PlaylistElementCaption';
import { TextElementPopup } from './TextElementPopup';
import ThreeDotsDropDownMaker from '../ThreeDotsDropDownForMaker';
import { smartFileItemType } from '../../../shared/smartFile/constant';
import TextEditor from '../../../features/TextEditor/TextEditor';
import LexicalViewer from '../../../features/LexicalViewer/LexicalViewer';
import EditPlaylist from '../../../redux/playlists/types';

const cn = classNames.bind(rawStyles);


const LexTextElement = ({
  item,
  playlistId,
  isDragHover,
  selectedIds,
  selectedIndex,
  setSelectedIndex,
  setSelectedIds,
  itemIndex,
  refSelectedBlocks,
  setIsDraggingText,
  itemStylesObject,
  isExpandedView,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isForSharedToWeb = history.location.pathname.startsWith(`/${SHARED_PLAYLIST_URL}`);
  const { id: userId } = useSelector((state) => state.user);
  const currentPage = useSelector((state) => state.currentPage);
  const ownerID = currentPage?.owner?.id;
  const isAdmin = userId === ownerID;
  const { isViewer } = isRoleInPlaylist;
  const [itemRef, , setIsEdit] = useComponentVisibleMouseDown(false, item.id);
  const [menuRef, isActive, setIsActive] = useComponentVisible(false);
  const [plainText, setPlainText] = useState('');
  const [isEditorWidthFocus, setIsEditorWidthFocus] = useState(false);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isCofiguringPopup, setCofiguringPopup] = useState(false);
  const canUpdForRedoUndo = useRef(null);

  const { newBlockId, selectedItems } = useSelector((reduxState) => (reduxState.currentPage));
  const [threeDotRef, isThreeDotsActive, setIsThreeDotsActive] = useComponentVisible(false, 'miniPage');

  const { unseenInChannel, unseenPlaylistManagerMapInAll } = useSelector(
    (state) => state.support,
  );

  const cmdZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+z'],
  );
  const cmdShZ = useSelector(
    (reduxState) => reduxState.shortcut?.combination['mod+shift+z'],
  );
  const socketUpd = useSelector(
    (reduxState) => reduxState.shortcut?.combination.socketUpd,
  );
  const timerId = useRef(null);

  const { channelId = null, id: sharedToWebPlaylistID, type } = useParams();
  const isChannels = !!channelId;

  const isAuthor = userId === item?.owner?.id;
  const otherOwner = !isAuthor && item.owner;

  useEffect(() => {
    if (cmdZ || cmdShZ || socketUpd) {
      setCanUpdForRedo();
    }
  }, [cmdZ, cmdShZ, socketUpd]);

  useEffect(() => {
    // editor.setEditorState(item.textComponent?.state);
  }, [item.textComponent?.state]);


  const setCanUpdForRedo = () => {
    canUpdForRedoUndo.current = true;
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      canUpdForRedoUndo.current = false;
    }, 1000);
  };

  const clear = () => {
    refSelectedBlocks.current.forEach((block) => {
      if (block) {
        block.removeAttribute('class');
      }
    });
    setSelectedIds([]);
    setSelectedIndex([]);
    dispatch(actionToggleLibraryDrag(false));
    dispatch(actionUpdateDraggableBlocks([]));
  };

  const onCardClickHandler = () => {
    if (isViewer[props.currentRole]) {
      return;
    }
    setIsEdit(true);
  };

  const onDragTextHandler = () => {
    if (isViewer[props.currentRole]) {
      return;
    }
    setIsDraggingText((prev) => !prev);
  };

  const deleteHandler = () => {
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        item.id,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
  };

  const newGoToPlaylist = (event, index) => {
    event.stopPropagation();
    !props.isForSharedToWeb && dispatch(actionPageWillDownload());
    if (!isChannels && unseenPlaylistManagerMapInAll[currentPage.wrapperId]) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistR, {
          playlistManagerId: currentPage.wrapperId,
        }),
      );
    }
    if (
      isChannels
      && unseenInChannel[channelId]
      && unseenInChannel[channelId][currentPage.playlistManagerId]
    ) {
      dispatch(
        actionCreator(SupportAction.DeleteUnseenPlaylistInChannel, {
          channelId,
          playlistId: currentPage.id,
          playlistManagerId: currentPage.playlistManagerId,
        }),
      );
    }

    if (isForSharedToWeb) {
      dispatch(
        actionAddPayloadUnifyHistory({ sharedToWebID: sharedToWebPlaylistID }),
      );
      history.push(`/shared_player/shared/${sharedToWebPlaylistID}/${index}`);
      return;
    }
    const defaultChannel = channelId
      || (isChannels
        && currentPage?.usedInChannels
        && Object.values(currentPage?.usedInChannels)[0]?.id)
      || 'preview';
    const exPlaylistId = type === 'shared' ? sharedToWebPlaylistID : currentPage.id;
    const firstChannelId = type === 'publish' ? defaultChannel : type === 'shared' ? 'shared' : 'preview';
    history.push(`/player/${firstChannelId}/${exPlaylistId}/${index}`);
  };

  const onChange = useCallback((jsonEditorState) => {
    if (!jsonEditorState || !isAuthor) return;
    dispatch(actionChangeTextElementBlockReduxMaker(
      item.textComponent.id,
      jsonEditorState,
      '',
      playlistId,
      item.id,
      smartFileItemType.title,
    ),
    );
  }, []);


  const onBlurHandler = useCallback((state) => {
    dispatch(actionCreator(EditPlaylist.removeNewInLinkPage, { state }));
  }, [dispatch]);

  return (
    <div
      className={cn('six_dot_balancer', {
        isCompressView: props.isCompressView,
        hoverable: !isDragHover && !isViewer[props.currentRole],
        active: isActive,
      })}
    >
      <div
        className={cn('empty_element_wrapper', { is_expanded_view: isExpandedView })}
        onMouseEnter={() => setMenuVisible(true)}
        onMouseLeave={() => setMenuVisible(false)}
      >
        <div
          onClick={onCardClickHandler}
          className={cn('text_element', {
            hoverable: !isDragHover,
            dragginOver: isDragHover,
            viewMode: isViewer[props.currentRole],
            notEdit:
              (userId !== item?.owner?.id)
              || isViewer[props.currentRole],
            blink_it: props.blinkId?.includes(item.id),
          })}
          ref={itemRef}
          onDragEnd={props.onDragEndHandler}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onDragStart={props.dragStartHandler}
          draggable={!isViewer[props.currentRole] && isAuthor}
        >
          <SixDotsItem
            isExpandedView={isExpandedView}
            clear={props.clear}
            createHandler={props.createHandler}
            onDragStartHandler={props.onDragStartHandler}
            onDragEndHandler={props.onDragEndHandler}
            title={t('textBlockUpT')}
            playlistId={playlistId}
            menuRef={menuRef}
            dragStartHandler={props.dragStartHandler}
            isActive={isActive}
            setIsActive={setIsActive}
            isText
            isTitle
            item={item}
            itemStylesObject={itemStylesObject}
            otherOwner={otherOwner}
            userRole={props.userRole}
            duplicateHandler={props.duplicateHandler}
            isEmptyPlaceholder
            isViewer={isViewer[props.currentRole]}
            currentRole={props.currentRole}
            createApproveButton={props.createApproveButton}
            addCaptionToLinkPage={props.addCaptionToLinkPage}
            removeCaptionFromLinkPage={props.removeCaptionFromLinkPage}
            deleteApproveButton={props.deleteApproveButton}
            handleShowPannel={props.handleShowPannel}
            ownerID={ownerID}
            isCompressView={props.isCompressView}
          />

          <div
            data-parent={item.id}
            className={`${cn('text_container', 'rich_text', {
              selected: selectedItems[item.id],
              notEdit:
                (userId !== item?.owner?.id)
                || isViewer[props.currentRole],
            })} highlightedPlace`}
            draggable
            onDragStart={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }
            }
            onMouseDown={(e) => {
              e.isText = true;
            }}
            onDragEnd={onDragTextHandler}
            onMouseEnter={() => setMenuVisible(true)}
            onMouseLeave={() => setMenuVisible(false)}
          >
            <div
              className={cn('counter', {
                counter_visible: isEditorWidthFocus,
                counter_grey: plainText.length < 600,
                counter_yellow:
                  plainText.length >= 600 && plainText.length < 900,
                counter_red: plainText.length >= 900,
              })}
            >
              {plainText.length}/1000
            </div>
            {!isAuthor && <LexicalViewer state={item.textComponent?.state || ''} />}
            {isAuthor && (
              <TextEditor
                onBlurHandler={onBlurHandler}
                setIsDNDInTextBlock={props.setIsDNDInTextBlock}
                isActive={newBlockId && (newBlockId === item.textComponent.id)}
                onChange={onChange}
                idKey={item.id}
                state={item.textComponent?.state || ''}
              />
            )}

          </div>
          {(isMenuVisible || isCofiguringPopup) && (
            <TextElementPopup
              linkPageId={item.id}
              linkPage={item}
              playlistType={props.playlistType}
              isOwner={userId === item?.owner?.id}
              setMenuVisible={setMenuVisible}
              setIsThreeDotsActive={setIsThreeDotsActive}
              setCofiguringPopup={setCofiguringPopup}
              isExpandedView={isExpandedView}
            />
          )}
          {isThreeDotsActive && (
            <ThreeDotsDropDownMaker
              playlistType={props.playlistType}
              show={isThreeDotsActive}
              refMenu={threeDotRef}
              itemRef={itemRef}
              isTitle
              isActive={isThreeDotsActive}
              setIsActive={setIsThreeDotsActive}
              playlistId={currentPage.id}
              isContentEditable={false}
              socketId={currentPage.socketId}
              isOwner={props.isOwner}
              item={item}
              goToItem={stopPropagation}
              currentPage={currentPage}
              itemIndex={itemIndex}
              play={(e) => newGoToPlaylist(e, itemIndex + 1)}
              currentRole={props.currentRole}
              upvCard
              isTextCardView
            />
          )}

        </div>
        <PlaylistElementCaption
          caption={item.caption}
          linkPageId={item.id}
          isShowCaption={item.isShowCaption}
          isNew={item.isNew}
        />
      </div>
      {!isViewer[props.currentRole]
        && ((item.owner && userId === item?.owner?.id) || isAdmin) && (
        <DeleteContentButton deleteHandler={deleteHandler} isCompressView={props.isCompressView} />
      )}
    </div>
  );
};

export default LexTextElement;
