import { call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { all, select } from '@redux-saga/core/effects';
import { v4 as uuidv4 } from 'uuid';
import {
  getActiveNavSliderAndSelectorType,
  getChannels,
  getSettings,
  getUser,
  showErrorMessage,
} from './sagasUtils';
import { actionCreator } from '../shared/redux/actionHelper';
import { Settings } from '../redux/settings/types';
import { LANG, TRANSLATIONS } from '../utils/Translations';
import { NOTIFICATIONS } from '../pages/SettingsSection/FormNotifications/infos';
import {
  request,
  requestCheckChangeEmailJwt,
  requestConfirmEmailChange,
  requestDeleteAvatar,
  requestDeleteEmoji,
  requestGetSomeUserContent,
  requestGetSortOptions,
  requestUpdateEmoji,
  requestUpdateSortOption,
  requestUpdateUser,
} from '../utils/request';
import { sanitizeToLoad, sanitizeToSave } from '../utils/helpers';
import { actionSaveRedirect, actionShowMessage } from '../redux/support/action';
import {
  DEFAULT_PAGE_PATH,
  MessageType,
  METHOD,
  SAVE_AVATAR,
  SAVE_EMOJI,
  UNSUBSCRIBE_URL,
} from '../utils/constants';
import { ServiceUser } from '../redux/user/types';
import { createUploadFileChannel } from './createFileUploadChannel';
import { delay } from './uploadSagas';
import {
  createError,
  DB_USER_PREFERENCES_GENERAL_FIELDS,
  DB_USER_PREFERENCES_LANGUAGE_FIELDS,
  ERROR_RESPONSES,
  FORM_INPUTS,
  FORM_REQUEST_MAP,
  INPUT_TO_MODAL_MAP,
  VALIDATORS_MAP,
} from '../utils/SettingsHelpers/constants';
import { ContentActionType } from '../redux/content/contentTypes';
import {
  actionChangeOneSortKeyInRedux,
  actionChangeSortRedux,
} from '../redux/settings/actions';
import {
  extractSortOptionFromHistory,
  sortOptionChooser,
} from '../utils/sort/sortTypeChooser';
import { actionSwitchContentSortType } from '../redux/content/actions';
import { actionOpenModal } from '../redux/user/action';
import { VALIDATORS } from '../utils/SettingsHelpers/utils';

const {
  settings: {
    forms: { success: T_success, errors: T_errors },
    upgrade: {
      values: { plans: T_plans },
    },
  },
} = TRANSLATIONS;

function* scheduleHideMessages(formName) {
  try {
    yield delay(10000);
    yield put(actionCreator(Settings.HideFormMessages, { formName }));
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* getUserSortOptions(action) {
  try {
    const history = action.payload;
    const currentPageOptionName = extractSortOptionFromHistory(history);
    const { data } = yield call(requestGetSortOptions);
    if (!data.length) return;
    const [{ sortOptions }] = data;
    yield put(actionSwitchContentSortType(sortOptions[currentPageOptionName]));
    yield put(actionChangeSortRedux(sortOptions));
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* updateUserSortOptions(action) {
  try {
    const { value } = action.payload;
    const { selectorType, activeNavSlider } = yield select(
      getActiveNavSliderAndSelectorType,
    );
    console.log({ selectorType, activeNavSlider, value });
    if (selectorType.startsWith('dynamic') || activeNavSlider === 'tag') return;
    const key = sortOptionChooser(activeNavSlider, selectorType);
    yield put(actionChangeOneSortKeyInRedux(key, value));
    yield call(requestUpdateSortOption, { key, value });
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* validateInput(action) {
  const { inputType } = action.payload;
  const { inputs } = yield select(getSettings);
  const inputValue = inputs[inputType].value;

  const errors = [];

  const errorsPriorities = yield all(
    VALIDATORS_MAP[inputType].map((key) => {
      return call(VALIDATORS[key], inputValue.trim());
    }),
  );

  VALIDATORS_MAP[inputType].forEach((key, index) => {
    if (errorsPriorities[index]) {
      errors.push(
        createError(uuidv4(), ERROR_RESPONSES[key], errorsPriorities[index]),
      );
    }
  });

  yield put(
    actionCreator(Settings.UpdateInputErrors, {
      inputType,
      errors: errors.sort((a, b) => a.errorPriority - b.errorPriority),
    }),
  );

  return !!errors.length;
}

function* revalidateAllInputs(formName) {
  try {
    const validationResults = yield all(
      FORM_INPUTS[formName].map((field) => {
        return call(validateInput, { payload: { inputType: field } });
      }),
    );
    const hasAnyErrors = validationResults.find((i) => i === true);
    return !!hasAnyErrors;
  } catch (err) {
    return true;
  }
}

function* validateForm(action) {
  const { formName } = action.payload;
  try {
    const isHasAnyErrors = yield call(revalidateAllInputs, formName);
    return !isHasAnyErrors;
  } catch (err) {
    return false;
  }
}

function* sendForm(action) {
  const { formName } = action.payload;
  try {
    const { inputs } = yield select(getSettings);

    const isNoChanges = FORM_INPUTS[formName].reduce((acc, inputName) => {
      const { value, dataBaseValue } = inputs[inputName];
      if (value !== dataBaseValue) acc = false;
      return acc;
    }, true);

    if (isNoChanges) {
      yield put(
        actionShowMessage({
          type: MessageType.NothingToSave,
        }),
      );
      return;
    }

    const isValid = yield call(validateForm, action);
    if (!isValid) {
      yield put(
        actionShowMessage({
          type: MessageType.Error,
          message: T_errors[`${formName}Error`][LANG.en],
        }),
      );
      return;
    }

    const formData = {};
    FORM_INPUTS[formName].forEach((input) => {
      if (inputs[input].value !== inputs[input].dataBaseValue) {
        formData[input] = sanitizeToSave(inputs[input].value.trim());
      }
    });
    let data;
    try {
      data = yield call(FORM_REQUEST_MAP[formName], formData);
    } catch (err) {
      yield put(
        actionShowMessage({
          type: MessageType.SettingsError,
          message: err.response.data.result,
          errors: err.response.data.errors,
        }),
      );
      return;
    }

    if (data.status === 200) {
      yield put(
        actionShowMessage({
          type: MessageType.SuccessfullyUpdated,
          message: T_success[`${formName}Success`][LANG.en],
        }),
      );
      yield put(
        actionCreator(Settings.Profile.UpdateUserInfoAfterSettingsChangeR, {
          requestedUpdates: data.data.requestedUpdates,
        }),
      );
    }

    if (data?.data?.isEmailConfirmation) {
      const notifications = [
        {
          id: uuidv4(),
          component: NOTIFICATIONS.ConfirmEmail,
          data: data.data.newEmail,
        },
      ];
      yield put(
        actionCreator(Settings.ShowFormNotifications, {
          formName,
          notifications,
        }),
      );
      yield call(scheduleHideMessages, formName);
    }

    yield put(actionCreator(Settings.Profile.SetInitialValuesR));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* getProfileData() {
  try {
    const {
      data: { User },
    } = yield requestGetSomeUserContent(['profile']);
    const exUser = User[0];
    const user = {
      username: sanitizeToLoad(exUser.username),
      firstName: sanitizeToLoad(exUser.first_name),
      lastName: sanitizeToLoad(exUser.last_name),
      email: sanitizeToLoad(exUser.email),
      phone: sanitizeToLoad(exUser.phone),
      company: sanitizeToLoad(exUser.company),
      infoRole: sanitizeToLoad(exUser.infoRole),
      bio: sanitizeToLoad(exUser.bio),
      displayImage: exUser.displayImage,
      showUsername: exUser.showUsername,
      avatar: exUser.avatarUrlSmall,
    };

    yield put(actionCreator(Settings.Profile.InitialDataLoading, { user }));
    yield put(actionCreator(Settings.DataIsLoaded));
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* confirmEmail(action) {
  try {
    const { password, setIsConfirmed, setIsLoading } = action.payload;
    setIsLoading(true);
    const { email } = yield select(getUser);
    let data;
    try {
      data = yield call(requestConfirmEmailChange, { password, email });
    } catch (err) {
      yield showErrorMessage(err);
    }

    setIsLoading(false);

    if (data.data.statusCode === 401) {
      yield put(
        actionShowMessage({
          type: MessageType.ForgotPassword_Error,
          notice: data.data.result,
        }),
      );
    } else if (data.status === 200) {
      yield put(
        actionShowMessage({
          type: MessageType.EmailConfirmed,
        }),
      );
      setIsConfirmed(true);
    }
  } catch (err) {
    yield put(
      actionShowMessage({
        type: MessageType.ForgotPassword_Error,
        notice: err.message,
      }),
    );
  }
}

function* handleAvatarFileUpload(action) {
  try {
    const { file, inputType } = action.payload;
    const fileMimeType = file.type;

    const errors = [];

    const errorsPriorities = yield all(
      VALIDATORS_MAP[inputType].map((key) => {
        return call(VALIDATORS[key], file);
      }),
    );

    VALIDATORS_MAP[inputType].forEach((key, index) => {
      if (errorsPriorities[index]) {
        errors.push(
          createError(uuidv4(), ERROR_RESPONSES[key], errorsPriorities[index]),
        );
      }
    });

    if (Object.keys(errors)?.length) {
      yield put(
        actionCreator(Settings.UpdateInputErrors, {
          inputType,
          errors: errors.sort((a, b) => a.errorPriority - b.errorPriority),
        }),
      );

      if (inputType === 'emojiFile') {
        const formErrors = [
          {
            id: uuidv4(),
            message: T_errors.emojiFileUploadError[LANG.en],
          },
        ];
        yield put(
          actionCreator(Settings.ShowFormErrors, {
            formName: 'emojiFileUpload',
            errors: formErrors,
          }),
        );
        yield call(scheduleHideMessages, 'emojiFileUpload');
      }

      return;
    }

    yield put(
      actionCreator(Settings.UpdateInputErrors, { inputType, errors: [] }),
    );
    const blobUrl = URL.createObjectURL(file);
    yield put(
      actionCreator(Settings.SaveTmpImageData, {
        data: blobUrl,
        type: fileMimeType,
        inputType,
        file,
      }),
    );

    if (Object.keys(INPUT_TO_MODAL_MAP).includes(inputType)) {
      yield put(actionOpenModal(INPUT_TO_MODAL_MAP[inputType]));
    }

    if (inputType === 'emojiFile') {
      yield put(
        actionCreator(Settings.HideFormMessages, {
          formName: 'emojiFileUpload',
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* saveAvatar(action) {
  try {
    const { imageBlob, crop } = action.payload;
    yield put(
      actionCreator(Settings.Profile.SetAvatarIsLoading, { isLoading: true }),
    );

    const formData = new FormData();
    formData.append('crop', JSON.stringify(crop));
    formData.append('image', imageBlob, 'image.png');

    if (imageBlob) {
      try {
        const channel = yield call(
          createUploadFileChannel,
          SAVE_AVATAR,
          formData,
        );
        let upload = true;
        while (upload) {
          yield delay(300);
          const { err, success, res } = yield take(channel);
          if (err) upload = false;
          if (success) {
            const { avatarUrlSmall, avatarUrlVerySmall, displayImage } = res;
            yield put(
              actionCreator(Settings.Profile.UpdateAvatarInput, {
                avatarUrlSmall,
                avatarUrlVerySmall,
                displayImage,
              }),
            );
          }
          yield delay(200);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* deleteAvatar() {
  try {
    yield call(requestDeleteAvatar);
    yield put(
      actionShowMessage({
        type: MessageType.AvatarDeleted,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* getChannelsForActivity() {
  try {
    const { myChannels } = yield select(getChannels);
    const { id } = yield select(getUser);
    const channels = {};
    Object.values(myChannels).forEach((channel) => {
      const channelOwner = channel?.node?.owner[0] || {};
      const ownerName = `${channelOwner.last_name || ''} ${(channelOwner.first_name || '').trim()}`.trim() || "User doesn't exist";
      const authors = channelOwner.id === id ? 'Me' : ownerName || channelOwner.email;
      channels[channel.id] = {
        id: channel.id,
        name: channel.name,
        role: channel.role,
        authorName: authors,
        isUserOwner: channel.owner.id === id,
        isUnpin: channel.isUnpin,
      };
    });

    yield put(actionCreator(Settings.Activity.Initial, { channels }));
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* changeEmailCheckJwt(action) {
  try {
    const { jwt, history } = action.payload;
    const token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');

    if (!token) {
      history.push('/login');
      yield put(actionSaveRedirect(`/confirm_new_email/${jwt}`));
      yield put(
        actionShowMessage({
          type: MessageType.ChangeEmail_NeedToLogin,
        }),
      );
      return;
    }

    const {
      data: { isTokenValid },
    } = yield call(requestCheckChangeEmailJwt, [jwt]);

    if (!isTokenValid) {
      history.push(DEFAULT_PAGE_PATH);
      yield put(
        actionShowMessage({
          type: MessageType.ChangeEmail_TokenNotValid,
        }),
      );
      return;
    }

    history.push('/protected_confirm_new_email');
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* getPreferencesGeneralData() {
  try {
    const {
      data: { User },
    } = yield requestGetSomeUserContent(['preference']);
    yield put(
      actionCreator(Settings.Preferences.LoadInitialData, { ...User[0] }),
    );
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* toggleInputValue(action) {
  try {
    const { inputType, newValue } = action.payload;
    const { id: userId } = yield select(getUser);
    const fieldsUpdateObg = {};
    fieldsUpdateObg[inputType] = inputType === DB_USER_PREFERENCES_GENERAL_FIELDS.displayImage
      ? `"${newValue}"` : newValue;
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg,
    });
    if (inputType === DB_USER_PREFERENCES_GENERAL_FIELDS.showUsername) {
      yield put(
        actionCreator(Settings.Profile.UpdateUserInfoAfterSettingsChangeR, {
          requestedUpdates: {
            showUsername: newValue,
          },
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* updateAvatarSelector(action) {
  try {
    const { inputType, newValue } = action.payload;
    const { id: userId } = yield select(getUser);
    const fieldsUpdateObg = {};
    fieldsUpdateObg[inputType] = `"${newValue}"`;
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg,
    });
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* changeLanguage(action) {
  try {
    const { newValue } = action.payload;
    const { id: userId } = yield select(getUser);
    const fieldsUpdateObg = {};
    fieldsUpdateObg[DB_USER_PREFERENCES_LANGUAGE_FIELDS.preferredLanguage] = newValue;
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg,
    });
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* changeTimeFormat(action) {
  try {
    const { newValue } = action.payload;
    const { id: userId } = yield select(getUser);
    const fieldsUpdateObg = {};
    fieldsUpdateObg[DB_USER_PREFERENCES_LANGUAGE_FIELDS.timeFormat] = newValue;
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg,
    });
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* changeDateFormat(action) {
  try {
    const { newValue } = action.payload;
    const { id: userId } = yield select(getUser);
    const fieldsUpdateObg = {};
    fieldsUpdateObg[DB_USER_PREFERENCES_LANGUAGE_FIELDS.dateFormat] = newValue;
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg,
    });
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* changeTimeZoneFormat(action) {
  try {
    const { newValue } = action.payload;
    const { id: userId } = yield select(getUser);
    const fieldsUpdateObg = {};
    fieldsUpdateObg[
      DB_USER_PREFERENCES_LANGUAGE_FIELDS.timeZone
    ] = `"${newValue}"`;
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg,
    });
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* saveEmoji() {
  try {
    const isValid = yield call(validateForm, {
      payload: { formName: 'emojiFileUpload' },
    });
    if (!isValid) {
      const errors = [
        { id: uuidv4(), message: T_errors.emojiFileUploadError[LANG.en] },
      ];
      yield put(
        actionCreator(Settings.ShowFormErrors, {
          formName: 'emojiFileUpload',
          errors,
        }),
      );
      yield call(scheduleHideMessages, 'emojiFileUpload');
      return;
    }
    yield put(
      actionCreator(Settings.HideFormMessages, { formName: 'emojiFileUpload' }),
    );

    const { inputs } = yield select(getSettings);

    const emojiName = inputs.emojiName.value;
    const file = inputs.emojiFile.file;
    const fileType = inputs.emojiFile.tmpImageType;
    const emojiId = uuidv4();

    const formData = new FormData();
    const lastModifiedDate = Math.floor(Date.now() / 1000);

    formData.append('type', fileType);
    formData.append('lastModifiedDate', lastModifiedDate.toString());
    formData.append('emojiName', emojiName);
    formData.append('emojiId', emojiId);
    formData.append('file', file);

    if (file) {
      try {
        const channel = yield call(
          createUploadFileChannel,
          SAVE_EMOJI,
          formData,
        );
        let upload = true;
        while (upload) {
          yield delay(300);
          const { err, success, res } = yield take(channel);
          if (err) upload = false;
          if (success) {
            const { emojiUrl } = res;
            const newEmoji = {
              id: emojiId,
              name: emojiName,
              url: emojiUrl,
              lastModifiedDate,
            };
            yield put(
              actionCreator(Settings.Preferences.AddListItemToEmojis, {
                newEmoji,
              }),
            );
            const successMessage = [
              {
                id: uuidv4(),
                message: T_success.emojiFileUploadSuccess[LANG.en],
              },
            ];
            yield put(
              actionCreator(Settings.ShowFormSuccess, {
                formName: 'emojiFileUpload',
                success: successMessage,
              }),
            );
            yield call(scheduleHideMessages, 'emojiFileUpload');
          }
          yield delay(200);
        }
      } catch (err) {
        yield put(
          actionCreator(Settings.ShowFormErrors, {
            formName: 'emojiFileUpload',
            errors: err,
          }),
        );
        yield call(scheduleHideMessages, 'emojiFileUpload');
      }
    }
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* emojiNameRenameCheckAndSave(action) {
  try {
    const { inputType, emojiId } = action.payload;
    const {
      inputs,
      tables: { emojis },
    } = yield select(getSettings);

    const inputValue = inputs[inputType].value;
    const errors = [];

    const errorsPriorities = yield all(
      VALIDATORS_MAP[inputType].map((key) => {
        return call(VALIDATORS[key], inputValue.trim(), emojiId);
      }),
    );

    VALIDATORS_MAP[inputType].forEach((key, index) => {
      if (errorsPriorities[index]) {
        errors.push(
          createError(uuidv4(), ERROR_RESPONSES[key], errorsPriorities[index]),
        );
      }
    });

    yield put(
      actionCreator(Settings.Preferences.UpdateEmojiTableErrors, {
        emojiId,
        errors: errors.sort((a, b) => a.errorPriority - b.errorPriority),
      }),
    );

    if (!errors.length && inputValue !== emojis[emojiId].name) {
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      yield requestUpdateEmoji(emojiId)({ name: inputValue });
      const updatedEmoji = {
        [emojiId]: {
          ...emojis[emojiId],
          name: inputs[inputType].value,
          lastModifiedDate,
        },
      };
      yield put(
        actionCreator(Settings.Preferences.UpdateEmojiInTable, {
          updatedEmoji,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* initialGetEmojis() {
  try {
    const { data } = yield requestGetSomeUserContent(['emoji']);

    if (!data?.errors) {
      const emojis = {};
      data.User[0].emojis.forEach((emoji) => {
        emojis[emoji.id] = { ...emoji };
      });

      yield put(
        actionCreator(Settings.Preferences.LoadEmojisR, {
          updatedEmojis: emojis,
        }),
      );
    }
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* removeUserEmoji(action) {
  try {
    const { emojiId, emojiName } = action.payload;
    yield requestDeleteEmoji(emojiId);
    yield put(
      actionShowMessage({
        type: MessageType.EmojiRemoved,
        itemName: emojiName,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* changePaymentPlan(action) {
  try {
    const { newPlan } = action.payload;
    const { id: userId } = yield select(getUser);
    yield call(requestUpdateUser, {
      id: userId,
      fieldsUpdateObg: { currentPlan: newPlan },
    });
    yield put(
      actionShowMessage({
        type: MessageType.UserChangedPlan,
        newPlan: T_plans[newPlan].title[LANG.en],
      }),
    );
  } catch (err) {
    yield showErrorMessage(err);
  }
}

function* unsubscribe(action) {
  try {
    const { id, history, type, historyType } = action.payload;
    yield call(request(UNSUBSCRIBE_URL(id, type), METHOD.GET));
    history.push(`/content/${historyType ?? type}s/shared`);
    yield put(
      actionCreator(ContentActionType.updateCounterS, {
        activeNavSliderStatePayload: 'shared',
      }),
    );
  } catch (err) {
    yield showErrorMessage(err);
  }
}

export default function* settingsSagas() {
  yield takeEvery(Settings.ValidateInputS, validateInput);
  yield takeEvery(Settings.HandleUploadFileS, handleAvatarFileUpload);
  yield takeEvery(Settings.ValidateForm, validateForm);
  yield takeEvery(Settings.SendForm, sendForm);
  yield takeEvery(Settings.ResetForm, getProfileData);
  yield takeEvery(Settings.GetProfileData, getProfileData);
  yield takeEvery(Settings.Profile.ConfirmEmailChange, confirmEmail);
  yield takeEvery(Settings.Profile.SaveAvatarS, saveAvatar);
  yield takeEvery(Settings.Profile.DeleteAvatar, deleteAvatar);
  yield takeEvery(Settings.Profile.ChangeEmailCheckJwt, changeEmailCheckJwt);
  yield takeEvery(Settings.Activity.GetChannels, getChannelsForActivity);
  yield takeEvery(
    Settings.Preferences.GetGeneralDataS,
    getPreferencesGeneralData,
  );
  yield takeEvery(Settings.Preferences.ToggleInputValue, toggleInputValue);
  yield takeEvery(
    Settings.Preferences.UpdateAvatarSelector,
    updateAvatarSelector,
  );
  yield takeEvery(Settings.Preferences.ChangeLanguage, changeLanguage);
  yield takeEvery(Settings.Preferences.ChangeTimeFormat, changeTimeFormat);
  yield takeEvery(Settings.Preferences.ChangeDateFormat, changeDateFormat);
  yield takeEvery(
    Settings.Preferences.ChangeTimeZoneFormat,
    changeTimeZoneFormat,
  );
  yield takeEvery(Settings.Preferences.SaveEmojiFileS, saveEmoji);
  yield takeEvery(
    Settings.Preferences.EmojiNameRenameCheckAndSave,
    emojiNameRenameCheckAndSave,
  );
  yield takeEvery(Settings.Preferences.InitialGetEmojisS, initialGetEmojis);
  yield takeEvery(Settings.Preferences.RemoveUserEmoji, removeUserEmoji);
  yield takeEvery(Settings.ChangePaymentPlan, changePaymentPlan);
  yield takeEvery(ServiceUser.unsubscribe, unsubscribe);
  yield takeLatest(Settings.Sort.getSortFromDB, getUserSortOptions);
  yield takeLatest(Settings.Sort.changeSortSagas, updateUserSortOptions);
}
