import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoSvgWhite } from '../../images/2023/svg/Logo_white_text.svg';
import QuidziLogoNeon from '../../images/png/quidzi_logo.png';
import styles from './Login.module.scss';
import { Logo } from './ForgotPassword/ForgotView';

const LoginIntro = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.page_body}>
      <Logo isLoginPageText isNotClickable>
        <LogoSvgWhite />
      </Logo>
      <p className={styles.page_body_title}>
        {t('introLine1T')}
      </p>
      <p className={styles.page_body_text}>{t('introLine2T')}</p>
      <p className={styles.page_body_text}>{t('introLine3T')}</p>
      <p className={styles.page_body_text}>{t('introLine4T')}</p>
      <img src={QuidziLogoNeon} className={styles.page_body_image} alt="QuidziLogoNeon" />
    </div>
  );
};

export default LoginIntro;
