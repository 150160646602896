import React from 'react';
import styled from 'styled-components/macro';

const MainWrapper = styled.fieldset`
  display: flex;
  border: none;
  padding: 0;
  justify-content: space-between;
  width: 169px;

  .radio_quidzi {
    cursor: pointer;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #d3c8c8;
    width: 16px;
    height: 16px;
    border: 1px solid #d3c8c8;
    border-radius: 50%;
    display: grid;
    place-content: center;
  }

  .radio_quidzi::before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffc72c;
    transform: scale(0);
    border: 1px solid #ffc72c;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  .radio_quidzi:checked::before {
    transform: scale(1);
  }
  .radio_quidzi:checked {
    border: 1px solid #ffe9ae;
  }
`;

const LabelWrapper = styled.label`
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
`;
const RadioGroupTags = ({ colorPickerMode, setColorPickerMode }) => {
  const listenForChoice = (e) => {
    if (e.target.name === 'text') {
      setColorPickerMode(true);
    } else {
      setColorPickerMode(false);
    }
  };
  return (
    <MainWrapper>
      <LabelWrapper>
        <input
          type="radio"
          name="bg"
          className="radio_quidzi"
          onChange={listenForChoice}
          checked={!colorPickerMode}
        />
        Background
      </LabelWrapper>
      <LabelWrapper>
        <input
          onChange={listenForChoice}
          type="radio"
          name="text"
          className="radio_quidzi"
          checked={colorPickerMode}
        />
        Text
      </LabelWrapper>
    </MainWrapper>
  );
};

export default RadioGroupTags;
