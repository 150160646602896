import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';
import styles from './Library.module.scss';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import MultiSelectPanel from '../../components/MultiSelect/MultiSelectPanel';
import {
  actionRemoveAllSelected,
  actionRemoveSelectedPage,
  actionSelectedPage,
} from '../../redux/selectedPage/action';
import useMiniPageClickOutside from '../../utils/hooks/useMiniPageClickOutside';
import LibraryContentSelector from '../../components/LibraryContentSelector';
import { DATA_PARENTS, empty, openModalType } from '../../utils/constants';
import { LibraryTableSchema } from '../../utils/tableHelpers/tableUtils';
import ActionCustomEvent from '../../redux/customEvent/action';
import { createPage } from '../../utils/helpers';
import { actionOpenModal, actionSwitchFolder } from '../../redux/user/action';
import MainNavSlider from '../../components/MainHeader/MainNavSlider/MainNavSlider';
import { actionCreator } from '../../shared/redux/actionHelper';
import { ContentActionType } from '../../redux/content/contentTypes';
import { useQuery } from '../../utils/hooks/useQuery';
import useTableInstallIndex from '../../utils/hooks/useTableInstalIndex';
import SmartViewTagMenu from '../../components/SmartViewTagMenu';
import { actionChangeSieveValue } from '../../redux/library/actions';
import { calculateSelectorAndSlider } from './helpers/calculateReqOptions';
import { sortOptionChooser } from '../../utils/sort/sortTypeChooser';
import ContactNavSlider from '../../components/MainHeader/MainNavSlider/ContactNavSlider';

const LibraryNew = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const query = useQuery();

  const {
    place: activeNavSlider = 'smartfiles',
    selector: selectorType = 'recent',
  } = useParams();

  const sortTypeInState = useSelector(state => state?.content?.sortType) || 'recent';
  const previousSortType = selectorType === 'recently_viewed' ? 'recent' : sortTypeInState;
  const isDownload = useSelector(state => state.user.isDownload);
  const isCardView = useSelector(state => state.currentPage.isCardView);
  const isShowLibraryWidget = useSelector(state => state.currentPage.isShowLibraryWidget);
  const { id: metaId } = useSelector(reduxState => reduxState.support.componentDescriptionData || empty);
  const { folderId, tags } = useSelector(state => state.user);
  const { componentDescription } = useSelector(state => state.support);
  const { contentIds, existMore } = useSelector(state => state.content);
  const dynamicCollection = useSelector(state => state.dynamicCollection);
  const selectedPage = useSelector(state => state.selectedPage) || empty;
  const { openLibSearch } = useSelector(state => state.customEvent);
  const isDescriptionOpened = useSelector(state => state.support.componentDescription);
  const { somePayload: lastStep } = useSelector(state => state.historyTrace);
  const { sortOptions } = useSelector(state => state.settings);

  const [showNewSmartView, setShowNewSmartView] = useState(false);
  const [filter] = useState('');
  const [sortType, setSort] = useState(previousSortType);
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [smartViewForEdit, setSmartViewForEdit] = useState({});
  const [counterSelectedPage, setCounterSelectedPage] = useState(0);
  const [ignoreSelectorType, setIgnoreSelectorType] = useState(false);
  const [test] = useState([]);
  const [needToDelete, setNeedToDelete] = useState(false);
  const [libFilterSearch, setLibFilterSearch] = useState('');
  const [libFilter, setLibFilter] = useState(empty);
  const [stateEndPoint, setEndPoint] = useState(null);
  const [selectionBox, setSelectionBox] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const isUsers = activeNavSlider === 'users_smartfiles' || activeNavSlider === 'users_smartfiles_favorite';

  const [isTableIsVisible, setTableIsVisible] = useComponentVisible(
    null,
    DATA_PARENTS.ActivityMore,
  );
  const [isSimplifiedMultiselect, setSimplifiedMultiselect] = useMiniPageClickOutside(false);
  const { installationIndex, tableInsertRef } = useTableInstallIndex({
    cardWidth: 293,
    paddingOffset: 36,
  });

  const isSidebarOpened = isShowLibraryWidget || metaId;

  const scrollParentRef = useRef(null);
  const mouseDown = useRef(null);
  const stateStartPoint = useRef(null);
  const boxEl = useRef(null);

  const setMouseDown = (vall) => {
    mouseDown.current = vall;
  };
  const setStateStartPoint = (vall) => {
    stateStartPoint.current = vall;
  };

  const intersect = (boxA, boxB) => {
    return (
      boxA.left <= boxB.left + boxB.width
      && boxA.left + boxA.width >= boxB.left
      && boxA.top <= boxB.top + boxB.height
      && boxA.top + boxA.height >= boxB.top
    );
  };

  useEffect(() => {
    const el = document.createElement('div');
    el.classList = [styles.selection_border];
    document.body.append(el);
    boxEl.current = el;
    return () => document.body.removeChild(el);
  }, []);

  useEffect(() => {
    if (selectionBox) {
      Object.assign(boxEl.current.style, {
        left: `${selectionBox.left}px`,
        top: `${selectionBox.top}px`,
        width: `${selectionBox.width}px`,
        height: `${selectionBox.height}px`,
      });
    } else {
      Object.assign(boxEl.current.style, {
        left: '0px',
        top: '0px',
        width: '0px',
        height: '0px',
      });
    }
  }, [selectionBox]);

  const mouseup = (e) => {
    e.stopPropagation();

    document.removeEventListener('mouseup', mouseup, true);
    setMouseDown(false);
    setStateStartPoint(null);
    setSelectionBox(null);
  };

  const calculateSelectionBox = (startPoint, endPoint) => {
    if (!mouseDown?.current || !endPoint || !startPoint) {
      return null;
    }

    const left = Math.min(startPoint.x, endPoint.x);
    const top = Math.min(startPoint.y, endPoint.y);
    const width = Math.abs(startPoint.x - endPoint.x);
    const height = Math.abs(startPoint.y - endPoint.y);

    if (height < 3 || width < 3) {
      return null;
    }

    return {
      left,
      top,
      width,
      height,
    };
  };

  const onMouseMove = (e) => {
    if (selectorType === 'board') return;
    if (mouseDown?.current) {
      e.preventDefault();
      const endPoint = {
        x: e.pageX,
        y: e.pageY,
      };
      setEndPoint(endPoint);
      const box = calculateSelectionBox(stateStartPoint.current, endPoint);
      setSelectionBox(box);
      if (!!box && !isSimplifiedMultiselect) {
        setSimplifiedMultiselect(true);
      }
    }
  };

  const onMouseDown = (e) => {
    const undefinedFoo = (e) => {
      try {
        if (
          e?.target?.className
          && e.target.className?.includes('TableResize')
        ) {
          return true;
        }
      } catch (e) {
        return false;
      }
    };

    if (undefinedFoo(e)) {
      return;
    }

    if (selectorType === 'board') return;

    if (
      !(e.button === 2 || e.nativeEvent.which === 2)
      && !(
        e.target?.dataset?.parent?.includes('miniPage')
        || e.target?.parentElement?.dataset?.parent?.includes('miniPage')
      )
    ) {
      e.preventDefault();
      if (Object.keys(selectedPage).length) {
        dispatch(actionRemoveAllSelected());
      }
      document.addEventListener('mouseup', mouseup, true);
      setMouseDown(true);
      setStateStartPoint({
        x: e.pageX,
        y: e.pageY,
      });
      if (stateEndPoint) {
        setEndPoint(null);
      }
      if (selectionBox) {
        setSelectionBox(null);
      }
      if (isSimplifiedMultiselect) {
        setSimplifiedMultiselect(false);
      }
    }
  };

  const createNewPage = useCallback(() => {
    createPage({ selectorType, dispatch, tag: tags[folderId], history });
  }, [
    selectorType,
    dispatch,
    tags,
    folderId,
    history,
  ]);

  const next = () => {
    setHasMore(false);
    const params = { libFilter, libFilterSearch };

    const { calcSelectorType, calcActiveNavSlider } = calculateSelectorAndSlider(
      activeNavSlider,
      selectorType,
      params,
      isEdit,
      ignoreSelectorType,
    );
    const sortName = sortOptionChooser(calcActiveNavSlider, calcSelectorType);
    const exSortType = sortOptions[sortName] || previousSortType;
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: calcActiveNavSlider,
          selectorType: ignoreSelectorType ? 'all' : calcSelectorType,
          sortType: exSortType,
        },
        'LibraryNew next',
      ),
    );
  };

  useEffect(() => {
    const isOpenSettings = !!query.get('isOpenSettings');

    if (isOpenSettings) {
      history.push(history.location.pathname);
      dispatch(actionOpenModal(openModalType.ApplicationSettings));
    }
  }, []);

  useEffect(() => {
    setHasMore(existMore);
  }, [contentIds]);

  useEffect(() => {
    if (openLibSearch && isShowFilter !== openLibSearch) {
      setTimeout(() => setIsShowFilter(true), 100);
    }
  }, [openLibSearch]);

  useEffect(() => {
    if (!isShowFilter) {
      setLibFilterSearch('');
      setTimeout(() => dispatch(ActionCustomEvent.Clear()), 400);
    }
  }, [isShowFilter]);

  useEffect(() => {
    setLoading(true);
    if (
      selectorType !== 'all'
      || selectorType !== 'recent'
      || selectorType !== 'favorites'
    ) {
      dispatch(actionSwitchFolder('all'));
    }
  }, []);

  useEffect(() => {
    setIsEdit(false);
    setIsShowFilter(false);
    if (activeNavSlider === 'dynamicCollection') {
      const { libFilterSearch: filterSearch, filter: filterValue } = dynamicCollection[selectorType];
      setLibFilter(filterValue || {});
      setLibFilterSearch(filterSearch);
    } else {
      setLibFilter(empty);
      setLibFilterSearch('');
    }
  }, [selectorType, folderId]);

  useEffect(() => {
    if (selectionBox && selectionBox.left) {
      const items = scrollParentRef.current.querySelectorAll('[data-intersect]');
      items.forEach((item) => {
        if (item) {
          const pageId = item.getAttribute('data-intersect');
          if (intersect(selectionBox, item.getBoundingClientRect())) {
            dispatch(actionSelectedPage(pageId));
          } else if (selectedPage[pageId]) {
            dispatch(actionRemoveSelectedPage(pageId));
          }
        }
      });
    }
  }, [selectionBox]);

  useEffect(() => {
    return () => {
      dispatch(ActionCustomEvent.Clear());
      document.removeEventListener('mouseup', mouseup, true);
    };
  }, []);

  useEffect(() => {
    setHasMore(false);
    if (lastStep?.updatePageDataId) {
      dispatch(
        actionCreator(ContentActionType.updateSpecificElementS, {
          updatePageDataId: lastStep?.updatePageDataId,
        }),
      );
      history.push(history?.location?.pathname);
    }
    const params = { libFilter, libFilterSearch };
    const { calcSelectorType, calcActiveNavSlider } = calculateSelectorAndSlider(
      activeNavSlider,
      selectorType,
      params,
      isEdit,
      ignoreSelectorType,
    );
    const sortName = sortOptionChooser(calcActiveNavSlider, calcSelectorType);
    const exSortType = sortOptions[sortName] || previousSortType;
    dispatch(
      actionCreator(
        ContentActionType.startUpload,
        {
          activeNavSlider: calcActiveNavSlider,
          selectorType: calcSelectorType,
          sortType: exSortType,
          isNeedUpdate: true,
        },
        'LibraryNew',
      ),
    );
  }, [selectorType, sortType, activeNavSlider, libFilter, libFilterSearch]);

  useEffect(() => {
    if (!isSimplifiedMultiselect && !Object.values(selectedPage || {}).length) dispatch(actionRemoveAllSelected());
  }, [isSimplifiedMultiselect]);

  useEffect(() => {
    if (!counterSelectedPage) {
      setSimplifiedMultiselect(false);
    }
  }, [counterSelectedPage]);

  useEffect(() => {
    setCounterSelectedPage(
      Object.values(selectedPage || {}).reduce(
        (acc, i) => acc + (typeof i === 'boolean' ? 1 : Object.values(i || {}).length),
        0,
      ),
    );
  }, [selectedPage]);

  useEffect(() => {
    return () => dispatch(actionRemoveAllSelected());
  }, [folderId, filter, libFilter, selectorType]);

  useEffect(() => {
    if (smartViewForEdit?.filter) {
      setLibFilter(smartViewForEdit.filter);
    }
    if (smartViewForEdit?.libFilterSearch) {
      setLibFilterSearch(smartViewForEdit.libFilterSearch);
    }
  }, [smartViewForEdit]);

  const resetSmartview = useCallback((status) => {
    if (status !== 'switchingFolder') {
      dispatch(actionChangeSieveValue('[LibComponent]Pages', 'library'));
    }
    setSmartViewForEdit({});
    setShowNewSmartView(false);
  }, [dispatch]);

  if (isDownload) {
    return (
      <div className="loader-wrapper-page">
        <div className="loader">{t('loadingT')}</div>
      </div>
    );
  }

  return (
    <div
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      className={`${styles.wrapper}
        ${isDescriptionOpened ? styles.minimize : ''}
        `}
    >
      {selectorType !== 'board' && (
        <>
          {!isUsers && (
          <MainNavSlider
            sortType={sortType}
            setSort={setSort}
            setShowNewSmartView={setShowNewSmartView}
            setSmartViewForEdit={setSmartViewForEdit}
            resetSmartview={resetSmartview}
          />
          )}
          {isUsers && (
          <ContactNavSlider
            sortType={sortType}
            setSort={setSort}
            setShowNewSmartView={setShowNewSmartView}
            setSmartViewForEdit={setSmartViewForEdit}
            resetSmartview={resetSmartview}
          />
          )}
          {showNewSmartView && (
            <SmartViewTagMenu
              setIgnoreSelectorType={setIgnoreSelectorType}
              isEdit={isEdit}
              exShowIt={showNewSmartView}
              setShowNewSmartView={setShowNewSmartView}
              setIsEdit={setIsEdit}
              libFilterSearch={libFilterSearch}
              setLibFilterSearch={setLibFilterSearch}
              libFilter={libFilter}
              dynamicCollectionId={selectorType}
              setLibFilter={setLibFilter}
              smartViewForEdit={smartViewForEdit}
              setSmartViewForEdit={setSmartViewForEdit}
            />
          )}
          <div
            ref={scrollParentRef}
            className={`${styles.wrapper2} ${
              isSidebarOpened ? styles.isSidebarOpened : ''
            }`}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={next}
              useWindow={false}
              hasMore={hasMore}
              loader={<div key={0}>{t('loadingT')}</div>}
              getScrollParent={() => scrollParentRef.current}
            >
              <div
                ref={tableInsertRef}
                className={`${
                  isCardView ? styles.cards_content : styles.rows_content
                }
                  ${componentDescription ? styles.isSideBar : ''}
                  ${activeNavSlider === 'smartfiles' ? styles.playlists : ''}
                `}
              >
                <LibraryContentSelector
                  sortedPages={test}
                  isCardsView={isCardView}
                  setSimplifiedMultiselect={setSimplifiedMultiselect}
                  isSimplifiedMultiselect={isSimplifiedMultiselect}
                  needToDelete={needToDelete}
                  selectorType={selectorType}
                  activeNavSlider={activeNavSlider}
                  setSort={setSort}
                  sortType={previousSortType}
                  tableSchema={LibraryTableSchema}
                  createPage={createNewPage}
                  installationIndex={installationIndex}
                  isTableIsVisible={isTableIsVisible}
                  setTableIsVisible={setTableIsVisible}
                  showNewSmartView={showNewSmartView}
                  counterSelectedPage={counterSelectedPage}
                />
              </div>
            </InfiniteScroll>
          </div>

          <div className={styles.multiSelectPanelWrapper}>
            {counterSelectedPage >= 1 && (
              <MultiSelectPanel
                isFavorite={selectorType === 'favorites'}
                setNeedToDelete={setNeedToDelete}
                counterSelectedPage={counterSelectedPage}
                contentType={
                  activeNavSlider !== 'smartfiles' ? 'library' : 'playlist'
                }
              />
            )}
          </div>
        </>
      )}

    </div>
  );
};

export default LibraryNew;
