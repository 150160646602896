import React from 'react';

import { ReactComponent as AdminIcon } from '../images/2023/svg/small/channel-role-icons/admin_icon_16_17.svg';
import { ReactComponent as CoAdminIcon } from '../images/2023/svg/small/channel-role-icons/co-admin_16_17.svg';
import { ReactComponent as ViewerIcon } from '../images/2023/svg/small/channel-role-icons/viewer_16_17.svg';
import { ReactComponent as ContributorIcon } from '../images/2023/svg/small/channel-role-icons/contributor_16_17.svg';
import './permissions.scss';

export const IconRole = {
  Viewer: <ViewerIcon /> as React.ReactElement,
  viewer: <ViewerIcon /> as React.ReactElement,
  admin: <AdminIcon /> as React.ReactElement,
  co_admin: <CoAdminIcon /> as React.ReactElement,
  contributor: <ContributorIcon /> as React.ReactElement,
} as const;

export const CHANNEL_PERMISSIONS = {
  co_admin: {
    canView: true,
    canPostContent: true,
    canManageMembers: true,
    canChangeRolesTo: {
      Viewer: true,
      contributor: true,
      co_admin: true,
    },
    canEditChannel: false,
    canManageContent: true,
    whichContentCanRemove: {
      contributor: true,
      co_admin: true,
    },
  },
  admin: {
    canView: true,
    canPostContent: true,
    canManageMembers: true,
    canChangeRolesTo: {
      Viewer: true,
      contributor: true,
      co_admin: true,
    },
    canEditChannel: true,
    canManageContent: true,
    whichContentCanRemove: {
      contributor: true,
      co_admin: true,
      admin: true,
    },
  },
  Viewer: {
    canView: true,
    canPostContent: false,
    canManageMembers: false,
    canChangeRolesTo: {},
    canEditChannel: false,
    canManageContent: false,
    whichContentCanRemove: {},
  },
  viewer: {
    canView: true,
    canPostContent: false,
    canManageMembers: false,
    canChangeRolesTo: {},
    canEditChannel: false,
    canManageContent: false,
    whichContentCanRemove: {},
  },
  contributor: {
    canView: true,
    canPostContent: true,
    canManageMembers: false,
    canChangeRolesTo: {},
    canEditChannel: false,
    canManageContent: false,
    whichContentCanRemove: {},
  },
} as const;

export const HELP_PERMISSIONS = {
  admin: {
    rearrange: true,
    add_playlists: true,
  },
  viewer: {
    rearrange: true,
    add_playlists: false,
  },
} as const;

export const ROLE_CHANGER_OPTION = {
  contributor: [
    {
      key: 'member_role_viewer',
      title: 'Viewer',
      description: 'View all content',
      apiRole: 'Viewer',
    },
    {
      key: 'member_role_contributor',
      title: 'Contributor',
      description: 'Manage their own content, view all content',
      apiRole: 'contributor',
    },
    {
      key: 'member_role_coAdmin',
      title: 'Co-Admin',
      description: 'Manage members & his content, assign contributor role',
      apiRole: 'co_admin',
    },
  ],
  co_admin: [
    {
      key: 'member_role_viewer',
      title: 'Viewer',
      description: 'View all content',
      apiRole: 'Viewer',
    },
    {
      key: 'member_role_contributor',
      title: 'Contributor',
      description: 'Manage their own content, view all content',
      apiRole: 'contributor',
    },
    {
      key: 'member_role_coAdmin',
      title: 'Co-Admin',
      description: 'Manage members & his content, assign contributor role',
      apiRole: 'co_admin',
    },
  ],
  Viewer: [
    {
      key: 'member_role_viewer',
      title: 'Viewer',
      description: 'View all content',
      apiRole: 'Viewer',
    },
    {
      key: 'member_role_contributor',
      title: 'Contributor',
      description: 'Manage their own content, view all content',
      apiRole: 'contributor',
    },
    {
      key: 'member_role_coAdmin',
      title: 'Co-Admin',
      description: 'Manage members & his content, assign contributor role',
      apiRole: 'co_admin',
    },
  ],
} as const;

export const ADMIN_CHANGE_OPTIONS = [
  {
    key: 'member_role_viewer',
    title: 'Viewer',
    description: 'View all content',
    apiRole: 'Viewer',
  },
  {
    key: 'member_role_contributor',
    title: 'Contributor',
    description: 'Manage their own content, view all content',
    apiRole: 'contributor',
  },
  {
    key: 'member_role_coAdmin',
    title: 'Co-Admin',
    description: 'Manage members & his content, assign contributor role',
    apiRole: 'co_admin',
  },
] as const;

export const Roles = {
  '': 'Role',
  'All Roles': 'All Roles',
  Admin: 'Admin',
  'Co-Admin': 'Co-Admin',
  Viewer: 'Viewer',
  Contributor: 'Contributor',
} as const;

export const ShortRole = {
  Viewer: 'V',
  viewer: 'V',
  admin: 'A',
  co_admin: 'CO',
  contributor: 'C',
} as const;

export const UserRole = {
  Viewer: 'Viewer',
  viewer: 'Viewer',
  admin: 'Admin',
  co_admin: 'Co-Admin',
  contributor: 'Contributor',
} as const;

export const RawUserRoleMap = {
  Viewer: 'Viewer',
  viewer: 'viewer',
  Admin: 'admin',
  'Co-Admin': 'co_admin',
  Contributor: 'contributor',
} as const;

export const Statuses = {
  '': 'Status',
  'All Statuses': 'All Statuses',
  Invited: 'Invited',
  'On Hold': 'On Hold',
  Active: 'Active',
  Blocked: 'Blocked',
  Created: 'Created',
} as const;

export const RolesForPlaylist = {
  owner: 'owner',
  coEdit: 'coEdit',
  viewer: 'viewer',
} as const;

export const isRoleInPlaylist = {
  isOwner: { owner: true },
  isCoEdit: { coEdit: true },
  isViewer: { viewer: true },
} as const;
