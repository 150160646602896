import styled from 'styled-components/macro';
import { css } from 'styled-components';

export const ImageWrapper = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 8px;
  overflow: hidden;
  position:relative;
  z-index: 1;

  #templateSvg {
    z-index: 5;
    position: absolute;
    top: 1px;
    width: 138px;
    height: 138px;
    left: 1px;
  }
`;

export const SmartfileItemsCounter = styled.div`
  display: flex;
  height: 22px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #FFF;
  font-family: RobotoFlex, sans-serif;
  color: #939393;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  left: 8px;
  top: 110px;
  z-index: 3;
`;

export const Bubble = styled.div`
  width: 36px;
  height: 18px;
  left: 52px;
  bottom: 8px;
  background: #FB4633;
  border-radius: 10px ;
  color: white;
  font-size: 11px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;`;
export const BubbleUpdate = styled.div`
  width: 53px;
  height: 18px;
  left: 44px;
  bottom: 9px;
  background: #FF8A00;
  border-radius: 10px ;
  color: white;
  font-size: 11px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;`;
export const BubbleInRow = styled.div`
  width: 36px !important;
  height: 18px !important;
  left: 50px;
  bottom: 10px;
  flex: none;
  background: #FB4633;
  border-radius: 10px ;
  color: white;
  font-size: 11px;
  //position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;`;
export const BubbleUpdateInRow = styled.div`
  width: 53px !important;
  height: 18px !important;
  left: 45px;
  bottom: 10px;
  flex: none;
  background: #FF8A00;
  border-radius: 10px ;
  color: white;
  font-size: 11px;
  //position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;`;

const highLightCardStyles = css`
  border: 1.5px solid rgba(255, 199, 44, 1);
  filter: drop-shadow(0px 2px 4px rgba(120, 120, 120, 0.25));
  .card-overlay-wrapper {
    display: block;
  }

  .avatar_border_hover {
    border: 1px solid white;
  }
  ${ImageWrapper} {
    #templateSvg {
      fill: #FFC72C;
      stroke: #FFC72C;

      path {
        fill: #FFEEBF;
      }
    }
  }

  ${SmartfileItemsCounter} {
    display: none;
  }
  ${Bubble} {
    display: none;
  }
  ${BubbleUpdate} {
    display: none;
  }`;

export const CardViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 140px;
  width: 142px;
  border-radius: 9px;
  z-index: 4;
  border: 1.5px solid transparent;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  ${({ hoveredByMeta }) => (hoveredByMeta
    ? `
      border: 1.5px solid #FFC72C;
      box-shadow: 1px 1px 10px rgba(122, 105, 105, 0.15);
  `
    : '')}
  .avatar_border_hover {
    border: 1px solid rgba(255, 255, 255, 0);
  }
  &:hover {
    ${highLightCardStyles}
    ${({ isHide }) => (isHide ? 'border: 1.5px solid #ECE2E2;' : '')}
    ${({ isDescriptionOpen }) => (isDescriptionOpen ? '' : 'background-color:rgba(255, 238, 191, 1);')}
  }
   ${({ isFrontLayer }) => (isFrontLayer
    ? `
      z-index: 6;
    ` : ''
  )}
  ${({ isDragging }) => (isDragging
    ? `
      opacity: 0.5;
    ` : ''
  )}
  ${({ selected }) => (selected ? highLightCardStyles : '')}
  ${({ isFrontLayer }) => (isFrontLayer ? ' z-index: 6; ' : '')}
`;

export const PlaylistGap = styled.div`  
  position: relative;
`;

export const FavouriteIconWrapper = styled.div`
  display: block;
  position: absolute;
  bottom: 8px;
  right: 13px;
  z-index: 2;

  & svg path {
    stroke: #fff;
    fill: rgba(255, 199, 44, 1);
  }
`;

export const TitleWrapper = styled.p`
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  line-height: 18px;
  letter-spacing: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 140px;
  text-align: center;
  text-overflow: ellipsis;
  height: 36px;
`;

export const SharedBadgeWrapper = styled.div`
  width: 140px;
  z-index: 3;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 16px;
`;

export const SharedBadge = styled.div`
  letter-spacing: 0.05em;
  //position: absolute;
  width: fit-content;
  padding: 0 6px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #429df8;
  border-radius: 4px;
  color: white;
  //left: 85px;
  //top: 16px;
  font-weight: 500;
  font-size: 12px;
`;

export const OverlayWrapper = styled.div`
  position: absolute;
  height: 138px;
  width: 140px;
  border-radius: 8px;
  z-index: 2;
  display: none;
  ${({ isSelected }) => (isSelected
    ? `
  background:rgba(255, 238, 191, 0.8);
  border: 0 solid #FFC72C;
  display:block;
  `
    : '')}
  ${({ isHide }) => (isHide
    ? `
      background: rgba(255, 255, 255, 0.8);
      display:block;

      :hover {
        #card-eye {
          background: #fff;
          opacity: 1;

          svg path {
            stroke: #484343;
          }
        }
      }
    `
    : '')}
     :hover {
        #card-eye {
          display: flex;
        }
      }
`;

export const SvgIcoWrapper = styled.div`
  position: absolute;
  background: rgba(27, 35, 54, 0.1);
  width: 22px;
  height: 22px;
  border-radius: 4px;
  z-index: 2;
  & svg {
    display: block;
    margin: auto;
  }

  & svg path {
    stroke: #ffffff;
  }

  &:hover {
    background: rgba(27, 35, 54, 0.25);
    opacity: 1;
  }
  ${({ isSelector }) => (isSelector
    ? `
    top: 10px;
    left: 8px;
    padding-top: 3px;
  `
    : '')}
  ${({ isSelected }) => (isSelected
    ? `
  background: rgba(255, 199, 44, 1);

  & svg path{
    stroke:rgba(52, 52, 52, 1);
  }
    &:hover {
    background: rgba(255, 199, 44, 1);
  }
  `
    : '')}
  ${({ is3Dots }) => (is3Dots
    ? `
    top: 10px;
    right: 8px;
    padding-top:9px;
    `
    : '')}
  ${({ active, is3Dots }) => (active && is3Dots
    ? `
  background:rgba(255, 199, 44, 1);
    & svg path{
        stroke:#343434;
  }
      &:hover {
   background:rgba(255, 199, 44, 1);
  }
  `
    : '')}
  ${({ isFavourite }) => (isFavourite
    ? `
    left: 107.5px;
    top: 108.5px;
    padding-top: 3px;

    & svg path {
    fill: none;
  }
  `
    : '')}
  ${({ active, isFavourite }) => (active && isFavourite
    ? `
  background:none;
    & svg path{
        stroke:none;
        fill: rgba(255, 199, 44, 1);
  }
      &:hover {
    background: rgba(27,35,54,0.25);
       & svg path{
        stroke:#FFC72C;
        fill:rgba(255, 199, 44, 0.6);
  }
  }
  `
    : '')}

  ${({ isEye }) => (isEye
    ? `
    left: 6px;
    top: 110px;
    padding-top: 3px;
    align-items: center;
    display: none;

    svg {
      margin: 0 auto 2px;
    }
  `
    : '')}
  ${({ isHide }) => (isHide
    ? `
    opacity: 0.4;
    padding: 0;
    &:hover {
      background: rgba(52, 52, 52, 1);
      opacity: 1;
    }
  `
    : '')}
`;

export const MainWrapper = styled.div`
  position: absolute;
  height: fit-content;
  width: 181px;
  border-radius: 12px;
  padding-bottom: 10px;
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ece2e2, #ece2e2);
  box-shadow: 1px 1px 6px 0 rgba(122, 105, 105, 0.25);
  border: 0.6px solid rgba(236, 226, 226, 1);
  right: -141px;
  top: 47px;
  z-index: 100;
  ${({ isTableView }) => (isTableView
    ? `
      right: 26px;
      top: 44px;
  `
    : '')}
  ${({ isPageMiniPage }) => (isPageMiniPage
    ? `
      right: -8px;
      top: 29px;
      width:195px;
  `
    : '')}
  ${({ isLib }) => (isLib ? 'height:351px;' : '')}
  ${({ isContacts }) => (isContacts
    ? `  
  width: 195px;
  right: 26px;
  top: 44px;
  `
    : '')}
  ${({ position }) => position}
`;

export const OptionsWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  /* or 150% */
  font-family: RobotoFlex, sans-serif;
  padding-left: 16px;
  height: 30px;
  //line-height: 30px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #484343;
  cursor: pointer;
  display: block;
  position: relative;

  ${({ isLast }) => (isLast ? 'color:#D32F2F;' : '')}
  &:hover {
    background: #ffeebf;

    .hide_me {
      div:first-child {
        visibility: visible;
      }
    }
  }

  ${({ isFirst }) => (isFirst
    ? `
  font-size: 12px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color:#D3C8C8;
  margin-top: 6px;
  cursor: default;
  &:hover{
    background:none;
    }
`
    : '')}
  ${({ disabled }) => (disabled
    ? `
  color:#D3C8C8;
  cursor: not-allowed;
  svg path{
    stroke:#D3C8C8;
  }
  `
    : '')}
  svg {
    display: block;
    position: absolute;
    top: 10px;
    right: 15px;
  }
  & .hidePlaylistSelector {
    display: none;
  }
  &:hover .hidePlaylistSelector {
    display: block;
  }
`;

export const Divider = styled.div`
  width: 149px;
  height: 1px;
  background-color: #1d1d1d14;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 16px;
`;

export const Info = styled.div`
  display: flex;
  //padding: 16px;
`;

export const AvatarContainer = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 8px;
`;

export const Username = styled.div`
  font-family: RobotoFlex, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0;
  text-align: center;
  color: rgba(147, 147, 147, 1);
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PlayWrapper = styled.div`
  margin-left: 78px;
  margin-top: 79px;
`;
