import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { actionCreator } from '../../shared/redux/actionHelper';
import EditPlaylist from '../../redux/playlists/types';
import { actionCreateUpload } from '../../redux/filesUpload/action';
import { actionEditImagePlaylistREDUX, actionChangeDefaultColorPlaylist } from '../../redux/playlists/action';
import { maxFileSize } from '../../GLOBAL_CONST';
import useComponentVisible from '../../utils/hooks/useComponentVisible';
import { empty, allowedFormatsImage, openModalType } from '../../utils/constants';
import { actionOpenModal } from '../../redux/user/action';
import UniversalPlaylistImageUI from './MakerCommon/UiComponents/UniversalPlaylistImageUI';
import mimeTypes from '../../utils/mimeTypes';
import { isRoleInPlaylist } from '../../utils/permissions';
import { ReactComponent as HoverIconSvg } from '../../images/2023/svg/hover_pencil.svg';
import DropDownCover from './cover/DropDown';
import FolderColorPicker from './FolderColorPicker';
import FolderImage from './FolderImage';
import FolderIconLayout from './FolderIconLayout';

const { isOwner } = isRoleInPlaylist;

const PreviewSelectorWrap = styled.div`
  width: 140px;
  height: 140px;

  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;

  &:hover {
    background: #ffeebf;
  }
  ${({ canDrop, isDrag, isDragFromDevice }) => ((canDrop || isDragFromDevice) && isDrag ? 'background: #FFEEBF;' : '')}
`;

const HoverWrapper = styled.div`
  width: 100%;
  height: 101%;
  border-radius: 6px;
  border: 1px solid #FFC72C;
  transition: all ease-in-out 0.2s;
  background: rgba(255, 238, 191, 0.95);
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;

  z-index: 4;
  opacity: 0;
`;

const ImagesWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${HoverWrapper} {
    ${({ isDrag }) => (isDrag
    ? `
      opacity: 1;
    ` : ''
  )}
  }
  :hover ${HoverWrapper} {
    opacity: 1;
  }
`;

const PreviewHovererHandler = styled.div`
  position: relative;

  ${({ isNoImage }) => (isNoImage
    ? ''
    : `
  width: 100%;
  height: 100%;
  margin: -1px;
  border-radius: 8px;
  background: white;
  &:hover{
    background: #FFEEBF;
    border: 1px solid #FFC72C;
  }
    box-sizing: content-box;

  `)}
`;
const PreviewSelectorShell = styled.div`
  width: 140px;
  min-width: 140px;
  height: 140px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 2px rgba(120, 120, 120, 0.25);
  background: transparent;
  ${({ active }) => (active ? '' : '')}
  margin: 10px 20px;
  position: relative;
  margin-left: 0;
  margin-top: 31px;
  cursor: pointer;
  //border: 1px solid #F1F1F0;

  &:hover {
    background: #ffeebf;
    //border: 1px solid #FFC72C;
    ${PreviewSelectorWrap} {
      background: #ffeebf;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 140px;
  min-width: 140px;
  height: 140px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.15);
  background: #ffffff;
  margin: 10px 20px;
  position: relative;
  margin-left: 0;
  margin-top: 31px;

  svg {
    width: 100%;
    position: absolute;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(120, 120, 120, 0.25);
`;

const SpinnerWrap = styled.div`
  position: absolute;
  width: 130px;
  height: 130px;
  top: 24px;
  overflow: hidden;
`;

const PreviewSelector = () => {
  // const [refList, isListActive, setIsListActive] = useComponentVisible(false, data);
  const dataParentDropDown = 'dataParentDropDown';

  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { idDragPage, type, cropUrlIsLoad } = useSelector(
    (state) => state.currentPage,
  );
  const contentData = useSelector((state) => state.content.contentData);
  const isImage = mimeTypes.image[type];
  const canDrop = idDragPage && isImage;
  const [ref, isComponentVisible, setIsComponentVisible] = useComponentVisible(
    false,
    dataParentDropDown,
  );
  const parentRef = useRef(null);
  const [uploadId, setUploadId] = useState('');
  const [isDrag, setDrag] = useState(false);
  const [isDragFromDevice, setDragFromDevice] = useState(false);
  const [isImageUploading, setImageUploading] = useState(false);
  const [dragTarget, setDragTarget] = useState(false);
  const [position, setPosition] = useState({});
  const dropDepthRef = useRef(0);

  const { id, img, cropUrl, currentRole, defaultPreviewColor } = useSelector((state) => state.currentPage);

  const setCurrentColor = useCallback((color) => {
    dispatch(actionChangeDefaultColorPlaylist(color, id));
  }, [dispatch, id]);

  const onDragLeave = (e) => {
    dropDepthRef.current -= 1;
    if (dropDepthRef.current > 0) return;
    e.stopPropagation();
    e.preventDefault();
    setDrag(false);
    setDragFromDevice(false);
  };

  const openHandler = (e) => {
    setIsComponentVisible(true);
    const calcPosition = {
      top: -parentRef.current?.getBoundingClientRect().y + e.clientY - 10,
      left: -parentRef.current?.getBoundingClientRect().x + e.clientX - 10,
    };
    setPosition(calcPosition);
  };

  const onDragEnter = (e) => {
    setDragTarget(e.target);
    e.stopPropagation();
    e.preventDefault();
    dropDepthRef.current += 1;
    if (e.dataTransfer.types.filter((i) => i === 'Files').length) {
      setDragFromDevice(true);
    }
    setDrag(true);
  };

  const onDrop = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setImageUploading(true);
    setDrag(false);
    const files = Array.from(e.dataTransfer?.files);
    if (files.length > 0) {
      setDragFromDevice(false);
      const firstFile = files[0];
      const newId = uuidv4();

      if (firstFile.size < maxFileSize.bytes && allowedFormatsImage[firstFile.type]) {
        dispatch(
          actionCreateUpload({
            newId,
            file: firstFile,
            title: firstFile.name,
            type: firstFile.type,
            callback: (url) => {
              actionEditImagePlaylistREDUX(newId, null, url);
              setImageUploading(false);
            },
            history,
          }),
        );
        setUploadId(newId);
      }
    }

    if (!canDrop) return;
    const item = contentData[idDragPage];
    dispatch(
      actionOpenModal(openModalType.CropPlaylistCover, {
        componentId: item.id,
        imagePath: item.urlFile,
      }),
    );
    setImageUploading(false);
  };
  const uploadData = useSelector((state) => state.uploads.components[uploadId]) || empty;

  const clearCoverByPickColor = useCallback(() => {
    if (img?.src || cropUrl) {
      dispatch(actionCreator(EditPlaylist.MakerDeleteImage));
    }
  }, [cropUrl, dispatch, img?.src]);


  useEffect(() => {
    if (uploadId && uploadData?.imagePath) {
      dispatch(
        actionOpenModal(openModalType.CropPlaylistCover, {
          ...uploadData,
          componentId: uploadData.newId,
        }),
      );
    }
  }, [dispatch, uploadData, uploadId]);

  if (!isOwner[currentRole]) {
    return (
      <ImageWrapper>
        {img?.urlFile || cropUrl ? (
          <>
            <Image src={cropUrl || img?.urlFile} />
            <FolderIconLayout isOverCoverImage />
          </>
        ) : (
          <FolderImage itemId={id} currentColor={defaultPreviewColor} isMaker />
        )}
      </ImageWrapper>
    );
  }

  return (
    <PreviewSelectorShell ref={parentRef} onClick={openHandler}>
      <PreviewHovererHandler isNoImage={!(!!img?.src || cropUrl)}>
        <ImagesWrapper
          onDrop={onDrop}
          onDragLeave={onDragLeave}
          onDragEnter={onDragEnter}
          canDrop={canDrop}
          isDrag={isDrag}
          isDragFromDevice={isDragFromDevice}
          ref={ref}
        >
          <HoverWrapper>
            <HoverIconSvg />
          </HoverWrapper>
          {(!!img?.src || cropUrl) && (
            <UniversalPlaylistImageUI img={{ urlFile: cropUrl } || img} newUPV />
          )}
          {!(!!img?.src || cropUrl) && isOwner[currentRole] && (
            <PreviewSelectorWrap>
              <FolderImage isMaker itemId={id} currentColor={defaultPreviewColor} />
            </PreviewSelectorWrap>
          )}
        </ImagesWrapper>
        {isOwner && isComponentVisible && (
          <DropDownCover
            position={position}
            setUploadId={setUploadId}
            setImageUploading={setImageUploading}
            dataParentDropDown={dataParentDropDown}
            nestedRef={ref}
            setIsComponentVisible={setIsComponentVisible}
            setCurrentColor={setCurrentColor}
          />
        )}
      </PreviewHovererHandler>
      {(cropUrlIsLoad || isImageUploading) && (
        <SpinnerWrap>
          <div className="loader_mini">{t('loadingT')}</div>
        </SpinnerWrap>
      )}
      <FolderColorPicker
        isCheckDisplayed={!(img?.src || cropUrl)}
        activeColor={defaultPreviewColor ?? 'yellow'}
        setCurrentColor={setCurrentColor}
        clearCoverByPickColor={clearCoverByPickColor}
      />
    </PreviewSelectorShell>
  );
};

export default PreviewSelector;
