import React from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Chevron } from '../../images/2023/svg/small/upv/chevron_10_5.svg';
import { ReactComponent as Checked } from '../../images/2023/svg/small/upv/checked_bird_16_15_thin.svg';
import { DATA_PARENTS } from '../../utils/constants';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

const SelectorDropDownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  display: none;
  position: relative;
  z-index: 1000;
  
  @media (max-width: 1430px) {
      display: block;
  }
  ${({ isDescriptionOpened }) => (isDescriptionOpened
    ? `
 @media (max-width: 1550px) {
      display: block;
  }`
    : '')};
`;

const CurrentChoiceWrapper = styled.div`
  background: #ffc72c;
  border-radius: 6px;
  height: 32px;
  padding-left: 10px;
  padding-top: 2px;
  padding-right: 28px;
  width: max-content;
  line-height: 28px;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;

  svg {
    position: absolute;
    right: 8px;
    top: 12px;
  }

  & svg {
    transition: transform;
    transition-duration: 0.4s;
    ${({ isOpened }) => (!isOpened ? ' transform: rotate(-90deg);' : ' transform: rotate(0deg);')}
  }

  ${({ withClose }) => (withClose
    ? `
  svg{
    right: 3px;
    top: 4px;
  }
  padding-right:32px;`
    : '')}
`;

const UPVLibSelectorDropDownWrapper = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #484343;
  position: relative;
`;

const DropDownWrapper = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: 40px;
  padding: 14px 0;
  z-index: 42;
  width: 160px;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  letter-spacing: 0.05em;
`;

const DropDownItem = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 30px;
  line-height: 34px;
  letter-spacing: 0.1px;
  color: #484343;
  padding-left: 15px;
  text-transform: capitalize;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  ${({ disabled }) => (disabled
    ? `
        color: #585858;
        opacity: 0.5;
        cursor:not-allowed;
   `
    : `
     &:hover {
        background-color: #FFEEBF;
        cursor: pointer;
    }
   `)}
`;

const DDTitleWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 12px;

  text-transform: uppercase;
  color: #d3c8c8;
  margin-left: 15px;
  margin-bottom: 7px;
`;


const SelectorDropDown = ({
  setSelector,
  dataP,
  selector,
  itemsList,
  calcCount,
  isChannels,
  mapTitles,
}) => {
  const { t } = useTranslation();
  const [refOpen, isOpened, setIsOpened] = useComponentVisible(
    false,
    DATA_PARENTS.newLibSideBarDropDown,
  );
  const isDescriptionOpened = useSelector(
    (state) => state.support.componentDescription,
  );
  const toggleDropDown = () => setIsOpened(!isOpened);

  const chooseSelector = (nav) => {
    setSelector(nav);
    setIsOpened(false);
  };

  const calculateDisplayedSelector = (itemType) => {
    if (itemType === 'shared') return t('sharedToMeT');
    if (itemType === 'sharedByMe') return t('sharedT');
    if (itemType === 'recently_viewed') return t('recentlyViewedT');
    return (t(`${itemType?.toLowerCase()}T`) || itemType).toUpperCase();
  };

  return (
    <SelectorDropDownWrapper isDescriptionOpened={isDescriptionOpened} data-parent={dataP}>
      <UPVLibSelectorDropDownWrapper ref={refOpen} data-parent={dataP}>
        <CurrentChoiceWrapper
          onClick={toggleDropDown}
          isOpened={isOpened}
          withClose={false}
          data-parent={dataP}
        >
          {isChannels ? mapTitles(selector) : calculateDisplayedSelector(selector)}{' '}
          {calcCount(selector)}
          <Chevron data-parent={dataP} />
        </CurrentChoiceWrapper>
        {isOpened && (
          <DropDownWrapper
            data-parent={`${dataP} miniPage ${DATA_PARENTS.newLibSideBarDropDown}`}
          >
            <DDTitleWrapper>{t('categoriesT')}</DDTitleWrapper>
            {(isChannels ? itemsList : itemsList.selectorsOrder).map((elem) => (
              <React.Fragment key={elem}>
                <DropDownItem
                  name={elem}
                  onClick={() => chooseSelector(elem)}
                  data-parent={`${dataP} miniPage ${DATA_PARENTS.newLibSideBarDropDown}`}
                >
                  {isChannels ? mapTitles(elem) : calculateDisplayedSelector(elem)}
                  {selector.toLowerCase() === elem.toLowerCase() && <Checked />}
                </DropDownItem>
              </React.Fragment>
            ))}
          </DropDownWrapper>
        )}
      </UPVLibSelectorDropDownWrapper>
    </SelectorDropDownWrapper>
  );
};

export default SelectorDropDown;
