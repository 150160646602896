import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { actionCloseModal } from '../../redux/user/action';
import Button2023 from '../Buttons/Button2023';
import { actionShowMessage } from '../../redux/support/action';
import {
  DEFAULT_POSITION_STEP, DEFAULT_TITLE,
  MessageType,
  stopPropagation,
  UploadSieveTypesForModal2023,
} from '../../utils/constants';
import { ReactComponent as Upload40Svg } from '../../images/icons/upload_40.svg';
import NavSlider from '../NavigationSlider/NavSlider';
import AddGoogleFile from '../LibraryHeaderMenu/childComponents/AddGoogleFile';
import AddDropboxFile from '../LibraryHeaderMenu/childComponents/AddDropboxFile';
import rawStyles from '../../pages/Maker/MakerCommon/elements.module.scss';
import filterFilesByMaxSize from '../../utils/validators/filterFilesByMaxSize';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import { LibraryComponents } from '../../redux/library/types';
import { actionCheckLinkForLibraryComponent } from '../../redux/library/actions';
import {
  actionCreateAndUploadLinkPage,
  actionRemoveLinkPageFromPlaylist,
} from '../../redux/currentPage/action';
import EditPlaylist from '../../redux/playlists/types';

const cn = classNames.bind(rawStyles);

const UploadModalWrapper = styled.div`
  position: fixed;
  background: rgba(147, 147, 147, 0.6);
  z-index: 104;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ModalWindow = styled.div`
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #ece2e2, #ece2e2);
  display: flex;
  flex-direction: column;
  padding: 14px;
  min-width: 513px;
  background: #ffffff;
  border: 0.6px solid #ece2e2;
  box-shadow: 1px 1px 6px rgba(122, 105, 105, 0.25);
  border-radius: 12px;
  ${({ noRightPadding }) => (noRightPadding ? 'padding-right:0px;' : '')}
`;

const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #d3c8c8;
  letter-spacing: 0.05em;
  margin-bottom: 14px;
  font-family: "RobotoFlex", sans-serif;
`;

const DnDBlockWrapper = styled.div`
  width: 481px;
  height: 100px;
  background: rgba(255, 199, 44, 0.15);
  border-radius: 12px;
  font-family: "RobotoFlex";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  position: relative;
  white-space: pre;
  margin-top: 15px;
  color: #585858;

  svg {
    position: relative;
    top: 34px;
    left: 44px;
    width: 32px;
    height: 32px;
  }
`;
const TextInDDWrapper = styled.div`
  width: max-content;
  left: 88px;
  position: relative;
  top: 6px;
`;
const BTNInDDWrapper = styled.div`
  width: max-content;
  left: 324px;
  position: relative;
  top: -19px;
`;
const StyledInputForFiles = styled.input`
  display: none;
`;
const EmbedSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  //width: 432px;
  padding-top: 7px;
`;
const EmbedInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const EmbedInput = styled.input`
  padding-left: 8px;
  border: 1px solid #ece2e2;
  width: 420px;
  border-radius: 6px;
  color: #484343;
  height: 32px;
  font-family: RobotoFlex, sen-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  &:focus {
    border: 1px solid #ffc72c;
  }
  &::placeholder {
    color: #7a696980;
  }
`;
const UploadModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputFile = useRef(null);
  const { t } = useTranslation();

  const [urlText, setUrl] = useState('');
  // const userId = useSelector(state => state.user.id);
  const { item = { position: 0 }, index: itemIndex = 0 } = useSelector((it) => it.user.dataPayload);
  const { id: playlistId, linkPages: exLinkPages } = useSelector(
    (state) => state.currentPage,
  );
  const itemID = item.id;
  const itemPosition = item.position;
  const [activeSlider, setActiveSlider] = useState(
    UploadSieveTypesForModal2023.Computer,
  );

  const nextItemPosition = exLinkPages[itemIndex + 1]?.position;

  const setSieve = (value) => {
    setActiveSlider(value);
  };
  const close = () => {
    dispatch(actionCloseModal());
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleError = () => {
    dispatch(
      actionShowMessage({
        type: MessageType.InvalidLink,
      }),
    );
  };

  const changeUrl = (e) => {
    setUrl(e.target.value);
  };

  const onCreateButtonClick = () => {
    if (!urlText) {
      handleError();
      return;
    }

    const newId = uuidv4();
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        itemID,
        newId,
      ),
    );

    if (
      urlText
      && urlText.startsWith('<iframe')
      && urlText.endsWith('</iframe>')
    ) {
      dispatch(
        actionCreator(LibraryComponents.CreateEmbedLibraryComponent, {
          iframeText: urlText,
          newId,
          history,
        }),
      );

      dispatch(
        actionCreator(EditPlaylist.AddPageToPlaylist, {
          playlistId,
          currentPageId: newId,
          history,
          linkPageId: newId,
          isNoNotifications: true,
          dataFromMaker: {
            isNew: true,
            linkUrl: urlText,
            customPosition: exLinkPages[itemIndex]?.position,
          },
        }),
      );
      close();
    } else {
      dispatch(
        actionCreator(CurrentPage.CreateLinkPageWithURL, {
          history,
          newId,
          urlText,
          newPosition: exLinkPages[itemIndex]?.position,
        }),
      );
      dispatch(actionCheckLinkForLibraryComponent(urlText, close, handleError));
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = Array.from(e.target.files);

    const filteredFilesArr = filterFilesByMaxSize([...e.target.files]);
    if (files.length !== filteredFilesArr.length) {
      dispatch(
        actionShowMessage({
          type: MessageType.CannotBeUploaded,
          itemName: t('defaultSmartPageTitleT'),
        }),
      );
    }

    const firstFile = filteredFilesArr[0];

    if (!firstFile) return;

    dispatch(
      actionCreateAndUploadLinkPage(
        firstFile,
        itemIndex,
        history,
        itemPosition,
      ),
    );

    if (filteredFilesArr.length > 1) {
      const availablePositions = {
        start: item.position,
        end: exLinkPages[itemIndex + 1]?.position,
      };
      const availablePositionRange = availablePositions.end - availablePositions.start;
      Array.from(filteredFilesArr).forEach((file, fileIndex) => {
        if (!fileIndex) return;

        const newPosition = availablePositions.end
          ? item.position + fileIndex * (availablePositionRange / filteredFilesArr.length)
          : item.position + (fileIndex + DEFAULT_POSITION_STEP);
        dispatch(
          actionCreateAndUploadLinkPage(
            file,
            fileIndex,
            history,
            newPosition,
            null,
            true,
          ),
        );
      });
    }
    dispatch(actionCreator(CurrentPage.RemoveLinkPageR, { id: itemID }));
    dispatch(
      actionRemoveLinkPageFromPlaylist(
        playlistId,
        itemID,
        item.textComponent?.id || item.libraryComponent?.id,
      ),
    );
    close();
  };

  return (
    <UploadModalWrapper onClick={close}>
      <ModalWindow
        onClick={stopPropagation}
        noRightPadding={activeSlider === UploadSieveTypesForModal2023.Computer}
      >
        <TitleWrapper>{t('uploadFileUpT')}</TitleWrapper>
        <div className={cn('main_right')}>
          <div className={cn('slider')}>
            <NavSlider
              isComputerTab={
                activeSlider === UploadSieveTypesForModal2023.Computer
              }
              activeNavSlider={activeSlider}
              setActiveNavSlider={setActiveSlider}
              usage="builderUpload"
              setSieve={setSieve}
              isNoLeftMargin
              sieveOptions={UploadSieveTypesForModal2023}
              isUploadElement
              forUploadModal
            />
          </div>
          {activeSlider === UploadSieveTypesForModal2023.Google && (
            <AddGoogleFile
              viewedTypes="DOCS_IMAGES"
              oldUploadBlockId={itemID}
              oldUploadBlockPosition={item.position}
              nextItemPosition={nextItemPosition}
              isPlaylist
            />
          )}
          {activeSlider === UploadSieveTypesForModal2023.Dropbox && (
            <AddDropboxFile
              oldUploadBlockId={itemID}
              oldUploadBlockPosition={item.position}
              isPlaylist
            />
          )}
        </div>
        {activeSlider === UploadSieveTypesForModal2023['Embed link'] && (
          <EmbedSectionWrapper>
            <EmbedInputWrapper>
              <EmbedInput type="text" placeholder={t('addFileURLPlaceholderT')} onChange={changeUrl} />
            </EmbedInputWrapper>
            <Button2023
              width={45}
              height={32}
              variant="secondary"
              text={t('addUpT')}
              noMargins
              handleButtonClick={onCreateButtonClick}
            />
          </EmbedSectionWrapper>
        )}
        {activeSlider === UploadSieveTypesForModal2023.Computer && (
          <DnDBlockWrapper>
            <Upload40Svg />
            <TextInDDWrapper>{t('dragDropToUploadFileOrT')} </TextInDDWrapper>
            <BTNInDDWrapper>
              <Button2023
                text={t('browseFileUpT')}
                variant="primary"
                width={100}
                height={32}
                handleButtonClick={onButtonClick}
              />
              <StyledInputForFiles
                onChange={submitForm}
                type="file"
                name="file"
                multiple
                ref={inputFile}
              />
            </BTNInDDWrapper>
          </DnDBlockWrapper>
        )}
      </ModalWindow>
    </UploadModalWrapper>
  );
};

export default UploadModal;
