import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CheckboxOnSvg } from '../../images/icons/checkbox_on.svg';
import { ReactComponent as CheckboxOffSvg } from '../../images/icons/checkbox_off.svg';
import { ReactComponent as MoreSvg } from '../../images/icons/more.svg';
import { DATA_PARENTS } from '../../utils/constants';
import ThreeDotsDropDown from '../MiniPlaylist/NewPlaylist2023/ThreeDotsDropDown';
import EditIcon from '../EditIcon';

import FavoriteIcon from './FavoriteIcon/FavoriteIcon';
import MiniPageUsedMenu from './UsedMenu/MiniPageUsedMenu';
import styles from './index.module.css';

const CardViewActions = React.memo(function CardViewActions({
  dataP,
  onCompositeClickHandler,
  toggleSelected,
  toggleFavorite,
  onClickShowEditMenu,
  usedRef,
  isUsedActive,
  setIsUsedActive,
  ...props
}) {
  const { selectorType } = useSelector(state => state.user);

  const isSharedToMe = props.item.type === 'sharedPage';

  const handleToggleSelected = (e) => {
    toggleSelected(e);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleToggleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    toggleFavorite(e);
  };

  const showActions = !props.isChannelMiniPage
  || (props.item.isOwnContent || props.permissions?.whichContentCanRemove[props.item.owner?.role]);

  const showEditMenu = (e) => {
    if (showActions && !props.isChannelMiniPage) {
      onClickShowEditMenu(e);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const showSelectAction = !props.isChannelMiniPage || props.permissions?.canManageContent;

  const showEditAction = props.item.type === 'page' || props.item.type === 'component';

  const showMiniPageUsedMenu = props.item.usedTimesCounter
    && !props.isMenuVisible
    && selectorType !== 'shared';

  const height = document.body.clientHeight;
  const cardPosition = props.refPage.current?.getBoundingClientRect();
  const couldBeCutBottom = cardPosition?.top && ((height - cardPosition.top) < 430);
  const couldBeCutTop = cardPosition?.top && ((cardPosition.top) < 535);

  return (
    <div
      className={`
      ${styles.actionsWrap}
      ${props.isOverlayActive ? styles.active : ''}
    `}
      onContextMenu={showEditMenu}
    >
      <div
        data-parent={dataP}
        onClick={onCompositeClickHandler}
        id="for-test"
        className={`${styles.overlay}
          ${props.bigSizeCondition ? styles.overlayedPage : ''}
        `}
      />

      {showActions && (
        <>
          <div
            data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            className={`
              ${styles.editActionsPanel}
              ${props.item.isFavorite ? '' : styles.unfavorite}
            `}
          >

            {!props.isChannelMiniPage && (
              <>
                <FavoriteIcon
                  isFavorite={props.item.isFavorite}
                  handleToggleFavorite={handleToggleFavorite}
                  bigSizeCondition={props.bigSizeCondition}
                  isHoveredAction
                />

                <div
                  data-cy="menu-library"
                  data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                  className={`${styles.itemMenu}
                    ${styles.moreOption}
                    ${props.isMenuVisible ? styles.itemMenu_active : ''}
                  `}
                  onClick={showEditMenu}
                  ref={props.refMenu}
                >
                  <MoreSvg
                    ref={props.inputEl}
                    data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                    className={styles.hover_icon}
                    alt=""
                  />
                </div>
              </>
            )}

            {showEditAction && (
              <div
                data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                className={`
                  ${props.item.type === 'page' ? styles.overlayOpenPage : styles.overlayOpenComponent}
                  ${styles.overlayOpenPage}
                `}
              >
                <EditIcon
                  id={props.item.id}
                  dataParent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                  isComponent={props.item.type === 'component'}
                  type="LibraryCard"
                />
              </div>
            )}

            {showMiniPageUsedMenu && (
              <div
                data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                className={`
                  ${props.bigSizeCondition ? styles.usedItem_page : styles.usedItem}
                  ${styles.overlayUsed}
                  ${styles.overlayUsedOffset}
                `}
              >
                <MiniPageUsedMenu
                  dataParent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                  usedRef={usedRef}
                  isUsedActive={isUsedActive}
                  setIsUsedActive={setIsUsedActive}
                  libraryComponentId={props.item.id}
                  usedIn={props.item.usedIn}
                  usedTimesCounter={props.item.usedTimesCounter}
                  isNoChannels={props.item.isNoChannels}
                />
              </div>
            )}
          </div>

          {showSelectAction && (
            <div
              className={`${styles.itemMenu}
                ${styles.checkboxItem}
                ${styles.overlayUsed}
                ${props.isSelected ? styles.active : ''}
              `}
              onClick={handleToggleSelected}
              data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
            >
              {props.isSelected ? (
                <CheckboxOnSvg
                  className={`${styles.hover_icon}`}
                  data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                />
              ) : (
                <CheckboxOffSvg
                  className={`${styles.hover_icon}`}
                  data-parent={`${DATA_PARENTS.PlaylistBuilderLibrary} miniPage`}
                />
              )}
            </div>
          )}
        </>
      )}

      {props.isMenuVisible && (
        <ThreeDotsDropDown
          singleClickHandler={props.singleClickHandler}
          isPageMiniPage
          isUPV={props.isUPV}
          setIsDropDownVisible={props.setIsMenuVisible}
          onCompositeClickHandler={onCompositeClickHandler}
          itemID={props.item.id}
          item={props.item}
          toggleFavorite={props.toggleFavorite}
          itemIsFavorite={props.item.isFavorite}
          itemType={props.item.type}
          status={props.item.status}
          refMenu={props.refMenu}
          show={props.isMenuVisible}
          isSharedToMe={isSharedToMe}
          itemWrapperID={props.item.wrapperId}
          couldBeCutBottom={couldBeCutBottom}
          couldBeCutTop={couldBeCutTop}
          isPdf={props.isPdf}
          bigSizeCondition={props.bigSizeCondition}
        />
      )}
    </div>
  );
});

export default CardViewActions;
