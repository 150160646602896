import React from 'react';

import {
  BoardColumnWrapper,
  CardBodyInfo,
  CardInfo,
  CardInfoChannel,
  CardInfoSize,
  HeaderWrapper,
  InfoBlock,
  TimeAndAvatar,
} from './styled';

const InboxHeader = () => {
  return (
    <HeaderWrapper>
      <CardInfo>
        Sender
      </CardInfo>

      <CardBodyInfo>
        Title
      </CardBodyInfo>

      <CardInfoChannel>
        Recipient(s)
      </CardInfoChannel>

      <CardInfoSize isRight>
        Size
      </CardInfoSize>

      <InfoBlock isRight>
        Availability
      </InfoBlock>

      <BoardColumnWrapper>
        Board
      </BoardColumnWrapper>

      <TimeAndAvatar>
        Received
      </TimeAndAvatar>

    </HeaderWrapper>
  );
};

export default InboxHeader;
