import { DATA_PARENTS } from './constants';
import { sanitizeToLoad } from './helpers';

const downloadStatus = {
  init: 'INIT',
  pending: 'PENDING',
  success: 'SUCCESS',
  failed: 'FAILED',
};

export default downloadStatus;

export const revertObject = (obj) => {
  // {a:"b"} to {b:"a"}

  const newObj = {};
  Object.entries(obj).forEach(([key, value]) => {
    newObj[value] = key;
  });
  return newObj;
};

export const parseUseInChannel = (inChannelState, result = {}) => {
  if (inChannelState && inChannelState.length) {
    inChannelState.forEach((item) => {
      result[item.id] = {
        ...item,
        name: sanitizeToLoad(item.name),
      };
    });
  } else if (inChannelState && inChannelState.edges) {
    inChannelState.edges.forEach((item) => {
      const { availableTo, availableFrom, node } = item;
      result[item.node.id] = { availableTo, availableFrom, ...node };
    });
  }
  return result;
};

export const transformNestedObjectToString = (currentNode) => {
  return Object.keys(currentNode).reduce((acc, cur) => {
    if (typeof currentNode[cur] === 'object') {
      transformNestedObjectToString(currentNode[cur]);
    } else {
      acc += `${currentNode[cur]} `;
    }
    return acc;
  }, '');
};

export const transformedDATA_PARENTS = transformNestedObjectToString(DATA_PARENTS);

export const calcNewUnreadState = (data) => {
  const unseenInChannel = {};
  const unseenPlaylistMapInChannel = {};
  const unseenPlaylistMapInChannelNotification = {};
  const unseenPlaylistManagerMapInAll = {};
  const unseenPlaylistManagerMapInAllNotification = {};
  const unseenPagesManagerMap = {};
  const unseenPagesManagerMapNotification = {};
  const unseenInvite = {};
  const unseenInbox = {};
  const unseenOutbox = {};
  data.unSeenPlaylistToSharing.forEach((playlistManager) => {
    unseenPlaylistManagerMapInAll[playlistManager.id] = {
      type: 'sharing',
      playlistId: playlistManager.editPlaylist.id,
    };
  });
  data.playlistOutNotificationUnreadID.forEach((id) => {
    unseenOutbox[id] = true;
  });
  data.unSeenPlaylistToSharingLastMonth.forEach((playlistManager) => {
    unseenPlaylistManagerMapInAllNotification[playlistManager.id] = {
      type: 'sharing',
      playlistId: playlistManager.editPlaylist.id,
    };
    unseenInbox[playlistManager.id] = true;
  });

  data.unseenPagesToSharing.forEach((page) => {
    unseenPagesManagerMap[page.id] = true;
  });


  data.unseenPagesToSharingLastMonth.forEach((page) => {
    unseenPagesManagerMapNotification[page.id] = true;
    unseenInbox[page.id] = true;
  });
  data.channelSubscriptionConnection?.edges?.forEach((edge) => {
    unseenInvite[edge.node?.id] = true;
    unseenInbox[edge.node?.id] = true;
  });
  data.unSeenPlaylistChannel.forEach((playlistManager) => {
    unseenPlaylistMapInChannel[playlistManager.id] = true;
    playlistManager.inChannel.forEach((channel) => {
      if (!unseenInChannel[channel.id]) {
        unseenInChannel[channel.id] = {
          [playlistManager.id]: {
            playlistId: playlistManager.editPlaylist.id,
          },
        };
      } else if (!unseenInChannel[channel.id][playlistManager.id]) {
        unseenInChannel[channel.id][playlistManager.id] = {
          playlistId: playlistManager.editPlaylist.id,
        };
      }
    });
  });
  data.unSeenPlaylistChannelLastMonth.forEach((playlistManager) => {
    unseenInbox[playlistManager.id] = true;
    unseenPlaylistMapInChannelNotification[playlistManager.id] = true;
  });
  data.updatePlaylistChannelLastMonth.forEach((playlistManager) => {
    unseenInbox[playlistManager.id] = true;
    unseenPlaylistMapInChannelNotification[playlistManager.id] = true;
    unseenPlaylistMapInChannel[playlistManager.id] = true;
  });
  const newState = {
    unseenInChannel,
    unseenPlaylistMapInChannel,
    unseenPlaylistManagerMapInAll,
    unseenPagesManagerMap,
    unseenInbox,
    unseenPagesManagerMapNotification,
    unseenPlaylistManagerMapInAllNotification,
    unseenPlaylistMapInChannelNotification,
    unseenInvite,
    unseenOutbox,
  };
  return newState;
};


function isObject(variable) {
  return variable !== null && typeof variable === 'object' && !Array.isArray(variable);
}
function compareObjectKeys(obj1, obj2) {
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();
  return JSON.stringify(keys1) === JSON.stringify(keys2);
}

// Function to compare two objects based on their keys
export function compareObjects(oldState, newDate) {
  if (!isObject(oldState) || !isObject(newDate)) {
    return false;
  }

  const keysNewDate = Object.keys(newDate);

  for (const type of keysNewDate) {
    const oldDataUnreadIn = oldState[type];
    const newDataUnreadIn = newDate[type];
    if (isObject(oldDataUnreadIn) && isObject(newDataUnreadIn)) {
      // Check the keys of the objects are different
      if (!compareObjectKeys(oldState[type], newDate[type])) {
        return true;
      }
    }
  }

  return false;
}
