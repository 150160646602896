export const DateRangeMark4SharedPlaylist = {
  OPEN_TO_READ: 'OPEN_TO_READ',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  CUSTOM_TIME_RANGE: 'CUSTOM_TIME_RANGE',
};

export const DateRangeMark4SharedPlaylistText = {
  OPEN_TO_READ: 'Open to Read',
  DAILY: 'Only 24 Hours',
  WEEKLY: 'One Week',
  CUSTOM_TIME_RANGE: 'Select a Date Range',
};
