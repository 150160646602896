import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CheckedSvg } from '../../images/icons/checked.svg';
import { ReactComponent as PlusSvg } from '../../images/icons/plus_8.svg';
import useComponentVisible from '../../utils/hooks/useComponentVisible';

import DropDownCover from './cover/ExtraColorsDropDown';
import {
  ColorPicker,
  HalfCircle,
} from './cover/styled';
import { folderColors } from '../../utils/constants';

const ColorsWrapper = styled.div`
  display: flex;
  width: 132px;
  height: 22px;
  position: absolute;
  gap: 6px;
  top: 151px;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
`;


const ExtraColorsButton = styled.div`
  height: 19px;
  width: 19px;
  background-color: white;
  transition: all ease-in-out 0.2s;

  border-radius: 50%;
  border: 1px solid #ECE2E2;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg{
    height: 8px;
    width: 8px;
     path {
      stroke: #242633;
    }
  }
`;

const ExtraColorsButtonWrapper = styled.div`
  min-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover ${ExtraColorsButton} {
    width: 24px;
    height: 24px;
    background-color: #FFEEBF;
    border-color: #FFC72C;
  }
`;
const FolderColorPicker = ({ activeColor, setCurrentColor, clearCoverByPickColor, isCheckDisplayed }) => {
  const dataParentDropDown = 'dataParentDropDown';

  const [ref, isComponentVisible, setIsComponentVisible] = useComponentVisible(
    false,
    dataParentDropDown,
  );


  const pickColor = (e, color) => {
    e.stopPropagation();
    clearCoverByPickColor();
    setCurrentColor(color);
    setIsComponentVisible(false);
  };

  const openModal = (e) => {
    e.stopPropagation();
    setIsComponentVisible(true);
  };

  return (
    <ColorsWrapper>
      {Object.keys(folderColors.main).map(color => (
        <ColorPicker onClick={(e) => pickColor(e, color)} key={color}>
          <HalfCircle color={folderColors.main[color].light} />
          <HalfCircle color={folderColors.main[color].dark} isRightSide />
          {color === activeColor && isCheckDisplayed && <CheckedSvg />}
        </ColorPicker>
      ))}
      <ExtraColorsButtonWrapper>
        <ExtraColorsButton onClick={openModal}>
          <PlusSvg />
        </ExtraColorsButton>
      </ExtraColorsButtonWrapper>
      {isComponentVisible && (
        <DropDownCover
          dataParentDropDown={dataParentDropDown}
          nestedRef={ref}
          pickColor={pickColor}
          activeColor={activeColor}
        />
      )}
    </ColorsWrapper>
  );
};

export default FolderColorPicker;
